import * as React from "react";

import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { connect } from "react-redux";
import { resetAlert } from "Redux/action/alert.action";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function NotificationPopup(props) {
  const [open, setOpen] = React.useState(true);
  const { type, message } = props.alert;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  setTimeout(() => {
    props.dispatch(resetAlert());
  }, 2000);

  return (
    <>
      {type === "" ? null : (
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
              {message}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
  };
};

export default connect(mapStateToProps)(NotificationPopup);
