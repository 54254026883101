import { StlViewer } from 'react-stl-viewer'
import { useEffect } from 'react'
import useFullPageLoader from 'Hooks/useFullPageLoader'

const STLFileViewer = ({ fileUrl }) => {
  const [loader, setLoader, resetLoader] = useFullPageLoader()

  useEffect(() => {
    setLoader()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <StlViewer
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer'
        }}
        orbitControls={true}
        shadows={true}
        url={fileUrl}
        onFinishLoading={() => resetLoader()}
      />
      {loader}
    </>
  )
}

export default STLFileViewer
