import { getMessaging } from "firebase/messaging";
import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyC7qraHWi7A4b_mgvYteLVDI6F_5soWF7M",
  authDomain: "amnius-81329.firebaseapp.com",
  projectId: "amnius-81329",
  storageBucket: "amnius-81329.appspot.com",
  messagingSenderId: "884794453858",
  appId: "1:884794453858:web:22db7f22825068a7b6863b",
};

export const initializeFirebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(initializeFirebaseApp);
