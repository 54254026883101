import "./RequestTransferOut.css";

import React, { useState } from "react";

import { createTransferOutRequest } from "Config/API/update";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const RequestTransferOutPopup = ({ hide, bankDetails }) => {
  const token = localStorage.getItem("token");
  const general_info = useSelector((state) => state.authenticationReducer);
  const organization_name = useSelector(
    (state) => state.renderGeneralInfo?.organization_name
  );
  const credit = useSelector((state) => state.getStripeCreditBalanceReducer);
  const [creditPoints, setCreditPoints] = useState("");
  const [comments, setComments] = useState("");
  const [creditError, setCreditError] = useState("");
  const [showCreditError, setShowCreditError] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showCreditError) return;
    if (loading) return;

    setLoading(true);
    const createTransferOutRequestResponse = createTransferOutRequest(
      token,
      general_info?.name,
      general_info?.email,
      bankDetails[0]?.accountNumber,
      creditPoints,
      bankDetails[0]?.routingNumber,
      comments
    );

    createTransferOutRequestResponse.then((res) => {
      setLoading(false);
      if (res?.status) {
        if (
          res?.message ===
          "Transfer Out Request Already Submitted. Request status is pending"
        ) {
          toast(res?.message, { type: "info" });
        } else {
          toast(res?.message, { type: "success" });
          hide(false);
        }
      } else {
        toast(res?.message, { type: "error" });
      }
    });
  };

  const handleCreditChange = (value) => {
    setCreditPoints(value);
    if (!value > 0) {
      setShowCreditError(false);
      setCreditError("");
      return;
    }
    if (value > credit - 100) {
      setShowCreditError(true);
      setCreditError(`Entered Credits cannot be greater than ${credit - 100}`);
    } else if (value < 0) {
      setShowCreditError(true);
      setCreditError("Credit cannot be less than 0");
    } else {
      setShowCreditError(false);
      setCreditError("");
    }
  };

  return (
    <>
      <div className="request-transfer-out-parent">
        <form
          className="request-transfer-out-form-body"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="request-transfer-out-heading">
            <p>Request Transfer Out</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="request-transfer-out-content">
            <input
              type="text"
              placeholder="User Name*"
              value={general_info?.name}
              readOnly
              style={{ backgroundColor: "#f1f2f6" }}
              required
            />
            <input
              type="text"
              placeholder="Email*"
              value={general_info?.email}
              readOnly
              style={{ backgroundColor: "#f1f2f6" }}
              required
            />
            <input
              type="text"
              placeholder="Organization*"
              value={organization_name}
              readOnly
              style={{ backgroundColor: "#f1f2f6" }}
              required
            />
            <label>Bank Details</label>
            <input
              type="number"
              value={bankDetails[0]?.accountNumber}
              placeholder="Account Number*"
              readOnly
              style={{ backgroundColor: "#f1f2f6" }}
              required
            />
            <input
              type="text"
              value={bankDetails[0]?.routingNumber}
              placeholder="Routing Number*"
              readOnly
              style={{ backgroundColor: "#f1f2f6" }}
              required
            />
            <input
              type="number"
              placeholder="Credit Points*"
              value={creditPoints}
              onChange={(e) => handleCreditChange(e.target.value.trim())}
              required
            />
            {showCreditError && (
              <div className="request-transfer-out-credit-error">
                {creditError}
              </div>
            )}
            <textarea
              value={comments}
              placeholder="Comments*"
              onChange={(e) => setComments(e.target.value.trimStart())}
              required
            />
            <button type="submit">Submit</button>
            <p className="request-mandatory-filed">* Mandatory Field</p>
          </div>
        </form>
      </div>
    </>
  );
};

export default RequestTransferOutPopup;
