import "./RefundRequest.css";

import React, { useState } from "react";

import { raiseRefundRequest } from "Config/API/action";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const RefundRequest = ({ hide, selectedRowData, getTransactionData }) => {
  const token = localStorage.getItem("token");
  const general_info = useSelector((state) => state.authenticationReducer);
  const organization_name = useSelector((state) => state.renderGeneralInfo);
  const creditRefundLimit = Math.abs(selectedRowData?.credit);

  // fields to fill
  const [requestorOrganization, setRequestorOrganization] = useState(
    organization_name?.organization_name || ""
  );
  const [requestorName, setRequestorName] = useState(general_info?.name || "");
  const [transactionId, setTransactionId] = useState(
    selectedRowData?.transactionId
  );
  const [credits, setCredits] = useState(0);
  const [refundReason, setRefundReason] = useState("");
  const [showCreditError, setShowCreditError] = useState(false);
  const [showOtherTextBox, setShowOtherTextBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showCreditError) return;
    if (loading) return;
    setLoading(true);

    const raise_request_status = raiseRefundRequest(
      token,
      credits,
      selectedRowData?.transactionTo,
      transactionId,
      refundReason,
      selectedRowData?.balanceId
    );

    raise_request_status.then((res) => {
      setLoading(false);
      if (res?.status) {
        toast(res?.message, { type: "success" });
        hide(false);
        getTransactionData();
      } else {
        toast(res?.message, { type: "error" });
      }
    });
  };

  const handleCreditChange = (e) => {
    setCredits(e.target.value);
    if (e.target.value > creditRefundLimit) {
      return setShowCreditError(true);
    } else return setShowCreditError(false);
  };

  const handleChangeRefundReason = (e) => {
    if (e.target.value !== "Other") {
      setRefundReason(e.target.value);
      setShowOtherTextBox(false);
    } else {
      setRefundReason("");
      setShowOtherTextBox(true);
    }
  };

  return (
    <>
      <div className="refund-request-parent">
        <div className="refund-request-main">
          <form
            className="refund-request-form-body"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="refund-request-heading">
              <p>Refund Request Form</p>
              <div className="rename-cross" onClick={() => hide(false)}>
                X
              </div>
            </div>
            <div className="refund-request-wrapper">
              {requestorOrganization?.toString() !== "" ? (
                <div className="refund-request-fields">
                  <label htmlFor="organization">
                    Requestor Organization<span>*</span>:
                  </label>

                  <input
                    style={{ backgroundColor: "#f1f2f6" }}
                    type="text"
                    id="organization"
                    value={requestorOrganization}
                    readOnly
                    required
                  />
                </div>
              ) : (
                <div className="refund-request-fields">
                  <label htmlFor="organization">
                    Requestor Organization<span>*</span>:
                  </label>

                  <input
                    type="text"
                    id="organization"
                    value={requestorOrganization}
                    onChange={(e) => {
                      setRequestorOrganization(e.target.value);
                    }}
                    required
                  />
                </div>
              )}

              <div className="refund-request-fields">
                <label htmlFor="contact-name">
                  Requestor Name<span>*</span>:
                </label>
                <input
                  style={{ backgroundColor: "#f1f2f6" }}
                  type="text"
                  value={requestorName}
                  id="contact-name"
                  onChange={(e) => {
                    setRequestorName(e.target.value);
                  }}
                  readOnly
                  required
                />
              </div>

              <div className="refund-request-fields">
                <label htmlFor="contact-email">
                  Transaction ID<span>*</span>:
                </label>
                <input
                  style={{ backgroundColor: "#f1f2f6" }}
                  type="text"
                  value={transactionId}
                  id="contact-email"
                  onChange={(e) => {
                    setTransactionId(e.target.value);
                  }}
                  readOnly
                  required
                />
              </div>

              <div className="refund-request-fields">
                <label htmlFor="credit">
                  Credits<span>*</span>:
                </label>
                <input
                  type="number"
                  id="credits"
                  placeholder="Enter Credits To Be Refunded"
                  min={0.01}
                  step="0.01"
                  onChange={(e) => handleCreditChange(e)}
                  required
                />
              </div>

              {showCreditError && (
                <div className="refund-credit-error">
                  {`Credits cannot be greater than ${creditRefundLimit}`}
                </div>
              )}

              <div className="refund-request-fields">
                <label htmlFor="refund-reason">
                  Reason for Request<span>*</span>:
                </label>
                <div
                  className="refund-request-radio-button"
                  onChange={(e) => handleChangeRefundReason(e)}
                >
                  <input
                    type="radio"
                    value="Dropped the call earlier"
                    name="refund-reason"
                    required
                  />
                  <label>Dropped the call earlier</label>
                  <input
                    type="radio"
                    value="Extra credits deducted"
                    name="refund-reason"
                  />
                  <label>Extra credits deducted</label>
                  <input type="radio" value="Other" name="refund-reason" />
                  <label>Other</label>
                </div>
              </div>

              {showOtherTextBox && (
                <div className="refund-credit-other-text-box">
                  <textarea
                    id="refund-reason"
                    rows="3"
                    placeholder="Enter Refund Request Reason"
                    onChange={(e) => setRefundReason(e.target.value)}
                    required
                  ></textarea>
                </div>
              )}

              <div className="refund-request-fields">
                <button type="submit">Raise Request</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RefundRequest;
