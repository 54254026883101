import React from "react";
import STDashboard from "../PureComponents/STDashboard";

const RenderSTDashboard = () => {
  return (
    <>
      <STDashboard />
    </>
  );
};

export default RenderSTDashboard;
