import "./AddPortfolioAndWorkBook.css";

import React, { useState } from "react";

import { createPortfolioAndWorkbookFolders } from "Config/API/update";
import { toast } from "react-toastify";

const AddPortfolioAndWorkBook = ({
  hide,
  classify,
  getPortfolio,
  portfolioId,
  folderKey,
  navigate,
  bookmarkLength,
  subType,
}) => {
  const token = localStorage.getItem("token");
  const [name, setName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const response = createPortfolioAndWorkbookFolders(
      token,
      name,
      classify,
      portfolioId ? portfolioId : 0,
      folderKey,
      subType
    );
    response.then((res) => {
      if (res.status) {
        toast(res.message, {
          type: "success",
        });
        hide(false);
        if (subType === "workbookFile")
          return navigate("/workbook-editing", {
            state: { data: res?.data[0] },
          });
        getPortfolio();
      } else
        toast(res.message, {
          type: "error",
        });
    });
  };

  return (
    <div className="add-portfolio-and-workbook-parent">
      <div className="add-portfolio-and-workbook-main">
        <form
          className="add-portfolio-and-workbook-form-body"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="add-portfolio-and-workbook-heading">
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="add-portfolio-and-workbook-wrapper">
            <div className="add-portfolio-and-workbook-fields">
              <label style={{ textTransform: "capitalize" }}>
                Create New {classify}
              </label>
            </div>

            <div className="add-portfolio-and-workbook-fields">
              <input
                type="text"
                required
                autoFocus
                placeholder={`Enter name of ${classify} here`}
                value={name}
                onChange={(e) => setName(e.target.value.trimStart())}
              />
            </div>

            <div className="add-portfolio-and-workbook-fields">
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPortfolioAndWorkBook;
