import CreateChatRoom from "../PureComponents/CreateChatRoom";
import React from "react";

const RenderCreateInternalChatRoom = ({
  selectedTeamMember,
  setSelectedTeamMember,
  popup,
  hide,
  availableMember,
  videoCallCreatorAdmin,
  toast,
}) => {
  return (
    <>
      <CreateChatRoom
        selectedTeamMember={selectedTeamMember}
        setSelectedTeamMember={setSelectedTeamMember}
        popup={popup}
        hide={hide}
        availableMember={availableMember}
        videoCallCreatorAdmin={videoCallCreatorAdmin}
        toast={toast}
      />
    </>
  );
};

export default RenderCreateInternalChatRoom;
