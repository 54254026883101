import "./SuperAdminStorageDashboard.css";

import { Button, useMediaQuery } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { getAllOrgStorageData, getStorageData } from "Config/API/get";

import ApiLoader from "Components/Loader/ApiLoader";
import { ConvertKiloByteToGigaByte } from "Constants/Functions";
import Headers from "Components/Header/Headers";
import ManageStoragePlanPopup from "Components/Popup/ManageStoragePlanPopup/ManageStoragePlanPopup";
import Navigation from "Components/SideNavBar/Navigation";
import blank from "Media/blank-profile-picture.png";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useSelector } from "react-redux";

const SuperAdminStorageDashboard = () => {
  const token = localStorage.getItem("token");
  const isRadiusDecreaseRequired = useMediaQuery("(max-width:1320px)");
  const isRadiusDecreaseRequired2 = useMediaQuery("(max-width:960px)");
  const isPieChartHeightDecreaseRequired = useMediaQuery("(max-width:600px)");
  const isRadiusDecreaseRequired3 = useMediaQuery("(max-width:600px)");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const organizationStorageData = useSelector(
    (state) => state.getOrganizationStorageDataReducer
  );
  const totalStorageAvailableInGb = ConvertKiloByteToGigaByte(
    organizationStorageData?.totalAvailableSize
  );
  const totalStorageUsedInGb = ConvertKiloByteToGigaByte(
    organizationStorageData?.usedSize
  );
  const toatlStorageUsedPercentage = (
    (totalStorageUsedInGb * 100) /
    totalStorageAvailableInGb
  )?.toFixed(2);

  const usersStorage =
    (organizationStorageData?.users * 100) /
    organizationStorageData?.totalAvailableSize;
  const emptyStorage =
    100 -
    (organizationStorageData?.usedSize * 100) /
      organizationStorageData?.totalAvailableSize;
  const portfolioAndWorkbookStorage =
    (organizationStorageData?.portfolio * 100) /
    organizationStorageData?.totalAvailableSize;
  const fileAndFolderStorage =
    (organizationStorageData?.fileMangement * 100) /
    organizationStorageData?.totalAvailableSize;
  const serviceTicketStorage =
    (organizationStorageData?.serviceTicket * 100) /
    organizationStorageData?.totalAvailableSize;
  const chatAndDataStorage =
    (organizationStorageData?.chat * 100) /
    organizationStorageData?.totalAvailableSize;

  const storageDataArray = [
    {
      name: " ",
      value: usersStorage > 0 && usersStorage < 1 ? 1 : usersStorage,
    },
    { name: " ", value: emptyStorage },
    {
      name: " ",
      value:
        portfolioAndWorkbookStorage > 0 && portfolioAndWorkbookStorage < 1
          ? 1
          : portfolioAndWorkbookStorage,
    },
    {
      name: " ",
      value:
        fileAndFolderStorage > 0 && fileAndFolderStorage < 1
          ? 1
          : fileAndFolderStorage,
    },
    {
      name: " ",
      value:
        serviceTicketStorage > 0 && serviceTicketStorage < 1
          ? 1
          : serviceTicketStorage,
    },
    {
      name: " ",
      value:
        chatAndDataStorage > 0 && chatAndDataStorage < 1
          ? 1
          : chatAndDataStorage,
    },
  ];
  const COLORS = [
    "#EDA74C",
    "#EFEFEF",
    "#6890EE",
    "#87D863",
    "#ECDC47",
    "#E1A4A4",
  ];

  const storageUsageBreakUpArray = [
    {
      name: "Free Storage",
      color: "#EFEFEF",
      borderColor: "#D1D1D1",
      usedStorage: (totalStorageAvailableInGb - totalStorageUsedInGb).toFixed(
        2
      ),
      usedPercentage: (100 - toatlStorageUsedPercentage).toFixed(2),
    },
    {
      name: "Users & Org",
      color: "#EDA74C",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.users) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.users) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(organizationStorageData?.users)?.toFixed(
              2
            ),
      usedPercentage:
        usersStorage > 0 && usersStorage < 0.01
          ? 0.01
          : usersStorage?.toFixed(2),
    },
    {
      name: "Chat & Data",
      color: "#E1A4A4",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.chat) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.chat) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(organizationStorageData?.chat)?.toFixed(
              2
            ),
      usedPercentage:
        chatAndDataStorage > 0 && chatAndDataStorage < 0.01
          ? 0.01
          : chatAndDataStorage?.toFixed(2),
    },
    {
      name: "Service Ticketing",
      color: "#ECDC47",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.serviceTicket) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.serviceTicket) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              organizationStorageData?.serviceTicket
            )?.toFixed(2),
      usedPercentage:
        serviceTicketStorage > 0 && serviceTicketStorage < 0.01
          ? 0.01
          : serviceTicketStorage?.toFixed(2),
    },
    {
      name: "Files & Folders",
      color: "#87D863",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.fileMangement) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.fileMangement) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              organizationStorageData?.fileMangement
            )?.toFixed(2),
      usedPercentage:
        fileAndFolderStorage > 0 && fileAndFolderStorage < 0.01
          ? 0.01
          : fileAndFolderStorage?.toFixed(2),
    },
    {
      name: "Portfolio & Workbook",
      color: "#6890EE",
      usedStorage:
        ConvertKiloByteToGigaByte(organizationStorageData?.portfolio) > 0 &&
        ConvertKiloByteToGigaByte(organizationStorageData?.portfolio) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              organizationStorageData?.portfolio
            )?.toFixed(2),
      usedPercentage:
        portfolioAndWorkbookStorage > 0 && portfolioAndWorkbookStorage < 0.01
          ? 0.01
          : portfolioAndWorkbookStorage?.toFixed(2),
    },
  ];

  const pageLoadingArray = ["", "", "", "", "", "", "", ""];

  const [showUpgradePlanPopup, setShowUpgradePlanPopup] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [selectedOrgStorageData, setSelectedOrgStorageData] = useState({});
  const [allOrgStorageOddData, setAllOrgStorageOddData] = useState([]);
  const [allOrgStorageEvenData, setAllOrgStorageEvenData] = useState([]);

  const selectedOrgStorageUsageBreakUpArray = [
    {
      name: "Free Storage",
      usedStorage: (
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.totalAvailableSize) -
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.usedSize)
      ).toFixed(2),
      usedPercentage:
        100 -
        ((selectedOrgStorageData?.usedSize * 100) /
          selectedOrgStorageData?.totalAvailableSize >
          0 &&
        (selectedOrgStorageData?.usedSize * 100) /
          selectedOrgStorageData?.totalAvailableSize <
          0.01
          ? 0.01
          : (
              (selectedOrgStorageData?.usedSize * 100) /
              selectedOrgStorageData?.totalAvailableSize
            )?.toFixed(2)),
    },
    {
      name: "Users & Org",
      usedStorage:
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.users) > 0 &&
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.users) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(selectedOrgStorageData?.users)?.toFixed(
              2
            ),
      usedPercentage:
        (selectedOrgStorageData?.users * 100) /
          selectedOrgStorageData?.totalAvailableSize >
          0 &&
        (selectedOrgStorageData?.users * 100) /
          selectedOrgStorageData?.totalAvailableSize <
          0.01
          ? 0.01
          : (
              (selectedOrgStorageData?.users * 100) /
              selectedOrgStorageData?.totalAvailableSize
            )?.toFixed(2),
    },
    {
      name: "Chat & Data",
      usedStorage:
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.chat) > 0 &&
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.chat) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(selectedOrgStorageData?.chat)?.toFixed(2),
      usedPercentage:
        (selectedOrgStorageData?.chat * 100) /
          selectedOrgStorageData?.totalAvailableSize >
          0 &&
        (selectedOrgStorageData?.chat * 100) /
          selectedOrgStorageData?.totalAvailableSize <
          0.01
          ? 0.01
          : (
              (selectedOrgStorageData?.chat * 100) /
              selectedOrgStorageData?.totalAvailableSize
            )?.toFixed(2),
    },
    {
      name: "Service Ticketing",
      usedStorage:
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.serviceTicket) > 0 &&
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.serviceTicket) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              selectedOrgStorageData?.serviceTicket
            )?.toFixed(2),
      usedPercentage:
        (selectedOrgStorageData?.serviceTicket * 100) /
          selectedOrgStorageData?.totalAvailableSize >
          0 &&
        (selectedOrgStorageData?.serviceTicket * 100) /
          selectedOrgStorageData?.totalAvailableSize <
          0.01
          ? 0.01
          : (
              (selectedOrgStorageData?.serviceTicket * 100) /
              selectedOrgStorageData?.totalAvailableSize
            )?.toFixed(2),
    },
    {
      name: "Files & Folders",
      usedStorage:
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.fileMangement) > 0 &&
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.fileMangement) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              selectedOrgStorageData?.fileMangement
            )?.toFixed(2),
      usedPercentage:
        (selectedOrgStorageData?.fileMangement * 100) /
          selectedOrgStorageData?.totalAvailableSize >
          0 &&
        (selectedOrgStorageData?.fileMangement * 100) /
          selectedOrgStorageData?.totalAvailableSize <
          0.01
          ? 0.01
          : (
              (selectedOrgStorageData?.fileMangement * 100) /
              selectedOrgStorageData?.totalAvailableSize
            )?.toFixed(2),
    },
    {
      name: "Portfolio & Workbook",
      usedStorage:
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.portfolio) > 0 &&
        ConvertKiloByteToGigaByte(selectedOrgStorageData?.portfolio) < 0.01
          ? 0.01
          : ConvertKiloByteToGigaByte(
              selectedOrgStorageData?.portfolio
            )?.toFixed(2),
      usedPercentage:
        (selectedOrgStorageData?.portfolio * 100) /
          selectedOrgStorageData?.totalAvailableSize >
          0 &&
        (selectedOrgStorageData?.portfolio * 100) /
          selectedOrgStorageData?.totalAvailableSize <
          0.01
          ? 0.01
          : (
              (selectedOrgStorageData?.portfolio * 100) /
              selectedOrgStorageData?.totalAvailableSize
            )?.toFixed(2),
    },
  ];

  useEffect(() => {
    getAllOrganizationStorageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllOrganizationStorageData = () => {
    setIsPageLoading(true);
    const allOrgStorageDataApiResponse = getAllOrgStorageData(token);
    allOrgStorageDataApiResponse.then((res) => {
      setIsPageLoading(false);
      if (res?.data?.status) {
        const evenData = res?.data?.data?.filter((data, index) => {
          return index % 2 === 0;
        });
        setAllOrgStorageEvenData(evenData);

        const oddData = res?.data?.data?.filter((data, index) => {
          return index % 2 !== 0;
        });
        setAllOrgStorageOddData(oddData);
      } else toast(res?.data?.message, { type: "error" });
    });
  };

  const getSelectedOrganizationStorageData = (orgId) => {
    setIsloading(true);
    const getStorageDataApiResponse = getStorageData(token, orgId);
    getStorageDataApiResponse.then((res) => {
      setIsloading(false);
      if (res?.data?.status) {
        setSelectedOrgStorageData(res?.data?.data);
      } else toast(res?.data?.message, { type: "error" });
    });
  };

  return (
    <>
      <div className="super-admin-storage-dashboard-main">
        <div className="super-admin-storage-dashboard-header">
          <Headers />
        </div>

        <div className="super-admin-storage-dashboard-navigation">
          <Navigation />
        </div>

        <div className="super-admin-storage-dashboard-wrapper">
          <div className="super-admin-storage-dashboard-wrapper-content">
            <aside>
              <header>
                <label>Total Data Usage Chart</label>
                <Button
                  variant="contained"
                  style={{
                    background: "rgb(92, 161, 39)",
                  }}
                  onClick={() => setShowUpgradePlanPopup(true)}
                >
                  MANAGE PLAN
                </Button>
              </header>
              <ResponsiveContainer
                width="100%"
                height={
                  isPieChartHeightDecreaseRequired
                    ? 320
                    : isRadiusDecreaseRequired
                    ? 400
                    : 460
                }
              >
                <PieChart>
                  <Pie
                    data={storageDataArray}
                    innerRadius={
                      isRadiusDecreaseRequired3
                        ? 90
                        : isRadiusDecreaseRequired2
                        ? 105
                        : isRadiusDecreaseRequired
                        ? 110
                        : 130
                    }
                    dataKey="value"
                  >
                    {storageDataArray.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div className="super-admin-storage-dashboard-pie-chat-center-label">
                <label>{toatlStorageUsedPercentage}%</label>
                <label>DATA USED</label>
              </div>
              <div className="super-admin-storage-dashboard-aside-body-content-cards-container">
                {storageUsageBreakUpArray.map((item) => {
                  return (
                    <div className="super-admin-storage-dashboard-aside-body-content-cards">
                      <div
                        className="super-admin-storage-dashboard-cards-color"
                        style={{
                          backgroundColor: item.color,
                          borderColor: item.borderColor
                            ? item.borderColor
                            : item.color,
                        }}
                      />
                      <span>
                        {item.name}: {item.usedStorage} GB /{" "}
                        {item.usedPercentage} %
                      </span>
                    </div>
                  );
                })}
              </div>
            </aside>
            <section>
              <header>
                <label>All Organizations Usage Breakup</label>
              </header>
              <div className="super-admin-storage-dashboard-section-body-content-cards-container">
                {!isPageLoading ? (
                  <>
                    <div className="super-admin-storage-dashboard-section-body-first-content">
                      {allOrgStorageEvenData.map((item) => {
                        return (
                          <div className="super-admin-storage-dashboard-section-body-content-cards">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                padding: "0 15px",
                              }}
                            >
                              <img
                                src={item?.logo ? item?.logo : blank}
                                alt="logo"
                              />
                              <div className="super-admin-storage-dashboard-section-body-content-cards-label-container">
                                <label>{item?.organization_name}</label>
                                <span style={{ color: "#647589" }}>
                                  Total Data Usage:{" "}
                                  <label>
                                    {(
                                      (item?.totalUsedSize * 100) /
                                      item?.totalAvailableSize
                                    )?.toFixed(2)}{" "}
                                    %
                                  </label>
                                </span>
                              </div>
                            </div>
                            {activeTab === item?.id && (
                              <div className="expanded-org-storage-details-container">
                                {!isLoading ? (
                                  selectedOrgStorageUsageBreakUpArray.map(
                                    (item) => {
                                      return (
                                        <p>
                                          {item?.name}: {item?.usedStorage}GB /{" "}
                                          {item?.usedPercentage}%
                                        </p>
                                      );
                                    }
                                  )
                                ) : (
                                  <div className="expanded-org-storage-details-loader">
                                    <ApiLoader
                                      height="35px"
                                      width="35px"
                                      borderWidth="7px"
                                      borderTopWidth="7px"
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            <a
                              onClick={() => {
                                if (activeTab === item?.id)
                                  return setActiveTab("");
                                getSelectedOrganizationStorageData(item?.id);
                                setActiveTab(item?.id);
                              }}
                            >
                              {activeTab === item?.id
                                ? "Close"
                                : "View Breakup"}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                    <div className="super-admin-storage-dashboard-section-body-second-content">
                      {allOrgStorageOddData.map((item) => {
                        return (
                          <div className="super-admin-storage-dashboard-section-body-content-cards">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                padding: "0 15px",
                              }}
                            >
                              <img
                                src={item?.logo ? item?.logo : blank}
                                alt="logo"
                              />
                              <div className="super-admin-storage-dashboard-section-body-content-cards-label-container">
                                <label>{item?.organization_name}</label>
                                <span style={{ color: "#647589" }}>
                                  Total Data Usage:{" "}
                                  <label>
                                    {(
                                      (item?.totalUsedSize * 100) /
                                      item?.totalAvailableSize
                                    )?.toFixed(2)}{" "}
                                    %
                                  </label>
                                </span>
                              </div>
                            </div>
                            {activeTab === item?.id && (
                              <div className="expanded-org-storage-details-container">
                                {!isLoading ? (
                                  selectedOrgStorageUsageBreakUpArray.map(
                                    (item) => {
                                      return (
                                        <p>
                                          {item?.name}: {item?.usedStorage}GB /{" "}
                                          {item?.usedPercentage}%
                                        </p>
                                      );
                                    }
                                  )
                                ) : (
                                  <div className="expanded-org-storage-details-loader">
                                    <ApiLoader
                                      height="35px"
                                      width="35px"
                                      borderWidth="7px"
                                      borderTopWidth="7px"
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            <a
                              onClick={() => {
                                if (activeTab === item?.id)
                                  return setActiveTab("");
                                getSelectedOrganizationStorageData(item?.id);
                                setActiveTab(item?.id);
                              }}
                            >
                              {activeTab === item?.id
                                ? "Close"
                                : "View Breakup"}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  pageLoadingArray?.map((item) => {
                    return (
                      <div
                        style={{ height: "87px" }}
                        className="animation"
                      ></div>
                    );
                  })
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
      {loader}
      {showUpgradePlanPopup && (
        <ManageStoragePlanPopup
          hide={setShowUpgradePlanPopup}
          token={token}
          setLoader={setLoader}
          resetLoader={resetLoader}
        />
      )}
    </>
  );
};

export default SuperAdminStorageDashboard;
