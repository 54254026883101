import { Mention, MentionsInput } from "react-mentions";

import React from "react";
import classNames from "./example.module.css";

const MentionCard = ({ users, message, setMessage }) => {
  return (
    <>
      <MentionsInput
        value={message}
        classNames={classNames}
        a11ySuggestionsListLabel={"Suggested mentions"}
        placeholder="Enter a message..."
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      >
        <Mention
          trigger="@"
          data={users}
          markup={"@[__display__]&&*&&__id__&&*&&"}
          className={classNames.mentions__mention}
        />
      </MentionsInput>
    </>
  );
};

export default MentionCard;
