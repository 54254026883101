import "./UserManagementInformationPopup.css";

import React from "react";

const UserManagementInformationPopup = ({ message, hide, infoHeading }) => {
  return (
    <>
      <nav className="user-management-pop-header">
        <div className="popup-popup confirm-box">
          <div
            className="popup-cross confirm-cross"
            onClick={() => hide(false)}
          >
            X
          </div>
          <p className="info-heading-text">{infoHeading}</p>
          {message.map((item) => {
            return (
              <span
                className={
                  item[0] === "User Manager"
                    ? "info-user"
                    : "usermanagement-info"
                }
              >
                <b>{item[0]}</b> {":"} {item[1]}
              </span>
            );
          })}
        </div>
      </nav>
    </>
  );
};

export default UserManagementInformationPopup;
