import React, { memo } from "react";

import ChatOrganization from "../PureComponents/ChatOrganization";

const RenderChatOrganization = () => {
  return (
    <>
      <ChatOrganization />
    </>
  );
};

export default memo(RenderChatOrganization);
