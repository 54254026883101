import { QRCodeCanvas } from "qrcode.react";
import React from "react";

const QRcode = React.forwardRef(({ path }, ref) => {
  return (
    <div ref={ref}>
      <QRCodeCanvas value={path} />
    </div>
  );
});

export default QRcode;
