import AppointmentOverview from "../PureComponents/AppointmentOverview";
import React from "react";
import { getAppointments } from "Config/API/get";
import { renderAppointmentOverview } from "Redux/action/render.action";
import { useDispatch } from "react-redux";

const RenderAppointmentOverview = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const get_appointment_status = getAppointments(token);
  get_appointment_status.then((res) => {
    dispatch(renderAppointmentOverview(res?.appointment_info));
  });

  return (
    <>
      <AppointmentOverview />
    </>
  );
};

export default RenderAppointmentOverview;
