import React from "react";
import Verification from "../PureComponents/Verification";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { userVerification } from "Config/API/authentication";

const RenderVerification = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  if (token) {
    const verification_status = userVerification(token);
    verification_status.then((res) => {
      if (res?.status) {
        toast(res?.message, {
          type: "success",
        });
        navigate("/login");
      } else {
        toast(res?.message, {
          type: "error",
        });
        navigate("/login");
      }
    });
  }

  return (
    <>
      <Verification />
    </>
  );
};

export default RenderVerification;
