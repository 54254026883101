import { useSelector } from "react-redux";

const useIsAmniusOrganization = () => {
  const orgId = useSelector((state) => state.renderGeneralInfo?.org_id);
  const isOrgAmnius = orgId?.toString() === "-1";

  return isOrgAmnius;
};

export default useIsAmniusOrganization;
