import React from "react";
import SearchBar from "../PureComponents/SearchBar";

const RenderSearchBar = ({ device, searchBarOpen }) => {
  return (
    <>
      <SearchBar device={device} searchBarOpen={searchBarOpen} />
    </>
  );
};

export default RenderSearchBar;
