import "./TranscationActivity.css";

import React, { useEffect, useRef, useState } from "react";

import Headers from "Components/Header/Headers";
import MoneyCreditTransactionReceipt from "Components/Popup/MoneyCreditTransactionReceipt/MoneyCreditTransactionReceipt";
import MoneyCreditTransactionReceiptPdf from "Components/Popup/MoneyCreditTransactionReceiptPdf/MoneyCreditTransactionReceiptPdf";
import Navigation from "Components/SideNavBar/Navigation";
import ReactToPrint from "react-to-print";
import RefundRequest from "Components/Popup/RefundRequest/RefundRequest";
import SearchIcon from "@mui/icons-material/Search";
import detailsIcon from "Media/icons/Group 641@2x.png";
import { getTranscationListApi } from "Config/API/get";
import { isMobile } from "react-device-detect";
import printIcon from "Media/icons/Group 640@2x.png";
import refundIcon from "Media/icons/cashback (1)@2x.png";
import refundIconDisabled from "Media/icons/cashbackDisabled.png";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useSelector } from "react-redux";

const TranscationActivity = () => {
  let doneTypingInterval = 1500;
  let typingTimer;
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [transcationList, setTranscationList] = useState("");
  const [filterKeyword, setFilterKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [receiptDetails, setReceiptDetails] = useState("");
  const [refundRequest, setRefundRequest] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const credit = useSelector((state) => state.getStripeCreditBalanceReducer);
  const componentRef = useRef();
  const nullRef = useRef();
  const [selectedRef, setSelectedRef] = useState("");

  useEffect(() => {
    // get transcation info
    getTransactionData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTransactionData = () => {
    setLoader();
    const transcation_status = getTranscationListApi(token, "", "");
    transcation_status.then((res) => {
      resetLoader();
      if (res?.status === 200) {
        setTranscationList(res?.data?.data);
      }
    });
  };

  const search_fuction = (value) => {
    const transcation_status = getTranscationListApi(
      token,
      value,
      filterKeyword
    );
    transcation_status.then((res) => {
      if (res?.status === 200) {
        setTranscationList(res?.data?.data);
      }
    });
  };

  const filter_function = (value) => {
    const transcation_status = getTranscationListApi(
      token,
      searchKeyword,
      value
    );
    transcation_status.then((res) => {
      if (res?.status === 200) {
        setTranscationList(res?.data?.data);
      }
    });
  };

  const openRefundDialog = (data) => {
    setSelectedRowData(data);
    setRefundRequest(true);
  };

  return (
    <>
      <div className="money-credit-transcation-main">
        <div className="money-credit-transcation-main-header">
          <Headers />
        </div>

        <div className="money-credit-transcation-navigation">
          <Navigation />
        </div>
        <div className="money-credit-transcation-wrapper">
          <div className="money-credit-transcation-header">
            Transaction Activity Page
          </div>
          <div className="money-credit-transcation-content">
            <div className="money-credit-transcation-top-part">
              <div className="money-credit-transcation-search">
                <input
                  type="search"
                  placeholder="Search..."
                  onChange={(e) => {
                    clearTimeout(typingTimer);
                    if (e.target.value || e.target.value === "") {
                      typingTimer = setTimeout(() => {
                        search_fuction(e.target.value);
                        setSearchKeyword(e.target.value);
                      }, doneTypingInterval);
                    }
                  }}
                />
                <SearchIcon />
              </div>
              <div className="money-credit-transcation-balance">
                <span>CREDIT BALANCE </span>
                <b>{credit ? credit : 0}</b>
              </div>
              <div className="money-credit-transcation-filter">
                <p>Filter:</p>
                <select
                  onClick={(e) => {
                    filter_function(e.target.value);
                    setFilterKeyword(e.target.value);
                  }}
                >
                  <option value="">All</option>
                  <option value="Credit">Credits</option>
                  <option value="Debit">Debits</option>
                </select>
              </div>
            </div>
            <div className="money-credit-transcation-bottom-part">
              <div className="money-credit-transcation-head-row-wrapper">
                <div
                  className="money-credit-transcation-head-row"
                  style={{ width: "15%" }}
                >
                  DATE
                </div>
                <div
                  className="money-credit-transcation-head-row"
                  style={{ width: "15%" }}
                >
                  TRANSACTION ID
                </div>
                <div
                  className="money-credit-transcation-head-row"
                  style={{ width: "20%" }}
                >
                  DESCRIPTION
                </div>
                <div
                  className="money-credit-transcation-head-row"
                  style={{ width: "30%" }}
                >
                  TRANSACTION WITH
                </div>
                <div
                  className="money-credit-transcation-head-row"
                  style={{ width: "7.5%" }}
                >
                  CREDITS
                </div>
                <div
                  className="money-credit-transcation-head-row"
                  style={{ width: "12.5%" }}
                >
                  ACTIONS
                </div>
              </div>
              <div className="money-credit-transcation-body-row-scroll">
                {transcationList ? (
                  transcationList.length > 0 ? (
                    transcationList?.map((curr, index) => {
                      return (
                        <div
                          className="money-credit-transcation-body-row-wrapper"
                          key={index}
                        >
                          <div
                            className="money-credit-transcation-body-row"
                            style={{ width: "15%" }}
                          >
                            {curr?.dateTime || "-"}
                          </div>
                          <div
                            className="money-credit-transcation-body-row"
                            style={{ width: "15%" }}
                          >
                            {curr?.transactionId || "-"}
                          </div>
                          <div
                            className="money-credit-transcation-body-row"
                            style={{ width: "20%" }}
                          >
                            {curr?.description || "-"}
                          </div>
                          <div
                            className="money-credit-transcation-body-row"
                            style={{ width: "30%" }}
                          >
                            {curr?.transactionWith || "-"}
                          </div>
                          <div
                            className="money-credit-transcation-body-row"
                            style={{ width: "7.5%" }}
                          >
                            {curr?.creditType ? (
                              curr?.creditType?.toString().toLowerCase() ===
                              "credit" ? (
                                <span className="money-credit-transcation-body-credit">
                                  + {curr?.credit || ""}
                                </span>
                              ) : (
                                <span className="money-credit-transcation-body-debit">
                                  - {curr?.credit.replace("-", "") || ""}
                                </span>
                              )
                            ) : (
                              "-"
                            )}
                          </div>
                          <div
                            className="money-credit-transcation-body-row"
                            style={{
                              width: "12.5%",
                            }}
                          >
                            {curr?.creditType?.toString().toLowerCase() ===
                              "debit" &&
                            curr?.refund == null &&
                            curr?.slug === "video_chat" ? (
                              <img
                                src={refundIcon}
                                alt="Refund"
                                onClick={() => openRefundDialog(curr)}
                              />
                            ) : (
                              <img src={refundIconDisabled} alt="Refund" />
                            )}

                            {!isMobile && (
                              <ReactToPrint
                                trigger={() => (
                                  <img src={printIcon} alt="Print" />
                                )}
                                onBeforeGetContent={() =>
                                  setSelectedRef(curr?.transactionId)
                                }
                                onAfterPrint={() => {
                                  setSelectedRef("");
                                }}
                                content={() => componentRef.current}
                              />
                            )}

                            {/* our pdf for each transaction */}
                            <div style={{ display: "none" }}>
                              <div
                                className="print-content-pdf"
                                ref={
                                  selectedRef === curr?.transactionId
                                    ? componentRef
                                    : nullRef
                                }
                              >
                                <MoneyCreditTransactionReceiptPdf
                                  details={curr}
                                />
                              </div>
                            </div>

                            <img
                              src={detailsIcon}
                              alt="Details"
                              onClick={() => {
                                setReceiptDetails(curr);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        fontFamily: "var(--montserrat)",
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: "50px",
                      }}
                    >
                      No transaction Activity found
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      fontFamily: "var(--montserrat)",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "50px",
                    }}
                  >
                    No transaction Activity found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* receipt details popup */}
      {receiptDetails && (
        <MoneyCreditTransactionReceipt
          hide={setReceiptDetails}
          details={receiptDetails}
        />
      )}
      {refundRequest && (
        <RefundRequest
          hide={setRefundRequest}
          selectedRowData={selectedRowData}
          getTransactionData={getTransactionData}
        />
      )}
      {loader}
    </>
  );
};

export default TranscationActivity;
