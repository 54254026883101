import {
  selectCameraStreamByPeerID,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";

import React from "react";

const HostVideoTile = ({ peer, isLocal, mirrorVideo }) => {
  const hmsActions = useHMSActions();
  const videoRef = React.useRef(null);
  const videoTrack = useHMSStore(selectCameraStreamByPeerID(peer.id));

  React.useEffect(() => {
    (async () => {
      if (videoRef.current && videoTrack) {
        if (videoTrack.enabled) {
          await hmsActions.attachVideo(videoTrack.id, videoRef.current);
        } else {
          await hmsActions.detachVideo(videoTrack.id, videoRef.current);
        }
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack]);

  return (
    <div className="host-video-container">
      <video
        ref={videoRef}
        autoPlay={true}
        playsInline
        muted={true}
        className={
          peer?.isLocal && !mirrorVideo
            ? "peer-host-video"
            : "peer-video-host-full"
        }
      ></video>
      <div className="peer-name-host">
        {peer?.isLocal ? `${peer?.name} (You)` : `${peer?.name} (Host)`}
      </div>
      <div className="peer-name-host-multiple-peer">
        {peer?.isLocal ? `${peer?.name} (You)` : `${peer?.name} (Host)`}
      </div>
    </div>
  );
};

export default HostVideoTile;
