import "./SuperAdmin.css";

import React, { useEffect } from "react";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderOrganizationList from "Components/SuperAdmin/SuperAdminData/RenderOrganizationList";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SuperAdmin = () => {
  const role = useSelector((state) => state.authenticationReducer.role);
  const navigate = useNavigate();

  useEffect(() => {
    if (role?.toString() !== "super_admin") {
      navigate("/dashboard");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="superadmin-container">
        <div className="superadmin-header">
          <Headers />
        </div>
        <div className="superadmin-navigation">
          <Navigation />
        </div>
        <div className="superadmin-main">
          <div className="superadmin-wrapper">
            <div className="superadmin-upper">
              <div className="superadmin-head">SuperAdmin</div>
            </div>
            <div className="superadmin-lower">
              <RenderOrganizationList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdmin;
