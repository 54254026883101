import {
  CONNECTED_AMNIUS_USER,
  CURRENT_ORGANIZATION,
  FOLER_AUTOMATION_ID,
  GLOBAL_OPTION_BUTTON,
  HASH_ID,
  RAISE_REQUEST_DATA,
  RAISE_REQUEST_ID,
  CLEAR_REQUEST_ID,
  SELECTED_ORGANIZATION_ST,
  TICKET_ID_DETAIL,
  USER_LIST,
  ASSET_UNIQUE_ID,PART_UNIQUE_ID,ASSET_HASH_ID
  
} from "Config/ActionType/action.type";

export const globalOptionButtonAction = (res) => {
  return {
    type: GLOBAL_OPTION_BUTTON,
    payload: res,
  };
};

export const hashIdAction = (res) => {
  return {
    type: HASH_ID,
    payload: res,
  };
};

export const userListAction = (res) => {
  return {
    type: USER_LIST,
    payload: res,
  };
};

export const currentVisitOrgAction = (res) => {
  return {
    type: CURRENT_ORGANIZATION,
    payload: res,
  };
};

export const currentFolderAutomationAction = (res) => {
  return {
    type: FOLER_AUTOMATION_ID,
    payload: res,
  };
};

export const selectedOrganizationSTAction = (res) => {
  return {
    type: SELECTED_ORGANIZATION_ST,
    payload: res,
  };
};

export const notConnectedAmniusAction = (res) => {
  return {
    type: CONNECTED_AMNIUS_USER,
    payload: res,
  };
};

export const ticketIdAction = (res) => {
  return {
    type: TICKET_ID_DETAIL,
    payload: res,
  };
};

export const raiseRequestIdAction = (res) => {
  return {
    type: RAISE_REQUEST_ID,
    payload: res,
  };
};
export const clearRequestIdAction = (res) => {
  return {
    type: CLEAR_REQUEST_ID,
    payload: res,
  };
};
export const raiseRequestDataAction = (res) => {
  return {
    type: RAISE_REQUEST_DATA,
    payload: res,
  };
};
export const assetuniqueid = (res) => {
  return {
    type:  ASSET_UNIQUE_ID,
    payload: res,
  };
};
export const assethashid = (res) => {
  return {
    type:  ASSET_HASH_ID,
    payload: res,
  };
};
export const partuniqueid = (res) => {
  return {
    type: PART_UNIQUE_ID,
    payload: res,
  };
};