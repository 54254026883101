import ForgetAccount from "../PureComponents/ForgetAccount";
import React from "react";
import { forgetPassword } from "Config/API/authentication";
import { setDialog } from "Redux/action/dialog.action";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const RenderForgetUI = (props) => {
  const dispatch = useDispatch();

  const verifyEmail = (email) => {
    const forget_status = forgetPassword(email);
    forget_status
      .then((res) => {
        if (res?.status) {
          dispatch(setDialog(res?.status, res?.message));
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      })
      .catch(() => toast("Failed! Try again later", { type: "error" }));
  };

  return props.popup ? (
    <>
      <ForgetAccount hide={props?.hide} verify={verifyEmail} />
    </>
  ) : (
    ""
  );
};

export default RenderForgetUI;
