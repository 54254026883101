import "./SelectedAppointmentEventPopup.css";

import { Button, IconButton } from "@mui/material";
import { deleteAppointment, downloadFolder } from "Config/API/action";
import {
  getEventListAction,
  getEventListAppointmentAction,
  getEventListVideoConferenceAction,
} from "Redux/action/get.action";
import { getFullAppointments, getZip } from "Config/API/get";

import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useState } from "react";

const SelectedAppointmentEventPopup = ({
  joinRoom,
  event,
  userId,
  token,
  setLoader,
  resetLoader,
  hide,
  openEditAppointmentPopup,
}) => {
  const dispatch = useDispatch();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const getAllAppointments = () => {
    const appointment_status = getFullAppointments(token, "all");
    appointment_status
      .then((res) => {
        hide(false);
        resetLoader();
        if (res?.status) {
          const filteredVideoConferenceEvent = res?.appointment?.filter(
            (item) => item?.type === "conference"
          );
          const filteredAppointmentEvent = res?.appointment?.filter(
            (item) => item?.type !== "conference"
          );
          dispatch(getEventListAction(res?.appointment));
          dispatch(getEventListAppointmentAction(filteredAppointmentEvent));
          dispatch(
            getEventListVideoConferenceAction(filteredVideoConferenceEvent)
          );
        } else {
          dispatch(getEventListAction([]));
          toast(res?.message, { type: "error" });
        }
      })
      .catch((e) => {
        resetLoader();
        toast("Failed! Try again later", {
          type: "error",
        });
      });
  };

  const deleteSelectedAppointment = (eventId) => {
    setLoader();
    const event_status = deleteAppointment(token, eventId);
    event_status
      .then((res) => {
        if (res?.status) {
          getAllAppointments();
          toast(res?.message, {
            type: "success",
          });
        } else {
          resetLoader();
          toast(res?.message, {
            type: "error",
          });
        }
      })
      .catch((e) => {
        resetLoader();
        toast("Failed! Try Again Later", {
          type: "error",
        });
      });
  };

  return (
    <>
      <div className="selected-appointment-popup-container">
        <div className="selected-appointment-popup-content">
          <div className="selected-appointment-details-heading">
            <p>Appointment Details</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="selected-appointment-popup-body">
            <div className="selected-appointment-body-top">
              <p>
                <b>Title:</b>
                <span>{event?.title}</span>
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                {/* this delete icon will be shown to the one who created the event */}
                {userId?.toString() === event?.org_id?.toString() && (
                  <>
                    <IconButton onClick={() => openEditAppointmentPopup()}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => setShowConfirmPopup(true)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </>
                )}
                {event?.type === "conference" && (
                  <Button
                    variant="contained"
                    style={{
                      background: "rgb(92, 161, 39)",
                      padding: "2px 16px",
                    }}
                    onClick={() => joinRoom(event?.videoChatDetail)}
                  >
                    JOIN
                  </Button>
                )}
              </div>
            </div>
            {event?.type === "conference" &&
              event?.videoChatDetail?.is_billable === "true" && (
                <p>
                  <b>Billing Rate:</b>
                  <span>
                    {event?.videoChatDetail?.billing_rate} credits/minute
                  </span>
                </p>
              )}
            <p>
              <b>Description:</b>
              <span>{event?.desc || "None"}</span>
            </p>
            <p>
              <b>Duration:</b>
              <span>{`${moment(event?.start).format("MM-DD-YYYY")}, ${moment(
                event?.start
              ).format("HH:mm")} to ${moment(event?.end).format(
                "MM-DD-YYYY"
              )}, ${moment(event?.end).format("HH:mm")}`}</span>
            </p>

            {Object.keys(event?.requestor)?.length?.toString() !== "0" ? (
              <p>
                <b>Requestor Details:</b>
                <span style={{ display: "block" }}>
                  {event?.requestor?.username || "No name"}
                </span>
                <span style={{ display: "block" }}>
                  {event?.requestor?.email || "No Email"}
                </span>
                <span style={{ display: "block" }}>
                  {event?.requestor?.phone || "No Phone"}
                </span>
              </p>
            ) : null}

            <p>
              <b>Participants: </b>
              <span>
                {event?.emp?.map((curr, index) => {
                  return curr?.name + ", ";
                })}
              </span>
            </p>

            <p>
              <b>Address: </b>
              <span>{event?.address || "None"}</span>
            </p>

            <p className="folder-div">
              <b>Folder Name: </b>
              <span className="folder-detail">
                {!event?.folder[0]?.folder_name ? (
                  "Not Assigned"
                ) : (
                  <span>
                    <span style={{ display: "block" }}>
                      {event?.folder[0]?.folder_name + " " || "Not Assigned"}
                    </span>
                    <span style={{ display: "block" }}>
                      ({event?.folder[0]?.folder_bookmark || null})
                    </span>
                  </span>
                )}
                {event?.folder[0]?.folder_unique_id &&
                event?.folder[0]?.scope ? (
                  <IconButton
                    onClick={() => {
                      setLoader();
                      const download_status = downloadFolder(
                        token,
                        event?.folder[0]?.folder_unique_id,
                        event?.folder[0]?.scope
                      );
                      download_status.then((res) => {
                        if (res?.status) {
                          const zip_status = getZip(
                            token,
                            event?.folder[0]?.folder_unique_id,
                            event?.folder[0]?.scope,
                            "",
                            ""
                          );
                          zip_status.then((response) => {
                            if (response?.status) {
                              const linkSource = `data:application/octet-stream;base64,${response?.results}`;
                              const downloadLink = document.createElement("a");
                              const fileName =
                                event?.folder[0]?.folder_name + ".zip";
                              downloadLink.href = linkSource;
                              downloadLink.download = fileName;
                              downloadLink.click();
                              toast(response?.message, {
                                type: "success",
                              });
                            } else {
                              toast(response?.message, {
                                type: "error",
                              });
                            }
                          });
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                        resetLoader();
                      });
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                ) : null}
              </span>
            </p>

            <p>
              <b>Tasks List:</b>

              {event.task.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "80px",
                    marginTop: "-16px",
                    gap: "2px",
                  }}
                >
                  {event.task?.map((curr, index) => {
                    return (
                      <span className="event-tasklist" key={index}>
                        {index + 1} {curr}
                      </span>
                    );
                  })}
                </div>
              ) : (
                <span>None</span>
              )}
            </p>
          </div>
        </div>
      </div>
      {showConfirmPopup && (
        <RenderConfirm
          hide={setShowConfirmPopup}
          data={event.event_id}
          status={deleteSelectedAppointment}
          message="delete this appointment?"
        />
      )}
    </>
  );
};

export default SelectedAppointmentEventPopup;
