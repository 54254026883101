import "./Navigation.css";

import React, { useEffect, useRef, useState } from "react";
import {
  setDevicePushNotificationTokenApi,
  userLogout,
} from "Config/API/action";
import { useDispatch, useSelector } from "react-redux";

import AppointIcon from "Media/icons/Date_range_fill.svg";
import FolderIcon from "Media/icons/Folder_file_fill.svg";
import HamburgerIcon from "Media/icons/Icon open-menu.svg";
import HomeIcon from "Media/icons/Home_fill.svg";
import MessageIcon from "Media/icons/Iconly-Bold-Chat.svg";
import PendingRoundedIcon from "@mui/icons-material/PendingRounded";
import PersonIcon from "@mui/icons-material/Person";
import RenderConfirm from "../Popup/Confirm/Container/RenderConfirm";
import ServiceTicketIcon from "Media/icons/ticket (1)@2x.png";
import SignIcon from "Media/icons/Sign_out.svg";
import portfolioFiles from "Media/icons/portfolioFiles.png";
import profilePic from "Media/blank-profile-picture.png";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { signout } from "Redux/action/auth.action";
import useIsAmniusOrganization from "Hooks/useIsAmniusOrganization";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOrgAmnius = useIsAmniusOrganization();
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [hamburgerList, setHamburgerList] = useState(false);
  const general_info = useSelector((state) => state.renderGeneralInfo);
  const loggedInUser = useSelector((state) => state.authenticationReducer);
  const token = localStorage.getItem("token");
  const deviceToken = localStorage.getItem("deviceTokenForPushNotification");
  const chatAlert = useSelector((state) => state.newChatAlertReducer);
  const dropUpRef = useRef(null);
  useOutsideAlerter(dropUpRef);

  const signOutFunction = () => {
    // hit the logout api
    const logout_status = userLogout(token);
    logout_status.then((res) => {
      navigate("/login");
      dispatch(signout());
    });
  };

  const setDevicePushNotificationTokenApiHandler = () => {
    const response = setDevicePushNotificationTokenApi(
      loggedInUser?.id,
      deviceToken,
      "unset",
      ""
    );
    response.then((res) => {
      signOutFunction();
    });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          ref.current.classList.add("hide-dropdown");
          setHamburgerList(false);
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const DesktopNavigation = () => {
    return (
      <div className="navigation-container">
        <div className="navigation-wrapper">
          <div
            className="nav-item user-profile"
            onClick={() => {
              navigate("/organization", {
                state: { data: general_info?.org_id, from: "navigation" },
              });
              dispatch(renderOrganizationInfo({}));
            }}
          >
            <img
              src={general_info?.logo ? general_info?.logo : profilePic}
              alt="Logo"
              width="45px"
              height="45px"
            />
          </div>
          <div
            className="nav-item card-box"
            style={{
              backgroundColor:
                window.location.href?.includes("/dashboard") && "white",
            }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <img src={HomeIcon} alt="Home" />
          </div>
          {loggedInUser?.role?.toString() === "super_admin" ? (
            <>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("/superadmin") && "white",
                }}
                onClick={() => {
                  navigate("/superadmin");
                }}
              >
                <PersonIcon />
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("chat") && "white",
                }}
                onClick={() => {
                  navigate("/chat");
                }}
              >
                <img src={MessageIcon} alt="Chat" />
                {chatAlert > 0 ? (
                  <PendingRoundedIcon className="sms-alert" />
                ) : null}
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    (window.location.href?.includes("portfolio") ||
                      window.location.href?.includes("workbook")) &&
                    "white",
                }}
                onClick={() => {
                  navigate({
                    pathname: `/portfolio/orgId/${
                      isOrgAmnius ? "-1" : general_info?.id
                    }`,
                    search: "?type=portfolio",
                  });
                }}
              >
                <img
                  src={portfolioFiles}
                  alt="Home"
                  style={{ width: "24px", height: "30px" }}
                />
              </div>
            </>
          ) : (
            <>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("file-explorer") && "white",
                }}
                onClick={() => {
                  navigate("/file-explorer");
                }}
              >
                <img src={FolderIcon} alt="Folder" />
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("chat") && "white",
                }}
                onClick={() => {
                  navigate("/chat");
                }}
              >
                <img src={MessageIcon} alt="Chat" />
                {chatAlert > 0 ? (
                  <PendingRoundedIcon className="sms-alert" />
                ) : null}
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("appointment") && "white",
                }}
                onClick={() => {
                  navigate(`/appointment`);
                }}
              >
                <img src={AppointIcon} alt="Appoint" />
              </div>
              {/* <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("service-ticket") && "white",
                }}
                onClick={() => {
                  navigate(`/service-ticket-dashboard`);
                }}
              >
                <img
                  src={ServiceTicketIcon}
                  alt="Appoint"
                  style={{ width: "22.5px", height: "22.5px" }}
                />
              </div> */}
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    (window.location.href?.includes("portfolio") ||
                      window.location.href?.includes("workbook")) &&
                    "white",
                }}
                onClick={() => {
                  navigate({
                    pathname: `/portfolio/orgId/${
                      isOrgAmnius ? "-1" : general_info?.id
                    }`,
                    search: "?type=portfolio",
                  });
                }}
              >
                <img
                  src={portfolioFiles}
                  alt="Home"
                  style={{ width: "24px", height: "30px" }}
                />
              </div>
            </>
          )}

          <div
            className="nav-item card-box"
            onClick={() => {
              setConfirmPopup(true);
            }}
          >
            <img src={SignIcon} alt="Sign out" />
          </div>
        </div>
      </div>
    );
  };

  const MobileNavigation = () => {
    return (
      <div className="navigation-container">
        <div className="navigation-wrapper">
          <div
            className="nav-item user-profile"
            onClick={() => {
              navigate("/organization", {
                state: { data: general_info?.org_id, from: "navigation" },
              });
              dispatch(renderOrganizationInfo({}));
            }}
          >
            <img
              src={general_info?.logo ? general_info?.logo : profilePic}
              alt="Logo"
              width="45px"
              height="45px"
            />
          </div>
          {loggedInUser?.role?.toString() === "super_admin" ? (
            <>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("/superadmin") && "white",
                }}
                onClick={() => {
                  navigate("/superadmin");
                }}
              >
                <PersonIcon />
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("chat") && "white",
                }}
                onClick={() => {
                  navigate("/chat");
                }}
              >
                <img src={MessageIcon} alt="Chat" />
                {chatAlert > 0 ? (
                  <PendingRoundedIcon className="sms-alert" />
                ) : null}
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    (window.location.href?.includes("portfolio") ||
                      window.location.href?.includes("workbook")) &&
                    "white",
                }}
                onClick={() => {
                  navigate({
                    pathname: `/portfolio/orgId/${
                      isOrgAmnius ? "-1" : general_info?.id
                    }`,
                    search: "?type=portfolio",
                  });
                }}
              >
                <img
                  src={portfolioFiles}
                  alt="Home"
                  style={{ width: "24px", height: "30px" }}
                />
              </div>
              <div
                className="nav-item card-box"
                onClick={() => {
                  setConfirmPopup(true);
                }}
              >
                <img src={SignIcon} alt="Sign out" />
              </div>
            </>
          ) : null}

          {loggedInUser?.role?.toString() !== "super_admin" ? (
            <>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("file-explorer") && "white",
                }}
                onClick={() => {
                  navigate("/file-explorer");
                }}
              >
                <img src={FolderIcon} alt="Folder" />
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("chat") && "white",
                }}
                onClick={() => {
                  navigate("/chat");
                }}
              >
                <img src={MessageIcon} alt="Chat" />
                {chatAlert > 0 ? (
                  <PendingRoundedIcon className="sms-alert" />
                ) : null}
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("appointment") && "white",
                }}
                onClick={() => {
                  navigate(`/appointment`);
                }}
              >
                <img src={AppointIcon} alt="Appoint" />
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    window.location.href?.includes("service-ticket") && "white",
                }}
                onClick={() => {
                  navigate(`/service-ticket-dashboard`);
                }}
              >
                <img
                  src={ServiceTicketIcon}
                  alt="Appoint"
                  style={{ width: "22.5px", height: "22.5px" }}
                />
              </div>
              <div
                className="nav-item card-box"
                style={{
                  backgroundColor:
                    (window.location.href?.includes("portfolio") ||
                      window.location.href?.includes("workbook")) &&
                    "white",
                }}
                onClick={() => {
                  navigate({
                    pathname: `/portfolio/orgId/${
                      isOrgAmnius ? "-1" : general_info?.id
                    }`,
                    search: "?type=portfolio",
                  });
                }}
              >
                <img
                  src={portfolioFiles}
                  alt="Home"
                  style={{ width: "24px", height: "30px" }}
                />
              </div>

              <div
                className="nav-item card-box"
                style={{ position: "relative" }}
                ref={dropUpRef}
              >
                <img
                  src={HamburgerIcon}
                  alt="Menu"
                  onClick={() => {
                    setHamburgerList(!hamburgerList);
                  }}
                />
                {hamburgerList && (
                  <div className="nav-item-drop-up">
                    {/* add menu items here for organization */}
                    <div
                      className="nav-item card-box"
                      onClick={() => {
                        setConfirmPopup(true);
                      }}
                    >
                      <img src={SignIcon} alt="Sign out" />
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="desktop-footer">
        <DesktopNavigation />
      </div>

      <div className="mobile-footer">
        <MobileNavigation />
      </div>

      {confirmPopup ? (
        <RenderConfirm
          message="Sign Out"
          data=""
          status={setDevicePushNotificationTokenApiHandler}
          hide={setConfirmPopup}
        />
      ) : null}
    </>
  );
};

export default Navigation;
