import React, { useState, useRef, useEffect } from "react";
import "./AddAssetPart.css";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import { useNavigate } from "react-router-dom";
import { createAssetPart, generateAssetPartId } from "Config/API/action";
import { partuniqueid } from "Redux/action/variable.action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import blank from "Media/blank-profile-picture.png";
import { Button } from "@mui/material";
import {
  getGeneralInfo,
  getLocationData,
  listOfAppointOrg,
  listOfServiceTicketUsers,
} from "Config/API/get";
const AddAssetPart = () => {
  const navigate = useNavigate();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [assetPart, setAssetPart] = useState("");
  const [vendorContactName, setVendorContactName] = useState("");
  const [description, setDescription] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [inventoryLocation, setInventoryLocation] = useState("");
  const [showVendorDropdown, setShowVendorDropdown] = useState(false);

  const [vendorPhone, setVendorPhone] = useState("");
  const uniqueid = useSelector((state) => state.assetIdReducer);

  const [selectedOrg, setSelectedOrg] = useState("");
  const [assetLocation, setAssetLocation] = useState([]);
  const [selectedVendorOrg, setSelectedVendorOrg] = useState([]);
  const [vendorContactList, setVendorContactList] = useState([]);
  const [vendorContactId, setVendorContactId] = useState([]);
  const [assetLocationType, setAssetLocationType] = useState("");
  const [vendorOrgList, setVendorOrgList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [showLocation, setShowLocation] = useState(false);
  const [selectedLocationId, setSelectedLocationid] = useState("");
  const vendorRef = useRef();
  const locationRef = useRef();
  const vendorWrapperRef = useRef();
  useOutsideAlerter(vendorRef);
  useOutsideAlerter(vendorWrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (locationRef.current && !locationRef.current.contains(event.target)) {
        setShowLocation(false); // Close location dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Handle location dropdown

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        vendorWrapperRef.current &&
        !vendorWrapperRef.current.contains(event.target)
      ) {
        setShowVendorDropdown(false); // Close vendor dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Handle vendor dropdown

  const getassetlocation = async () => {
    try {
      const info = await getGeneralInfo(token);
      const res = await getLocationData(token, info?.org_info?.id);

      if (res?.status === true) {
        // Check for the boolean value 'true'
        setAssetLocation(res.location);
        console.log(res.location); // Log the asset data directly
      } else {
        toast.error("No asset location found");
      }
    } catch (error) {
      toast.error(`Error fetching asset location: ${error.message}`);
      // Handle the error if needed (e.g., show a toast or alert)
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim the values to avoid empty or whitespace-only submissions
    const trimmedAssetPart = assetPart.trim();
    const trimmedVendorContactName = vendorContactName.trim();
    const trimmedVendorEmail = vendorEmail.trim();
    const trimmedDescription = description.trim();
    const trimmedVendorName = vendorName.trim();
    const trimmedInventoryLocation = inventoryLocation.trim();
    const trimmedVendorPhone = vendorPhone.trim();

    // Ensure assetName is not empty
    if (trimmedAssetPart !== "") {
      if (trimmedVendorName !== "") {
        if (trimmedDescription !== "") {
          if (trimmedInventoryLocation !== "") {
            if (trimmedVendorContactName !== "") {
              if (trimmedVendorEmail !== "") {
                if (trimmedVendorPhone !== "") {
                  setLoader(); // Show loading spinner or state

                  // Fetch token from local storage
                  const token = localStorage.getItem("token");

                  try {
                    // First, generate the assetPartId using the token

                    const assetPartData = {
                      token: token,
                      assetId: uniqueid,
                      partHash: trimmedAssetPart,
                      description: trimmedDescription,
                      inventoryLocation:
                        selectedLocationId && selectedLocationId !== ""
                          ? selectedLocationId
                          : trimmedInventoryLocation,
                      vendorName: trimmedVendorName,
                      vendorContact: trimmedVendorContactName,
                      vendorEmail: trimmedVendorEmail,
                      vendorPhone: trimmedVendorPhone,
                      assetLocationType: assetLocationType,
                    };

                    // Now, create the asset part using the assetPartData
                    const assetPartResponse = await createAssetPart(
                      assetPartData
                    );

                    resetLoader(); // Hide loader once request is done

                    if (assetPartResponse?.status) {
                      toast(assetPartResponse?.message, {
                        type: "success",
                      });

                      dispatch(partuniqueid(assetPartResponse.data.unique_id));
                      navigate("/part-detail");
                    } else {
                      toast(assetPartResponse?.message, {
                        type: "error",
                      });
                    }
                  } catch (error) {
                    resetLoader(); // Hide loader in case of error
                    console.error("Error during asset part creation:", error);
                    toast("An error occurred, please try again", {
                      type: "error",
                    });
                  }
                } else {
                  toast("Please enter a vendor phone number", { type: "info" });
                }
              } else {
                toast("Please enter a vendor email", { type: "info" });
              }
            } else {
              toast("Please enter a vendor contact name", { type: "info" });
            }
          } else {
            toast("Please enter an inventory location", { type: "info" });
          }
        } else {
          toast("Please enter a description", { type: "info" });
        }
      } else {
        toast("Please select a vendor name", { type: "info" });
      }
    } else {
      toast("Please enter an asset part", { type: "info" });
    }
  };

  return (
    <>
      <div className="create-assets-parent">
        <div className="create-assets-header">
          <Headers />
        </div>
        <div className="create-assets-navigation">
          <Navigation />
        </div>
        <div className="create-assets-main create-parts">
          <div className="create-asset-heading">
            <h2>Create/Add New Part</h2>
          </div>
          <div className="create-assets-form">
            <form
              className="create-assets-form-body"
              onSubmit={handleSubmit} // Attach the submit handler here
              autoComplete="off"
            >
              <div className="create-asset-fields">
                <label htmlFor="Asset-Part">
                  {" "}
                  Asset ID<span>*</span>:
                </label>
                <input
                  type="text"
                  id="Asset-Part"
                  required
                  value={uniqueid} // Bind to state
                  readOnly
                  style={{
                    color: "#446FA2",
                    fontWeight: 700,
                    fontSize: "15px",
                  }}
                />
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Asset-Part">
                  {" "}
                  Enter Part # <span>*</span>:
                </label>
                <input
                  type="text"
                  id="Asset-Part"
                  required
                  value={assetPart} // Bind to state
                  onChange={(e) => setAssetPart(e.target.value)} // Update state
                  placeholder="Model1_SN:12345"
                />
              </div>

              <div className="create-asset-fields">
                <label htmlFor="Asset-Description">
                  Enter Description <span>*</span>:
                </label>
                <textarea
                  id="Asset-Description"
                  rows={4}
                  required
                  value={description} // Bind to state
                  onChange={(e) => setDescription(e.target.value)} // Update state
                  placeholder="Enter Description "
                ></textarea>
              </div>

              <div className="create-asset-fields ">
                <label htmlFor="assetLocation">
                  Enter Inventory Location :
                </label>

                <div ref={locationRef} className="vendor-wrapper">
                  <input
                    type="text"
                    name="assetLocation"
                    value={selectedLocation} // Bind to selected location
                    placeholder="Location"
                    onChange={(e) => {
                      setSelectedLocation(e.target.value);
                      setAssetLocationType("text");
                    }}
                    onClick={() => {
                      getassetlocation();
                      setShowLocation(true);
                    }}
                  />

                  {showLocation && (
                    <div
                      className="vendor-organization-list"
                      style={{ zIndex: "10" }}
                    >
                      {assetLocation &&
                        assetLocation.length > 0 &&
                        assetLocation.map((loc) => (
                          <div
                            className="data-list"
                            key={loc.id}
                            onClick={() => {
                              setSelectedLocation(loc.location);
                              setSelectedLocationid(loc.id); // Set selected location for both fields
                              setAssetLocationType("id");
                              setShowLocation(false);
                            }}
                            style={{cursor:"pointer"
                            }}
                          >
                            <span
                              className="multiple-line-text"
                              style={{ color: "#446FA2" }}
                            >
                              {loc.location || "No Location"}
                            </span>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="create-asset-fields ">
                <label htmlFor="Vendor-Name">Vendor Org :</label>
                <div ref={vendorWrapperRef} className="vendor-wrapper">
                  <input
                    type="text"
                    id="Vendor-Name"
                    name="Vendor-Name"
                    value={vendorName}
                    onClick={() => {
                      setShowVendorDropdown(true); // Make sure this correctly shows the dropdown
                      // Fetch the vendor list based on the input value
                      const vendor_list = listOfAppointOrg(token, "");
                      vendor_list.then((res) => {
                        if (res?.status) {
                          setVendorOrgList(res?.organizationData);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onChange={(e) => {
                      setVendorName(e.target.value);
                      const vendor_list = listOfAppointOrg(
                        token,
                        e.target.value
                      );
                      vendor_list.then((res) => {
                        if (res?.status) {
                          setVendorOrgList(res?.organizationData);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 8) {
                        setSelectedVendorOrg("");
                      }
                    }}
                    required
                    placeholder="Part Vendor Name"
                  />
                  {showVendorDropdown && (
                    <div className="vendor-organization-list">
                      {vendorOrgList?.length?.toString() !== "0"
                        ? !vendorOrgList?.message
                          ? vendorOrgList?.map((curr, index) => {
                              return (
                                <div className="data-list" key={index}>
                                  <div>
                                    <img
                                      src={curr?.logo ? curr?.logo : blank}
                                      alt="Vendor Logo"
                                    />
                                    <span style={{ color: "#446FA2" }}>
                                      {curr?.organization_name || "No Name"}
                                    </span>
                                  </div>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                      setSelectedVendorOrg(curr?.id); // Set selected vendor ID
                                      setVendorOrgList(""); // Clear the vendor list
                                      setVendorName(curr?.organization_name); // Set the selected vendor name
                                      setShowVendorDropdown(false); // Close the dropdown
                                    }}
                                  >
                                    Select
                                  </Button>
                                </div>
                              );
                            })
                          : null
                        : null}
                    </div>
                  )}
                </div>
              </div>

              <div className="create-asset-fields">
                <label htmlFor="Vendor-Contact-Name">Vendor Name:</label>
                <div ref={vendorRef} className="vendor-wrapper">
                  <input
                    type="text"
                    id="Vendor-Contact-Name"
                    name="Vendor-Contact-Name"
                    value={vendorContactName}
                    disabled={selectedOrg === ""}
                    style={{
                      backgroundColor: selectedOrg === "" && "#EFEFEF",
                    }}
                    onClick={() => {
                      if (selectedOrg !== "") {
                        const vendor_list = listOfServiceTicketUsers(
                          token,
                          selectedOrg,
                          ""
                        );
                        vendor_list.then((res) => {
                          if (res?.status) {
                            setVendorContactList(res?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }
                    }}
                    onChange={(e) => {
                      setVendorContactName(e.target.value);
                      const vendor_list = listOfServiceTicketUsers(
                        token,
                        selectedOrg,
                        e.target.value
                      );
                      vendor_list.then((res) => {
                        if (res?.status) {
                          setVendorContactList(res?.data);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 8) {
                        setVendorContactId("");
                      }
                    }}
                    required
                    placeholder="Part Vendor Contact Name"
                  />

                  {/****************  Vendor Contact List ***************/}
                  <div
                    className="vendor-organization-list"
                    style={{
                      display: vendorContactList.length > 0 ? "block" : "none",
                    }}
                  >
                    {vendorContactList.length > 0 && !vendorContactList.message
                      ? vendorContactList?.map((curr, index) => {
                          return (
                            <div className="data-list" key={index}>
                              <div>
                                <img
                                  src={curr?.image ? curr?.image : blank}
                                  alt="Vendor Logo"
                                />
                                <span style={{ color: "#446FA2" }}>
                                  {curr?.name || "No Name"}
                                </span>
                              </div>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => {
                                  setVendorContactId(curr?.id);
                                  setVendorContactList([]); // Clear the list after selection
                                  setVendorContactName(curr?.name);
                                }}
                              >
                                Select
                              </Button>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>

              <div className="create-asset-fields">
                <label htmlFor="Vendor-Email">Vendor Email :</label>
                <input
                  type="email"
                  id="Vendor-Email"
                  required
                  value={vendorEmail} // Bind to state
                  onChange={(e) => setVendorEmail(e.target.value)} // Update state
                  placeholder="Enter Vendor Email"
                />
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Vendor-Phone">Vendor Phone:</label>
                <input
                  type="number"
                  id="Vendor-Phone"
                  required
                  value={vendorPhone} // Bind to state
                  onChange={(e) => setVendorPhone(e.target.value)} // Update state
                  placeholder="Enter Vendor Phone Number"
                />
              </div>

              <div className="submitbutton">
                <button type="submit" onSubmit={handleSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default AddAssetPart;
