import "./Notification.css";

import { Badge, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  clearAllNotification,
  setNotificationReadApi,
} from "Config/API/action";
import {
  getFullNotification,
  getNotificationTabs,
  getNotificationTabsTotalCount,
} from "Config/API/get";
import {
  getFullNotificationAction,
  getRecentNotificationCountAction,
} from "Redux/action/get.action";
import { useDispatch, useSelector } from "react-redux";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderNotificationCard from "Components/Cards/FullNotificationCard/Container/RenderNotificationCard";
import { toast } from "react-toastify";
import useIsAmniusOrganization from "Hooks/useIsAmniusOrganization";
import { useSearchParams } from "react-router-dom";

const Notification = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [notificationTabs, setNotificationTabs] = useState([]);
  const isOrgAmnius = useIsAmniusOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const loadingArray = ["", "", "", ""];
  const notificationCount = useSelector(
    (state) => state.getRecentNotificationCountReducer
  );
  let recentNotificationList = useSelector(
    (state) => state.getFullNotificationReducer
  );

  useEffect(() => {
    getNotificationTabsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationCount]);

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    getNotificationByType(searchParams.get("type"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, notificationCount]);

  const getNotificationTabsHandler = () => {
    const response = getNotificationTabs(token);
    response
      .then((res) => {
        if (res?.status) {
          if (!isOrgAmnius) {
            const tabs = res?.Notification.filter(
              (tab) => tab?.type !== "registered"
            );
            setNotificationTabs(tabs);
          } else setNotificationTabs(res?.Notification);
        } else {
          toast(res?.message, { type: "error" });
        }
      })
      .catch((e) => {
        toast("Failed! Try again later", { type: "error" });
      });
  };

  const setNotificationReadApiHandler = () => {
    const response = setNotificationReadApi(token, searchParams.get("type"));
    response.then((res) => {
      if (res?.status) {
        const response = getNotificationTabsTotalCount(token);
        response.then((res) => {
          setIsLoading(false);
          if (res?.status) {
            dispatch(
              getRecentNotificationCountAction(
                res?.TotalUnreadNotificationCount
              )
            );
          }
        });
      }
    });
  };

  const getNotificationByType = (type) => {
    const response = getFullNotification(token, type);
    response
      .then((res) => {
        if (res?.status) {
          dispatch(getFullNotificationAction(res?.data[1]?.notification));
          setNotificationReadApiHandler();
        } else {
          setIsLoading(false);
          toast("Failed! Try again later", { type: "error" });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast("Failed! Try again later", { type: "error" });
      });
  };

  return (
    <>
      <div className="notification-container">
        <div className="notification-header">
          <Headers />
        </div>
        <div className="notification-navigation">
          <Navigation />
        </div>
        <div className="notification-main">
          <div className="notification-wrapper">
            <div className="notification-upper">
              <div className="notification-head">
                <label>Notifications</label>
              </div>

              <Button
                className="clear-action-button"
                variant="contained"
                onClick={() => {
                  if (recentNotificationList?.length === 0 || isLoading) {
                    toast("No notifications to clear", { type: "info" });
                    return;
                  }
                  const clear_all = clearAllNotification(
                    token,
                    searchParams.get("type")
                  );
                  clear_all
                    .then((res) => {
                      if (res?.status) {
                        toast(res?.message, {
                          type: "success",
                        });
                        const notification_status = getFullNotification(
                          token,
                          searchParams.get("type")
                        );
                        notification_status.then((res) => {
                          dispatch(
                            getFullNotificationAction(
                              res?.data[1]?.notification
                            )
                          );
                        });
                      } else {
                        toast(res?.message, {
                          type: "error",
                        });
                      }
                    })
                    .catch((e) => {
                      toast("Failed! Try again later", {
                        type: "error",
                      });
                    });
                }}
              >
                Clear Group
              </Button>
            </div>
            <span className="notification-types">
              {notificationTabs?.length > 0 &&
                notificationTabs?.map((tab, index) => (
                  <Badge
                    key={index}
                    className="notification-badge"
                    badgeContent={tab?.count}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: `${
                          tab?.type === searchParams.get("type")
                            ? "#5cbb55"
                            : "#446fa2"
                        }`,
                      }}
                      onClick={() => {
                        if (searchParams.get("type") === tab?.type || isLoading)
                          return;
                        setIsLoading(true);
                        setSearchParams({ type: tab?.type });
                      }}
                    >
                      {tab?.type === "subscription" ? "site" : tab?.type}
                    </Button>
                  </Badge>
                ))}
            </span>
            <div className="notification-lower">
              {!isLoading ? (
                <RenderNotificationCard />
              ) : (
                loadingArray.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="portfolio-content-item"
                      style={{ borderColor: "#ececec" }}
                    >
                      <div>
                        <div className="loading-content-image animation" />
                        <span
                          className="loading-span animation"
                          style={{ marginLeft: "10px" }}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          className="loading-content-button animation"
                          style={{ width: "15px", marginRight: "0" }}
                        />
                        <div
                          className="loading-content-button animation"
                          style={{ width: "15px", marginRight: "35px" }}
                        />
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
