import Card from "../PureComponents/ProfileCard";
import React from "react";

const RenderCard = ({
  currEle,
  role,
  usersAllPermissions,
  state,
  selectedCategoryTabData,
  changeCategoryTab,
  adminLoading2,
  showEdit,
  isAdmin,
  getAmniusAdmin,
  getAllCategories
}) => {
  return (
    <>
      <Card
        currEle={currEle}
        orgRole={role}
        usersAllPermissions={usersAllPermissions}
        state={state}
        selectedCategoryTabData={selectedCategoryTabData}
        changeCategoryTab={changeCategoryTab}
        adminLoading2={adminLoading2}
        showEdit={showEdit}
        isAdmin={isAdmin}
        getAmniusAdmin={getAmniusAdmin}
        getAllCategories={  getAllCategories} 
      />
    </>
  );
};

export default RenderCard;
