import "./GeneralCard.css";

import React, { useEffect, useState } from "react";

import InputCard from "../InputCard/InputCard";
import MentionDisplayCard from "../MentionCard/MentionDisplayCard";
import NestedCard from "../NestedCard/NestedCard";
import blank from "Media/blank-profile-picture.png";
import { getUserList } from "Config/API/get";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const GeneralCard = ({ data, myId, reply, docid, chatType, type }) => {
  const token = localStorage.getItem("token");
  const { sentBy, message, imageData, createdAt, keey, username } = data;

  // chat date
  let date = moment(createdAt).tz("America/Chicago").format();
  let timeConverted = moment(date).format("hh:mm A");

  // chat user data
  const my_image = useSelector((state) => state.authenticationReducer?.image);
  const [chatUserDetails, setChatUserDetails] = useState([]);
  let other_image = blank;
  const [messageCount, setMessageCount] = useState("");

  // triggers
  const [rightPopup, setRightPopup] = useState(false);
  const [leftPopup, setLeftPopup] = useState(false);
  const [leftChatShow, setLeftChatShow] = useState(false);
  const [rightChatShow, setRightChatShow] = useState(false);

  // getting other person data
  useEffect(() => {
    if (docid) {
      const user_list_status = getUserList(token, docid?.split("-"));
      user_list_status
        .then((res) => {
          if (res?.status) {
            setChatUserDetails(res?.data);
          }
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // selecting other person data
  if (chatUserDetails?.length > 0) {
    // eslint-disable-next-line array-callback-return
    chatUserDetails.map((item) => {
      if (sentBy?.toString() === item?.id?.toString()) {
        other_image = item.image;
      }
    });
  }
  // sender
  if (sentBy?.toString() === myId?.toString()) {
    return (
      <div className="right-side-chat general-card-mention">
        <div>
          <div className="chat-avatar">
            {sentBy?.toString() === myId?.toString() ? (
              <img src={my_image ? my_image : blank} alt="Avatar" />
            ) : null}
          </div>

          {/* videolink, message and media conditions */}
          {message ? (
            <>
              <li>
                <b>{username ? username : "Unknown"}:</b>
                <MentionDisplayCard message={message} />

                <span className="timeconverted">
                  {timeConverted ? timeConverted : null}
                </span>

                <div className="nested-feature">
                  {rightChatShow ? (
                    <span
                      onClick={() => {
                        setRightChatShow(false);
                      }}
                    >
                      Hide replies
                      {messageCount
                        ? messageCount > 0
                          ? " (" + messageCount + ")"
                          : ""
                        : ""}
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setRightChatShow(true);
                      }}
                    >
                      Show replies
                      {messageCount
                        ? messageCount > 0
                          ? " (" + messageCount + ")"
                          : ""
                        : ""}
                    </span>
                  )}

                  <span
                    onClick={() => {
                      setRightPopup(!rightPopup);
                    }}
                  >
                    {rightPopup ? "Close Reply" : "Reply"}
                  </span>
                </div>
              </li>
            </>
          ) : imageData ? (
            <li className="chat-media">
              <img src={imageData} alt="Media" />
              <span className="timeconverted">
                {timeConverted ? timeConverted : null}
              </span>
            </li>
          ) : null}
        </div>
        {/* Calling nested card */}
        <NestedCard
          docid={docid}
          message_id={keey}
          chatUserDetails={chatUserDetails}
          customSendBy={sentBy}
          setMessageCount={setMessageCount}
          popupShow={rightChatShow}
        />
        {/* reply box */}
        {rightPopup ? (
          <InputCard
            onSend={reply}
            message_id={keey}
            chatType={chatType}
            setLeftPopup={setLeftPopup}
            setRightPopup={setRightPopup}
            type={type}
          />
        ) : null}
      </div>
    );
  }

  //receiver
  if (sentBy?.toString() !== myId?.toString()) {
    return (
      <div className="left-side-chat general-card-mention">
        <div>
          <div className="chat-avatar">
            {sentBy?.toString() !== myId?.toString() ? (
              <img src={other_image ? other_image : blank} alt="Avatar" />
            ) : null}
          </div>

          {/* message and media conditions */}
          {message ? (
            <>
              <li>
                <b>{username ? username : "Unknown"}:</b>
                <MentionDisplayCard message={message} />
                <span className="timeconverted">
                  {timeConverted ? timeConverted : null}
                </span>

                <div className="nested-feature">
                  {leftChatShow ? (
                    <span
                      onClick={() => {
                        setLeftChatShow(false);
                      }}
                    >
                      Hide replies
                      {messageCount
                        ? messageCount > 0
                          ? " (" + messageCount + ")"
                          : ""
                        : ""}
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setLeftChatShow(true);
                      }}
                    >
                      Show replies
                      {messageCount
                        ? messageCount > 0
                          ? " (" + messageCount + ")"
                          : ""
                        : ""}
                    </span>
                  )}

                  <span
                    onClick={() => {
                      setLeftPopup(!leftPopup);
                    }}
                  >
                    {leftPopup ? "Close Reply" : "Reply"}
                  </span>
                </div>
              </li>
            </>
          ) : imageData ? (
            <li className="chat-media">
              <img src={imageData} alt="Media" />
              <span className="timeconverted">
                {timeConverted ? timeConverted : null}
              </span>
            </li>
          ) : null}
        </div>
        {/* Calling nested card */}
        <NestedCard
          docid={docid}
          message_id={keey}
          chatUserDetails={chatUserDetails}
          customSendBy={sentBy}
          setMessageCount={setMessageCount}
          popupShow={leftChatShow}
        />

        {/* reply box */}
        {leftPopup ? (
          <InputCard
            onSend={reply}
            message_id={keey}
            chatType={chatType}
            setLeftPopup={setLeftPopup}
            setRightPopup={setRightPopup}
            type={type}
          />
        ) : null}
      </div>
    );
  }
  return <></>;
};

export default GeneralCard;
