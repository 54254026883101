import "./NotificationOverview.css";

import { Button } from "@mui/material";
import React from "react";
import RenderNotificationCard from "Components/Cards/NotificationCard/Container/RenderNotificationCard";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="notif-wrapper">
        <div className="notif-upper notif-flex">
          <div className="notif-head">Notifications</div>
          <div className="notif-view_all">
            <Button
              variant="contained"
              style={{ background: "#5ca127" }}
              onClick={() => {
                navigate({
                  pathname: "/notification",
                  search: "?type=video",
                });
              }}
            >
              VIEW ALL
            </Button>
          </div>
        </div>
        <div className="notif-lower">
          <RenderNotificationCard />
        </div>
      </div>
    </>
  );
};

export default Notification;
