import "firebase/compat/firestore";
import "./ChatScreen.css";
import "react-toastify/dist/ReactToastify.css";

import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  createARoom,
  sendChatNotification,
  sendImageAPI,
} from "Config/API/action";
import {
  getExternalMembers,
  getInternalMembers,
  getTeamListForGroup,
  newDataGroup,
  requestVideoChatRoom,
  statusOfRoom,
} from "Config/API/get";
import { getMessageDate, insertDateHeaders } from "Constants/Functions";
import { useDispatch, useSelector } from "react-redux";

import ChatCard from "Components/Cards/ChatCard/ChatCard";
import ChatInput from "./ChatInput";
import Divider from "@mui/material/Divider";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MessageIcon from "Media/icons/Iconly-Bold-Message.svg";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import RenderCreateExternalChatRoom from "Components/Popup/CreateExternalChatRoom/Container/RenderCreateExternalChatRoom";
import RenderCreateInternalChatRoom from "Components/Popup/CreateInternalChatRoom/Container/RenderCreateInternalChatRoom";
import RenderEmail from "Components/Popup/Email/Container/RenderEmail";
import RenderGroupPopup from "Components/Popup/GroupPopup/Container/RenderGroupPopup";
import VideoIcon from "Media/icons/Iconly-Bold-Video.svg";
import blank from "Media/blank-profile-picture.png";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "Constants/FirebaseConfig";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { seenOnSend } from "../../ChatTimer/ChatTimer";
import { storeVideoChatLinkAction } from "Redux/action/get.action";
import { useEffect } from "react";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsMyOrganization from "Hooks/useIsMyOrganization";
import { useNavigate } from "react-router-dom";

const ChatScreen = ({
  profile,
  group_data,
  setShowStorageLimitReachedPopup,
}) => {
  const [groupPopup, setGroupPopup] = useState(false);
  const [displayMessage, setDisplayMessage] = useState([]);
  const userToken = localStorage.getItem("token");
  const role = useSelector((state) => state.authenticationReducer?.role);
  const send_by = useSelector((state) => state.authenticationReducer?.id);
  const user = useSelector((state) => state.authenticationReducer);
  const send_to = profile?.id;
  let empId = useSelector((state) => state.renderChatProfileReducer);
  const [groupData, setGroupData] = useState({});
  const dispatch = useDispatch();
  const [loader, setLoading, resetLoading] = useFullPageLoader();
  const screenId = empId.toString();
  const [EmailPopup, setEmailPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openInternalChat, setOpenInternalChat] = useState(false);
  const [openExternalChat, setOpenExternalChat] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loggedInUser, setLoggedInUser] = useState([]);
  const [availableMember, setAvaiableMember] = useState([]);
  const [externalMember, setExternalMember] = useState([]);
  const [allExternalMember, setAllExternalMember] = useState([]);
  const [videoCallCreatorAdmin, setVideoCallCreatorAdmin] = useState([]);
  const isMyOrganisation = useIsMyOrganization();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  // for chat scrolling
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [displayMessage]);

  useEffect(() => {
    if (!loggedInUser?.includes(user)) {
      loggedInUser?.push(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    getExternalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // firebase integration
  firebase.initializeApp(firebaseConfig);

  // getting messages from firebase
  useEffect(() => {
    fetchChatMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const fetchChatMessages = () => {
    let docid = "";
    if (screenId.includes("-")) {
      docid = empId;
    } else {
      docid =
        send_to > send_by ? send_by + "-" + send_to : send_to + "-" + send_by;
    }
    const messageRef = firebase
      .firestore()
      .collection("chatroom")
      .doc(docid)
      .collection("messages")
      .orderBy("createdAt", "asc");

    messageRef.onSnapshot((querySnap) => {
      const allmsg = querySnap.docs?.map((docSanp) => {
        const data = docSanp.data();
        if (data.createdAt) {
          return {
            ...docSanp.data(),
            id: docSanp.id,
            createdAt: docSanp.data().createdAt.toDate(),
          };
        } else {
          return {
            ...docSanp.data(),
            id: docSanp.id,
            createdAt: new Date(),
          };
        }
      });
      const chatWithDateHeaders = insertDateHeaders(allmsg);
      setDisplayMessage(chatWithDateHeaders);
    });
  };

  const getData = () => {
    try {
      const data = getInternalMembers(userToken);
      data.then((res) => {
        if (res?.status) {
          const newArray = res?.data?.internal?.map((item) => {
            item["name"] = item?.username;
            return item;
          });
          const videoCallCreatorAdminData = newArray?.filter((item) => {
            return item?.role?.toString() === "admin";
          });
          setAvaiableMember(newArray);
          setVideoCallCreatorAdmin(videoCallCreatorAdminData);
        }
      });
    } catch (error) {}
  };

  const getExternalData = () => {
    try {
      const data = getExternalMembers(userToken, profile?.organization_id);
      data.then((res) => {
        if (res?.status) {
          const newArray = res?.data?.external?.map((item) => {
            item["name"] = item?.username;
            return item;
          });
          setAllExternalMember(newArray);
        }
      });
    } catch (error) {}
  };

  const checkInternalMember = () => {
    const allIds = availableMember?.map((item) => {
      return item?.id;
    });
    if (allIds?.includes(profile?.id)) {
      return true;
    } else return false;
  };

  // sending messages to firebase
  const onSend = (message) => {
    if (message !== "") {
      let mymsg = {};
      let docid = "";
      let seen_Data;
      if (screenId.includes("-")) {
        // for group type //
        mymsg = { message, sentBy: send_by, sentTo: "all" };
        docid = empId;

        // creating seen data for groups
        seen_Data = seenOnSend(profile?.docid, send_by);

        firebase
          .firestore()
          .collection("chatroom")
          .doc(docid)
          .set({
            chatID: profile?.docid?.split("-"),
            docID: docid,
            currID: profile?.docid,
            type: "group",
            parentDocId: "",
            lastMessage: message,
            seen_data: seen_Data,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
      } else {
        // for regular chats
        mymsg = {
          message,
          sentBy: send_by,
          sentTo: send_to,
        };

        docid =
          send_to > send_by ? send_by + "-" + send_to : send_to + "-" + send_by;

        // creating seen data for one-to-one
        seen_Data = seenOnSend(docid, send_by);

        firebase
          .firestore()
          .collection("chatroom")
          .doc(docid)
          .set({
            chatID: [send_to, send_by],
            docID: docid,
            lastMessage: message,
            seen_data: seen_Data,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }

      firebase
        .firestore()
        .collection("chatroom")
        .doc(docid)
        .collection("messages")
        .add({
          ...mymsg,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

      const title = `Message from ${user?.name}${
        screenId.includes("-") ? ` (Group ${profile?.name})` : ""
      }`;
      const body = `${message}`;
      let sendTo = [send_to?.toString()];
      if (screenId.includes("-")) {
        const newDocId = profile.docid
          ?.split("-")
          ?.filter((item) => item !== send_by?.toString());
        sendTo = newDocId;
      }
      const sendChatNotificationRes = sendChatNotification(
        userToken,
        title,
        body,
        seen_Data,
        docid,
        send_by?.toString(),
        sendTo,
        "chat",
        "",
        screenId.includes("-") ? "true" : "false"
      );
      sendChatNotificationRes.then(() => {});
    }
  };

  // sending media to firebase
  const onMediaSend = (media) => {
    if (media !== "") {
      let docid = "";
      if (screenId.includes("-")) {
        docid = empId;
      } else {
        docid =
          send_to > send_by ? send_by + "-" + send_to : send_to + "-" + send_by;
      }

      setLoading();
      const media_status = sendImageAPI(userToken, media, `chat/${docid}`, "");
      media_status.then((res) => {
        res?.json().then((data) => {
          if (data?.status) {
            if (data?.message?.includes("limit")) {
              resetLoading();
              return toast(data?.message, {
                type: "info",
              });
            }
            let imageData = data?.data;

            // sending media to firebase
            let mymsg = {};
            let seen_Data;

            if (screenId.includes("-")) {
              // creating seen data for groups
              seen_Data = seenOnSend(profile?.docid, send_by);

              firebase
                .firestore()
                .collection("chatroom")
                .doc(docid)
                .set({
                  chatID: profile?.docid?.split("-"),
                  docID: docid,
                  currID: profile?.docid,
                  type: "group",
                  parentDocId: "",
                  lastMessage: "Media",
                  seen_data: seen_Data,
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                });

              mymsg = {
                imageData,
                sentBy: send_by,
                sentTo: "all",
              };
            } else {
              // creating seen data for one-to-one
              seen_Data = seenOnSend(docid, send_by);

              firebase
                .firestore()
                .collection("chatroom")
                .doc(docid)
                .set({
                  chatID: [send_to, send_by],
                  docID: docid,
                  lastMessage: "Media",
                  seen_data: seen_Data,
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                });

              mymsg = {
                imageData,
                sentBy: send_by,
                sentTo: send_to,
              };
            }

            firebase
              .firestore()
              .collection("chatroom")
              .doc(docid)
              .collection("messages")
              .add({
                ...mymsg,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              });

            const title = `Message from ${user?.name}${
              screenId.includes("-") ? ` (Group ${profile?.name})` : ""
            }`;
            const body = `${user?.name} sent an attachment`;
            let sendTo = [send_to?.toString()];
            if (screenId.includes("-")) {
              const newDocId = profile.docid
                ?.split("-")
                ?.filter((item) => item !== send_by?.toString());
              sendTo = newDocId;
            }
            const sendChatNotificationRes = sendChatNotification(
              userToken,
              title,
              body,
              seen_Data,
              docid,
              send_by?.toString(),
              sendTo,
              "chat",
              "",
              screenId.includes("-") ? "true" : "false"
            );
            sendChatNotificationRes.then(() => {});
          } else {
            toast(data?.message, { type: "error" });
          }
        });
        resetLoading();
      });
    }
  };

  const openCreateChatDialog = () => {
    if (checkInternalMember()) {
      getData();
      if (!selectedTeamMember.includes(user)) {
        const newUser = loggedInUser?.map((item) => {
          item["isCreator"] = true;
          item["isPermanent"] = true;
          return item;
        });
        newUser?.forEach((item) => {
          selectedTeamMember.push(item);
        });
      }
      group_data?.forEach((item) => {
        if (!selectedTeamMember.includes(item)) {
          const newUser = group_data?.map((item) => {
            item["isCreator"] = false;
            item["isPermanent"] = true;
            return item;
          });
          newUser?.forEach((item) => {
            selectedTeamMember.push(item);
          });
        }
      });
      setOpenInternalChat(true);
    } else {
      getExternalData();
      if (!selectedTeamMember.includes(user)) {
        const newUser = loggedInUser?.map((item) => {
          item["isCreator"] = true;
          item["isPermanent"] = true;
          return item;
        });
        newUser?.forEach((item) => {
          selectedTeamMember.push(item);
        });
      }
      group_data?.forEach((item) => {
        if (!externalMember.includes(item)) {
          const newUser = group_data?.map((item) => {
            item["isCreator"] = false;
            item["isPermanent"] = true;
            return item;
          });
          newUser?.forEach((item) => {
            externalMember.push(item);
          });
        }
      });
      setOpenExternalChat(true);
    }
  };

  const requestVideoChat = () => {
    if (checkInternalMember()) {
      const isBillable = "false";
      const conn_id = "";
      const role = "host";
      const billingRate = "";
      const uniqueId =
        send_to > send_by ? send_by + "-" + send_to : send_to + "-" + send_by;
      const createRoom = createARoom(
        userToken,
        uniqueId,
        isBillable,
        conn_id,
        billingRate,
        "true"
      );
      createRoom
        .then((res) => {
          if (res?.status) {
            const token = res?.data?.msToken;
            const id = res?.data?.id;
            const name = res?.data?.name;
            const room_status = statusOfRoom(userToken, token, id);
            room_status.then((res) => {
              if (res?.status) {
                if (res?.data.enabled) {
                  navigate("/video-chat");
                  dispatch(
                    storeVideoChatLinkAction({
                      token,
                      id,
                      name,
                      role,
                      from: "videoChat",
                    })
                  );
                } else {
                  toast("Link is expired! Create a new Video Chat", {
                    type: "error",
                  });
                }
              } else {
                toast("Link is expired! Create a new Video Chat", {
                  type: "error",
                });
              }
            });
          } else toast(res?.message, { type: "info" });
        })
        .catch((error) => {
          toast(error, { type: "error" });
        });
    } else {
      const requestVideoChat = requestVideoChatRoom(userToken, send_to);
      requestVideoChat
        .then((res) => {
          if (res?.status) {
            toast("Video Chat Request Sent", { type: "success" });
          } else toast(res?.data.message, { type: "info" });
        })
        .catch((error) => {
          toast("error", { type: "error" });
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="chat-screen-container">
        <div className="chat-screen-wrapper">
          <div className="chat-screen-upper">
            <div className="chat-screen-head">
              <div>
                <div className="chat-screen-logo">
                  <img
                    src={profile?.image ? profile?.image : blank}
                    alt="Logo"
                  />
                  <div className="chat-screen-status-dot">
                    {profile?.online_status?.toString() === "online" ? (
                      <span
                        className="user-status"
                        style={{ color: "#87c823" }}
                      >
                        <FiberManualRecordIcon />
                      </span>
                    ) : (
                      <span
                        className="user-status"
                        style={{ color: "#c23616" }}
                      >
                        <FiberManualRecordIcon />
                      </span>
                    )}
                  </div>
                </div>
                <div className="chat-screen-profile">
                  {profile?.docid ? (
                    <>
                      <h2>Group: </h2>
                      <h3> {profile?.name ? profile?.name : "Name unknown"}</h3>
                    </>
                  ) : (
                    <h3>{profile?.name ? profile?.name : "Name unknown"}</h3>
                  )}

                  <p>{profile?.designation ? profile?.designation : null}</p>
                  <b>
                    {profile?.organization_name
                      ? profile?.organization_name
                      : null}
                  </b>
                </div>
              </div>
              <div className="chat-screen-options">
                {!isMyOrganisation(profile?.organization_id) &&
                  profile?.chat_type !== "group" && (
                    <button
                      className="chat-screen-org-visit-btn"
                      onClick={() => {
                        navigate("/organization", {
                          state: { data: profile?.organization_id },
                        });
                        dispatch(renderOrganizationInfo({}));
                      }}
                    >
                      <img
                        src={profile?.logo ? profile?.logo : blank}
                        alt="organisation logo"
                      />
                      VISIT PAGE
                    </button>
                  )}
                <div>
                  {role?.toString() === "admin" ||
                  role?.toString() === "super_admin" ? (
                    <>
                      <GroupAddIcon
                        onClick={() => {
                          setGroupPopup(true);
                          if (profile?.chat_type?.toString() === "group") {
                            // updating team members
                            const group_list = newDataGroup(userToken, empId);
                            group_list
                              .then((res) => {
                                if (res?.status) {
                                  setGroupData(res?.detail);
                                } else {
                                  toast(res?.message, {
                                    type: "error",
                                  });
                                }
                              })
                              .catch((e) => {
                                toast("Failed! Try again later", {
                                  type: "error",
                                });
                              });
                          } else {
                            // getting the team list
                            const group_list = getTeamListForGroup(
                              userToken,
                              empId
                            );
                            group_list
                              .then((res) => {
                                if (res?.status) {
                                  setGroupData(res?.data);
                                } else {
                                  toast(res?.message, {
                                    type: "error",
                                  });
                                }
                              })
                              .catch((e) => {
                                toast("Failed! Try again later", {
                                  type: "error",
                                });
                              });
                          }
                        }}
                      />
                      {/* rendering popup */}
                      {groupPopup ? (
                        <RenderGroupPopup
                          profileData={profile}
                          setGroupPopup={setGroupPopup}
                          groupData={groupData}
                          conn_id={profile?.id}
                        />
                      ) : null}
                    </>
                  ) : null}

                  {((profile?.chat_type === "group" &&
                    profile?.docid?.split("-").includes(send_by?.toString())) ||
                    profile?.chat_type !== "group") && (
                    <>
                      <button
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <img src={VideoIcon} alt="Video" />
                      </button>
                      <button
                        onClick={() => {
                          setEmailPopup(true);
                        }}
                      >
                        <img src={MessageIcon} alt="Message " />
                      </button>
                    </>
                  )}
                </div>
                {EmailPopup ? (
                  <RenderEmail
                    setEmailPopup={setEmailPopup}
                    toEmail={profile?.email}
                  />
                ) : null}
              </div>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={() => setAnchorEl(null)}
                onClick={(e) => setAnchorEl(null)}
                className="hello"
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className="chat-screen-menuItem"
                  onClick={() => openCreateChatDialog()}
                >
                  <MapsUgcIcon color="#446FA2" />
                  Create Chatroom
                </MenuItem>
                <Divider />
                <MenuItem
                  className="chat-screen-menuItem"
                  onClick={() => requestVideoChat()}
                >
                  <PlayCircleFilledOutlinedIcon color="#446FA2" />
                  {checkInternalMember()
                    ? "Video Call User"
                    : "Request Video Chat"}
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className="chat-screen-lower">
            <div className="chat-screen-body">
              <div className="chat-screen-chatsection">
                <ul className="chat-thread">
                  {displayMessage?.length > 0
                    ? displayMessage?.map((currEle, index) => {
                        if (currEle?.type === "date")
                          return (
                            <div className="chat-date">
                              {getMessageDate(currEle?.date)}
                            </div>
                          );
                        else
                          return (
                            <ChatCard
                              data={currEle}
                              key={index}
                              myId={send_by}
                              single_data={profile?.image}
                              group_data={group_data}
                              fetchChatMessages={fetchChatMessages}
                            />
                          );
                      })
                    : null}
                </ul>
                <div ref={messagesEndRef} />
              </div>
              {profile?.chat_type === "group" ? (
                profile?.docid?.split("-").includes(send_by?.toString()) ? (
                  <ChatInput
                    onSend={onSend}
                    onMediaSend={onMediaSend}
                    location=""
                    setShowStorageLimitReachedPopup={
                      setShowStorageLimitReachedPopup
                    }
                  />
                ) : (
                  <span className="chat-remove-group-message">
                    You are no longer part of the group
                  </span>
                )
              ) : (
                <ChatInput
                  onSend={onSend}
                  onMediaSend={onMediaSend}
                  location=""
                  setShowStorageLimitReachedPopup={
                    setShowStorageLimitReachedPopup
                  }
                />
              )}
            </div>
          </div>
        </div>
        <RenderCreateInternalChatRoom
          selectedTeamMember={selectedTeamMember}
          setSelectedTeamMember={setSelectedTeamMember}
          popup={openInternalChat}
          hide={setOpenInternalChat}
          availableMember={availableMember}
          videoCallCreatorAdmin={videoCallCreatorAdmin}
          toast={toast}
        />
        <RenderCreateExternalChatRoom
          selectedTeamMember={selectedTeamMember}
          setSelectedTeamMember={setSelectedTeamMember}
          popup={openExternalChat}
          hide={setOpenExternalChat}
          availableMember={availableMember}
          allExternalMember={allExternalMember}
          externalMember={externalMember}
          setExternalMember={setExternalMember}
          externalProfile={profile}
          videoCallCreatorAdmin={videoCallCreatorAdmin}
          toast={toast}
        />
      </div>
      {loader}
    </>
  );
};

export default ChatScreen;
