import EditOrganization from "../PureComponents/EditOrganization";
import React from "react";

const RenderEditOrganization = ({ popup, hide }) => {
  return (
    <>
      <EditOrganization popup={popup} hide={hide} />
    </>
  );
};

export default RenderEditOrganization;
