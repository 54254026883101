import "./AssignWorkBookPopup.css";

import React from "react";
import { assignWorkBook } from "Config/API/update";
import blank from "Media/blank-profile-picture.png";
import { toast } from "react-toastify";

const AssignWorkBookPopup = ({
  hide,
  token,
  parent_unique_id,
  conn_id,
  internalMembers,
  allAssigneeId,
  headerText,
  onSuccessCallback,
}) => {
  const handleAssignClick = (id) => {
    const response = assignWorkBook(token, parent_unique_id, conn_id, id);
    response.then((res) => {
      if (res?.status) {
        toast(res?.message, { type: "success" });
        onSuccessCallback();
        hide(false);
      } else toast(res?.message, { type: "error" });
    });
  };

  return (
    <div className="assign-workbook-parent">
      <div className="assign-workbook-main">
        <div className="assign-workbook-heading">
          <div className="rename-cross" onClick={() => hide(false)}>
            X
          </div>
        </div>
        <div className="assign-workbook-fields">
          <label>{headerText}</label>
        </div>
        <div className="assign-workbook-wrapper">
          {internalMembers?.map((item) => {
            return (
              <div className="assign-workbook-content" key={item?.id}>
                <div>
                  <img
                    src={item?.image ? item?.image : blank}
                    alt="userImage"
                  />
                  <label>{item?.username}</label>
                </div>
                <button
                  onClick={() => {
                    handleAssignClick(item?.id);
                  }}
                  style={{
                    backgroundColor: allAssigneeId?.includes(item?.id)
                      ? "#446fa2"
                      : "#5ca127",
                  }}
                >
                  {allAssigneeId?.includes(item?.id) ? "REASSIGN" : "ASSIGN"}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AssignWorkBookPopup;
