import React, { useEffect, useState } from "react";
import ServiceTicketIcon from "Media/Group 353@2x.png";
import "./LogoPopup.css";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "Media/Amnius_connect_noText.png";
import AssetManagementIcon from "Media/Group 746.png";



const LogoPopup = ({ hide, popup }) => {
  const navigate = useNavigate();
  return (
    <>
      {popup && (
        <div className="logo-popup-container">
          <div>
            <div className="logo-popup-close">
              <IconButton onClick={hide}>
                <CloseIcon style={{ color: "black" }} />
              </IconButton>
            </div>
            <div className="logo-popup-top-wrapper">
              <div className="logo-popup-image">
                <img className="logo-popup" src={logo} alt="Application logo" />
              </div>
              <div className="logo-popup-head">Amnius Application</div>
            </div>
            <div className="logo-popup-body">
              <div
                className={`logo-popup-card `}
                onClick={() => 
                  navigate("/service-ticket-dashboard")}
              >
                {/* <img src={PremiumIcon} alt="Premium service" className="hover-image" /> */}
                <img src={ServiceTicketIcon} alt="Service Ticketing icon" className="logo-card-image" />
                <span className="heading">Service Ticketing</span>
              </div>
              <div
                className="logo-popup-card"
                onClick={()=>{
                  navigate("/asset-management-view-page");
                }}
              >
                {/* <img src={PremiumIcon} alt="Premium service" className="hover-image" /> */}
                <img src={AssetManagementIcon} alt="Asset Management icon" className="logo-card-image" />
                <span className="heading">Asset Management</span>
              </div>
            </div>
          </div>
        </div>
      )}
  
    </>
  );
};

export default LogoPopup;
