import OrganisationUI from "../PureComponents/OrganisationUI";
import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const RenderOrganisation = () => {
  let data = useParams();

  // organization details depends wherther our org or other org
  const general_info = useSelector((state) => state.renderGeneralInfo);
  const role = useSelector((state) => state.authenticationReducer?.role);
  let organization_info = useSelector(
    (state) => state.renderOrganizationInfoReducer
  );
  let ourOrg = true;

  if (
    organization_info === undefined ||
    organization_info === null ||
    organization_info === "" ||
    organization_info?.length === 0
  ) {
    organization_info = {};
  }

  if (Object.keys(organization_info).length !== 0) {
    if (general_info?.id?.toString() !== data?.org_id?.toString()) {
      ourOrg = false;
    }
  }

  return (
    <>
      <OrganisationUI
        general_info={
          ourOrg && Object.keys(organization_info).length?.toString() === "0"
            ? general_info
            : organization_info
        }
        role={
          ourOrg && Object.keys(organization_info).length?.toString() === "0"
            ? role
            : "other"
        }
      />
    </>
  );
};

export default RenderOrganisation;
