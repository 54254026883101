import "./ShareFolderLinkPopup.css";

import { Button } from "@mui/material";
/* eslint-disable array-callback-return */
import React from "react";
import { toast } from "react-toastify";

const ShareFolderLinkPopup = ({ hide, folderLink }) => {
  const handleCopyLink = () => {
    var copyText = document.getElementById("folderLink");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
    toast("Link Copied", { type: "success" });
  };

  return (
    <>
      <div className="share-folder-link-parent">
        <div className="share-folder-link-main">
          <div className="share-folder-link-heading">
            <p>Folder Link</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="share-folder-link-wrapper">
            <input id="folderLink" type="text" disabled value={folderLink} />
            <Button
              variant="contained"
              style={{
                background: "rgb(92, 161, 39)",
              }}
              onClick={() => handleCopyLink()}
            >
              Copy Link
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareFolderLinkPopup;
