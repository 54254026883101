import "./STAppointmentPopup.css";

import React, { useEffect, useState } from "react";

import RenderCreateAppointment from "Components/Appointment/CreateAppointment/Container/RenderCreateAppointment";
import VideoCallingRates from "Components/Popup/VideoCallingRates/VideoCallingRates";
import { getRespectiveRates } from "Config/API/get";
import { useSelector } from "react-redux";

const STAppointmentPopup = ({
  hide,
  ticketId,
  details,
  token,
  requestorId,
  requestorName,
}) => {
  const org_id = useSelector((state) => state.renderGeneralInfo?.id);
  const [showVideoChatRatePopup, setShowVideoChatRatePopup] = useState(false);
  const [videoChatDetails, setVideoChatDetails] = useState({});

  useEffect(() => {
    getVideoChatRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVideoChatRate = () => {
    const response = getRespectiveRates(token);
    response.then((res) => {
      const videoRate = res.data.data?.filter(
        (item) => item?.rate_type === "video_chat"
      );
      setVideoChatDetails(videoRate);
    });
  };
  return (
    <>
      <div className="stappointmentpopup-main">
        <div className="stappointmentpopup-wrapper">
          <RenderCreateAppointment
            ticketId={ticketId}
            details={details}
            videoChatDetails={videoChatDetails}
            setShowVideoChatRatePopup={setShowVideoChatRatePopup}
            requestorId={requestorId}
            billableOrgDetails={[{ id: requestorId, orgName: requestorName }]}
          />
          <div
            className="cross-button"
            onClick={() => {
              hide(false);
            }}
          >
            X
          </div>
        </div>
      </div>
      {showVideoChatRatePopup && (
        <VideoCallingRates
          hide={setShowVideoChatRatePopup}
          ratesData={videoChatDetails}
          message="You are Setting Video Chat Rates"
          rateType="video_chat"
          getAllSubscriptionsRates={getVideoChatRate}
        />
      )}
    </>
  );
};

export default STAppointmentPopup;
