import FileViewerComponent from "../PureComponents/FileViewer";
import React from "react";

const RenderFileViewer = () => {
  return (
    <>
      <FileViewerComponent />
    </>
  );
};

export default RenderFileViewer;
