import React, { useEffect, useState } from "react";

import MentionDisplayCard from "../MentionCard/MentionDisplayCard";
import blank from "Media/blank-profile-picture.png";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const NestedCardList = ({ data, chatUserDetails, customSendBy }) => {
  const token = localStorage.getItem("token");
  const myId = useSelector((state) => state.authenticationReducer?.id);
  const { sentBy, message, imageData, createdAt, username } = data;

  // chat date
  let date = moment(createdAt).tz("America/Chicago").format();
  let timeConverted = moment(date).format("hh:mm A");

  // chat user data
  const my_image = useSelector((state) => state.authenticationReducer?.image);
  let other_image = blank;

  // selecting other person data
  if (chatUserDetails?.data) {
    for (let i in chatUserDetails?.data) {
      if (sentBy?.toString() === chatUserDetails?.data[i]?.id?.toString()) {
        other_image = chatUserDetails?.data[i].image;
      }
    }
  }

  // sender
  if (customSendBy?.toString() === myId?.toString()) {
    return (
      <div className="right-side-chat">
        <div className="chat-avatar">
          {sentBy?.toString() === myId?.toString() ? (
            <img src={my_image ? my_image : blank} alt="Avatar" />
          ) : (
            <img src={other_image ? other_image : blank} alt="Avatar" />
          )}
        </div>

        {/* videolink, message and media conditions */}
        {message ? (
          <>
            <li>
              <b>{username ? username : "Unknown"}:</b>
              <MentionDisplayCard message={message} />
              <span className="timeconverted">
                {timeConverted ? timeConverted : null}
              </span>
            </li>
          </>
        ) : imageData ? (
          <li className="chat-media">
            <img src={imageData} alt="Media" />
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : null}
      </div>
    );
  }

  //receiver
  if (customSendBy?.toString() !== myId?.toString()) {
    return (
      <div className="left-side-chat">
        <div className="chat-avatar">
          {sentBy?.toString() !== myId?.toString() ? (
            <img src={other_image ? other_image : blank} alt="Avatar" />
          ) : (
            <img src={my_image ? my_image : blank} alt="Avatar" />
          )}
        </div>

        {/* message and media conditions */}
        {message ? (
          <li>
            <b>{username ? username : "Unknown"}:</b>
            <MentionDisplayCard message={message} />
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : imageData ? (
          <li className="chat-media">
            <img src={imageData} alt="Media" />
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : null}
      </div>
    );
  }

  return <></>;
};

export default NestedCardList;
