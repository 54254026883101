import React from "react";
import STCreate from "../PureComponents/STCreate";

const RenderSTCreate = () => {
  return (
    <>
      <STCreate />
    </>
  );
};

export default RenderSTCreate;
