import ChatHandling from "../PureComponents/ChatHandling";
import React from "react";

const RenderCreateExternalChatRoom = ({
  selectedTeamMember,
  setSelectedTeamMember,
  popup,
  hide,
  availableMember,
  allExternalMember,
  externalMember,
  setExternalMember,
  externalProfile,
  videoCallCreatorAdmin,
  toast,
}) => {
  return (
    <>
      <ChatHandling
        selectedTeamMember={selectedTeamMember}
        setSelectedTeamMember={setSelectedTeamMember}
        popup={popup}
        hide={hide}
        availableMember={availableMember}
        allExternalMember={allExternalMember}
        externalMember={externalMember}
        setExternalMember={setExternalMember}
        externalProfile={externalProfile}
        videoCallCreatorAdmin={videoCallCreatorAdmin}
        toast={toast}
      />
    </>
  );
};

export default RenderCreateExternalChatRoom;
