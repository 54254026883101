import {
  CHAT_PROFILE,
  GET_APPOINTMENTS_OVERVIEW,
  GET_GENERAL_INFO,
  GET_ORGANIZATION_DATA,
  GROUP_DATA,
  SERVICE_PAGE_TYPE,
  TICKET_CHAT_DATA,
  USER_LIST_DATA,
} from "Config/ActionType/action.type";

export const renderGeneralInfo = (response) => {
  return {
    type: GET_GENERAL_INFO,
    payload: response,
  };
};

export const renderAppointmentOverview = (response) => {
  return {
    type: GET_APPOINTMENTS_OVERVIEW,
    payload: response,
  };
};

export const renderOrganizationInfo = (response) => {
  return {
    type: GET_ORGANIZATION_DATA,
    payload: response,
  };
};

export const renderChatProfileAction = (response) => {
  return {
    type: CHAT_PROFILE,
    payload: response,
  };
};

export const renderRecentChatUserAction = (response) => {
  return {
    type: USER_LIST_DATA,
    payload: response,
  };
};

export const renderGroupDataAction = (response) => {
  return {
    type: GROUP_DATA,
    payload: response,
  };
};

export const servicePageTypeAction = (response) => {
  return {
    type: SERVICE_PAGE_TYPE,
    payload: response,
  };
};

export const ticketChatDataAction = (response) => {
  return {
    type: TICKET_CHAT_DATA,
    payload: response,
  };
};
