import EditProfile from "../PureComponents/EditProfile";
import React from "react";

const RenderEditProfile = ({ currEle, popup, hide  , getAllCategories}) => {
  return (
    <>
      <EditProfile currEle={currEle} popup={popup} hide={hide}   getAllCategories={  getAllCategories} />
    </>
  );
};

export default RenderEditProfile;
