import "./AllRefundRequest.css";

import React, { useEffect, useState } from "react";
import { approveRefundRequest, rejectRefundRequest } from "Config/API/action";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import { getRefundRequests } from "Config/API/get";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

const AllRefundRequest = () => {
  let doneTypingInterval = 1500;
  let typingTimer;
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [requestList, setRequestList] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [filterType, setFilterType] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoader();
    getData(filterType, searchedText);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (filter, searchKeywords) => {
    const requestResponse = getRefundRequests(token, searchKeywords, filter);
    requestResponse.then((res) => {
      resetLoader();
      if (res?.status === 200) {
        setRequestList(res?.data?.data);
      }
    });
  };

  const searchData = (value) => {
    setSearchedText(value);
    getData(filterType, value);
  };

  const filterData = (value) => {
    if (value === "all") {
      setFilterType("");
      getData("", searchedText);
      return;
    } else {
      setFilterType(value);
      getData(value, searchedText);
    }
  };

  const changeStatus = (status, data) => {
    if (loading) return;
    setLoading(true);
    if (status === "approve") {
      const statusChange = approveRefundRequest(token, data?.id);
      statusChange.then((res) => {
        setLoading(false);
        if (res?.status) {
          if (res?.message === "In-sufficient Credit") {
            toast(res?.message, {
              type: "info",
            });
          } else if (res?.message === "Refund Already approved Successfully") {
            toast(res?.message, {
              type: "info",
            });
          } else {
            getData("", "");
            toast(res?.message, {
              type: "success",
            });
          }
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      });
    } else if (status === "reject") {
      const statusChange = rejectRefundRequest(token, data?.id);
      statusChange.then((res) => {
        setLoading(false);
        if (res?.status) {
          getData("", "");
          toast(res?.message, {
            type: "success",
          });
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      });
    }
  };

  return (
    <>
      <div className="all-refund-request-parent">
        <div className="all-refund-request-header">
          <Headers />
        </div>

        <div className="all-refund-request-navigation">
          <Navigation />
        </div>

        <div className="all-refund-request-main">
          <div className="all-refund-request-wrapper">
            <div className="all-refund-request-heading">
              Refund Request Page
            </div>
            <div className="all-refund-request-all-active">
              <div className="all-refund-request-top">
                <input
                  type="search"
                  placeholder="Search"
                  onChange={(e) => {
                    clearTimeout(typingTimer);
                    if (e.target.value || e.target.value === "") {
                      typingTimer = setTimeout(() => {
                        searchData(e.target.value);
                      }, doneTypingInterval);
                    }
                  }}
                />
                <div>
                  <p>Filter:</p>
                  <select
                    onClick={(e) => {
                      filterData(e.target.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="pending">PENDING</option>
                    <option value="approved">APPROVED</option>
                    <option value="rejected">REJECTED</option>
                  </select>
                </div>
              </div>
              <div className="all-refund-request-row-wrapper">
                <div className="all-refund-request-head-row">DATE</div>
                <div className="all-refund-request-head-row">
                  TRANSACTION ID
                </div>
                <div className="all-refund-request-head-row">REQUESTOR ORG</div>
                <div className="all-refund-request-head-row">REASON</div>
                <div className="all-refund-request-head-row">CREDITS</div>
                <div className="all-refund-request-head-row">ACTIONS</div>
              </div>
              <div className="all-refund-request-bottom">
                {requestList?.length?.toString() !== "0" ? (
                  requestList?.map((curr, index) => {
                    return (
                      <div
                        className="all-refund-request-content-wrapper"
                        key={index}
                      >
                        <div className="all-refund-request-content-row">
                          {curr?.dateTime}
                        </div>
                        <div className="all-refund-request-content-row">
                          {curr?.transactionId}
                        </div>
                        <div className="all-refund-request-content-row">
                          {curr?.requestorOrg}
                        </div>
                        <div className="all-refund-request-content-row">
                          {curr?.reason}
                        </div>
                        <div
                          className="all-refund-request-content-row"
                          style={{ color: "#5CA127", fontWeight: "bold" }}
                        >
                          {curr?.credit}
                        </div>
                        <div className="all-refund-request-content-row">
                          {curr?.status?.toString() === "pending" && (
                            <div className="all-refund-status-button-container">
                              <CheckIcon
                                className="all-refund-status-button"
                                fontSize="small"
                                style={{ backgroundColor: "#5CA127" }}
                                onClick={() => changeStatus("approve", curr)}
                              />
                              <CloseIcon
                                className="all-refund-status-button"
                                fontSize="small"
                                style={{ backgroundColor: "#D81C1C" }}
                                onClick={() => changeStatus("reject", curr)}
                              />
                            </div>
                          )}
                          {curr?.status?.toString() === "approved" && (
                            <label style={{ color: "#5CA127" }}>Approved</label>
                          )}
                          {curr?.status?.toString() === "rejected" && (
                            <label style={{ color: "#CB1212" }}>Rejected</label>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="all-refund-request-msg">
                    No Requests Found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default AllRefundRequest;
