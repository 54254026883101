import "./Confirm.css";

import { Button } from "@mui/material";
import React from "react";

const Confirm = ({ message, option1, option2, funct, hide }) => {
  return (
    <>
      <nav className="popup-container">
        <div className="popup-popup confirm-box">
          <div
            className="popup-cross confirm-cross"
            onClick={() => hide(false)}
          >
            X
          </div>
          <span>{message}</span>
          <div className="popup-option-button">
            <Button
              variant="contained"
              style={{ color: "white", background: "#446fa2" }}
              onClick={() => {
                funct(option1);
                setTimeout(() => {
                  hide(false);
                }, [1000]);
              }}
            >
              {option1}
            </Button>
            <Button
              variant="contained"
              style={{ color: "white", background: "#446fa2" }}
              onClick={() => {
                funct(option2);
                setTimeout(() => {
                  hide(false);
                }, [1000]);
              }}
            >
              {option2}
            </Button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Confirm;
