import "./MoneyCreditTransactionReceiptPdf.css";

import React from "react";
import stripeImg from "Media/stripe-receipt-img.png";

const MoneyCreditTransactionReceiptPdf = ({ details }) => {
  return (
    <>
      <div className="money-credit-transaction-receipt-pdf-parent">
        <div className="money-credit-transaction-receipt-pdf-main">
          <div className="money-credit-transaction-receipt-pdf-form-body">
            {/* <div className="money-credit-transaction-receipt-pdf-heading">
              <p>Receipt Details</p>
              <div className="rename-cross">X</div>
            </div> */}
            <div className="money-credit-transaction-receipt-pdf-img">
              <img src={stripeImg} alt="stripe image" />
            </div>
            <div className="money-credit-transaction-receipt-pdf-wrapper">
              <div className="money-credit-transaction-receipt-pdf-top">
                <h3>Receipt from {details?.transactionWith || "-"}</h3>
                <span>Transaction ID: {details?.transactionId || "-"}</span>
              </div>
              <div className="money-credit-transaction-receipt-pdf-mid">
                <div className="money-credit-transaction-receipt-pdf-mid-content">
                  <h6>AMOUNT PAID</h6>
                  <span>${Math.abs(details?.credit) || "0"}</span>
                </div>
                <div className="money-credit-transaction-receipt-pdf-mid-content">
                  <h6>DATE PAID</h6>
                  <span>
                    {details?.dateTime || "-"}, {details?.time || "-"}
                  </span>
                </div>
                <div className="money-credit-transaction-receipt-pdf-mid-content">
                  <h6>PAYMENT METHOD</h6>
                  <span>
                    {details?.brand && details?.cardNumber
                      ? `${details?.brand} - ${details?.cardNumber}`
                      : "Site Credits"}
                  </span>
                </div>
              </div>
              {details?.slug === "video_chat" && (
                <>
                  <div
                    className="money-credit-transaction-receipt-pdf-mid"
                    style={{ marginTop: -30 }}
                  >
                    <div className="money-credit-transaction-receipt-pdf-mid-content">
                      <h6>CALL DURATION</h6>
                      <span>{+details?.duration / 60} min</span>
                    </div>
                    <div className="money-credit-transaction-receipt-pdf-mid-content">
                      <h6>RATE PER MINUTE</h6>
                      <span>${details?.rate}</span>
                    </div>
                  </div>
                  <div
                    className="money-credit-transaction-receipt-mid"
                    style={{ marginTop: -30 }}
                  >
                    <div className="money-credit-transaction-receipt-mid-content">
                      <h6>CALL ATTENDEES</h6>
                      <span>{details?.userNames}</span>
                    </div>
                  </div>
                </>
              )}
              <div className="money-credit-transaction-receipt-pdf-bot">
                <h4>SUMMARY</h4>
                <div className="money-credit-transaction-receipt-pdf-bot-box">
                  <div>
                    <span>{details?.description}</span>
                    <span>${Math.abs(details?.credit) || "0"}</span>
                  </div>
                  <div>
                    <span>Amount charged</span>
                    <span>${Math.abs(details?.credit) || "0"}</span>
                  </div>
                </div>
              </div>
              <div className="money-credit-transaction-receipt-pdf-email-box">
                <span>If you have any questions, contact us at</span>
                <a href="mailto:billingsupport@amnius.us">
                  billingsupport@amnius.us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoneyCreditTransactionReceiptPdf;
