import "./FileExplorer.css";

import React, { useEffect, useRef, useState } from "react";
import { fileExplorerSearch, getInternalFolder } from "Config/API/get";
import {
  internalFolderDataAction,
  selectedFolderDataAction,
  selectedFolderRootDataAction,
} from "Redux/action/get.action";
import { useDispatch, useSelector } from "react-redux";

import Bookmark from "Components/FileExplorer/Bookmark/Bookmark";
import ExternalIcon from "Media/icons/External_icon.svg";
import Headers from "Components/Header/Headers";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Navigation from "Components/SideNavBar/Navigation";
import RenderDirectory from "Components/FileExplorer/Directory/Container/RenderDirectory";
import RenderFolder from "Components/FileExplorer/Folder/Container/RenderFolder";
import SearchIcon from "@mui/icons-material/Search";
import { currentFolderAutomationAction } from "Redux/action/variable.action";
import folderIcon from "Media/icons/Folder-Close.png";
import profilePic from "Media/blank-profile-picture.png";
import smallFolderIcon from "Media/icons/Folder-Closeblue.png";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

const FileExplorer = ({ general_info }) => {
  let doneTypingInterval = 1500;
  let typingTimer;
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const internalFolder = useSelector(
    (state) => state.internalFolderDataReducer
  );
  const currentOrg = useSelector((state) => state.currentVisitOrgReducer);
  const [folder, setFolder] = useState("");
  // const addFolderData = useSelector((state) => state.selectedFolderDataReducer);
  // const bookmark_array = addFolderData?.bookmark?.split(" > ");

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref === wrapperRef) return setFolder("");
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // render organisation
  const Organisation = () => {
    return (
      <>
        <div className="file_explorer-logo">
          <img
            src={general_info?.logo ? general_info?.logo : profilePic}
            alt="profile"
            width="68px"
            height="68px"
          />
        </div>
        <div className="file_explorer_bio">
          <h3>
            {general_info?.organization_name
              ? general_info?.organization_name
              : "Organization Name"}
          </h3>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              setLoader();
              // we are auto clicking internal folder to skip it
              const internalFolderStatus = getInternalFolder(token);
              internalFolderStatus.then((res) => {
                resetLoader();
                if (res.status) {
                  dispatch(selectedFolderDataAction(res?.result[0]));
                  dispatch(internalFolderDataAction(res?.result[0]?.items));
                  dispatch(selectedFolderRootDataAction(res?.result));
                } else {
                  toast("Failed! Try again later", {
                    type: "error",
                  });
                }
              });
            }}
          >
            <img
              src={smallFolderIcon}
              alt="folder"
              style={{ width: "24px", height: "18px", marginRight: "5px" }}
            />
            <span style={{ color: "#647589", fontSize: "14px" }}>
              Internal Folders
            </span>
          </div>
        </div>
      </>
    );
  };

  // render left side
  const LeftSide = () => {
    return (
      <>
        <div className="file_explorer_lower">
          <div className="file_explorer_org">
            <Organisation />
          </div>
          <div className="file_explorer_folder">
            <RenderFolder />
          </div>
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // render right side
  const RightSide = () => {
    return (
      <>
        <div className="file_explorer_wrapper">
          <div className="file_explorer_lower">
            <div className="file_explorer_height">
              <Bookmark
                internalFolder={internalFolder}
                currentOrg={currentOrg}
                setLoader={setLoader}
                resetLoader={resetLoader}
              />
              <RenderDirectory />
            </div>
          </div>
        </div>
      </>
    );
  };

  // render search results
  const SearchComponent = ({ folder, setFolder, setLoader, resetLoader }) => {
    const handleSearchFolder = (array, folderId) => {
      array?.map((item) => {
        if (item?.unique_id === folderId) {
          dispatch(selectedFolderDataAction(item));
          return dispatch(internalFolderDataAction(item?.items));
        } else return handleSearchFolder(item?.items, folderId);
      });
    };

    return (
      <>
        {folder !== "" ? (
          folder?.length?.toString() !== "0" ? (
            <div className="organization-list">
              {folder?.map((curr, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setFolder("");
                      // folder automation
                      let length = curr?.sharePath?.split("document/").length;
                      let unique_id =
                        curr?.sharePath?.split("document/")[length - 1];
                      let before_document =
                        curr?.sharePath?.split("document/")[0];
                      if (before_document?.includes("internal")) {
                        setLoader();
                        // emptying the selected folder
                        dispatch(selectedFolderDataAction(""));
                        dispatch(internalFolderDataAction(""));
                        const internalFolderStatus = getInternalFolder(token);
                        internalFolderStatus.then((res) => {
                          resetLoader();
                          dispatch(selectedFolderRootDataAction(res?.result));
                          handleSearchFolder(
                            res?.result[0]?.items,
                            curr?.unique_id
                          );
                        });
                      } else dispatch(currentFolderAutomationAction(unique_id));
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      {curr?.type?.toString() === "folder" ? (
                        <img src={folderIcon} alt="Folder" />
                      ) : (
                        <InsertDriveFileIcon />
                      )}

                      {curr?.scope?.toString() === "external" ? (
                        <img
                          src={ExternalIcon}
                          alt="External"
                          style={{
                            position: "absolute",
                            right: "0",
                            bottom: "0",
                            width: "17.5px",
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="multiple-line-text">
                      <span>{curr?.name ? curr?.name : "No Name"}</span>
                      <i>({curr?.bookmark ? curr?.bookmark : null})</i>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="orgListAlert">No Such Folder Found</div>
          )
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className="file_explorer_container">
        <div className="file_explorer_header">
          <Headers />
        </div>
        <div className="file_explorer_navigation">
          <Navigation />
        </div>
        <div className="file_explorer_main">
          <div className="file_explorer_left">
            <div className="file_explorer_wrapper">
              <div className="file_explorer_upper">
                <div className="file_explorer_head">File Explorer</div>
                <div className="file_explorer_search" ref={wrapperRef}>
                  <div className="flex">
                    <input
                      type="search"
                      placeholder="Search Folders"
                      onChange={(e) => {
                        clearTimeout(typingTimer);
                        if (e.target.value) {
                          typingTimer = setTimeout(() => {
                            const search_status = fileExplorerSearch(
                              token,
                              e.target.value,
                              ""
                            );
                            search_status.then((res) => {
                              if (res?.status) {
                                setFolder(res?.result);
                              }
                            });
                          }, doneTypingInterval);
                        }
                      }}
                    />
                    <SearchIcon />
                  </div>

                  {/****************  Folder List ***************/}
                  {folder ? (
                    <SearchComponent
                      folder={folder}
                      setFolder={setFolder}
                      setLoader={setLoader}
                      resetLoader={resetLoader}
                    />
                  ) : null}
                </div>
              </div>
              <LeftSide />
            </div>
          </div>
          <div className="file_explorer_right">
            <RightSide />
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default FileExplorer;
