import React from "react";
import SuperAdmin from "../PureComponents/SuperAdmin";

const RenderSuperAdmin = () => {
  return (
    <>
      <SuperAdmin />
    </>
  );
};

export default RenderSuperAdmin;
