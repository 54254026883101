import "./STReassignPopup.css";

import { Button, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  getCategoryListNames,
  getTechListing,
  listOfServiceTicketPermissionUsers,
} from "Config/API/get";

import blank from "Media/blank-profile-picture.png";
import { toast } from "react-toastify";

const STReassignPopup = ({
  verify,
  hide,
  org_id,
  users,
  user_id,
  type,
  category,
  ticketScope,
  teamsName,
}) => {
  const token = localStorage.getItem("token");
  // ref**
  const userRef = useRef(null);
  useOutsideAlerter(userRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const [text, setText] = useState(user_id ? user_id : "");
  const [inlineText, setInlineText] = useState(users ? users : "");
  const [userList, setUserList] = useState("");
  const [AllTeamsList, setAllTeamsList] = useState([]);
  const [teamName, setTeamName] = useState(teamsName ? teamsName : "");
  const [teamsId, setTeamsId] = useState(category ? category : "");
  const [assignTechList, setAssignTechList] = useState([]);
  const [assignTechName, setAssignTechName] = useState(users ? users : "");
  const [techId, setTechId] = useState(user_id ? user_id : "");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === "external") {
      if (user_id?.toString() === text?.toString()) {
        toast("Please select a different user", {
          type: "info",
        });
      } else {
        verify(text, "");
        setTimeout(() => {
          hide(false);
        }, 1000);
      }
    } else {
      if (
        user_id?.toString() === techId?.toString() &&
        category?.toString() === teamsId?.toString()
      ) {
        toast("Please select a different user", {
          type: "info",
        });
      } else {
        verify(techId, teamsId);
        setTimeout(() => {
          hide(false);
        }, 1000);
      }
    }
  };

  return (
    <>
      <nav className="streassign-container">
        <div className="streassign-popup">
          <div className="streassign-cross" onClick={() => hide(false)}>
            X
          </div>
          <span>Reassign Ticket:</span>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="streassign-email">
              <div ref={userRef}>
                {type === "external" ? (
                  <TextField
                    required
                    id="assignTech"
                    label="Assigned Tech"
                    placeholder="Assigned Tech"
                    variant="outlined"
                    type="text"
                    className="assign-tech"
                    value={inlineText}
                    onClick={() => {
                      const user_list = listOfServiceTicketPermissionUsers(
                        token,
                        ticketScope,
                        ""
                      );
                      user_list.then((res) => {
                        if (res?.status) {
                          setUserList(res?.emp);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onChange={(e) => {
                      setInlineText(e.target.value);
                      const user_list = listOfServiceTicketPermissionUsers(
                        token,
                        ticketScope,
                        e.target.value
                      );
                      user_list.then((res) => {
                        if (res?.status) {
                          setUserList(res?.emp);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                  />
                ) : (
                  <>
                    <TextField
                      required
                      id="category"
                      label="Category"
                      placeholder="Category"
                      variant="outlined"
                      type="text"
                      value={teamName}
                      className="category-name"
                      onClick={() => {
                        const team_list = getCategoryListNames(token);
                        team_list.then((res) => {
                          if (res?.status) {
                            setAllTeamsList(res?.data?.data);
                            setAssignTechName("");
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }}
                      onChange={(e) => {
                        setTeamName(e.target.value);
                        const team_list = getCategoryListNames(token);
                        team_list.then((res) => {
                          if (res?.status) {
                            setAllTeamsList(res?.data?.data);
                            setAssignTechName("");
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }}
                    />
                    <TextField
                      required
                      id="assignTech"
                      label="Assigned Tech"
                      placeholder="Assigned Tech"
                      variant="outlined"
                      type="text"
                      className="assign-tech"
                      value={assignTechName}
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        const response = getTechListing(token, teamsId);
                        response.then((res) => {
                          if (res?.status) {
                            setAssignTechList(res?.data?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }}
                      onChange={(e) => {
                        const response = getTechListing(token, teamsId);
                        response.then((res) => {
                          if (res?.status) {
                            setAssignTechList(res?.data?.data);
                          } else {
                            toast(res?.message, {
                              type: "error",
                            });
                          }
                        });
                      }}
                    />
                  </>
                )}

                {/****************  User List ***************/}
                <div className="organization-list">
                  {type === "external" ? (
                    <>
                      {userList.length?.toString() !== "0" ? (
                        !userList.message ? (
                          userList?.map((curr, index) => {
                            return (
                              <div className="data-list" key={index}>
                                <div>
                                  <img
                                    src={curr?.image ? curr?.image : blank}
                                    alt="Logo"
                                  />
                                  <span>
                                    {curr?.username
                                      ? curr?.username
                                      : "No Name"}
                                  </span>
                                </div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    setText(curr?.id);
                                    setUserList("");
                                    setInlineText(curr?.username);
                                  }}
                                >
                                  Select
                                </Button>
                              </div>
                            );
                          })
                        ) : (
                          <div className="content-loading">No Result Found</div>
                        )
                      ) : null}
                    </>
                  ) : (
                    <>
                      {AllTeamsList.length?.toString() !== "0" ? (
                        !AllTeamsList.message ? (
                          AllTeamsList?.map((curr, index) => {
                            return (
                              <div className="data-list" key={index}>
                                <div>
                                  <img
                                    src={curr?.image ? curr?.image : blank}
                                    alt="Logo"
                                  />
                                  <span>
                                    {curr?.category_id
                                      ? curr?.category_id
                                      : "No Name"}
                                  </span>
                                </div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    setTeamsId(curr?.id);
                                    setAllTeamsList("");
                                    setTeamName(curr?.category_id);
                                  }}
                                >
                                  Select
                                </Button>
                              </div>
                            );
                          })
                        ) : (
                          <div className="content-loading">No Result Found</div>
                        )
                      ) : null}
                      {assignTechList.length !== 0 ? (
                        !assignTechList.message ? (
                          assignTechList?.map((curr, index) => {
                            return (
                              <div className="data-list" key={index}>
                                <div>
                                  <img
                                    src={curr?.image ? curr?.image : blank}
                                    alt="Logo"
                                  />
                                  <span>
                                    {curr?.username
                                      ? curr?.username
                                      : "No Name"}
                                  </span>
                                </div>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => {
                                    setTechId(curr?.id);
                                    setAssignTechList("");
                                    setAssignTechName(curr?.username);
                                  }}
                                >
                                  Select
                                </Button>
                              </div>
                            );
                          })
                        ) : (
                          <div className="content-loading">No Result Found</div>
                        )
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="rename-submit">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: "#446fa2" }}
              >
                SUBMIT
              </Button>
            </div>
          </form>
        </div>
      </nav>
    </>
  );
};

export default STReassignPopup;
