import "./AcceptExternalVideoChat.css";

import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const AcceptExternalVideoChat = ({ popup, hide, accept, rate }) => {
  return popup ? (
    <div className="accept-chat-room-container">
      <div className="accept-external-chat-room-dialog ">
        <div className="accept-external-chat-room-icon">
          <button
            className="accept-chat-room-close-button"
            onClick={() => hide(false)}
          >
            <CloseIcon fontSize="small" color="black" />
          </button>
        </div>
        <p>
          This Is A Billable Video Chat. You Agree To The{" "}
          <a onClick={() => alert("TERMS AND CONDITIONS PAGE OPENED")}>
            Terms And Conditions
          </a>{" "}
          And Accept The Users Billing Rate of {rate} Credits Per Minute.
        </p>
        <button
          onClick={() => accept()}
          className="accept-chat-room-accept-button"
        >
          I Accept
        </button>
      </div>
    </div>
  ) : null;
};

export default AcceptExternalVideoChat;
