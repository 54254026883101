import "./StorageLimitReachedPopup.css";

import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

const StorageLimitReachedPopup = ({ hide }) => {
  const navigate = useNavigate();
  return (
    <div className="storage-limit-reached-parent">
      <div className="storage-limit-reached-main">
        <div className="storage-limit-reached-form-body" autoComplete="off">
          <div className="storage-limit-reached-heading">
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="storage-limit-reached-wrapper">
            <div className="storage-limit-reached-fields">
              <label>Storage Plan</label>
            </div>
            <div className="storage-limit-reached-fields">
              <span>
                You have used all your storage. Please upgrade your plan to
                continue.
              </span>
            </div>

            <div className="storage-limit-reached-fields">
              <Button
                variant="contained"
                style={{
                  background: "rgb(92, 161, 39)",
                }}
                onClick={() => {
                  hide(false);
                  navigate("/organization-storage-dashboard", {
                    state: { showUpgradePlan: true },
                  });
                }}
              >
                Upgrade
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorageLimitReachedPopup;
