import "./TransferCredits.css";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import ApiLoader from "Components/Loader/ApiLoader";
import { renderOrganizations } from "Config/API/get";
import { toast } from "react-toastify";
import { useState } from "react";

const TransferCredits = ({ credits, status, hide, callback }) => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [orgId, setOrgId] = useState("");
  const [orgList, setOrgList] = useState([]);

  const getAllOrganizationList = async () => {
    try {
      if (!loading) setLoading(true);
      const response = await renderOrganizations(token, "");
      const data = await response;
      setLoading(false);
      if (data?.status) {
        setOrgList(data?.listOfOrganisation);
      } else {
        toast(data?.message, { type: "error" });
      }
    } catch (e) {
      setLoading(false);
      toast("Failed! Try Again Later", { type: "error" });
    }
  };

  const renderPayButton = () => {
    if (!status)
      return (
        <Button
          type="submit"
          className="transfer-credit-pay-button"
          variant="contained"
        >
          Pay
        </Button>
      );

    return (
      <Button
        type="button"
        className="transfer-credit-pay-button"
        style={{ backgroundColor: "rgb(92, 161, 39)", pointerEvents: "none" }}
        variant="contained"
      >
        Processing ...
      </Button>
    );
  };

  return (
    <div className="super-admin-transfer-credits-wrapper">
      <div className="super-admin-transfer-credits-inner">
        <div
          style={{
            marginBottom: "15px",
            color: "#446fa2",
            textTransform: "uppercase",
          }}
        >
          Available Credits:{" "}
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            {credits || 0}
          </span>
        </div>
        <button type="button" onClick={() => hide(false)}>
          X
        </button>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            callback(orgId, Number.parseFloat(amount));
          }}
        >
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="select-organization">
              Select Organization *
            </InputLabel>
            <Select
              required
              labelId="select-organization"
              label="Select Organization *"
              onFocus={() => getAllOrganizationList()}
              value={orgId}
              onChange={(e) => setOrgId(e?.target?.value)}
            >
              {!loading ? (
                orgList?.length > 0 ? (
                  orgList?.map((item) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      {item?.organization_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">Select Any One Organization</MenuItem>
                )
              ) : (
                <MenuItem
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100px",
                  }}
                >
                  <ApiLoader
                    height="35px"
                    width="35px"
                    borderWidth="6px"
                    borderTopWidth="6px"
                  />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            required
            type="number"
            name="amount"
            value={amount}
            placeholder="Enter Amount"
            label="Add Amount"
            style={{ margin: "16px 0" }}
            onChange={(e) => setAmount(e.target.value)}
          />
          {renderPayButton()}
        </form>
      </div>
    </div>
  );
};

export default TransferCredits;
