import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import blank from "Media/blank-profile-picture.png";
import firebase from "firebase/compat/app";
import moment from "moment-timezone";
import { renderChatProfileAction } from "Redux/action/render.action";
import { seenOnClick } from "Components/Chat/ChatTimer/ChatTimer";

const ChatUserSubCard = () => {
  const user_data_list = useSelector(
    (state) => state.renderRecentChatUserReducer
  );
  const my_id = useSelector((state) => state.authenticationReducer?.id);
  const send_by = useSelector((state) => state.authenticationReducer?.id);
  const dispatch = useDispatch();

  const LoadCard = ({ curr }) => {
    const [message, setMessage] = useState("");

    // getting message and time from firebase
    useEffect(() => {
      const messageFunction = async () => {
        const messageRef = firebase
          .firestore()
          .collection("chatroom")
          .orderBy("createdAt", "desc");

        messageRef.onSnapshot((querySnap) => {
          const allData = querySnap.docs?.map((docSanp) => {
            const data = docSanp.data();
            if (data.createdAt) {
              return {
                ...docSanp.data(),
                createdAt: docSanp.data().createdAt.toDate(),
              };
            } else {
              return {
                ...docSanp.data(),
                createdAt: new Date(),
              };
            }
          });
          setMessage(allData);
        });
      };

      const messageFnReturn = messageFunction();
    }, []);

    const RenderChatList = ({
      message,
      time,
      curr,
      date,
      seen_data,
      docid,
    }) => {
      while (message.includes("&*&")) {
        message = message.replace("&*&", "\n");
      }
      let recent_msg = false;

      // condition for recent msg class
      if (seen_data) {
        // checking if this is empty
        for (let i in seen_data) {
          if (
            Object.values(seen_data[i])?.toString() === "unseen" &&
            Object.keys(seen_data[i])?.toString() === send_by?.toString()
          ) {
            recent_msg = true;
          }
        }
      }

      return (
        <div
          className={
            recent_msg
              ? "chatusercard-container recent-msg"
              : "chatusercard-container"
          }
          onClick={() => {
            if (curr?.unique_id || false) {
              dispatch(renderChatProfileAction(curr?.unique_id));
            } else {
              dispatch(renderChatProfileAction(curr?.id));
            }
            // on click we are updating UNSEEN to SEEN
            if (seen_data) {
              const update_seen_data = seenOnClick(seen_data, send_by);
              firebase.firestore().collection("chatroom").doc(docid).update({
                seen_data: update_seen_data,
              });
            }
          }}
        >
          <div>
            <div className="chat-screen-logo">
              <img src={curr?.image ? curr?.image : blank} alt="Logo" />
              <div className="chat-screen-status-dot">
                {curr?.online_status?.toString() === "online" ? (
                  <span className="user-status" style={{ color: "#87c823" }}>
                    <FiberManualRecordIcon />
                  </span>
                ) : (
                  <span className="user-status" style={{ color: "#c23616" }}>
                    <FiberManualRecordIcon />
                  </span>
                )}
              </div>
            </div>
            <div className="chat-screen-profile">
              <div className="chat-screen-box">
                <img src={curr?.logo ? curr?.logo : blank} alt="Logo" />
              </div>

              <h5>{curr?.name ? curr?.name : "No Name"}</h5>
              <p>{message ? message : "..."}</p>
            </div>
          </div>
          <div className="chat-screen-time">
            <span>{time ? time : null}</span>
            <span>{date ? date : null}</span>
          </div>
        </div>
      );
    };

    return (
      <>
        {message?.toString() !== ""
          ? message?.map((currEle, index) => {
              // this logic compares the firebase and backend data then prints the result
              for (let i in currEle.chatID) {
                if (my_id?.toString() === currEle?.chatID[i]?.toString()) {
                  // for one-to-one chat
                  if (currEle?.type?.toString() !== "group") {
                    for (let j in currEle.chatID) {
                      if (my_id !== currEle.chatID[j]) {
                        if (
                          currEle.chatID[j]?.toString() === curr?.id?.toString()
                        ) {
                          var date = moment(currEle?.createdAt)
                            .tz("Europe/Paris")
                            .format();
                          let timeConverted = moment(date).format("hh:mm A");
                          let dateConverted = moment(date).format("MM/DD/YYYY");
                          return (
                            <RenderChatList
                              key={index}
                              message={currEle?.lastMessage}
                              time={timeConverted}
                              curr={curr}
                              date={dateConverted}
                              seen_data={currEle?.seen_data}
                              docid={currEle?.docID}
                            />
                          );
                        }
                      }
                    }
                  } else if (currEle?.type?.toString() === "group") {
                    // for groups
                    if (
                      curr?.unique_id?.toString() === currEle?.docID?.toString()
                    ) {
                      var date = moment(currEle.createdAt)
                        .tz("Europe/Paris")
                        .format();
                      let timeConverted = moment(date).format("hh:mm A");
                      let dateConverted = moment(date).format("MM/DD/YYYY");
                      return (
                        <RenderChatList
                          key={index}
                          message={currEle.lastMessage}
                          time={timeConverted}
                          curr={curr}
                          date={dateConverted}
                          seen_data={currEle?.seen_data}
                          docid={currEle?.docID}
                        />
                      );
                    }
                  }
                }
              }
            })
          : null}
      </>
    );
  };

  return (
    <>
      {user_data_list.length?.toString() !== "0" ? (
        user_data_list?.map((curr, index) => {
          return <LoadCard key={index} curr={curr} />;
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          No Recent Chats
        </div>
      )}
    </>
  );
};

export default ChatUserSubCard;
