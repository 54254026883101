const formValidators = (phone, email, office) => {
  const errors = {};
  const regEmail = new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const regPhone = new RegExp(/^\d{10}$/);

  if (email) {
    if (!regEmail.test(email)) {
      errors.email = "Not a valid email!";
    }
  }
  if (phone) {
    if (!regPhone.test(phone)) {
      errors.phone = "Not a valid number!";
    }
  }
  if (office) {
    if (!regPhone.test(office)) {
      errors.office = "Not a valid number!";
    }
  }

  return errors;
};

export default formValidators;
