import ApiLoader from "Components/Loader/ApiLoader";
import React from "react";
import logo from "Media/Amnius_connect_noText.png";

const PreviewScreen = ({ joinVideoCallRoom }) => {
  return (
    <>
      <div style={{ width: "100%", height: "100vh" }}>
        <div
          style={{
            display: "flex",
            padding: "0 20px",
            height: "100px",
            alignItems: "center",
          }}
        >
          <img className="video-call-logo" src={logo} alt="logo" height={60} />
          <h2 style={{ color: "white", marginLeft: 20 }}>Amnius Connect</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
          }}
        >
          <ApiLoader
            height="40px"
            width="40px"
            borderWidth="7px"
            borderTopWidth="7px"
          />
          <h3 style={{ color: "white" }}>Connecting...</h3>
        </div>
      </div>
    </>
  );
};

export default PreviewScreen;
