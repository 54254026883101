import "./STDashboard.css";

import React, { useEffect, useState } from "react";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import PremiumIcon from "Media/premium@2x.png";
import PurchaseSubscription from "Components/Popup/PurchaseSubscription/PurchaseSubscription";
import ServiceTicketIcon from "Media/Group 353@2x.png";
import SubscriptionIcon from "Media/subscription@2x.png";
import UserManagementIcon from "Media/team-management (1)@2x.png";
import ViewTicketsIcon from "Media/Group 354@2x.png";
import { getAllSubscriptions } from "Config/API/get";
import { purchaseSubscription } from "Config/API/action";
import { purchaseSubscriptionMessages } from "Constants/Values";
import { servicePageTypeAction } from "Redux/action/render.action";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const STDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const token = localStorage.getItem("token");
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [showPurchasePopup, setShowPurchasePopup] = useState(false);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [serviceTicketSubscription, setServiceTicketSubscription] = useState(
    []
  );

  useEffect(() => {
    getAllAvailableSubscriptions();
    // eslint-disable-next-line
  }, []);

  const getAllAvailableSubscriptions = () => {
    setLoader();
    const response = getAllSubscriptions(token);
    response.then((res) => {
      resetLoader();
      if (res.data.status) {
        const filteredSubscription = res.data.data?.filter(
          (item) => item?.subscription_type === "service_ticket"
        );
        setServiceTicketSubscription(filteredSubscription[0]);
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const handleServiceTicketButtonClick = () => {
    if (serviceTicketSubscription?.subscriptionStatus?.toString() === "true") {
      dispatch(servicePageTypeAction("PREMIUM"));
      if (
        loggedInUserDetails?.ticket_external_read_role !== "manager" &&
        loggedInUserDetails?.ticket_internal_read_role !== "manager"
      ) {
        return toast("You do not have access", {
          type: "info",
        });
      }
      if (loggedInUserDetails?.ticket_external_read_role === "manager") {
        navigate("/service-ticket-view-page");
      } else {
        navigate("/all-internal-service-tickets");
      }
    } else if (serviceTicketSubscription?.rate?.length > 0) {
      if (loggedInUserDetails?.storage_subscription === "manager") {
        setShowPurchasePopup(true);
      } else {
        toast("You do not have access", {
          type: "info",
        });
      }
    } else toast("Service not purchasable yet", { type: "info" });
  };

  const subscribeService = () => {
    const response = purchaseSubscription(
      token,
      serviceTicketSubscription?.subscription_type,
      serviceTicketSubscription?.rate,
      ""
    );
    response.then((res) => {
      setPurchaseLoading(false);
      if (res.status) {
        if (purchaseSubscriptionMessages?.includes(res?.message?.toLowerCase()))
          return toast(res.message, {
            type: "info",
          });
        else {
          getAllAvailableSubscriptions();
          setShowPurchasePopup(false);
          toast(res.message, {
            type: "success",
          });
        }
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  return (
    <>
      <div className="stdashboard-parent">
        <div className="stdashboard-header">
          <Headers />
        </div>

        <div className="stdashboard-navigation">
          <Navigation />
        </div>

        <div className="stdashboard-main">
          <div className="stdashboard-heading">
            <p>Service Ticketing Dashboard</p>
          </div>
          <div className="stdashboard-wrapper">
            <div className="stdashboard-wrapper-content">
              <div
                className={`stdashboard-card ${
                  (serviceTicketSubscription?.subscriptionStatus?.toString() !==
                    "true" ||
                    (loggedInUserDetails?.ticket_external_read_role !==
                      "manager" &&
                      loggedInUserDetails?.ticket_internal_read_role !==
                        "manager")) &&
                  "disable"
                }`}
                onClick={() => handleServiceTicketButtonClick()}
              >
                <img src={ServiceTicketIcon} alt="Service-Ticket" />
                <span>Service Ticketing Main Page</span>
                <img src={PremiumIcon} alt="Premium" className="hover-image" />
              </div>

              <div
                className="stdashboard-card"
                onClick={() => {
                  dispatch(servicePageTypeAction("NON-PREMIUM"));
                  navigate("/service-ticket-view-page");
                }}
              >
                <img src={ViewTicketsIcon} alt="Service-Ticket" />
                <span>View Tickets Created for You</span>
              </div>

              <div
                className="stdashboard-card-last-child"
                onClick={() => {
                  navigate("/service-ticket-user-management");
                }}
              >
                <img src={UserManagementIcon} alt="Service-Ticket" />
                <span>User Management</span>
              </div>
              <div
                className="stdashboard-card-last-child"
                onClick={() => {
                  navigate("/rates-and-subscription-management", {
                    state: { tab: "mySubscriptions" },
                  });
                }}
              >
                <img src={SubscriptionIcon} alt="Service-Ticket" />
                <span>Subscription Management</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPurchasePopup && (
        <PurchaseSubscription
          header="Service Ticketing & Asset Management"
          description={`This is a Premium Service. You need to purchase it with a charge of ${serviceTicketSubscription?.rate} Credits Per Month.`}
          hide={setShowPurchasePopup}
          subscribeService={subscribeService}
          setLoading={setPurchaseLoading}
          loading={purchaseLoading}
        />
      )}
      {loader}
    </>
  );
};

export default STDashboard;
