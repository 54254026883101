import { Box3, Vector3 } from 'three'
import { useEffect, useRef, useState } from 'react'

import { Canvas } from '@react-three/fiber'
import { LoadStep } from 'Helper/StepLoader'
import { OrbitControls } from '@react-three/drei'
import useFullPageLoader from 'Hooks/useFullPageLoader'

function StepModel({ url, showLoader, hideLoader, ...props }) {
  const meshRef = useRef()
  const [obj, setObj] = useState(null)
  const [scale, setScale] = useState([0.035, 0.035, 0.035]) // Default scale

  useEffect(() => {
    async function load() {
      showLoader()
      const mainObject = await LoadStep(url)
      setObj(mainObject)
      hideLoader()
    }
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!obj) return
    const box = new Box3().setFromObject(meshRef.current)
    const size = new Vector3()
    box.getSize(size)

    const maxDim = Math.max(size.x, size.y, size.z)

    const desiredSize = 0.11
    const scaleFactor = desiredSize / maxDim

    setScale([scaleFactor, scaleFactor, scaleFactor])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj])

  if (!obj) {
    return null
  }
  return (
    <mesh ref={meshRef} scale={scale} {...props}>
      <primitive object={obj} />
    </mesh>
  )
}

const STEPFileViewer = ({ fileUrl }) => {
  const [loader, setLoader, resetLoader] = useFullPageLoader()
  return (
    <>
      <Canvas>
        <OrbitControls />
        <ambientLight intensity={0.7} />
        <spotLight
          castShadow
          position={[15, 20, 15]} // Moved it up for a sharper angle
          angle={0.2} // Narrower angle for more focused light
          penumbra={0.1} // Reduced penumbra for sharper edges
          intensity={1.2} // Increased intensity
          shadow-mapSize-width={2048} // Higher resolution shadow map
          shadow-mapSize-height={2048} // Higher resolution shadow map
          shadow-bias={-0.0001} // Small bias to reduce shadow artifacts
        />
        <spotLight
          castShadow
          position={[-15, 20, 15]}
          angle={0.2}
          penumbra={0.1}
          intensity={1.2}
          shadow-mapSize-width={2048}
          shadow-mapSize-height={2048}
          shadow-bias={-0.0001}
        />
        <directionalLight
          castShadow
          position={[5, 15, 7.5]}
          intensity={0.8} // Slightly increased intensity
          shadow-mapSize-width={2048}
          shadow-mapSize-height={2048}
          shadow-bias={-0.0001}
        />
        <pointLight
          castShadow
          position={[-10, -10, -10]}
          intensity={0.3} // Reduced intensity to avoid over-brightening
          distance={50} // Added distance for attenuation
          decay={2} // Added decay for more realistic fall-off
        />
        <hemisphereLight
          skyColor={'#ffffff'}
          groundColor={'#222222'} // Darkened ground color for more contrast
          intensity={0.4} // Slightly reduced intensity
        />
        <StepModel castShadow receiveShadow url={fileUrl} showLoader={setLoader} hideLoader={resetLoader} />
      </Canvas>
      {loader}
    </>
  )
}

export default STEPFileViewer
