import "./ApiLoader.css";

import React from "react";

const ApiLoader = ({ height, width, borderWidth, borderTopWidth }) => {
  return (
    <div
      class="loader"
      style={{
        height: height,
        width: width,
        borderWidth: borderWidth,
        borderTopWidth: borderTopWidth,
      }}
    ></div>
  );
};

export default ApiLoader;
