import React, { useState } from "react";

import Loader from "Components/Loader/Loader";

const useFullPageLoader = () => {
  const [loading, setLoading] = useState(false);
  return [
    loading ? <Loader /> : null,
    () => setLoading(true),
    () => setLoading(false),
  ];
};

export default useFullPageLoader;
