import {
  CHAT_PROFILE,
  GET_APPOINTMENTS_OVERVIEW,
  GET_GENERAL_INFO,
  GET_ORGANIZATION_DATA,
  GROUP_DATA,
  SERVICE_PAGE_TYPE,
  TICKET_CHAT_DATA,
  USER_LIST_DATA,
} from "Config/ActionType/action.type";

const initial = "";

export const renderGeneralInfo = (state = initial, action) => {
  switch (action?.type) {
    case GET_GENERAL_INFO:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const renderOrganizationInfoReducer = (state = initial, action) => {
  switch (action?.type) {
    case GET_ORGANIZATION_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const appointmentDashboardReducer = (state = initial, action) => {
  switch (action?.type) {
    case GET_APPOINTMENTS_OVERVIEW:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const renderChatProfileReducer = (state = initial, action) => {
  switch (action?.type) {
    case CHAT_PROFILE:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const renderRecentChatUserReducer = (state = initial, action) => {
  switch (action?.type) {
    case USER_LIST_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const renderGroupDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case GROUP_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const servicePageTypeReducer = (state = initial, action) => {
  switch (action?.type) {
    case SERVICE_PAGE_TYPE:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const ticketChatDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case TICKET_CHAT_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};
