import Popup from "../PureComponents/QRcodePopup";
import React from "react";

const RenderQRPopup = ({
  path,
  hide,
  internalFolder,
  folderData,
  selectedOrgLogo,
}) => {
  return (
    <Popup
      selectedOrgLogo={selectedOrgLogo}
      folderData={folderData}
      internalFolder={internalFolder}
      path={path}
      hide={hide}
    />
  );
};

export default RenderQRPopup;
