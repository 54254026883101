import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51N8M1pJ0g5mwZtdKfG95lCB7P0BSvSbs2oNp7KoZMF4NujJKeLzivxtlvvoRFylwdfc6oG8r7PtdOdFJJH8t5xu900mKd9jcT8"
);

const StripeCredit = () => {
  const navigate = useNavigate();
  const clientSecret = useSelector(
    (state) => state.setClientSecretKeyReducer?.client_secret
  );
  // const clientSecret =
  //   "pi_3Mt6jJSGqK04lQI509W4EWLp_secret_36wVX4QJh5NAOCYoLchxXXFtC";

  // this is a condition when we don't have client secret key
  useEffect(() => {
    if (!clientSecret && clientSecret?.length <= 0) {
      navigate("/money-credit-dashboard");
      toast("You do not have client secret key", { type: "error" });
    }
  }, []);

  return (
    <>
      <div className="stripe-credit-main">
        <div className="stripe-credit-wrapper">
          <h1>Stripe Gateway</h1>
          {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm />
            </Elements>
          )}
        </div>
      </div>
    </>
  );
};

export default StripeCredit;
