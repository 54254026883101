import AcceptExternalVideoChat from "../PureComponents/AcceptExternalVideoChat";
import React from "react";

const RenderAcceptExternalVideoChat = ({ popup, hide, accept, rate }) => {
  return (
    <>
      <AcceptExternalVideoChat
        popup={popup}
        hide={hide}
        accept={accept}
        rate={rate}
      />
    </>
  );
};

export default RenderAcceptExternalVideoChat;
