import { useDispatch, useSelector } from "react-redux";

import Email from "../PureComponents/Email";
import React from "react";
import { toast } from "react-toastify";
import { writeEmail } from "Config/API/action";

const RenderEmail = ({ setEmailPopup, toEmail }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const email_data = (to, from, subject, bodydata) => {
    const email_status = writeEmail(token, to, from, subject, bodydata);
    email_status
      .then((res) => {
        res?.json().then((response) => {
          if (response?.status) {
            toast(response?.message, {
              type: "success",
            });
          } else {
            toast(response?.message, {
              type: "error",
            });
          }
        });
      })
      .catch((e) => {
        toast("Failed! Try Again Later", {
          type: "error",
        });
      });
  };

  return (
    <Email
      email_data={email_data}
      setEmailPopup={setEmailPopup}
      toEmail={toEmail}
    />
  );
};

export default RenderEmail;
