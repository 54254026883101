import "./Verification.css";

import React from "react";
import logo from "Media/Amnius_connect_with_text.png";

const Verification = () => {
  return (
    <>
      <div className="verification-container">
        <div className="verification-wrapper">
          <div className="verification-upper">
            <div className="verification-logo">
              <img src={logo} alt="Aminus logo" />
            </div>
          </div>

          <div className="verification-below">
            {/* <div className="verification-message">Your Email is Verified!</div>
            <div className="verification-button">
              <Button
                variant="contained"
              >
                Click Here to Login!
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
