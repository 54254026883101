import "./PartDetail.css";
import React, { useEffect, useState } from "react";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import uploadIcon from "Media/icons/awesome-file-upload.svg";
import qrcode from "Media/icons/metro-qrcode.svg";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { getAssetPartbyId } from "Config/API/get";
import useFullPageLoader from "Hooks/useFullPageLoader";
import AssetQrPopup from "Components/Popup/AssetQRPopup/assetQrPopup.jsx";
import { toast } from "react-toastify";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import { addPartFile } from "Config/API/action";
const PartDetail = () => {
  const [inputClass, setInputClass] = useState("");
  const [qrPopup, setQrPopup] = useState(false);
  const [myPart, setMyPart] = useState(null);
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const handleFocus = () => {
    setInputClass("input-no-border");
  };
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [uploadButtonDisable, setUploadButtonDisable] = useState(false);
  const isStorageLimitReached = useIsStorageLimitReached();
  const token = localStorage.getItem("token");
  const handleBlur = () => {
    setInputClass("");
  };
  const fullURL = window.location.href;
  const parsedURL = new URL(fullURL);

  const baseURL = parsedURL.protocol + "//" + parsedURL.hostname;
  const qrCodeUrl = myPart?.path
    ?.toString()
    ?.replace(
      baseURL,
      `${baseURL}/amnius-connect/api/deep-link?url=amniusconnect://app`
    );

  const uniqueid = useSelector((state) => state.partIdReducer);
  useEffect(() => {
    getAssetParts();
  }, []);
  const getAssetParts = async () => {
    setLoader();
    try {
      const assetData = await getAssetPartbyId(token, uniqueid);

      if (assetData && assetData.status) {
        setMyPart(assetData.data.data);
      } else {
        toast("Failed! Try again later", { type: "error" });
      }
    } catch (error) {
      console.error("Error fetching part data:", error);
      toast("Failed! Try again later", { type: "error" });
    } finally {
      resetLoader();
    }
  };
  const addAFile = async (uploadFile) => {
    setUploadButtonDisable(true);
    const toastId = toast("Uploading, Please Wait...", { type: "info" });

    try {
      const res = await addPartFile(
        token,
        myPart.assetId,
        myPart.parent_unique_id,
        uploadFile,
        { current: toastId }
      );

      if (res?.status) {
        if (res?.message?.includes("limit")) {
          return toast(res?.message, { type: "info" });
        }

        toast.update(toastId, {
          render: "Uploaded!",
          type: "success",
          autoClose: 4000,
        });
      } else {
        toast(res?.message, { type: "error" });
      }
    } catch (error) {
      console.error("Upload failed:", error);
      toast("Failed! Try again later", { type: "error" });
    } finally {
      setUploadButtonDisable(false);
    }
  };
  return (
    <div className="part-detail-parent">
      <div className="part-detail-header">
        <Headers />
      </div>
      <div className="part-detail-navigation">
        <Navigation />
      </div>
      <div className="part-detail-main">
        <div className="part-detail-left">
          <div className="left-header">Part Details</div>
          <div className="left-form">
            {myPart ? ( // Assuming 'myPart' is the state that holds part details
              <>
                <div className="part-detail-field">
                  <label htmlFor="part-number">Part Number:</label>
                  <input
                    type="text"
                    id="part-number"
                    value={myPart.partHash || "N/A"}
                    readOnly
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="part-detail-field">
                  <label htmlFor="part-description">Part Description:</label>
                  <textarea
                    id="part-description"
                    value={myPart.description || "N/A"}
                    readOnly
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    rows="4"
                    style={{ resize: "none" }}
                  />
                </div>
                <div className="part-detail-field">
                  <label htmlFor="inventory-locations">
                    Inventory Location(s):
                  </label>
                  <input
                    id="inventory-locations"
                    type="text"
                    value={myPart.inveroryLocation || "N/A"}
                    readOnly
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="part-detail-field">
                  <label htmlFor="vendor-name">Vendor Name:</label>
                  <input
                    type="text"
                    id="vendor-name"
                    value={myPart.vendorName || "N/A"}
                    readOnly
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="part-detail-field">
                  <label htmlFor="vendor-contact">Vendor Contact:</label>
                  <input
                    type="text"
                    id="vendor-contact"
                    value={myPart.vendorContact || "N/A"}
                    readOnly
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="part-detail-field">
                  <label htmlFor="vendor-email">Vendor Email:</label>
                  <input
                    type="text"
                    id="vendor-email"
                    value={myPart.vendorEmail || "N/A"}
                    readOnly
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="part-detail-field">
                  <label htmlFor="vendor-phone">Vendor Phone:</label>
                  <input
                    type="number"
                    id="vendor-phone"
                    value={myPart.vendorPhone || "N/A"}
                    readOnly
                    className={inputClass}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
              </>
            ) : (
              <div className="part-detail-field">No parts available.</div>
            )}
          </div>
          <div className="left-footer">
            <p onClick={() => setQrPopup(true)}>
              <img src={qrcode} width="21px" alt="Create QR Code" />
              <span>Create QR Code</span>
            </p>
            {qrPopup ? (
              <>
                <AssetQrPopup
                  orglogo={myPart.organizationDetail.logo}
                  assetName={myPart.partHash}
                  hide={setQrPopup}
                  path={qrCodeUrl}
                />
              </>
            ) : null}
            <p>
              <label htmlFor="uploadFile">
                <img
                  src={uploadIcon}
                  width="21px"
                  alt="Part Files"
                  style={{ width: "17px" }}
                />
                <span>Part Files</span>
              </label>
            </p>

            <input
              type="file"
              id="uploadFile"
              hidden
              disabled={uploadButtonDisable}
              onChange={(e) => {
                const file = e.target.files[0];
                if (!file) return;
                const size = file.size;
                if (isStorageLimitReached(size)) {
                  setShowStorageLimitReachedPopup(true);
                  return;
                }
                addAFile(file);

                e.target.value = "";
              }}
              style={{
                pointerEvents: uploadButtonDisable ? "none" : "auto",
              }}
            />
          </div>
        </div>

        <div className="part-detail-right">
          <div className="right-insider" style={{ marginBottom: "20px" }}>
            <p>Assets Linked</p>
            <div className="linked-asset-header">
              <div className="header-box" style={{ width: "20%" }}>
                ASSET#
              </div>
              <div className="header-box" style={{ width: "40%" }}>
                ASSET NAME
              </div>
              <div className="header-box" style={{ width: "40%" }}>
                ASSET
              </div>
            </div>
            <div className="linked-asset-content"></div>
          </div>
          <div className="right-insider">
            <p>Inventory On Hand</p>
            <div className="inventory-content">
              <div className="inventory-content-box">
                <p>N/A</p>
                <div className="inventory-quantity">
                  <RemoveCircleOutlineIcon />
                  0
                  <ControlPointIcon />
                </div>
              </div>
              <div className="inventory-content-box">
                <p>N/A</p>
                <div className="inventory-quantity">
                  <RemoveCircleOutlineIcon />
                  0
                  <ControlPointIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartDetail;
