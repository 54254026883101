import "./IFramePopup.css";
 
import Iframe from "react-iframe";
import React from "react";
 
const IFramePopup = ({ hide, filePath, org_id }) => {
  let url = `${org_id}/${filePath}`;
  const isValidUrl = (string) => {
    const pattern = /^(https?:\/\/)?([\w.-]+)(\.[a-z]{2,})([\/\w .-]*)*$/i;
    return pattern.test(string);
  };
  if (isValidUrl(filePath)) {
    url = filePath; //changed to get orgid with path and condition to check for valid path
  }
 
  if (filePath?.includes("youtube.com") && filePath?.includes("watch?v="))
    url = filePath?.replace("watch?v=", "embed/");
  else if (filePath?.includes("youtu.be")) {
    const code = filePath?.split("/").pop();
    url = `https://www.youtube.com/embed/${code}`;
  } else if (
    !filePath.startsWith("http://") &&
    !filePath.startsWith("https://")
  ) {
    // Ensure the URL has a proper protocol
    url = `https://${filePath}`;
  }
 
  return (
    <div className="i-frame-parent">
      <div className="i-frame-button-container">
        <a href={url} target="_blank" rel="noreferrer">
          Open
        </a>
        <button onClick={() => hide(false)}>Close</button>
      </div>
      <div className="i-frame-main">
        <Iframe
          url={url}
          width="100%"
          height="100%"
          id="filePath"
          className=""
          display="block"
          position="relative"
        />
      </div>
    </div>
  );
};
 
export default IFramePopup;
 