import "./DeviceSettings.css";

import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const DeviceSettings = ({
  showSettings,
  allDevices,
  setShowSettings,
  updateSettings,
  selectedDeviceIDs,
}) => {
  return showSettings ? (
    <div className="setting-container">
      <div className="setting-content ">
        <div className="setting-header-section">
          <h3 style={{ color: "white" }}>Device Settings</h3>
          <button
            className="close-button"
            onClick={() => setShowSettings(false)}
          >
            <CloseIcon fontSize="small" color="white" />
          </button>
        </div>
        <h4 style={{ color: "white" }}>Video</h4>
        {allDevices?.videoInput.length > 0 ? (
          <select
            className="setting-select"
            name="video"
            id="video"
            onChange={(value) => updateSettings(value, "videoInput")}
            value={selectedDeviceIDs?.videoInput}
          >
            {allDevices?.videoInput.map((item) => {
              return <option value={item?.deviceId}>{item?.label}</option>;
            })}
          </select>
        ) : (
          <select
            className="setting-select"
            name="video"
            id="video"
            value="noData"
          >
            <option value="noData">No Camera Found</option>
          </select>
        )}
        <h4 style={{ color: "white" }}>Speaker</h4>
        {allDevices?.audioOutput.length > 0 ? (
          <select
            className="setting-select"
            name="speaker"
            id="speaker"
            onChange={(value) => updateSettings(value, "audioOutput")}
            value={selectedDeviceIDs?.audioOutput}
          >
            {allDevices?.audioOutput.map((item) => {
              return <option value={item?.deviceId}>{item?.label}</option>;
            })}
          </select>
        ) : (
          <select
            className="setting-select"
            name="speaker"
            id="speaker"
            value="noData"
          >
            <option value="noData">No Speaker Found</option>
          </select>
        )}
        <h4 style={{ color: "white" }}>Microphone</h4>
        {allDevices?.audioInput.length > 0 ? (
          <select
            className="setting-select"
            name="microphone"
            id="microphone"
            onChange={(value) => updateSettings(value, "audioInput")}
            value={selectedDeviceIDs?.audioInput}
          >
            {allDevices?.audioInput.map((item) => {
              return <option value={item?.deviceId}>{item?.label}</option>;
            })}
          </select>
        ) : (
          <select
            className="setting-select"
            name="microphone"
            id="microphone"
            value="noData"
          >
            <option value="noData">No Microphone Found</option>
          </select>
        )}
      </div>
    </div>
  ) : null;
};

export default DeviceSettings;
