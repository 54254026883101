import "firebase/compat/firestore";
import "./Chat.css";

import React, { useEffect, useRef, useState } from "react";
import {
  getExternalMembers,
  getInternalMembers,
  getUserList,
  tagUsers,
} from "Config/API/get";
import {
  renderChatProfileAction,
  ticketChatDataAction,
} from "Redux/action/render.action";
import { useDispatch, useSelector } from "react-redux";

import ChatIcon from "@mui/icons-material/Chat";
import EmailIcon from "@mui/icons-material/Email";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import GeneralCard from "../../Cards/GeneralCard/GeneralCard";
import Headers from "Components/Header/Headers";
import { IconButton } from "@mui/material";
import InputCard from "../../Cards/InputCard/InputCard";
import IosShareIcon from "@mui/icons-material/IosShare";
import LanguageIcon from "@mui/icons-material/Language";
import Navigation from "Components/SideNavBar/Navigation";
import RenderCreateExternalChatRoom from "Components/Popup/CreateExternalChatRoom/Container/RenderCreateExternalChatRoom";
import RenderCreateInternalChatRoom from "Components/Popup/CreateInternalChatRoom/Container/RenderCreateInternalChatRoom";
import RenderEmail from "Components/Popup/Email/Container/RenderEmail";
import TextsmsIcon from "@mui/icons-material/Textsms";
import VideoIcon from "Media/icons/Iconly-Bold-Video.svg";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "Constants/FirebaseConfig";
import profilePic from "Media/blank-profile-picture.png";
import { ticketIdAction } from "Redux/action/variable.action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useNavigate } from "react-router-dom";

const Chat = () => {
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [group_data, setGroup_data] = useState([]);
  const ticket_main_data = useSelector((state) => state.ticketChatDataReducer);
  const my_id = useSelector((state) => state.authenticationReducer?.id);
  const my_name = useSelector((state) => state.authenticationReducer?.name);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let my_org_id = useSelector((state) => state.authenticationReducer?.org_id);
  const user = useSelector((state) => state.authenticationReducer);

  // condition for admin
  if (my_org_id?.toString() === "0") {
    my_org_id = my_id;
  }

  // for chat scrolling
  const messagesEndRef = useRef(null);

  // data for chat
  const send_by = useSelector((state) => state.authenticationReducer?.id);

  // condition for docid
  let docid = "";
  let user_docid = "";
  let requestorOrgId = "";
  // general conditions
  if (ticket_main_data?.type?.toString() === "internal") {
    docid = ticket_main_data?.ticket?.internalGroup[0]?.unique_id;
    user_docid = ticket_main_data?.ticket?.internalGroup[0]?.docid;
    requestorOrgId = "";
  } else if (ticket_main_data?.type?.toString() === "external") {
    docid = ticket_main_data?.ticket?.externalGroup[0]?.unique_id;
    user_docid = ticket_main_data?.ticket?.externalGroup[0]?.docid;

    // condition for external
    requestorOrgId = ticket_main_data?.ticket?.requestor?.id;

    // conditions for manager list (tags*)
    if (my_org_id?.toString() === requestorOrgId?.toString()) {
      requestorOrgId = ticket_main_data?.ticket?.org_id;
    }
  }

  const [fbmessage, setFbmessage] = useState("");

  // requestor company
  // eslint-disable-next-line no-unused-vars
  const [requestorLogo, setRequestorLogo] = useState(
    ticket_main_data?.ticket?.requestor?.logo || profilePic
  );
  // eslint-disable-next-line no-unused-vars
  const [requestor, setRequestor] = useState(
    ticket_main_data?.ticket?.requestor?.organization_name || "Not Found"
  );
  // eslint-disable-next-line no-unused-vars
  const [requestorWeb, setRequestorWeb] = useState(
    ticket_main_data?.ticket?.requestor?.organization_website || "Not Found"
  );
  // eslint-disable-next-line no-unused-vars
  const [requestorAddress, setRequestorAddress] = useState(
    ticket_main_data?.ticket?.requestor?.address || "Not Found"
  );

  // assigned tech
  // eslint-disable-next-line no-unused-vars
  const [assignId, setAssignId] = useState(
    ticket_main_data?.ticket?.assign_tech?.id || ""
  );
  // eslint-disable-next-line no-unused-vars
  const [assignLogo, setAssignLogo] = useState(
    ticket_main_data?.ticket?.assign_tech?.image || profilePic
  );
  // eslint-disable-next-line no-unused-vars
  const [activeStatus, setActiveStatus] = useState(
    ticket_main_data?.ticket?.assign_tech?.online_status || ""
  );
  // eslint-disable-next-line no-unused-vars
  const [assignName, setAssignName] = useState(
    ticket_main_data?.ticket?.assign_tech?.username || "No Username Found"
  );
  // eslint-disable-next-line no-unused-vars
  const [assignDes, setAssignDes] = useState(
    ticket_main_data?.ticket?.assign_tech?.designation || "No Designation Found"
  );
  // eslint-disable-next-line no-unused-vars
  const [assignEmail, setAssignEmail] = useState(
    ticket_main_data?.ticket?.assign_tech?.email || "No Email Found"
  );
  // eslint-disable-next-line no-unused-vars
  const [assignPhone, setAssignPhone] = useState(
    ticket_main_data?.ticket?.assign_tech?.phone || "No Phone Found"
  );
  // eslint-disable-next-line no-unused-vars
  const [assignCompLogo, setAssignCompLogo] = useState(
    ticket_main_data?.ticket?.assign_tech?.logo || profilePic
  );

  // requestor tech
  // eslint-disable-next-line no-unused-vars
  const [requesId, setRequesId] = useState(
    ticket_main_data?.ticket?.requestor_id?.id || ""
  );
  // eslint-disable-next-line no-unused-vars
  const [requesLogo, setRequesLogo] = useState(
    ticket_main_data?.ticket?.requestor_id?.image || profilePic
  );
  // eslint-disable-next-line no-unused-vars
  const [requesStatus, setRequesStatus] = useState(
    ticket_main_data?.ticket?.requestor_id?.online_status || ""
  );
  // eslint-disable-next-line no-unused-vars
  const [requesName, setRequesName] = useState(
    ticket_main_data?.ticket?.requestor_id?.username || "No Username Found"
  );
  // eslint-disable-next-line no-unused-vars
  const [requesDes, setRequesDes] = useState(
    ticket_main_data?.ticket?.requestor_id?.designation ||
      "No Designation Found"
  );
  // eslint-disable-next-line no-unused-vars
  const [requesEmail, setRequesEmail] = useState(
    ticket_main_data?.ticket?.requestor_id?.email || "No Email Found"
  );
  // eslint-disable-next-line no-unused-vars
  const [requesPhone, setRequesPhone] = useState(
    ticket_main_data?.ticket?.requestor_id?.phone || "No Phone Found"
  );

  // options
  const [EmailPopup, setEmailPopup] = useState(false);
  const [AssignEmailPopup, setAssignEmailPopup] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ticketId, setTicketId] = useState(
    ticket_main_data?.ticket?.ticket_id || "###"
  );
  // eslint-disable-next-line no-unused-vars
  const [doesExternalExist, setDoesExternalExist] = useState(
    ticket_main_data?.ticket?.externalGroup || false
  );

  // video chat
  // eslint-disable-next-line no-unused-vars
  const [loggedInUser, setLoggedInUser] = useState([]);
  const [openInternalChat, setOpenInternalChat] = useState(false);
  const [openExternalChat, setOpenExternalChat] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState([]);
  const [availableMember, setAvailableMember] = useState([]);
  const [externalMember, setExternalMember] = useState([]);
  const [allExternalMember, setAllExternalMember] = useState([]);
  const [videoCallCreatorAdmin, setVideoCallCreatorAdmin] = useState([]);

  // firebase integration
  firebase.initializeApp(firebaseConfig);

  const normalMessage = (message) => {
    if (message?.toString() !== "") {
      let mymsg = {};
      mymsg = { message, sentBy: send_by, sentTo: "all", username: my_name };

      // condition for notificaiton api
      let notificationArray = [];
      if (message?.includes("&&*&&")) {
        let tagArray = message?.split("&&*&&");
        for (let tag in tagArray) {
          if (!isNaN(parseInt(tagArray[tag]))) {
            notificationArray.push(tagArray[tag]);
          }
        }
        const newNotificationArray = notificationArray.filter(
          (item, index, inputArray) => {
            return inputArray.indexOf(item) == index;
          }
        );
        //notifying the tagged persons
        const tag_status = tagUsers(token, newNotificationArray, docid);
        tag_status.then((response) => {
          // if(response?.data) {
          // }
        });
      }

      firebase
        .firestore()
        .collection("external-chat")
        .doc(docid)
        .set({
          docID: user_docid?.split("-"),
          lastMessage: message,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

      firebase
        .firestore()
        .collection("external-chat")
        .doc(docid)
        .collection("messages")
        .add({
          ...mymsg,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
  };

  const nestedMessage = (message, message_id) => {
    if (message?.toString() !== "" && message_id?.toString() !== "") {
      let mymsg = {};
      mymsg = { message, sentBy: send_by, sentTo: "all", username: my_name };

      // condition for notificaiton api
      let notificationArray = [];
      if (message?.includes("&&*&&")) {
        let tagArray = message?.split("&&*&&");
        for (let tag in tagArray) {
          if (!isNaN(parseInt(tagArray[tag]))) {
            notificationArray.push(tagArray[tag]);
          }
        }
        const newNotificationArray = notificationArray.filter(
          (item, index, inputArray) => {
            return inputArray.indexOf(item) == index;
          }
        );
        //notifying the tagged persons
        const tag_status = tagUsers(token, newNotificationArray, docid);
        tag_status.then((response) => {
          // if(response?.data) {
          // }
        });
      }

      firebase
        .firestore()
        .collection("external-chat")
        .doc(docid)
        .collection("messages")
        .doc(message_id)
        .collection("messages")
        .add({
          ...mymsg,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
  };

  useEffect(() => {
    if (ticket_main_data) {
      const messageRef = firebase
        .firestore()
        .collection("external-chat")
        .doc(docid)
        .collection("messages")
        .orderBy("createdAt", "asc");

      messageRef.onSnapshot((querySnap) => {
        const allmsg = querySnap.docs?.map((docSanp) => {
          const data = docSanp.data();
          const keey = docSanp.id;
          if (data.createdAt) {
            return {
              ...docSanp.data(),
              createdAt: docSanp.data().createdAt.toDate(),
              keey,
            };
          } else {
            return {
              ...docSanp.data(),
              createdAt: new Date(),
            };
          }
        });
        setFbmessage(allmsg);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket_main_data]);

  // scroll
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, [fbmessage, ticket_main_data]);

  useEffect(() => {
    if (!loggedInUser?.includes(user)) {
      loggedInUser?.push(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    getExternalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestorOrgId]);

  useEffect(() => {
    if (!openExternalChat) {
      setSelectedTeamMember([]);
      setExternalMember([]);
    }
  }, [openExternalChat]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getData = () => {
    try {
      const data = getInternalMembers(token);
      data.then((res) => {
        if (res?.status) {
          const newArray = res?.data?.internal?.map((item) => {
            item["name"] = item?.username;
            return item;
          });
          const videoCallCreatorAdminData = newArray?.filter((item) => {
            return item?.role?.toString() === "admin";
          });
          setAvailableMember(newArray);
          setVideoCallCreatorAdmin(videoCallCreatorAdminData);
        }
      });
    } catch (error) {}
  };

  const getExternalData = () => {
    try {
      const data = getExternalMembers(token, requestorOrgId);
      data.then((res) => {
        if (res?.status) {
          const newArray = res?.data?.external?.map((item) => {
            item["name"] = item?.username;
            return item;
          });
          setAllExternalMember(newArray);
        }
      });
    } catch (error) {}
  };

  const checkInternalMember = (memberId) => {
    const allIds = availableMember?.map((item) => item?.id);
    if (allIds?.includes(memberId)) {
      return true;
    } else return false;
  };

  const openCreateChatDialog = (memberId) => {
    setLoader();
    if (checkInternalMember(memberId)) {
      getData();
      if (!selectedTeamMember.includes(user)) {
        const newUser = loggedInUser?.map((item) => {
          item["isCreator"] = true;
          item["isPermanent"] = true;
          return item;
        });
        newUser?.forEach((item) => {
          selectedTeamMember.push(item);
        });
      }
      const user_list_status = getUserList(token, [memberId.toString()]);
      user_list_status
        .then((res) => {
          resetLoader();
          res?.data?.forEach((item) => {
            if (!selectedTeamMember.includes(item)) {
              const newUser = res?.data?.map((item) => {
                item["isCreator"] = false;
                item["isPermanent"] = true;
                return item;
              });
              newUser?.forEach((item) => {
                selectedTeamMember.push(item);
              });
            }
          });
          setOpenInternalChat(true);
        })
        .catch((e) => {
          resetLoader();
          toast("Failed!, Try again later", { type: "error" });
        });
    } else {
      getExternalData();
      if (!selectedTeamMember.includes(user)) {
        const newUser = loggedInUser?.map((item) => {
          item["isCreator"] = true;
          item["isPermanent"] = true;
          return item;
        });
        newUser?.forEach((item) => {
          selectedTeamMember.push(item);
        });
      }
      const user_list_status = getUserList(token, [memberId.toString()]);
      user_list_status
        .then((res) => {
          resetLoader();
          res?.data?.forEach((item) => {
            if (!externalMember.includes(item)) {
              const newUser = res?.data?.map((item) => {
                item["isCreator"] = false;
                item["isPermanent"] = true;
                return item;
              });
              newUser?.forEach((item) => {
                externalMember.push(item);
              });
              setGroup_data(newUser);
            }
          });
          setOpenExternalChat(true);
        })
        .catch((e) => {
          resetLoader();
          toast("Failed!, Try again later", { type: "error" });
        });
    }
  };

  return (
    <>
      <div className="stdetails-parent">
        <div className="stdetails-header">
          <Headers />
        </div>

        <div className="stdetails-navigation">
          <Navigation />
        </div>

        <div className="stdetails-main">
          <div className="stdetails-wrapper">
            <div className="stdetails-left">
              <div className="heading">
                {ticket_main_data?.type?.toString() === "internal" ? (
                  <p>Internal Group Chat</p>
                ) : (
                  <p>External Group Chat</p>
                )}
                <span>
                  <b style={{ color: "#51595E", fontWeight: "bold" }}>
                    Ticket #:
                  </b>
                  <span
                    onClick={() => {
                      dispatch(ticketIdAction(ticketId));
                      navigate("/service-ticket-details");
                    }}
                    style={{ color: "#446FA2", cursor: "pointer" }}
                  >
                    {ticketId}
                  </span>
                </span>
              </div>
              <div className="content with-chat">
                <div className="chat-screen-chatsection">
                  <ul className="chat-thread">
                    {fbmessage?.toString() !== ""
                      ? fbmessage?.map((currEle, index) => {
                          return (
                            <GeneralCard
                              data={currEle}
                              key={index}
                              myId={send_by}
                              reply={nestedMessage}
                              docid={docid}
                              chatType={requestorOrgId}
                              type={ticket_main_data?.type}
                            />
                          );
                        })
                      : null}

                    <div ref={messagesEndRef} />
                  </ul>
                </div>
                {user_docid?.split("-").includes(my_id?.toString()) ? (
                  <InputCard
                    onSend={normalMessage}
                    message_id=""
                    chatType={requestorOrgId}
                    type={ticket_main_data?.type}
                  />
                ) : (
                  <div className="no-permission-service-chat">
                    You have no permission to send messages
                  </div>
                )}
              </div>
            </div>

            <div className="stdetails-right">
              <div className="fields">
                <div className="content">
                  <div className="heading">
                    <p>ASSIGNED TO</p>
                  </div>
                  <div className="card">
                    <div className="card-logo">
                      <img
                        src={assignLogo}
                        alt="profile"
                        width="55px"
                        height="55px"
                      />

                      {activeStatus?.toString() === "online" ? (
                        <span
                          className="user-status"
                          style={{ color: "#87c823" }}
                        >
                          <FiberManualRecordIcon />
                        </span>
                      ) : (
                        <span
                          className="user-status"
                          style={{ color: "#c23616" }}
                        >
                          <FiberManualRecordIcon />
                        </span>
                      )}
                    </div>
                    <div className="card-text">
                      <h3>{assignName}</h3>
                      {activeStatus ? (
                        activeStatus === "online" ? (
                          <p style={{ color: "#87c823" }}>{activeStatus}</p>
                        ) : (
                          <p style={{ color: "#ababab" }}>{activeStatus}</p>
                        )
                      ) : (
                        <p style={{ color: "#ababab" }}>No Activity Yet</p>
                      )}
                      <p>{assignDes}</p>
                      <p>{assignEmail}</p>
                      <p>{assignPhone}</p>
                    </div>

                    {assignId?.toString() !== my_id?.toString() ? (
                      <div className="card-icon">
                        <IconButton
                          onClick={() => {
                            navigate("/chat");
                            if (assignId) {
                              dispatch(renderChatProfileAction(assignId));
                            }
                          }}
                        >
                          <ChatIcon
                            className="chat-icon"
                            style={{ color: "#507dd6" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setAssignEmailPopup(true);
                          }}
                        >
                          <EmailIcon
                            className="email-icon"
                            style={{ color: "#d1a02e" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            if (!assignId) return;
                            dispatch(renderChatProfileAction(assignId));
                            openCreateChatDialog(assignId);
                          }}
                        >
                          <img src={VideoIcon} alt="VideoIcon" />
                        </IconButton>
                        {AssignEmailPopup ? (
                          <RenderEmail
                            setEmailPopup={setAssignEmailPopup}
                            toEmail={assignEmail}
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="content" style={{ marginTop: "35px" }}>
                  <div className="heading">
                    <p>REQUESTOR COMPANY</p>
                  </div>
                  <div className="card" style={{ padding: "15px 10px" }}>
                    <div className="card-logo">
                      <img
                        src={requestorLogo}
                        alt="profile"
                        width="55px"
                        height="55px"
                      />
                    </div>
                    <div className="card-text">
                      <h3 style={{ marginBottom: "5px" }}>{requestor}</h3>
                      <div
                        className="profile-url"
                        style={{ marginBottom: "5px" }}
                      >
                        <LanguageIcon />
                        <span style={{ display: "flex" }}>
                          Website:
                          {requestorWeb?.toString() === "#" ? (
                            "None"
                          ) : (
                            <a
                              href={requestorWeb}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {requestorWeb}
                            </a>
                          )}
                        </span>
                        <IosShareIcon />
                      </div>
                      <p>{requestorAddress}</p>
                    </div>
                  </div>
                </div>

                <div className="content" style={{ marginTop: "35px" }}>
                  <div className="heading">
                    <p>REQUESTOR CONTACT</p>
                  </div>
                  <div className="card">
                    <div className="card-logo">
                      <img
                        src={requesLogo}
                        alt="profile"
                        width="55px"
                        height="55px"
                      />

                      {requesStatus?.toString() === "online" ? (
                        <span
                          className="user-status"
                          style={{ color: "#87c823" }}
                        >
                          <FiberManualRecordIcon />
                        </span>
                      ) : (
                        <span
                          className="user-status"
                          style={{ color: "#c23616" }}
                        >
                          <FiberManualRecordIcon />
                        </span>
                      )}
                    </div>
                    <div className="card-text">
                      <h3>{requesName}</h3>
                      {requesStatus ? (
                        requesStatus?.toString() === "online" ? (
                          <p style={{ color: "#87c823" }}>{requesStatus}</p>
                        ) : (
                          <p style={{ color: "#ababab" }}>{requesStatus}</p>
                        )
                      ) : (
                        <p style={{ color: "#ababab" }}>No Activity Yet</p>
                      )}
                      <p>{requesDes}</p>
                      <p>{requesEmail}</p>
                      <p>{requesPhone}</p>
                    </div>
                    {requesId?.toString() !== my_id?.toString() ? (
                      <div className="card-icon">
                        <IconButton
                          onClick={() => {
                            navigate("/chat");
                            dispatch(renderChatProfileAction(requesId));
                          }}
                        >
                          <ChatIcon
                            className="chat-icon"
                            style={{ color: "#507dd6" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setEmailPopup(true);
                          }}
                        >
                          <EmailIcon
                            className="email-icon"
                            style={{ color: "#d1a02e" }}
                          />
                        </IconButton>
                        <IconButton>
                          <img src={VideoIcon} alt="VideoIcon" />
                        </IconButton>
                        {EmailPopup ? (
                          <RenderEmail
                            setEmailPopup={setEmailPopup}
                            toEmail={requesEmail}
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="content" style={{ marginTop: "35px" }}>
                  <div className="heading">
                    <p>MESSAGE BOARD</p>
                  </div>
                  <div className="two-card">
                    {ticket_main_data?.ticket?.org_id && my_org_id ? (
                      ticket_main_data?.ticket?.org_id?.toString() ===
                      my_org_id?.toString() ? (
                        <div
                          style={{ marginRight: "25px" }}
                          onClick={() => {
                            dispatch(
                              ticketChatDataAction({
                                type: "internal",
                                ticket: ticket_main_data?.ticket,
                              })
                            );
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              width: "fit-content",
                              marginBottom: "5px",
                            }}
                          >
                            <img src={assignCompLogo} alt="Organization" />
                            <TextsmsIcon style={{ color: "#507DD6" }} />
                          </div>

                          <span>Internal Group Chat</span>
                        </div>
                      ) : null
                    ) : null}

                    {doesExternalExist?.length > 0 ? (
                      <div
                        onClick={() => {
                          dispatch(
                            ticketChatDataAction({
                              type: "external",
                              ticket: ticket_main_data?.ticket,
                            })
                          );
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "fit-content",
                            marginBottom: "5px",
                          }}
                        >
                          <img src={assignCompLogo} alt="Organization" />
                          <img
                            src={requestorLogo}
                            alt="Organization"
                            style={{ position: "relative", left: "-10px" }}
                          />
                          <TextsmsIcon
                            style={{ color: "#507DD6", right: "0" }}
                          />
                        </div>

                        <span>External Group Chat</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RenderCreateInternalChatRoom
        selectedTeamMember={selectedTeamMember}
        setSelectedTeamMember={setSelectedTeamMember}
        popup={openInternalChat}
        hide={setOpenInternalChat}
        availableMember={availableMember}
        videoCallCreatorAdmin={videoCallCreatorAdmin}
        toast={toast}
      />
      <RenderCreateExternalChatRoom
        selectedTeamMember={selectedTeamMember}
        setSelectedTeamMember={setSelectedTeamMember}
        popup={openExternalChat}
        hide={setOpenExternalChat}
        availableMember={availableMember}
        allExternalMember={allExternalMember}
        externalMember={externalMember}
        setExternalMember={setExternalMember}
        externalProfile={group_data[0]}
        videoCallCreatorAdmin={videoCallCreatorAdmin}
        toast={toast}
      />
      {loader}
    </>
  );
};

export default Chat;
