import React from "react";
import STDetails from "../PureComponents/STDetails";

const RenderSTDetails = () => {
  return (
    <>
      <STDetails />
    </>
  );
};

export default RenderSTDetails;
