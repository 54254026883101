import CreateAppointment from "../PureComponents/CreateAppointment";
import React from "react";

const RenderCreateAppointment = ({
  ticketId,
  details,
  videoChatDetails,
  setShowVideoChatRatePopup,
  requestorId,
  billableOrgDetails,
}) => {
  // We are list data here becuase the list is reseting in same component
  let list_data = [];
  let org_arr = requestorId ? [requestorId] : [];
  let org_name_arr = billableOrgDetails?.length > 0 ? billableOrgDetails : [];
  let emp_arr = [];

  return (
    <>
      <CreateAppointment
        tasklist_arr={list_data}
        org_arr={org_arr}
        org_name_arr={org_name_arr}
        emp_arr={emp_arr}
        ticketId={ticketId}
        details={details}
        videoChatDetails={videoChatDetails}
        setShowVideoChatRatePopup={setShowVideoChatRatePopup}
      />
    </>
  );
};

export default RenderCreateAppointment;
