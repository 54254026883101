import React, { useEffect, useState } from "react";

import ChatScreen from "../PureComponents/ChatScreen";
import chatImage from "Media/Chat-Icon-PNG-Pic.png";
import { getUserList } from "Config/API/get";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const RenderChatScreen = ({ setShowStorageLimitReachedPopup }) => {
  const user_id = useSelector((state) => state.renderChatProfileReducer);
  const token = localStorage.getItem("token");
  const [user_profile, setUser_profile] = useState("");

  const screenId = user_id.toString();

  useEffect(() => {
    // calling api which return user list
    if (user_id || user_id?.length?.toString() !== "0") {
      // for group
      if (screenId.includes("-")) {
        const user_list_status = getUserList(token, [user_id]);
        user_list_status
          .then((res) => {
            if (res?.status) {
              setUser_profile(res?.data);
            } else {
              toast(res?.message, {
                type: "error",
              });
            }
          })
          .catch((e) => {
            toast("Failed!, Try again later", { type: "error" });
          });
      } else {
        // for one-to-one
        const user_list_status = getUserList(token, [user_id.toString()]);
        user_list_status
          .then((res) => {
            setUser_profile(res?.data);
          })
          .catch((e) => {
            toast("Failed!, Try again later", { type: "error" });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  return (
    <>
      {user_profile ? (
        <ChatScreen
          profile={user_profile[0]}
          group_data={user_profile}
          setShowStorageLimitReachedPopup={setShowStorageLimitReachedPopup}
        />
      ) : (
        <div className="no-chat">
          <img src={chatImage} alt="Chat" />
          Click on user to initiate chat
        </div>
      )}
    </>
  );
};

export default RenderChatScreen;
