import "./RatesAndSubscriptionSuperAdmin.css";

import React, { useEffect, useState } from "react";
import { getAllSubscribers, getRespectiveRates } from "Config/API/get";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderInformationPopup from "Components/Popup/InformationPopup/PureComponents/InformationPopup";
import VideoCallingRates from "Components/Popup/VideoCallingRates/VideoCallingRates";
import blank from "Media/blank-profile-picture.png";
import editRates from "Media/icons/editRates.png";
import mySubscribers from "Media/icons/mySubscribers.png";
import mySubscribersLogo from "Media/icons/mySubscribersLogo.png";
import rateSetting from "Media/icons/rateSetting.png";
import { renderOrganizationInfo } from "Redux/action/render.action";
import serviceTicketIcon from "Media/Group 353@2x.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
const RatesAndSubscriptionSuperAdmin = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState("rateSetting");
  const [ratesData, setRatesData] = useState([]);
  const [allSubscribers, setAllSubscribers] = useState([]);
  const [rateLoading, setRateLoading] = useState(false);
  const [subscribersLoading, setSubscribersLoading] = useState(false);
  const [showEditRatesPopup, setShowEditRatesPopup] = useState(false);
  const loadingArray = ["", ""];
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabsText, setTabsText] = useState("");
  useEffect(() => {
    getAllSubscriptionsRates();
    getAllSubscribersData();
    // eslint-disable-next-line
  }, []);

  const getAllSubscriptionsRates = () => {
    if (!rateLoading) setRateLoading(true);
    const response = getRespectiveRates(token);
    response.then((res) => {
      setRateLoading(false);
      if (res.data.status) {
        const filteredData = res?.data.data.filter(
          (item) => item?.rate_type === "service_ticket"
        );
        setRatesData(filteredData);
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const getAllSubscribersData = () => {
    if (!subscribersLoading) setSubscribersLoading(true);
    const response = getAllSubscribers(token);
    response.then((res) => {
      setSubscribersLoading(false);
      if (res.data.status) {
        setAllSubscribers(res.data.data);
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  return (
    <>
      <div className="admin-rates-and-subscription-main">
        <div className="admin-rates-and-subscription-header">
          <Headers />
        </div>

        <div className="admin-rates-and-subscription-navigation">
          <Navigation />
        </div>

        <div className="admin-rates-and-subscription-wrapper">
          <div className="admin-rates-and-subscription-heading">
            Rates & Subscription Management
          </div>
          <div className="admin-rates-and-subscription-content">
            <div style={{ position: "relative" }}>
              <div
                className={`admin-rates-and-subscription-tile ${
                  selectedTab === "rateSetting" && "active"
                }`}
                onClick={() => setSelectedTab("rateSetting")}
              >
                <img
                  src={rateSetting}
                  alt="rateSetting"
                  style={{ width: "50px", height: "50px" }}
                />

                <span>Rate Setting</span>
              </div>
              {selectedTab === "rateSetting" && (
                <QuestionMarkIcon
                  className="moneyCreditManagement-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setTabsText("Rate Setting");
                  }}
                />
              )}
            </div>
            <div style={{ position: "relative" }}>
              <div
                className={`admin-rates-and-subscription-tile ${
                  selectedTab === "mySubscribers" && "active"
                }`}
                onClick={() => setSelectedTab("mySubscribers")}
              >
                <img
                  src={mySubscribers}
                  alt="mySubscribers"
                  style={{ width: "43px", height: "43px", marginBottom: "7px" }}
                />
                <span>My Subscribers</span>
              </div>
              {selectedTab === "mySubscribers" && (
                <QuestionMarkIcon
                  className="moneyCreditManagement-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setTabsText("My Subscribers");
                  }}
                />
              )}
            </div>
          </div>
          <div className="admin-rates-and-subscription-lower-tile-container">
            {selectedTab === "rateSetting" &&
              (!rateLoading ? (
                <div
                  className="admin-rates-and-subscription-lower-tile"
                  style={{
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={serviceTicketIcon}
                      alt="serviceTicketRate"
                      style={{ width: "76px", height: "76px" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>SERVICE TICKETING RATE</label>
                      {!rateLoading &&
                        (ratesData.length > 0 ? (
                          <span>${ratesData[0].rate} Credits Per Month</span>
                        ) : (
                          <span style={{ color: "#C50000" }}>
                            Please set rate per month
                          </span>
                        ))}
                    </div>
                  </div>
                  <img
                    src={editRates}
                    alt="editRates"
                    style={{ width: "22px", height: "22px" }}
                    onClick={() => setShowEditRatesPopup(true)}
                  />
                  {selectedTab === "rateSetting" && (
                    <QuestionMarkIcon
                      className="moneyCreditManagement-info"
                      onClick={() => {
                        setInfoPopup(true);
                        setTabsText("SERVICE TICKETING RATE");
                      }}
                    />
                  )}
                </div>
              ) : (
                <div
                  className="rates-and-subscription-lower-tile"
                  style={{
                    justifyContent: "space-between",
                    borderColor: "#ececec",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{ width: "70px", height: "57px" }}
                      className="animation"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "7px",
                        marginLeft: "22px",
                      }}
                    >
                      <label className="rates-and-subscription-rate-loading-label animation" />
                      <span className="rates-and-subscription-rate-loading-span  animation" />
                    </div>
                  </div>
                  <div
                    style={{ width: "22px", height: "22px" }}
                    className="animation"
                  />
                </div>
              ))}
            {selectedTab === "mySubscribers" && (
              <>
                <div className="admin-rates-and-subscription-lower-tile-header-container">
                  <img
                    src={mySubscribersLogo}
                    alt="logo"
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: 50,
                    }}
                  />
                  <span>Locked Content Subscribers</span>
                </div>
                {!subscribersLoading ? (
                  allSubscribers?.length > 0 ? (
                    <div
                      style={{
                        height: "300px",
                        overflowY: "scroll",
                        width: "100%",
                        maxWidth: "424px",
                      }}
                    >
                      {allSubscribers.map((item) => {
                        return (
                          <div
                            className="admin-rates-and-subscription-lower-tile"
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={
                                  item?.organization?.logo
                                    ? item?.organization?.logo
                                    : blank
                                }
                                alt="orgLogo"
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  borderRadius: 50,
                                  objectFit: "contain",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>
                                  {item?.organization?.organization_name}
                                </span>
                              </div>
                            </div>
                            <button
                              className="admin-rates-and-subscription-lower-tile-button"
                              onClick={() => {
                                navigate("/organization", {
                                  state: { data: item?.org_id },
                                });
                                dispatch(renderOrganizationInfo({}));
                              }}
                            >
                              Visit Page
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <span
                      style={{
                        color: "#4671a4",
                        marginTop: "10px",
                        fontSize: "20px",
                        fontWeight: "bolder",
                      }}
                    >
                      No Subscribers Yet
                    </span>
                  )
                ) : (
                  <div className="rates-and-subscription-lower-tile-content-container">
                    {loadingArray.map((item) => {
                      return (
                        <div
                          className="rates-and-subscription-lower-tile"
                          style={{
                            justifyContent: "space-between",
                            borderColor: "#ececec",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                width: "70px",
                                height: "70px",
                                borderRadius: 50,
                              }}
                              className="animation"
                            />
                            <span className="rates-and-subscription-subscribers-loading-span animation" />
                          </div>
                          <div className="rates-and-subscription-subscribers-loading-button animation" />
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showEditRatesPopup && (
        <VideoCallingRates
          hide={setShowEditRatesPopup}
          ratesData={ratesData}
          message="You are Setting Service Ticket Management Rates"
          rateType="service_ticket"
          getAllSubscriptionsRates={getAllSubscriptionsRates}
        />
      )}
      {infoPopup && (
        <RenderInformationPopup message={tabsText} hide={setInfoPopup} />
      )}
    </>
  );
};

export default RatesAndSubscriptionSuperAdmin;
