import React, { memo } from "react";

import ChatConnection from "../PureComponents/ChatConnection";

const RenderChatConnection = () => {
  return (
    <>
      <ChatConnection />
    </>
  );
};

export default memo(RenderChatConnection);
