import { useSelector } from "react-redux";

const useIsStorageLimitReached = () => {
  const organizationStorageData = useSelector(
    (state) => state.getOrganizationStorageDataReducer
  );
  // converting kb to bytes
  const availableSize =
    (organizationStorageData?.totalAvailableSize -
      organizationStorageData?.usedSize) *
    1024;

  const isStorageLimitReached = (fileSize) => {
    return availableSize < fileSize;
  };

  return isStorageLimitReached;
};

export default useIsStorageLimitReached;
