import "./create-account.css";

import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import ApartmentIcon from "@mui/icons-material/Apartment";
import LanguageIcon from "@mui/icons-material/Language";
import LockIcon from "@mui/icons-material/Lock";
import MailIcon from "@mui/icons-material/Mail";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import RenderTimeZone from "Components/TimeZone/Container/RenderTimeZone";
import TagIcon from "@mui/icons-material/Tag";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import logo from "Media/Amnius_connect_with_text.png";
import { register } from "Config/API/authentication";
import { setDialog } from "Redux/action/dialog.action";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import validators from "Config/Validators/validators";

const CreateAccount = () => {
  const [orgName, setOrgName] = useState("");
  const [orgUrl, setOrgUrl] = useState("");
  const [orgPhone, setOrgPhone] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [adminFirstName, setAdminFirstName] = useState("");
  const [adminLastName, setAdminLastName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminphone, setAdminPhone] = useState("");
  const [adminOffice, setAdminOffice] = useState("");
  const [extension, setExtension] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [visibility, setVisibility] = useState(false);

  // validators
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // for timezone
  const date = new Date();
  const getTimezone = (country) => {
    let strTime = date.toLocaleString("en-US", {
      timeZone: `${country}`,
    });
    setTimeZone(strTime);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validators(orgPhone, adminEmail, adminphone, adminOffice));
    setIsSubmit(true);
  };

  // we used useEffect so that we get updatedState
  useEffect(() => {
    if (Object.keys(formErrors).length?.toString() === "0" && isSubmit) {
      if (password?.toString() === verifyPassword?.toString()) {
        const adminName = `${adminFirstName} ${adminLastName}`;
        const registration_status = register(
          orgName,
          orgUrl,
          orgPhone,
          timeZone,
          adminName,
          adminFirstName,
          adminLastName,
          adminEmail,
          adminphone,
          adminOffice,
          extension,
          password
        );
        registration_status
          .then((res) => {
            if (res?.status) {
              dispatch(
                setDialog(
                  res?.status,
                  "Your account is under review for verification"
                )
              );
              navigate("/login");
            } else {
              toast(res?.message, {
                type: "error",
              });
            }
          })
          .catch(() =>
            toast("Failed! Try again later", {
              type: "error",
            })
          );
      } else {
        toast("Password Mismatched!", {
          type: "error",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  return (
    <>
      <div className="create-upper">
        <div className="create-logo">
          <div className="create-img">
            <img src={logo} alt="Aminus logo" />
          </div>
          <div className="create-header">
            <h2>Register an organization</h2>
          </div>
        </div>
      </div>

      <div className="create-lower">
        <form
          className="account-form"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="org-name org-flex">
            <h6>Organization Details</h6>
            <TextField
              required
              label="Organization name"
              placeholder="Sun Solutions"
              variant="outlined"
              type="text"
              style={{ marginTop: "unset" }}
              onChange={(e) => {
                setOrgName(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ApartmentIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              label="Organization website"
              placeholder="www.sunsolutions.com"
              variant="outlined"
              type="text"
              style={{ marginTop: "20px" }}
              onChange={(e) => {
                if (e.target.value.includes("https://")) {
                  setOrgUrl(e.target.value);
                } else {
                  setOrgUrl("https://" + e.target.value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LanguageIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Organization phone"
              placeholder="Enter 10-digit number"
              variant="outlined"
              type="number"
              style={{ marginTop: "20px" }}
              onChange={(e) => {
                setOrgPhone(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LanguageIcon />
                  </InputAdornment>
                ),
              }}
            />
            {formErrors.orgphone ? (
              <p className="error">{formErrors.orgphone}</p>
            ) : null}

            {/* render time zone  */}
            <RenderTimeZone getTimezone={getTimezone} />
          </div>

          <div className="admin-details org-flex">
            <h6>Admin Details</h6>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                required
                label="First name"
                placeholder="John"
                variant="outlined"
                type="text"
                style={{ marginTop: "unset", width: "49%" }}
                onChange={(e) => {
                  setAdminFirstName(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                required
                label="Last name"
                placeholder="Smith"
                variant="outlined"
                type="text"
                style={{ marginTop: "unset", width: "49%" }}
                onChange={(e) => {
                  setAdminLastName(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <TextField
              required
              label="Admin email"
              placeholder="johnsmith@xyz.com"
              variant="outlined"
              type="email"
              style={{ marginTop: "20px", marginBottom: "unset" }}
              onChange={(e) => {
                setAdminEmail(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
            />
            {formErrors?.email ? (
              <p className="error">{formErrors?.email}</p>
            ) : null}
            <TextField
              label="Admin mobile number"
              variant="outlined"
              placeholder="Enter 10-digit number"
              type="number"
              style={{ marginTop: "20px" }}
              onChange={(e) => {
                setAdminPhone(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
            {formErrors?.phone ? (
              <p className="error">{formErrors?.phone}</p>
            ) : null}
            <div className="admin-offic-phone">
              <div>
                <TextField
                  label="Admin office number"
                  variant="outlined"
                  fullWidth
                  placeholder="Enter 10-digit number"
                  type="number"
                  style={{ marginTop: "20px" }}
                  onChange={(e) => {
                    setAdminOffice(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {formErrors?.office ? (
                  <p className="error">{formErrors?.office}</p>
                ) : null}
              </div>
              <TextField
                label="Extension"
                variant="outlined"
                placeholder="123"
                type="number"
                style={{ marginTop: "20px" }}
                onChange={(e) => {
                  setExtension(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TagIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="password-verify org-flex">
            <h6>Password Verification</h6>
            <TextField
              required
              label="Create password"
              placeholder="Abcde@6"
              variant="outlined"
              type="password"
              style={{ marginTop: "20px" }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              label="Verify password"
              variant="outlined"
              type={visibility ? "text" : "password"}
              style={{ marginTop: "20px" }}
              onChange={(e) => {
                setVerifyPassword(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      onClick={() => {
                        setVisibility(!visibility);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="create-btn">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{
                background: "#446fa2",
                margin: "15px 0",
              }}
            >
              CREATE
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateAccount;
