import "./CreateStoragePlanPopup.css";

/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { createStoragePlan, updateStoragePlan } from "Config/API/action";

import { toast } from "react-toastify";

const CreateStoragePlanPopup = ({
  hide,
  token,
  getAllStoragePlans,
  selectedPlanDetails,
  setSelectedPlanDetails,
  setLoader,
  resetLoader,
}) => {
  const [planName, setPlanName] = useState(
    "" || selectedPlanDetails?.storageName
  );
  const [rate, setRate] = useState("" || selectedPlanDetails?.rate);
  const [from, setFrom] = useState("" || selectedPlanDetails?.fromSize);
  const [to, setTo] = useState("" || selectedPlanDetails?.toSize);

  const handleCreatePlanClick = (e) => {
    e.preventDefault();
    setLoader();
    let createPlanApiResponse;
    if (selectedPlanDetails?.id)
      createPlanApiResponse = updateStoragePlan(
        token,
        planName,
        from,
        to,
        rate,
        selectedPlanDetails?.id
      );
    else
      createPlanApiResponse = createStoragePlan(
        token,
        planName,
        from,
        to,
        rate
      );
    createPlanApiResponse.then((res) => {
      resetLoader();
      if (res?.status) {
        setSelectedPlanDetails({});
        if (
          res?.message?.includes("already") ||
          res?.message?.includes("missed")
        )
          return toast(res?.message, { type: "info" });
        toast(res?.message, { type: "success" });
        getAllStoragePlans();
        hide(false);
      } else toast(res?.message, { type: "error" });
    });
  };

  return (
    <>
      <div className="create-storage-plan-parent">
        <div className="create-storage-plan-main">
          <div className="create-storage-plan-heading">
            <p>Create Plan</p>
            <div
              className="rename-cross"
              onClick={() => {
                setSelectedPlanDetails({});
                hide(false);
              }}
            >
              X
            </div>
          </div>

          <form
            onSubmit={(e) => handleCreatePlanClick(e)}
            className="create-storage-plan-wrapper"
          >
            <div className="create-storage-plan-wrapper-content">
              <div>
                <label>
                  Plan Name<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Plan Name"
                  required
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value.trimStart())}
                />
              </div>
              <div>
                <label>
                  Rate<span>*</span>
                </label>
                <input
                  type="number"
                  placeholder="Rate"
                  required
                  min={0}
                  value={rate}
                  step="0.01"
                  onChange={(e) => setRate(e.target.value)}
                />
              </div>
            </div>
            <div className="create-storage-plan-wrapper-content">
              <div>
                <label>
                  From<span>*</span>
                </label>
                <input
                  type="number"
                  placeholder="From"
                  required
                  value={from}
                  min={0}
                  step="0"
                  onChange={(e) => setFrom(e.target.value.trimStart())}
                />
              </div>
              <div>
                <label>
                  To<span>*</span>
                </label>
                <input
                  type="number"
                  placeholder="To"
                  required
                  min={0}
                  value={to}
                  step="0"
                  onChange={(e) => setTo(e.target.value.trimStart())}
                />
              </div>
            </div>
            <button className="create-storage-plan-create-button" type="submit">
              {selectedPlanDetails?.id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateStoragePlanPopup;
