import "./STRaiseRequestForm.css";

import React, { useEffect, useRef, useState } from "react";
import {
  raiseRequestFileUpload,
  raiseServiceRequestApi,
} from "Config/API/action";

import ExternalIcon from "Media/icons/External_icon.svg";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import { fileExplorerSearch } from "Config/API/get";
import folderIcon from "Media/icons/Folder-Close.png";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import { useSelector } from "react-redux";

const STRaiseRequestForm = ({ hide, service_org_id, ticketData }) => {
  const token = localStorage.getItem("token");
  const general_info = useSelector((state) => state.authenticationReducer);
  const organization_name = useSelector((state) => state.renderGeneralInfo);
  const isStorageLimitReached = useIsStorageLimitReached();

  // fields to fill
  const [requestorOrganization, setRequestorOrganization] = useState(
    organization_name?.organization_name || ""
  );
  const [requestorName, setRequestorName] = useState(general_info?.name || "");
  const [requestorPhone, setRequestorPhone] = useState(
    general_info?.phone || ""
  );
  const [requestorEmail, setRequestorEmail] = useState(
    general_info?.email || ""
  );
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [folderList, setFolderList] = useState([]);
  const [folder, setFolder] = useState("" || ticketData?.folderNameState);
  const [folderLink, setFolderLink] = useState(
    "" || ticketData?.folderLinkState
  );
  const [folderId, setFolderId] = useState("" || ticketData?.folderIdState);
  const [files, setFiles] = useState([]);
  // to show popups
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  const [loader, setLoader, resetLoader] = useFullPageLoader();

  const folderRef = useRef(null);
  useOutsideAlerter(folderRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    var folder_name = folderId;
    setLoader();
    if (folderId?.toString() === "" && folderLink?.toString() === "") {
      folder_name = folder;
    }

    const raise_request_status = raiseServiceRequestApi(
      token,
      service_org_id,
      "",
      description,
      subject,
      folderLink,
      folder_name,
      "",
      "",
      "external"
    );

    raise_request_status.then((res) => {
      if (res?.data?.status) {
        if (files?.length > 0) {
          const request_service_files = raiseRequestFileUpload(
            token,
            res?.data?.id,
            files
          );
          request_service_files.then((ress) => {
            resetLoader();
            if (ress?.status) {
              hide(false);
              toast(res?.message, { type: "success" });
            } else toast(ress?.message, { type: "error" });
          });
        } else {
          hide(false);
          resetLoader();
          toast(res?.message, { type: "success" });
        }
      } else {
        resetLoader();
        toast(res?.message, { type: "error" });
      }
    });
  };

  return (
    <>
      <div className="straisereq-parent">
        <div className="straisereq-main">
          <form
            className="straisereq-form-body"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="straisereq-heading">
              <p>Service Request Form</p>
              <div className="rename-cross" onClick={() => hide(false)}>
                X
              </div>
            </div>
            <div className="straisereq-wrapper">
              {requestorOrganization?.toString() !== "" ? (
                <div className="straisereq-fields">
                  <label htmlFor="organization">
                    Requestor Organization<span>*</span>:
                  </label>

                  <input
                    style={{ backgroundColor: "#f1f2f6" }}
                    type="text"
                    id="organization"
                    value={requestorOrganization}
                    readOnly
                    required
                  />
                </div>
              ) : (
                <div className="straisereq-fields">
                  <label htmlFor="organization">
                    Requestor Organization<span>*</span>:
                  </label>

                  <input
                    type="text"
                    id="organization"
                    value={requestorOrganization}
                    onChange={(e) => {
                      setRequestorOrganization(e.target.value);
                    }}
                    required
                  />
                </div>
              )}

              <div className="straisereq-fields">
                <label htmlFor="contact-name">
                  Requestor Name<span>*</span>:
                </label>
                <input
                  style={{ backgroundColor: "#f1f2f6" }}
                  type="text"
                  value={requestorName}
                  id="contact-name"
                  onChange={(e) => {
                    setRequestorName(e.target.value);
                  }}
                  readOnly
                  required
                />
              </div>

              <div className="straisereq-fields">
                <label htmlFor="contact-email">
                  Requestor Email<span>*</span>:
                </label>
                <input
                  style={{ backgroundColor: "#f1f2f6" }}
                  type="text"
                  value={requestorEmail}
                  id="contact-email"
                  onChange={(e) => {
                    setRequestorEmail(e.target.value);
                  }}
                  readOnly
                  required
                />
              </div>

              <div className="straisereq-fields">
                <label htmlFor="description">
                  Subject<span>*</span>:
                </label>
                <input
                  type="text"
                  id="subject"
                  placeholder="Enter Issue Title"
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="stcreate-fields">
                <label htmlFor="folder">Folder:</label>
                <div ref={folderRef}>
                  <input
                    type="search"
                    id="folder"
                    value={folder}
                    readOnly={ticketData?.folderNameState?.length > 0}
                    style={{
                      backgroundColor:
                        ticketData?.folderNameState?.length > 0 && "#f1f2f6",
                    }}
                    onClick={(e) => {
                      if (ticketData?.folderNameState?.length > 0) return;
                      const folder_status = fileExplorerSearch(
                        token,
                        "",
                        service_org_id
                      );
                      folder_status.then((res) => {
                        if (res?.status) {
                          setFolderList(res?.result);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                    onChange={(e) => {
                      setFolder(e.target.value);
                      const folder_status = fileExplorerSearch(
                        token,
                        e.target.value,
                        service_org_id
                      );
                      folder_status.then((res) => {
                        if (res?.status) {
                          setFolderList(res?.result);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      });
                    }}
                  />

                  {/****************  Folder List ***************/}
                  <div className="organization-list folder-list">
                    {folderList?.length?.toString() !== "0" ? (
                      !folderList?.message ? (
                        folderList?.map((curr, index) => {
                          if (curr?.type?.toString() === "folder") {
                            return (
                              <div
                                className="data-list"
                                onClick={() => {
                                  setFolderLink(curr?.sharePath);
                                  setFolderId(curr?.unique_id);
                                  setFolder(curr?.name);
                                  setFolderList("");
                                }}
                                key={index}
                              >
                                <div>
                                  <div className="organization-img-parent">
                                    <img src={folderIcon} alt="Folder" />
                                    {curr?.scope === "external" ? (
                                      <img
                                        src={ExternalIcon}
                                        alt="External"
                                        style={{
                                          position: "absolute",
                                          right: "0",
                                          bottom: "-10px",
                                          width: "17.5px",
                                        }}
                                      />
                                    ) : null}
                                  </div>

                                  <span className="multiple-line-text">
                                    <span>
                                      {curr?.name ? curr?.name : "No Name"}
                                    </span>
                                    <i>
                                      ( {curr?.bookmark ? curr?.bookmark : null}{" "}
                                      )
                                    </i>
                                  </span>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="orgListAlert">No Such Folder Found</div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="stcreate-fields stcreate-file-upload">
                <label htmlFor="file">Upload Files:</label>
                <input
                  type="file"
                  id="file"
                  multiple
                  accept=".png,.jpg,.jpeg,.pdf, .docx, .doc, .xlsx, .xls, .csv, .mp4"
                  onChange={(e) => {
                    let totalSize = 0;
                    for (let i = 0; i < e.target.files.length; i++) {
                      totalSize = totalSize + e.target.files[i].size;
                    }
                    if (isStorageLimitReached(totalSize))
                      return setShowStorageLimitReachedPopup(true);
                    setFiles(e.target.files);
                  }}
                />
              </div>
              <div className="straisereq-fields">
                <label htmlFor="description">
                  Description<span>*</span>:
                </label>
                <textarea
                  id="description"
                  rows="5"
                  placeholder="Enter Issue Description"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  name="description"
                  required
                ></textarea>
              </div>

              <div className="straisereq-fields">
                <button type="submit">Raise Request</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {showStorageLimitReachedPopup && (
        <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
      )}
      {loader}
    </>
  );
};

export default STRaiseRequestForm;
