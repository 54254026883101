import React, { useState } from "react";
import { disableRoom, leaveCall } from "Config/API/action";
import {
  selectIsConnectedToRoom,
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  selectPeerCount,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useDispatch, useSelector } from "react-redux";

import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import call from "Media/icons/call.png";
import moment from "moment-timezone";
import { storeVideoChatLinkAction } from "Redux/action/get.action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ControlBar = ({
  checkPermissions,
  hours,
  minutes,
  seconds,
  loggedInUser,
  localPeer,
  peers,
  setMirrorVideo,
  mirrorVideo,
  startTime,
  tabUniqueId,
}) => {
  const hmsActions = useHMSActions();
  const isLocalAudioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const isLocalVideoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const count = useHMSStore(selectPeerCount);
  const msRoom = useSelector((state) => state.storeVideoChatLinkReducer.name);
  const msToken = useSelector((state) => state.storeVideoChatLinkReducer.token);
  const from = useSelector((state) => state.storeVideoChatLinkReducer.from);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableEndButton, setDisableEndButton] = useState(false);
  const toggleAudio = async () => {
    await checkPermissions();
    await hmsActions.setLocalAudioEnabled(!isLocalAudioEnabled);
  };
  const toggleVideo = async () => {
    await checkPermissions();
    await hmsActions.setLocalVideoEnabled(!isLocalVideoEnabled);
  };

  const changeHost = () => {
    const allParticipants = peers?.filter((item) => {
      return item?.roleName?.toString() !== "host";
    });
    hmsActions.changeRoleOfPeer(localPeer?.id, "participant", true);
    hmsActions.changeRoleOfPeer(allParticipants[0]?.id, "host", true);
  };

  const toggleMirrorVideo = () => {
    setMirrorVideo(!mirrorVideo);
  };
  return (
    <div className="control-bar">
      <button className="btn-control" onClick={toggleAudio}>
        {isLocalAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      </button>
      <button className="btn-control" onClick={toggleVideo}>
        {isLocalVideoEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
      </button>
      <button className="btn-control" onClick={toggleMirrorVideo}>
        {mirrorVideo ? (
          <VideoCameraFrontIcon className="video-mirroring" />
        ) : (
          <VideoCameraFrontIcon />
        )}
      </button>
      {isConnected &&
        (count?.toString() === "1" && from !== "appointment" ? (
          <button
            id="leave-btn"
            className="btn-danger-bottom"
            disabled={disableEndButton}
            onClick={() => {
              setDisableEndButton(true);
              const duration = hours * 3600 + minutes * 60 + seconds;
              const leave_room = leaveCall(
                token,
                loggedInUser?.id,
                loggedInUser?.org_id,
                duration % 30,
                msRoom,
                "false",
                "false",
                moment().format("YYYY-MM-DD HH:mm:ss"),
                tabUniqueId,
                startTime
              );
              leave_room.then((res) => {
                if (res?.status) {
                  const disable_room = disableRoom(token, msToken, msRoom);
                  disable_room
                    .then((res) => {
                      if (res?.status) {
                        hmsActions.leave();
                        dispatch(storeVideoChatLinkAction(""));
                        navigate(-1);
                      } else {
                        toast(res?.message, {
                          type: "error",
                        });
                      }
                    })
                    .catch((e) => {
                      toast("Failed! Try again later", {
                        type: "error",
                      });
                    });
                }
              });
            }}
          >
            <img src={call} alt="call" style={{ height: 23, width: 25 }} />
          </button>
        ) : (
          <button
            id="leave-btn"
            className="btn-danger-bottom"
            disabled={disableEndButton}
            onClick={() => {
              setDisableEndButton(true);
              const duration = hours * 3600 + minutes * 60 + seconds;
              const leave_room = leaveCall(
                token,
                loggedInUser?.id,
                loggedInUser?.org_id,
                duration % 30,
                msRoom,
                "true",
                "false",
                moment().format("YYYY-MM-DD HH:mm:ss"),
                tabUniqueId,
                startTime
              );
              leave_room.then((res) => {
                if (res?.status) {
                  if (localPeer.roleName?.toString() === "host") {
                    changeHost();
                  }
                  hmsActions.leave();
                  dispatch(storeVideoChatLinkAction(""));
                  if (from === "appointment") return navigate("/appointment");
                  navigate(-1);
                }
              });
            }}
          >
            <img src={call} alt="call" style={{ height: 23, width: 25 }} />
          </button>
        ))}
    </div>
  );
};

export default ControlBar;
