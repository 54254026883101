import {
  DeviceType,
  selectHMSMessages,
  selectLocalPeer,
  selectPeerCount,
  selectPeerScreenSharing,
  selectPeers,
  selectRoomStartTime,
  selectScreenShareByPeerID,
  useDevices,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import ControlBar from "./ControlBar";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import EmojiPicker from "emoji-picker-react";
import Header from "./Header";
import HostVideoTile from "./HostVideoTile";
import RenderDeviceSettings from "Components/Popup/DeviceSettings/Container/RenderDeviceSettings";
import ScreenShareVideoPeers from "./ScreenShareVideoPeers";
import SendIcon from "@mui/icons-material/Send";
import VideoTile from "./VideoTile";
import { leaveCall } from "Config/API/action";
import moment from "moment-timezone";
import { statusOfRoom } from "Config/API/get";
import { storeVideoChatLinkAction } from "Redux/action/get.action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useStopwatch } from "react-timer-hook";
import video from "Media/icons/video.png";

const Room = () => {
  const videoRef = React.useRef(null);
  const hmsActions = useHMSActions();
  const localPeer = useHMSStore(selectLocalPeer);
  const peers = useHMSStore(selectPeers);
  const count = useHMSStore(selectPeerCount);
  const presenter = useHMSStore(selectPeerScreenSharing);
  const allMessages = useHMSStore(selectHMSMessages);
  const roomStartTime = useHMSStore(selectRoomStartTime);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.authenticationReducer);
  const msRoom = useSelector((state) => state.storeVideoChatLinkReducer.name);
  const msToken = useSelector((state) => state.storeVideoChatLinkReducer.token);
  const from = useSelector((state) => state.storeVideoChatLinkReducer.from);
  const roomId = useSelector((state) => state.storeVideoChatLinkReducer.id);
  const token = localStorage.getItem("token");
  const screenshareVideoTrack = useHMSStore(
    selectScreenShareByPeerID(presenter?.id)
  );
  const { allDevices, selectedDeviceIDs, updateDevice } = useDevices();
  const [showSettings, setShowSettings] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [containerWidth, setContainerWidth] = useState("100%");
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [mirrorVideo, setMirrorVideo] = useState(false);
  const { seconds, minutes, hours } = useStopwatch({ autoStart: true });
  const [tabUniqueId, setTabUniqueId] = useState("");
  // window.addEventListener("beforeunload", () => localStorage.setItem("HELLO BEFORE UNLOAD", 'value'));
  // window.addEventListener("onunload", () => localStorage.setItem("HELLO ONUNLOAD", 'value'));

  React.useEffect(() => {
    (async () => {
      if (videoRef.current && screenshareVideoTrack) {
        if (screenshareVideoTrack.enabled) {
          await hmsActions.attachVideo(
            screenshareVideoTrack.id,
            videoRef.current
          );
        } else {
          await hmsActions.detachVideo(
            screenshareVideoTrack.id,
            videoRef.current
          );
        }
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenshareVideoTrack]);

  React.useEffect(() => {
    if (showChat?.toString() === "true") {
      setContainerWidth("70%");
    } else if (showChat?.toString() === "false") {
      setContainerWidth("100%");
    }
  }, [showChat]);

  React.useEffect(() => {
    const host = peers.filter((peer) => peer.roleName?.toString() === "host");
    if (host.length === 0)
      hmsActions.changeRoleOfPeer(peers[0]?.id, "host", true);
    const room_status = statusOfRoom(token, msToken, roomId);
    room_status.then((res) => {
      if (res?.status) {
        if (!res?.data.enabled) {
          hmsActions.leave();
          dispatch(storeVideoChatLinkAction(""));
          toast(
            "Room got Disabled as one of the Organization does not have enough Credits",
            {
              type: "info",
            }
          );
          if (from === "appointment") return navigate("/appointment");
          navigate(-1);
        }
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    setTabUniqueId(loggedInUser?.id?.toString().concat("-", result));
  }, []);

  React.useEffect(() => {
    const duration = hours * 3600 + minutes * 60 + seconds;
    if (duration % 30 === 0 && duration > 29) {
      const leave_room = leaveCall(
        token,
        loggedInUser?.id,
        loggedInUser?.org_id,
        30,
        msRoom,
        "true",
        "true",
        "",
        tabUniqueId,
        moment(roomStartTime).format("YYYY-MM-DD HH:mm:ss")
      );
      leave_room.then((res) => {
        if (res?.statusCode === 400) {
          hmsActions.leave();
          dispatch(storeVideoChatLinkAction(""));
          toast("You don't have Enough Credits to continue the Call.", {
            type: "info",
          });
          if (from === "appointment") return navigate("/appointment");
          navigate(-1);
        }
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, minutes, hours]);

  const updateSettings = async (device, type) => {
    if (type?.toString() === "videoInput") {
      return updateDevice({
        deviceId: device.target.value,
        deviceType: DeviceType.videoInput,
      });
    } else if (type?.toString() === "audioInput") {
      return updateDevice({
        deviceId: device.target.value,
        deviceType: DeviceType.audioInput,
      });
    } else if (type?.toString() === "audioOutput") {
      return updateDevice({
        deviceId: device.target.value,
        deviceType: DeviceType.audioOutput,
      });
    }
  };

  const formatTime = (item) => {
    const date = new Date(item);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinute = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${hours}:${formattedMinute}`;
    return time;
  };

  const checkPermissions = async (msg) => {
    if (msg?.toString() === "setting") {
      setShowSettings(true);
    }
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    permissions
      .then((stream) => {})
      .catch((err) => {
        if (err?.message?.toString() === "Permission denied") {
          return toast(
            "Enable video and audio access in your browser's access bar.",
            {
              type: "info",
            }
          );
        } else if (msg?.toString() !== "setting") {
          return toast(`${err?.message}`, {
            type: "info",
          });
        }
      });
  };

  return (
    <>
      <Header
        showChat={setShowChat}
        checkPermissions={checkPermissions}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        loggedInUser={loggedInUser}
        localPeer={localPeer}
        peers={peers}
        startTime={moment(roomStartTime).format("YYYY-MM-DD HH:mm:ss")}
        tabUniqueId={tabUniqueId}
      />
      <div style={{ width: "100%", height: "80%", display: "flex" }}>
        <div
          className="conference-section"
          style={{ width: `${containerWidth}` }}
        >
          {screenshareVideoTrack?.enabled ? (
            <div className="video-call-sharing-screen-container">
              <video
                ref={videoRef}
                autoPlay={true}
                playsInline
                muted={true}
                className="video-call-sharing-screen-video-section"
                style={{ backgroundColor: "transparent" }}
              ></video>
              <div className="video-call-sharing-screen-peers-section">
                {peers &&
                  peers?.map((peer, index) => {
                    return (
                      <ScreenShareVideoPeers
                        isLocal={false}
                        peer={peer}
                        key={index}
                        mirrorVideo={mirrorVideo}
                      />
                    );
                  })}
              </div>
              <ControlBar
                checkPermissions={checkPermissions}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
                loggedInUser={loggedInUser}
                localPeer={localPeer}
                peers={peers}
                setMirrorVideo={setMirrorVideo}
                mirrorVideo={mirrorVideo}
                startTime={moment(roomStartTime).format("YYYY-MM-DD HH:mm:ss")}
                tabUniqueId={tabUniqueId}
              />
            </div>
          ) : count?.toString() === "1" ? (
            <>
              <div className="video-call-single-user-Container">
                {peers &&
                  peers.map((peer, index) => {
                    return (
                      <VideoTile
                        isLocal={false}
                        peer={peer}
                        key={index}
                        mirrorVideo={mirrorVideo}
                      />
                    );
                  })}
              </div>
              <ControlBar
                checkPermissions={checkPermissions}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
                loggedInUser={loggedInUser}
                localPeer={localPeer}
                peers={peers}
                setMirrorVideo={setMirrorVideo}
                mirrorVideo={mirrorVideo}
                startTime={moment(roomStartTime).format("YYYY-MM-DD HH:mm:ss")}
                tabUniqueId={tabUniqueId}
              />
            </>
          ) : (
            <>
              {/* <div className="peers-container">
             
              {peers &&
                peers
                  //.filter((peer) => !peer.isLocal)
                  ?.map((peer, index) => {
                    return (
                      <VideoTile isLocal={false} peer={peer} key={index} />
                    );
                  })}
            </div> */}
              <div className="video-call-sharing-screen-container">
                <div className="video-call-sharing-screen-video-section">
                  {peers &&
                    peers
                      .filter((peer) => peer.roleName?.toString() === "host")
                      .map((peer, index) => {
                        return (
                          <HostVideoTile
                            isLocal={false}
                            peer={peer}
                            key={index}
                            mirrorVideo={mirrorVideo}
                          />
                        );
                      })}
                </div>
                <div className="video-call-sharing-screen-peers-section">
                  {peers &&
                    peers
                      .filter((peer) => peer.roleName?.toString() !== "host")
                      .map((peer, index) => {
                        return (
                          <>
                            <ScreenShareVideoPeers
                              isLocal={false}
                              peer={peer}
                              key={index}
                              mirrorVideo={mirrorVideo}
                            />
                          </>
                        );
                      })}
                </div>
              </div>
              <ControlBar
                checkPermissions={checkPermissions}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
                loggedInUser={loggedInUser}
                localPeer={localPeer}
                peers={peers}
                setMirrorVideo={setMirrorVideo}
                mirrorVideo={mirrorVideo}
                startTime={moment(roomStartTime).format("YYYY-MM-DD HH:mm:ss")}
                tabUniqueId={tabUniqueId}
              />
            </>
          )}
        </div>
        {showChat && (
          <div className="room-chat-dialog">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 10px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h4
                style={{
                  fontWeight: 900,
                  fontSize: "18px",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Meeting Chat
              </h4>
              <button
                className="chat-close-button"
                onClick={() => {
                  setShowChat(false);
                  setShowEmojiPicker(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </button>
            </div>
            <div
              style={{
                width: "100%",
                height: "90%",
                overflow: "scroll",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  width: "100%",
                  padding: "0px 10px",
                  alignItems: "center",
                }}
              >
                <img src={video} alt="video" height={20} width={30} />
                {formatTime(roomStartTime)}
                <span>Meeting Started</span>
              </div>
              {allMessages.map((item) => (
                <div
                  style={{
                    marginTop: "25px",
                    marginBottom: "-15px",
                    padding: "10px 20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h5
                      style={{
                        fontWeight: 900,
                        fontSize: "18px",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {item?.senderName}
                    </h5>
                    <span
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {formatTime(item?.time)}
                    </span>
                  </div>
                  <span style={{ marginTop: 2 }}>{item?.message}</span>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "0px 10px",
                flexDirection: "column",
              }}
            >
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                  width: "100%",
                  height: "40px",
                  backgroundColor: "#1F1F1F",
                }}
                placeholder="Type a new message"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "3px 7px",
                }}
              >
                <button
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="chat-close-button"
                >
                  <EmojiEmotionsIcon fontSize="small" />
                </button>
                <button
                  onClick={() => {
                    if (message.length > 0) {
                      hmsActions.sendBroadcastMessage(message);
                      setMessage("");
                      setShowEmojiPicker(false);
                    }
                  }}
                  className="chat-close-button"
                >
                  <SendIcon fontSize="small" />
                </button>
              </div>
            </div>
            {showEmojiPicker && (
              <EmojiPicker
                height="250%"
                width="100%"
                onEmojiClick={(e) => setMessage(message.concat(e.emoji))}
                theme="dark"
                searchDisabled={true}
              />
            )}
          </div>
        )}
      </div>
      <RenderDeviceSettings
        showSettings={showSettings}
        allDevices={allDevices}
        setShowSettings={setShowSettings}
        updateSettings={updateSettings}
        selectedDeviceIDs={selectedDeviceIDs}
      />
    </>
  );
};

export default Room;
