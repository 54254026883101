import "./InformationPopup.css";

import React from "react";

const InformationPopup = ({ message, hide }) => {
  return (
    <>
      <nav className="info-popup-container">
        <div className="popup-popup confirm-box">
          <div
            className="popup-cross confirm-cross"
            onClick={() => hide(false)}
          >
            X
          </div>
          <span>{message}</span>
        </div>
      </nav>
    </>
  );
};

export default InformationPopup;
