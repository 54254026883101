import React, { useEffect } from "react";
import {
  getEventListAction,
  getEventListAppointmentAction,
  getEventListVideoConferenceAction,
} from "Redux/action/get.action";

import AppointmentSchedule from "../PureComponents/AppointmentSchedule";
import { getFullAppointments } from "Config/API/get";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const RenderAppointmentSchedule = ({
  createAppointmentRef,
  videoChatDetails,
  setVideoChatDetails,
}) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    getAllAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllAppointments = () => {
    const appointment_status = getFullAppointments(token, "all");
    appointment_status
      .then((res) => {
        if (res?.status) {
          const filteredVideoConferenceEvent = res?.appointment?.filter(
            (item) => item?.type === "conference"
          );
          const filteredAppointmentEvent = res?.appointment?.filter(
            (item) => item?.type !== "conference"
          );
          dispatch(getEventListAction(res?.appointment));
          dispatch(getEventListAppointmentAction(filteredAppointmentEvent));
          dispatch(
            getEventListVideoConferenceAction(filteredVideoConferenceEvent)
          );
        } else {
          dispatch(getEventListAction([]));
          toast(res?.message, { type: "error" });
        }
      })
      .catch((e) => {
        toast("Failed! Try again later", {
          type: "error",
        });
      });
  };

  return (
    <AppointmentSchedule
      createAppointmentRef={createAppointmentRef}
      videoChatDetails={videoChatDetails}
      setVideoChatDetails={setVideoChatDetails}
    />
  );
};

export default RenderAppointmentSchedule;
