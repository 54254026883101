import "./PurchaseSubscription.css";

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import DefaultPaymentPopup from "../DefaultPaymentPopup/DefaultPaymentPopup";
import { getCreditCardDetails } from "Config/API/get";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const PurchaseSubscription = ({
  header,
  description,
  hide,
  subscribeService,
  loading,
  setLoading,
}) => {
  const token = localStorage.getItem("token");
  const [cardDetails, setCardDetails] = useState([]);
  const [showDefaultPaymentPopoup, setShowDefaultPaymentPopoup] =
    useState(false);
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const defaultPaymentPreference = useSelector(
    (state) => state.authenticationReducer?.defaultPaymentPreference
  );
  const preference =
    defaultPaymentPreference === "credit" ? "Site Credit" : "Default Card";

  useEffect(() => {
    getCardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCardDetails = () => {
    const cardDetailsResponse = getCreditCardDetails(token);
    cardDetailsResponse.then((res) => {
      if (res?.data?.status) {
        setCardDetails(res?.data?.data);
      }
    });
  };

  return (
    <>
      <div className="purchase-subscription-parent">
        <div className="purchase-subscription-body">
          <div className="purchase-subscription-close">
            <div onClick={() => hide(false)}>X</div>
          </div>
          <label className="purchase-subscription-heading">{header}</label>
          <label className="purchase-subscription-description">
            {description} Credits will get deduct from {preference}.<br />
            <a
              onClick={() => {
                if (loggedInUserDetails?.credit_account_manager === "manager")
                  return setShowDefaultPaymentPopoup(true);
                toast("You do not have rights to edit default payment method", {
                  type: "info",
                });
              }}
            >
              Click here
            </a>{" "}
            to change default payment method.
          </label>
          <button
            onClick={() => {
              if (!loading) {
                subscribeService();
                setLoading(true);
              }
            }}
          >
            {loading ? "Please wait..." : "Subscribe"}
          </button>
        </div>
      </div>
      {showDefaultPaymentPopoup && (
        <DefaultPaymentPopup
          hide={setShowDefaultPaymentPopoup}
          defaultCardDetails={cardDetails?.filter(
            (item) => item?.default === true
          )}
        />
      )}
    </>
  );
};

export default PurchaseSubscription;
