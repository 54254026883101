import "./AddMoneyPopup.css";

import React, { useState } from "react";
import { getClientSecretKey, getCreditCardDetails } from "Config/API/get";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import { setClientSecretKeyAction } from "Redux/action/get.action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useNavigate } from "react-router-dom";

const AddMoneyPopup = ({ hide }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credit, setCredit] = useState("");
  const [selectedTile, setSelectedTile] = useState("");
  const token = localStorage.getItem("token");
  const stripeCustomerId = useSelector(
    (state) => state.authenticationReducer?.stripe_customer_id
  );
  const [loader, setLoader, resetLoader] = useFullPageLoader();

  const getCredit = (paymentMethodId) => {
    // get the client secret key
    const clientSecretFn = getClientSecretKey(
      token,
      stripeCustomerId,
      credit,
      "Adding Credits",
      "Credit",
      0,
      paymentMethodId
    );
    clientSecretFn.then((res) => {
      resetLoader();
      if (res?.status === 200) {
        dispatch(setClientSecretKeyAction(res?.data?.data));
        if (
          res?.data?.data?.payment_method !== null &&
          res?.data?.data?.payment_method?.length > 0
        )
          // navigate to completion page
          return navigate("/completion");
        // navigate to checkout page
        else return navigate("/checkout");
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const getDefaultCard = () => {
    const bankDetailsResponse = getCreditCardDetails(token);
    bankDetailsResponse.then((res) => {
      if (res?.status) {
        const defaultCard = res?.data?.data.filter(
          (card) => card.default === true
        );
        if (defaultCard.length > 0) {
          getCredit(defaultCard[0]?.id);
        } else {
          resetLoader();
          return toast("You don't have any Default Card", {
            type: "info",
          });
        }
      } else {
        resetLoader();
        return toast("Failed! Try again later", {
          type: "error",
        });
      }
    });
  };

  const validate = (paymentType) => {
    if (stripeCustomerId) {
      if (credit > 0) {
        setLoader();
        if (paymentType === "defaultCard") return getDefaultCard();
        else return getCredit("");
      } else {
        toast("Select An Amount", { type: "info" });
      }
    } else {
      toast("Stripe Issue: Stripe Id Missing", { type: "error" });
    }
  };

  return (
    <>
      <nav className="add-money-container">
        <div className="add-money-popup">
          <div className="add-money-cross" onClick={() => hide(false)}>
            X
          </div>

          <div className="add-money-wrapper">
            <div className="add-money-header">
              How much would you like to add?
            </div>
            <div className="add-money-content">
              <button
                className={selectedTile?.toString() === "50" ? "active" : ""}
                onClick={() => {
                  setCredit(50);
                  setSelectedTile("50");
                }}
              >
                $50
              </button>
              <button
                className={selectedTile?.toString() === "100" ? "active" : ""}
                onClick={() => {
                  setCredit(100);
                  setSelectedTile("100");
                }}
              >
                $100
              </button>
              <button
                className={selectedTile?.toString() === "200" ? "active" : ""}
                onClick={() => {
                  setCredit(200);
                  setSelectedTile("200");
                }}
              >
                $200
              </button>
              <input
                className={
                  selectedTile?.toString() === "custom-cred" ? "active" : ""
                }
                type="number"
                value={credit}
                style={{ fontSize: "14px" }}
                placeholder="Custom Amount"
                onClick={() => setSelectedTile("custom-cred")}
                onChange={(e) => {
                  setCredit(e.target.value);
                  setSelectedTile("custom-cred");
                }}
              />
            </div>

            <div className="add-money-submit">
              <Button
                variant="contained"
                style={{ background: "#5CA127" }}
                onClick={() => validate("defaultCard")}
              >
                Pay with Default Card
              </Button>
              <Button
                variant="contained"
                style={{ background: "#446FA2" }}
                onClick={() => validate("")}
              >
                Pay
              </Button>
            </div>

            <div className="add-money-bottom">
              <div>To Change or Update Payment method:</div>
              <button
                onClick={() => {
                  hide(false);
                  if (window.location.pathname !== "/transfer-and-banking")
                    navigate("/transfer-and-banking");
                }}
              >
                Go to Account Management Page
              </button>
            </div>
          </div>
        </div>
      </nav>
      {loader}
    </>
  );
};

export default AddMoneyPopup;
