import Chat from "../PureComponents/Chat";
import React from "react";

const RenderChat = () => {
  return (
    <>
      <Chat />
    </>
  );
};

export default RenderChat;
