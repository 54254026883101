import "./Error.css";

import React from "react";
import pageNotFound from "Media/icons/pageNotFound.png";

const PageNotFoundSection = () => {
  return (
    <div className="page-not-found-container">
      <h1>404</h1>
      <h2>PAGE NOT FOUND</h2>
      <img src={pageNotFound} alt="pagenotFound" />
    </div>
  );
};

export default PageNotFoundSection;
