import "./ForgetAccount.css";

import { Button, InputAdornment, TextField } from "@mui/material";

import MailIcon from "@mui/icons-material/Mail";
import React from "react";

const ForgetAccount = ({ hide, verify }) => {
  const [email, setEmail] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    verify(email);
    setTimeout(() => {
      hide(false);
    }, 1000);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <div className="forget-container">
        <div className="forget-popup">
          <div className="forget-cross" onClick={() => hide(false)}>
            X
          </div>
          <span>Please enter your registered Email!</span>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="forget-email">
              <TextField
                fullWidth
                required
                value={email}
                label="Email"
                variant="outlined"
                type="email"
                onChange={handleEmail}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="forget-submit">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ background: "#446fa2" }}
              >
                SUBMIT
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgetAccount;
