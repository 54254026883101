import React from "react";
import Trash from "../PureComponents/Trash";
import { getTrashData } from "Config/API/get";
import { getTrashDataAction } from "Redux/action/get.action";
import { useDispatch } from "react-redux";

const RenderTrash = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const trash_status = getTrashData(token);
  trash_status.then((res) => {
    if (res?.status) {
      dispatch(getTrashDataAction(res?.subfolderData));
    }
  });

  return <Trash />;
};

export default RenderTrash;
