import React from "react";
import STInternalRaiseRequestForm from "../PureComponents/STInternalRaiseRequestForm";

const RenderSTInternalRaiseRequestForm = ({
  hide,
  service_org_id,
  ticketData,
}) => {
  return (
    <STInternalRaiseRequestForm
      hide={hide}
      service_org_id={service_org_id}
      ticketData={ticketData}
    />
  );
};

export default RenderSTInternalRaiseRequestForm;
