import NotificationCard from "../PureComponents/NotificationCard";
import React from "react";
import { getNotification } from "Config/API/get";
import { getRecentNotificationAction } from "Redux/action/get.action";
import { useDispatch } from "react-redux";

const RenderNotificationCard = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const notification_status = getNotification(token);
  notification_status.then((res) => {
    dispatch(getRecentNotificationAction(res?.data[1].notification));
  });
  return (
    <>
      <NotificationCard />
    </>
  );
};

export default RenderNotificationCard;
