import "./PurchaseStoragePlanPopup.css";

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  getStorageData,
  getStoragePlans,
  getUserDetails,
} from "Config/API/get";
import { useDispatch, useSelector } from "react-redux";

import InfoPopup from "../InfoPopup/InfoPopup";
import { changeDefaultPaymentMethod } from "Config/API/update";
import creditCard from "Media/icons/creditCardIcon.png";
import dollarIcon from "Media/icons/cards-with-dollar-sign@2x.png";
import { getOrganizationStorageDataAction } from "Redux/action/get.action";
import { logging } from "Redux/action/auth.action";
import { purchaseStoragePlan } from "Config/API/action";
import { toast } from "react-toastify";

const PurchaseStoragePlanPopup = ({
  hide,
  totalStorageUsedInGb,
  selectedPlanId,
  token,
  defaultCardDetails,
  setLoader,
  resetLoader,
  buttonText,
  from,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
  const [selectedPlanUniqueId, setSelectedPlanUniqueId] =
    useState(selectedPlanId);
  const defaultPaymentPreference = useSelector(
    (state) => state.authenticationReducer?.defaultPaymentPreference
  );
  const usableCredit = useSelector(
    (state) => state.getUsableCreditBalanceReducer
  );

  const [allStoragePlans, setAllStoragePlans] = useState([]);

  const pageLoadingArray = ["", "", "", "", "", ""];

  useEffect(() => {
    getAllStoragePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllStoragePlans = () => {
    setIsPageLoading(true);
    const storagePlansApiResponse = getStoragePlans(token);
    storagePlansApiResponse.then((res) => {
      setIsPageLoading(false);
      if (res?.data?.status) {
        setAllStoragePlans(res?.data?.data);
      } else toast(res?.data?.message, { type: "error" });
    });
  };

  const getuserData = () => {
    const apiResponse = getUserDetails(token);
    apiResponse.then((res) => {
      setIsLoading(false);
      if (res?.data?.status) dispatch(logging(res.data.data));
    });
  };

  const handleSelectPaymentButtonClick = (value) => {
    if (value === defaultPaymentPreference) return;
    if (defaultCardDetails?.length === 0 && value === "card")
      return toast(
        "You haven't saved any default card yet to select this method",
        { type: "info" }
      );
    setIsLoading(true);
    const response = changeDefaultPaymentMethod(token, value);
    response.then((res) => {
      if (res?.status) {
        getuserData();
        toast(res?.message, { type: "success" });
      } else {
        setIsLoading(false);
        toast(res?.message, { type: "error" });
      }
    });
  };

  const handleProceedButtonClick = () => {
    if (selectedPlanId === selectedPlanUniqueId) return;
    if (
      defaultPaymentPreference === "credit" &&
      Number(selectedPlanDetails?.rate) > Number(usableCredit)
    )
      return toast("You do not have enough site credits", { type: "info" });
    setLoader();
    const upgradePlanApiResponse = purchaseStoragePlan(
      token,
      selectedPlanUniqueId
    );
    upgradePlanApiResponse.then((res) => {
      if (res?.status) {
        const apiResponse = getStorageData(token, "");
        apiResponse.then((response) => {
          resetLoader();
          if (response?.data?.status) {
            hide(false);
            toast(res?.message, { type: "success" });
            dispatch(getOrganizationStorageDataAction(response?.data?.data));
          } else toast(response?.message, { type: "error" });
        });
      } else {
        resetLoader();
        toast(res?.message, { type: "error" });
      }
    });
  };

  return (
    <>
      <div className="purchase-storage-plan-parent">
        <div className="purchase-storage-plan-main">
          <div className="purchase-storage-plan-heading">
            <p>Upgrade Plan</p>
            {from !== "renewCloudSubscription" && (
              <div className="rename-cross" onClick={() => hide(false)}>
                X
              </div>
            )}
          </div>
          <div className="purchase-storage-plan-body">
            <div className="purchase-storage-plan-wrapper">
              {!isPageLoading
                ? allStoragePlans?.map((item) => {
                    return (
                      <div
                        className="purchase-storage-plan-wrapper-content"
                        onClick={() => {
                          setSelectedPlanDetails(item);
                          if (item?.toSize < totalStorageUsedInGb) {
                            return setShowInfoPopup(true);
                          }
                          setSelectedPlanUniqueId(item?.id);
                        }}
                      >
                        <label>{item?.storageName}</label>
                        <button
                          className="purchase-storage-plan-wrapper-content-card"
                          style={{
                            border:
                              item?.id === selectedPlanUniqueId &&
                              "2px solid #5CA127",
                          }}
                        >
                          <input
                            type="radio"
                            checked={item?.id === selectedPlanUniqueId}
                          />
                          <div className="purchase-storage-plan-wrapper-content-card-labels">
                            <label>
                              {item?.fromSize === 0
                                ? `${item?.toSize} GB`
                                : item?.fromSize === 500
                                ? `${item?.fromSize} GB+`
                                : `${item?.fromSize} - ${item?.toSize} GB`}
                            </label>
                            <span>
                              {item?.slug === "free"
                                ? "FREE"
                                : `${(+item?.rate)?.toFixed(2)}/month`}
                            </span>
                          </div>
                        </button>
                      </div>
                    );
                  })
                : pageLoadingArray?.map((item, index) => {
                    return (
                      <div className="purchase-storage-plan-wrapper-content">
                        <label
                          className="animation"
                          style={{ height: "19px", width: "65px" }}
                        />
                        <div
                          className="animation"
                          style={{
                            marginTop: "5px",
                            height: "70px",
                            width: index === 0 ? "135px" : "197px",
                          }}
                        />
                      </div>
                    );
                  })}
            </div>
            <p style={{ padding: 0 }}>Choose Payment Method</p>
            <div
              className="default-payment-wrapper"
              style={{ paddingBottom: "0px" }}
            >
              {!isLoading ? (
                <>
                  <div
                    className="default-payment-card"
                    style={{ maxWidth: "391px", height: "56px" }}
                  >
                    <div>
                      <img src={dollarIcon} alt="dolarIcon" />
                      <label>Site Credits</label>
                    </div>
                    <button
                      onClick={() => handleSelectPaymentButtonClick("credit")}
                      style={{
                        backgroundColor:
                          defaultPaymentPreference === "credit"
                            ? "#5ca127"
                            : "#446fa2",
                      }}
                    >
                      {defaultPaymentPreference === "credit"
                        ? "Selected"
                        : "Select"}
                    </button>
                  </div>
                  <div
                    className="default-payment-card"
                    style={{ maxWidth: "391px", height: "56px" }}
                  >
                    <div>
                      <img src={creditCard} alt="" />
                      <label>Default Card</label>
                    </div>
                    <button
                      onClick={() => handleSelectPaymentButtonClick("card")}
                      style={{
                        backgroundColor:
                          defaultPaymentPreference === "credit"
                            ? "#446fa2"
                            : "#5ca127",
                      }}
                    >
                      {defaultPaymentPreference === "credit"
                        ? "Select"
                        : "Selected"}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="default-payment-card"
                    style={{
                      borderColor: "#ececec",
                      maxWidth: "391px",
                      height: "56px",
                    }}
                  >
                    <div>
                      <div
                        style={{ height: "45px", width: "45px" }}
                        className="animation"
                      />
                      <label
                        style={{ height: "19px", width: "91px" }}
                        className="animation"
                      />
                    </div>
                    <div
                      style={{ height: "28px", width: "81px" }}
                      className="animation"
                    />
                  </div>
                  <div
                    className="default-payment-card"
                    style={{
                      borderColor: "#ececec",
                      maxWidth: "391px",
                      height: "56px",
                    }}
                  >
                    <div>
                      <div
                        style={{ height: "45px", width: "45px" }}
                        className="animation"
                      />
                      <label
                        style={{ height: "19px", width: "102px" }}
                        className="animation"
                      />
                    </div>
                    <div
                      style={{ height: "28px", width: "81px" }}
                      className="animation"
                    />
                  </div>
                </>
              )}
              <button
                onClick={() => {
                  if (isPageLoading) return;
                  handleProceedButtonClick();
                }}
                className="upgrade-plan-proceed-button"
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showInfoPopup && (
        <InfoPopup
          hide={setShowInfoPopup}
          header="Downgrade Storage Plan"
          description={`You have used ${totalStorageUsedInGb?.toFixed(
            2
          )}Gb Storage. The Plan you are trying to select has an upper limit of ${selectedPlanDetails?.toSize?.toFixed(
            2
          )}Gb. To Continue try deleting ${(
            totalStorageUsedInGb - selectedPlanDetails?.toSize
          ).toFixed(2)}Gb of you stored data.`}
        />
      )}
    </>
  );
};

export default PurchaseStoragePlanPopup;
