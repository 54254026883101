import OrganizationList from "./OrganizationList";
import React from "react";

const RenderOrganizationList = () => {
  return (
    <>
      <OrganizationList />
    </>
  );
};

export default RenderOrganizationList;
