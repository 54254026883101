import "./AcceptTransferOutRequest.css";

import React, { useState } from "react";

const AcceptTransferOutRequest = ({ hide, requestDetails, changeStatus }) => {
  const [transactionNumber, setTransactionNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    changeStatus("approve", requestDetails, transactionNumber, setLoading);
  };

  return (
    <>
      <div className="accept-request-transfer-out-parent">
        <form
          className="accept-request-transfer-out-form-body"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="accept-request-transfer-out-heading">
            <p>
              Please Enter Valid Transaction Number To Approve Transfer Out
              Request
            </p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="accept-request-transfer-out-content">
            <input
              type="text"
              placeholder="Enter Transaction Number"
              value={transactionNumber}
              onChange={(e) => setTransactionNumber(e.target.value.trim())}
              required
            />
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AcceptTransferOutRequest;
