import "../PureComponents/VideoChat.css";

import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";

import PreviewScreen from "../PureComponents/PreviewScreen";
import Room from "../PureComponents/Room";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function RenderVideoChat() {
  const endPoint =
    "https://prod-in2.100ms.live/hmsapi/august-videoconf-946.app.100ms.live/";

  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  const msID = useSelector((state) => state.storeVideoChatLinkReducer.id);
  const userName = useSelector((state) => state.authenticationReducer?.name);
  const role = useSelector((state) => state.storeVideoChatLinkReducer?.role);
  const userId = useSelector((state) => state.authenticationReducer?.id);
  useEffect(() => {
    joinVideoCallRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  const getToken = async (user_id) => {
    const response = await fetch(`${endPoint}api/token`, {
      method: "POST",
      body: JSON.stringify({
        user_id,
        role: role, // host, participant
        type: "app",
        room_id: msID,
      }),
    });
    const { token } = await response?.json();
    return token;
  };

  const joinVideoCallRoom = async () => {
    const token = await getToken(userId?.toString());
    const config = {
      userName: userName,
      authToken: token, // client-side token generated from your token service
      settings: {
        isAudioMuted: true,
        isVideoMuted: true,
      },
    };
    hmsActions
      .join({ ...config, rememberDeviceSelection: true })
      .then((res) => {})
      .catch((error) => {
        const err = JSON.parse(error?.description);
        console.log(err);
      });
  };

  return (
    <div className="video-call">
      {isConnected ? (
        <Room />
      ) : (
        <PreviewScreen joinVideoCallRoom={joinVideoCallRoom} />
      )}
    </div>
  );
}
