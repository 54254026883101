import React from "react";
import ResetPassword from "../PureComponents/ResetPassword";
import { resetPassword } from "Config/API/authentication";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RenderResetPassword = () => {
  const navigate = useNavigate();
  // for token
  const login_stat = localStorage.getItem("token");

  const changePassword = (password, verifyPassword) => {
    const reset_status = resetPassword(password, verifyPassword, login_stat);
    reset_status
      .then((res) => {
        if (res?.status) {
          toast(res?.message, {
            type: "success",
          });
          navigate("/dashboard");
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      })
      .catch(() =>
        toast("Failed! Try again later", {
          type: "error",
        })
      );
  };

  return (
    <>
      <ResetPassword changePass={changePassword} />
    </>
  );
};

export default RenderResetPassword;
