import React, { useEffect, useState } from "react";

import Dashboard from "../PureComponents/Dashboard";
import ImageLoader from "Hooks/ImageLoader";
import { getGeneralInfo } from "Config/API/get";
import { renderGeneralInfo } from "Redux/action/render.action";
import { useDispatch } from "react-redux";

const RenderDashboard = () => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [imageLoader, setImageLoader] = useState(true);

  useEffect(() => {
    // general info rendering
    const received_info = getGeneralInfo(token);
    received_info
      .then((res) => {
        if (res?.status) {
          dispatch(renderGeneralInfo(res?.org_info));
          setImageLoader(false);
        }
      })
      .catch((e) => {
        setImageLoader(false);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Intially this folder api was
   * defined inside the RenderFileExplorer Components
   * but as it was resetting the data of right side of
   * File explorer to intial base folders
   * We are using it here if face any issue then
   * move it to original location
   */

  return <>{imageLoader ? <ImageLoader /> : <Dashboard />}</>;
};

export default RenderDashboard;
