import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FileExplorer from "../PureComponents/FileExplorer";
import ImageLoader from "Hooks/ImageLoader";
import { folderData } from "Config/API/get";
import { storeFolderDataAction } from "Redux/action/get.action";

const RenderFileExplorer = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [imageLoader, setImageLoader] = useState(true);

  const files = folderData(token);
  files
    .then((res) => {
      dispatch(storeFolderDataAction(res?.result));
      setImageLoader(false);
    })
    .catch((e) => {
      setImageLoader(false);
    });

  const general_info = useSelector((state) => state.renderGeneralInfo);

  return (
    <>
      {imageLoader ? (
        <ImageLoader />
      ) : (
        <FileExplorer general_info={general_info} />
      )}
    </>
  );
};

export default RenderFileExplorer;
