import "./TransferAndBanking.css";

import React, { useEffect, useState } from "react";
import { getBankDetails, getCreditCardDetails } from "Config/API/get";

import AddMoneyPopup from "Components/Popup/AddMoneyPopup/AddMoneyPopup";
import AllCreditCardDetails from "Components/Popup/EditCreditCardDetails/AllCreditCardDetails/AllCreditCardDetails";
import DefaultPaymentPopup from "Components/Popup/DefaultPaymentPopup/DefaultPaymentPopup";
import EditBankDetails from "Components/Popup/EditBankDetails/EditBankDetails";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RequestTransferOutPopup from "Components/Popup/RequestTransferOut/RequestTransferOut";
import bank from "Media/icons/bank.png";
import creditCard from "Media/icons/creditCard.png";
import defaultPayment from "Media/icons/defaultPayment.png";
import dollarIcon from "Media/icons/cards-with-dollar-sign@2x.png";
import editRates from "Media/icons/editRates.png";
import file from "Media/icons/file.png";
import moneyBack from "Media/icons/moneyBack.png";
import moneyTransfer from "Media/icons/money-transfer.png";
import plusIcon from "Media/icons/plus (1)@2x.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RenderInformationPopup from "Components/Popup/InformationPopup/PureComponents/InformationPopup";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
const TransferAndBanking = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const credit = useSelector((state) => state.getStripeCreditBalanceReducer);
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const [addCreditPopup, setAddCreditPopup] = useState(false);
  const [showDefaultPaymentPopoup, setShowDefaultPaymentPopoup] =
    useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [showEditBankDetailsPopup, setShowEditBankDetailsPopup] =
    useState(false);
  const [showEditCreditCardDetailsPopup, setShowEditCreditCardDetailsPopup] =
    useState(false);
  const [showRequestTransferOutPopup, setShowRequestTransferOutPopup] =
    useState(false);
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabsText, setTabsText] = useState("");
  useEffect(() => {
    getBankingDetails();
    getCardDetails();
    // eslint-disable-next-line
  }, []);

  const getBankingDetails = () => {
    const bankDetailsResponse = getBankDetails(token);
    bankDetailsResponse.then((res) => {
      if (res?.status) {
        setBankDetails(res?.data?.data);
      }
    });
  };

  const getCardDetails = () => {
    const cardDetailsResponse = getCreditCardDetails(token);
    cardDetailsResponse.then((res) => {
      if (res?.status) {
        setCardDetails(res?.data?.data);
      }
    });
  };

  return (
    <>
      <div className="transfer-and-banking-main">
        <div className="transfer-and-banking-header">
          <Headers />
        </div>

        <div className="transfer-and-banking-navigation">
          <Navigation />
        </div>

        <div className="transfer-and-banking-wrapper">
          <div className="transfer-and-banking-heading">
            Transfers & Banking Page
          </div>
          <div className="transfer-and-banking-content">
            <div
              className="transfer-and-banking-tile"
              style={{ height: "110px" }}
            >
              <img
                src={dollarIcon}
                alt="Wallet"
                style={{ width: "65px", height: "65px" }}
              />
              <span>
                <span>
                  CURRENT CREDITS <b> {credit ? credit : 0}</b>
                </span>
                <span>$1 USD = 1 CREDIT</span>
              </span>
              <QuestionMarkIcon
                className="transfer-and-banking-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("CURRENT CREDITS");
                }}
              />
            </div>
            <div
              className="transfer-and-banking-tile"
              style={{
                height: "110px",
                border: "unset",
                boxShadow: "unset",
                padding: "unset",
                background: "unset",
              }}
            >
              <div className="transfer-and-banking-tile-internal-wrapper">
                <div
                  style={{
                    background: "white",
                    position: "relative",
                  }}
                >
                  <div
                    className="transfer-banking-title"
                    onClick={() => {
                      if (loggedInUserDetails?.add_credit_rights === "manager")
                        return setAddCreditPopup(true);
                      toast("You do not have rights to add credit", {
                        type: "info",
                      });
                    }}
                  >
                    <img
                      src={plusIcon}
                      alt="Wallet"
                      style={{ width: "42px", height: "42px" }}
                    />
                    <span style={{ marginTop: "10px" }}>Add Credit</span>
                  </div>
                  <QuestionMarkIcon
                    className="transfer-and-banking-info"
                    onClick={() => {
                      setInfoPopup(true);
                      setTabsText("Add Credit");
                    }}
                  />
                </div>
                <div
                  style={{
                    background: "white",
                    position: "relative",
                  }}
                >
                  <div
                    className="transfer-banking-title"
                    onClick={() => {
                      if (
                        loggedInUserDetails?.credit_account_manager ===
                        "manager"
                      )
                        return setShowDefaultPaymentPopoup(true);
                      toast(
                        "You do not have rights to edit default payment method",
                        {
                          type: "info",
                        }
                      );
                    }}
                  >
                    <img
                      src={defaultPayment}
                      alt="defaultPayment"
                      style={{ width: "42px", height: "42px" }}
                    />
                    <span style={{ marginTop: "10px" }}>Default Payment</span>
                  </div>
                  <QuestionMarkIcon
                    className="transfer-and-banking-info"
                    onClick={() => {
                      setInfoPopup(true);
                      setTabsText("Default Payment");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="transfer-and-banking-content">
            <div
              className="transfer-and-banking-tile"
              style={{ height: "92px", justifyContent: "space-between" }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <img
                  src={bank}
                  alt="Wallet"
                  style={{ width: "65px", height: "65px" }}
                />

                <span>Bank Details</span>
              </div>
              <img
                src={editRates}
                alt="editRates"
                style={{ width: "21px", height: "22px" }}
                onClick={() => {
                  if (loggedInUserDetails?.credit_account_manager === "manager")
                    return setShowEditBankDetailsPopup(true);
                  toast("You do not have rights to edit bank details", {
                    type: "info",
                  });
                }}
              />
              <QuestionMarkIcon
                className="transfer-and-banking-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("Bank Details");
                }}
              />
            </div>
            <div
              className="transfer-and-banking-tile"
              style={{ height: "92px", justifyContent: "space-between" }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <img
                  src={creditCard}
                  alt="Wallet"
                  style={{ width: "65px", height: "65px" }}
                />
                <span>Credit Card Details</span>
              </div>
              <img
                src={editRates}
                alt="editRates"
                style={{ width: "21px", height: "22px" }}
                onClick={() => {
                  if (loggedInUserDetails?.credit_account_manager === "manager")
                    return setShowEditCreditCardDetailsPopup(true);
                  toast("You do not have rights to edit credit card details", {
                    type: "info",
                  });
                }}
              />
              <QuestionMarkIcon
                className="transfer-and-banking-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("Credit Card Details");
                }}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                className="transfer-and-banking-tile"
                style={{ height: "92px" }}
                onClick={() => {
                  if (loggedInUserDetails?.credit_read_rights === "manager")
                    return navigate("/all-transfer-out-requests");
                  toast(
                    "You do not have rights to view transfer out requests",
                    {
                      type: "info",
                    }
                  );
                }}
              >
                <img
                  src={file}
                  alt="Wallet"
                  style={{ width: "65px", height: "65px" }}
                />
                <span>My Transfer Out Request History</span>
              </div>
              <QuestionMarkIcon
                className="transfer-and-banking-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("My Transfer Out Request History");
                }}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                className="transfer-and-banking-tile"
                style={{ height: "92px" }}
                onClick={() => {
                  if (loggedInUserDetails?.credit_account_manager === "manager")
                    return setShowRequestTransferOutPopup(true);
                  toast(
                    "You do not have rights to raise a transfer out requests",
                    {
                      type: "info",
                    }
                  );
                }}
              >
                <img
                  src={moneyTransfer}
                  alt="Wallet"
                  style={{ width: "65px", height: "65px" }}
                />

                <span>Transfer Out</span>
              </div>
              <QuestionMarkIcon
                className="transfer-and-banking-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("Transfer Out");
                }}
              />
            </div>
          </div>
          <div className="transfer-and-banking-lower-tile-container">
            <div
              className="transfer-and-banking-lower-tile"
              style={{ position: "relative" }}
            >
              <div
                className="transfer-and-banking-lower-tile-text"
                onClick={() => {
                  if (loggedInUserDetails?.credit_read_rights === "manager")
                    return navigate("/all-refund-requests");
                  toast("You do not have rights to view refund requests", {
                    type: "info",
                  });
                }}
              >
                <img
                  src={moneyBack}
                  alt="Wallet"
                  style={{ width: "65px", height: "65px" }}
                />

                <span>Refund Requests</span>
              </div>
              <QuestionMarkIcon
                className="transfer-and-banking-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("Refund Requests");
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {addCreditPopup && <AddMoneyPopup hide={setAddCreditPopup} />}
      {showEditBankDetailsPopup && (
        <EditBankDetails
          hide={setShowEditBankDetailsPopup}
          bankDetails={bankDetails}
          getBankingDetails={getBankingDetails}
        />
      )}
      {showEditCreditCardDetailsPopup && (
        <AllCreditCardDetails
          hide={setShowEditCreditCardDetailsPopup}
          cardDetails={cardDetails}
          getCardDetails={getCardDetails}
        />
      )}
      {showRequestTransferOutPopup && (
        <RequestTransferOutPopup
          hide={setShowRequestTransferOutPopup}
          bankDetails={bankDetails}
        />
      )}
      {showDefaultPaymentPopoup && (
        <DefaultPaymentPopup
          hide={setShowDefaultPaymentPopoup}
          defaultCardDetails={cardDetails?.filter(
            (item) => item?.default === true
          )}
        />
      )}
      {infoPopup ? (
        <RenderInformationPopup message={tabsText} hide={setInfoPopup} />
      ) : null}
    </>
  );
};

export default TransferAndBanking;
