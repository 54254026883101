import React, { useState, useEffect, useRef } from "react";
import "./CreateAssets.css";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import { useNavigate } from "react-router-dom";
import {
  createAsset,
  generateAssetId,
  getassetlocations,
} from "Config/API/action";
import {
  getGeneralInfo,
  getLocationData,
  getOrganizationConnection,
} from "Config/API/get";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

const CreateAssets = () => {
  const navigate = useNavigate();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [assetName, setAssetName] = useState("");
  const [assetNumber, setAssetNumber] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [vendorNameid, setVendorNameid] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLocations, setShowLocations] = useState(false); // Manage dropdown visibility
  const [assetTicket, setAssetticket] = useState("");
  const [assetLocation, setAssetLocation] = useState([]);
  const [assetLocationType, setAssetLocationType] = useState("");
  const token = localStorage.getItem("token");
  const vendorRef = useRef(null);
  const [generalInfo, setGeneralInfo] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  useOutsideAlerter(vendorRef);
  const dropdownRef = useRef(null);
  const assetScope = "internal";
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
          // Hide dropdown when clicking outside
        } else {
          setShowDropdown(true);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLocations(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const generateAssetResponse = await generateAssetId(token);
      if (generateAssetResponse?.status) {
        setAssetticket(generateAssetResponse?.data?.assetId);
      }
    };

    fetchData();

    getassetlocation();
  }, [token]);
  const getloginorg = async () => {
    const info = await getGeneralInfo(token);
    if (info?.status) {
      setGeneralInfo(info?.org_info);
    }
  };
  const getassetlocation = async () => {
    try {
      const info = await getGeneralInfo(token);
      const res = await getLocationData(token, info?.org_info?.id);

      if (res?.status === true) {
        // Check for the boolean value 'true'
        setAssetLocation(res.location);
        console.log(res.location); // Log the asset data directly
      } else {
        toast.error("No asset location found");
      }
    } catch (error) {
      toast.error(`Error fetching asset location: ${error.message}`);
      // Handle the error if needed (e.g., show a toast or alert)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedAssetName = assetName.trim();
    const trimmedVendorName = vendorNameid;
    const trimmedAssetNumber = assetNumber.trim();
    const trimmedDescription = description.trim();
    const trimmedLocation = location;

    if (
      trimmedAssetName !== "" &&
      trimmedVendorName !== "" &&
      trimmedDescription !== "" &&
      trimmedLocation !== "" &&
      trimmedAssetNumber.length <= 25
    ) {
      setLoader();

      try {
        const generateAssetResponse = await generateAssetId(token);
        if (generateAssetResponse?.status) {
          const assetId = generateAssetResponse?.data?.assetId;

          const assetData = {
            token: token,
            assetsName: trimmedAssetName,
            vendorName: trimmedVendorName,
            assetNumber: trimmedAssetNumber,
            description: trimmedDescription,
            assetId: assetId,
            assetLocation: trimmedLocation,
            assetScope: assetScope,
            assetLocationType: assetLocationType,
          };

          const assetResponse = await createAsset(assetData);
          resetLoader();

          if (assetResponse?.status) {
            toast(assetResponse?.message, { type: "success" });
            navigate("/asset-management-view-page");
          } else {
            toast(assetResponse?.message, { type: "error" });
          }
        } else {
          resetLoader();
          toast("Failed to generate asset ID", { type: "error" });
        }
      } catch (error) {
        resetLoader();
        console.error("Error during asset creation:", error);
        toast("An error occurred, please try again", { type: "error" });
      }
    } else {
      if (trimmedAssetName === "") {
        toast("Please enter an asset name", { type: "info" });
      }
      if (trimmedVendorName === "") {
        toast("Please select a vendor name", { type: "info" });
      }
      if (trimmedDescription === "") {
        toast("Please enter a description", { type: "info" });
      }
      if (trimmedLocation === "") {
        toast("Please enter a location", { type: "info" });
      }
      if (trimmedAssetNumber.length > 25) {
        toast("Asset number must be 25 characters or fewer.", { type: "info" });
      }
    }
  };

  return (
    <>
      <div className="create-assets-parent">
        <div className="create-assets-header">
          <Headers />
        </div>
        <div className="create-assets-navigation">
          <Navigation />
        </div>
        <div className="create-assets-main">
          <div className="create-asset-heading">
            <h2>Create New Asset</h2>
          </div>
          <div className="create-assets-form">
            <form
              className="create-assets-form-body"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className="create-asset-fields">
                <label htmlFor="Asset-Name">
                  Ticket No.(auto generate)<span>*</span>:
                </label>
                <input
                  type="text"
                  id="Asset-Name"
                  required
                  readOnly
                  value={assetTicket}
                  placeholder="Ticket number for Asset"
                  style={{ color: "#446FA2", fontWeight: 700 }}
                />
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Asset-Name">
                  Asset Name <span>*</span>:
                </label>
                <input
                  type="text"
                  id="Asset-Name"
                  required
                  value={assetName}
                  onChange={(e) => setAssetName(e.target.value)}
                  placeholder="Enter Name for Asset"
                />
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Asset-Number">
                  Enter Asset # <span>*</span>:
                </label>
                <input
                  type="text"
                  id="Asset-Number"
                  required
                  value={assetNumber}
                  onChange={(e) => setAssetNumber(e.target.value.slice(0, 25))}
                  placeholder="Enter Serial Number or Asset Number"
                  maxLength={25}
                />
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Asset-Description">
                  Enter Description<span>*</span>:
                </label>
                <textarea
                  id="Asset-Description"
                  rows={4}
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter Description of Asset"
                ></textarea>
              </div>
              <div className="create-asset-fields">
                <label htmlFor="Asset-Location">
                  Asset Location <span>*</span>:
                </label>
                <div style={{ position: "relative" }} ref={dropdownRef}>
                  <input
                    type="text"
                    id="Asset-Location"
                    required
                    value={selectedLocationId}
                    onClick={() => {
                      getassetlocation();
                      setShowLocations(true);
                    }}
                    onChange={(e) => {
                      setLocation(e.target.value);
                      setSelectedLocationId(e.target.value);
                      setShowLocations(true);
                      setAssetLocationType("text")
                    }}
                    placeholder="Company Location for Asset"
                  />
                  {showLocations &&
                    assetLocation &&
                    assetLocation.length > 0 && (
                      <div className="location-dropdown">
                        <ul>
                          {assetLocation.map((loc) => (
                            <li
                              key={loc.id}
                              onClick={() => {
                                setLocation(loc.id);
                                setSelectedLocationId(loc.location);
                                setShowLocations(false);
                                setAssetLocationType("id")
                              }}
                              className="dropdown-option"
                            >
                              {loc.location}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                </div>
              </div>
              <div className="create-asset-fields vendor-name-field">
                <label htmlFor="Asset-Vendor">
                  Vendor Name <span>*</span>:
                </label>

                <div ref={vendorRef} style={{ position: "relative" }}>
                  <input
                    type="search"
                    id="Asset-Vendor"
                    style={{
                      backgroundColor:
                        vendorName.length > 0 ? "#f1f2f6" : "white",
                    }}
                    value={vendorName}
                    placeholder="Search Vendor"
                    onClick={() => {
                      if (vendorName.length > 0) return;
                      getloginorg();
                      const vendor_status = getOrganizationConnection(token);
                      vendor_status.then((res) => {
                        if (res?.status) {
                          setOrganizations(res.organizationData);
                          setShowDropdown(true); // Show dropdown on successful fetch
                        } else {
                          toast(res?.message, { type: "error" });
                        }
                      });
                    }}
                    onChange={(e) => {
                      setVendorName(e.target.value);
                      setVendorNameid(e.target.value);
                      getloginorg();
                      const vendor_status = getOrganizationConnection(
                        token,
                        e.target.value
                      );
                      vendor_status.then((res) => {
                        if (res?.status) {
                          setOrganizations(res.organizationData);
                          setShowDropdown(true); // Show dropdown while typing
                        } else {
                          toast(res?.message, { type: "error" });
                        }
                      });
                    }}
                  />

                  {showDropdown && (
                    <div className="organization-list folder-list">
                      {!organizations.some(
                        (org) => org.id === generalInfo?.id
                      ) &&
                        generalInfo && (
                          <div
                            className="data-list"
                            key="generalInfo"
                            onClick={() => {
                              setVendorName(generalInfo?.organization_name);
                              setVendorNameid(generalInfo.id);
                              setOrganizations([]);
                              setShowDropdown(false); // Hide dropdown on selection
                            }}
                          >
                            <div>
                              <div className="organization-img-parent">
                                <img src={generalInfo?.logo} alt="Vendor" />
                              </div>

                              <span
                                className="multiple-line-text"
                                style={{ color: "#51595E" }}
                              >
                                {generalInfo?.organization_name || "No Name"}
                              </span>
                            </div>
                          </div>
                        )}
                      {organizations.length > 0 &&
                        organizations.map((vendor, index) => (
                          <div
                            className="data-list"
                            key={index}
                            onClick={() => {
                              setVendorName(vendor.organization_name);
                              setVendorNameid(vendor.id);
                              setOrganizations([]);
                              setShowDropdown(false); // Hide dropdown on selection
                            }}
                          >
                            <div>
                              <div className="organization-img-parent">
                                <img src={vendor.logo} alt="Vendor" />
                              </div>

                              <span
                                className="multiple-line-text"
                                style={{ color: "#51595E" }}
                              >
                                {vendor.organization_name || "No Name"}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="submitbutton">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAssets;
