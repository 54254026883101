import {
  APPOINTMENT_EVENT_LIST,
  CREDIT_BALANCE,
  EVENT_LIST,
  GET_FULL_NOTIFICATION,
  GET_NOTIFICATION,
  GET_NOTIFICATION_BADGE,
  GET_TRASH_DATA,
  GROUP_CHAT_DATA,
  INTERNAL_FOLDER_DATA,
  ORGANIZATION_STORAGE_DATA,
  SECRET_KEY,
  SELECT_FILE_DATA,
  SELECT_FOLDER_DATA,
  SELECT_FOLDER_ROOT_DATA,
  STORE_FOLDER_DATA,
  USABLE_CREDIT_BALANCE,
  VIDEO_EVENT_LIST,
} from "Config/ActionType/action.type";

const initial = "";

export const getRecentNotificationReducer = (state = initial, action) => {
  switch (action?.type) {
    case GET_NOTIFICATION:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getFullNotificationReducer = (state = initial, action) => {
  switch (action?.type) {
    case GET_FULL_NOTIFICATION:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getRecentNotificationCountReducer = (state = initial, action) => {
  switch (action?.type) {
    case GET_NOTIFICATION_BADGE:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getFolderDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case STORE_FOLDER_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const selectedFileDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case SELECT_FILE_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const selectedFolderDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case SELECT_FOLDER_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const internalFolderDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case INTERNAL_FOLDER_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const selectedFolderRootDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case SELECT_FOLDER_ROOT_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getTrashDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case GET_TRASH_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const storeVideoChatLinkReducer = (state = initial, action) => {
  switch (action?.type) {
    case GROUP_CHAT_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getEventListReducer = (state = initial, action) => {
  switch (action?.type) {
    case EVENT_LIST:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getEventListAppointmentReducer = (state = initial, action) => {
  switch (action?.type) {
    case APPOINTMENT_EVENT_LIST:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getEventListVideoConferenceReducer = (state = initial, action) => {
  switch (action?.type) {
    case VIDEO_EVENT_LIST:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const setClientSecretKeyReducer = (state = initial, action) => {
  switch (action?.type) {
    case SECRET_KEY:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getStripeCreditBalanceReducer = (state = initial, action) => {
  switch (action?.type) {
    case CREDIT_BALANCE:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getUsableCreditBalanceReducer = (state = initial, action) => {
  switch (action?.type) {
    case USABLE_CREDIT_BALANCE:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export const getOrganizationStorageDataReducer = (state = initial, action) => {
  switch (action?.type) {
    case ORGANIZATION_STORAGE_DATA:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};
