import {
  internalFolderDataAction,
  selectedFolderDataAction,
  selectedFolderRootDataAction,
} from "Redux/action/get.action";

import { currentFolderAutomationAction } from "Redux/action/variable.action";
import { getInternalFolder } from "Config/API/get";
import moment from "moment-timezone";
import cadIcon from "Media/icons/cad.png";
import excelIcon from "Media/icons/simple-microsoftexcel.svg";
import imageIcon from "Media/icons/simple-image.png";
import officeIcon from "Media/icons/simple-microsoftword.svg";
import pdfIcon from "Media/icons/simple-pdf.svg";
import pptDocument from "Media/icons/pptx.png";
import unknownFile from "Media/icons/unknownFile.png";
import videoFile from "Media/icons/videoFile.png";

export const CompareTwoArray = (arr1, arr2) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

export const ChangeDateToIosString = (selectedDate, selectedTime) => {
  const date = moment(selectedDate).toLocaleString();
  const dateArray = date.toString().split(" ");
  const newDate = dateArray.map((item, index) => {
    if (index === 4) item = selectedTime;
    return item;
  });
  return moment(newDate.join(" ")).toISOString();
};

export const ConvertKiloByteToGigaByte = (value) => {
  const divideBy = 1024 * 1024;
  return value / divideBy;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return moment(date).toLocaleString();
};

export const insertDateHeaders = (chatData) => {
  if (chatData.length === 0) return [];

  const result = [];
  let lastDate = null;

  for (const chat of chatData) {
    const currentDate = formatDate(chat.createdAt);
    if (moment(currentDate).format("DD MMM, YYYY") !== lastDate) {
      result.push({ type: "date", date: currentDate });
      lastDate = moment(currentDate).format("DD MMM, YYYY");
    }
    result.push(chat);
  }

  return result;
};

export const getMessageDate = (item) => {
  const inputDate = new Date(item);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // Strip time part for comparison
  const inputDateStripped = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate()
  );
  const todayStripped = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const yesterdayStripped = new Date(
    yesterday.getFullYear(),
    yesterday.getMonth(),
    yesterday.getDate()
  );

  if (inputDateStripped.getTime() === todayStripped.getTime()) {
    return "Today";
  } else if (inputDateStripped.getTime() === yesterdayStripped.getTime()) {
    return "Yesterday";
  } else {
    return moment(item).format("DD MMM YYYY");
  }
};

export const handleDeepLinking = (from, navigate, dispatch, token) => {
  let length = from?.split("document/").length;
  let unique_id = from?.split("document/")[length - 1];
  let before_document = from?.split("document/")[0];
  if (before_document.includes("internal")) {
    // emptying the selected folder
    dispatch(selectedFolderDataAction(""));
    const internalFolderStatus = getInternalFolder(token);
    internalFolderStatus.then((res) => {
      dispatch(internalFolderDataAction(res?.result));
      dispatch(selectedFolderRootDataAction(res?.result));
    });
  }
  dispatch(currentFolderAutomationAction(unique_id));
  navigate("/file-explorer");
};

const fileIconMap = {
  png: imageIcon,
  jpg: imageIcon,
  jpeg: imageIcon,
  webp: imageIcon,
  pdf: pdfIcon,
  docx: officeIcon,
  doc: officeIcon,
  xlsx: excelIcon,
  xls: excelIcon,
  csv: excelIcon,
  ppt: pptDocument,
  pptx: pptDocument,
  mp4: videoFile,
  mkv: videoFile,
  webm: videoFile,
  stp: cadIcon,
  step: cadIcon,
  dxf: cadIcon,
  stl: cadIcon,
};

export const getFileIcon = (files) => {
  const fileType = files?.name?.split(".").pop()?.toLowerCase();
  return fileIconMap[fileType] || unknownFile;
};
