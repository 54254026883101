import "./Error.css";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import PageNotFoundSection from "./PageNotFoundSection";
import React from "react";

const Error = () => {
  return (
    <>
      <div className="error-header">
        <Headers />
      </div>

      <div className="error-navigation">
        <Navigation />
      </div>

      <div className="error-wrapper">
        <PageNotFoundSection />
      </div>
    </>
  );
};

export default Error;
