import DeviceSettings from "../PureComponents/DeviceSettings";
import React from "react";

const RenderDeviceSettings = ({
  showSettings,
  allDevices,
  setShowSettings,
  updateSettings,
  selectedDeviceIDs,
}) => {
  return (
    <>
      <DeviceSettings
        showSettings={showSettings}
        allDevices={allDevices}
        setShowSettings={setShowSettings}
        updateSettings={updateSettings}
        selectedDeviceIDs={selectedDeviceIDs}
      />
    </>
  );
};

export default RenderDeviceSettings;
