import React, { useEffect, useState } from "react";
import { deleteFilesServer, getChatImagesByPath } from "Config/API/get";

import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import DownloadIcon from "@mui/icons-material/Download";
import FilePreviewPopup from "Components/Popup/filePreviewPopup/FilePreviewPopup";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import blank from "Media/blank-profile-picture.png";
import excelDocument from "Media/icons/excelDocument.png";
import firebase from "firebase/compat/app";
import moment from "moment-timezone";
import options from "Media/icons/feather-more-vertical.svg";
import pdfDocument from "Media/icons/pdfDocument.png";
import playbutton from "Media/play-button.webp";
import pptDocument from "Media/icons/pptx.png";
import unknownFile from "Media/icons/unknownFile.png";
import { useSelector } from "react-redux";
import wordDocument from "Media/icons/wordDocument.png";

const ChatCard = ({ data, myId, group_data, fetchChatMessages }) => {
  const pToken = localStorage.getItem("token");
  let { sentBy, sentTo, message, imageData, createdAt, id } = data;
  const my_image = useSelector((state) => state.authenticationReducer?.image);
  let empId = useSelector((state) => state.renderChatProfileReducer);
  var date = moment(createdAt).tz("America/Chicago").format();
  let timeConverted = moment(date).format("hh:mm A");
  let other_image = "";
  const [image, setImage] = useState(
    "https://placehold.co/600x400/?text=Loading...&font=lato"
  );
  const [video, setVideo] = useState("");
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [type, setType] = useState("");
  const [fileExt, setFileExt] = useState("");
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [fileId, setFileId] = useState("");
  const [fileDelete, setFileDelete] = useState("");
  const screenId = empId.toString();
  const open = Boolean(anchorEl);
  if (group_data[0].data) {
    for (let i in group_data[0].data) {
      if (sentBy?.toString() === group_data[0].data[i].id?.toString()) {
        other_image = group_data[0].data[i].image;
      }
    }
  } else {
    other_image = group_data[0].image;
  }

  useEffect(() => {
    if (imageData) {
      getchatImages(imageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData]);

  const getchatImages = async (path) => {
    const response = await getChatImagesByPath(pToken, path);
    setFileExt(response?.data?.type);
    if (response?.data?.type === ".mp4") {
      setVideo(response?.data?.data);
      setType("video");
      setFileId(id);
      setFileName(response?.data?.name);
    } else if (
      response?.data?.type === ".png" ||
      response?.data?.type === ".jpg" ||
      response?.data?.type === ".jpeg"
    ) {
      setImage(response?.data?.data);
      setFileName(response?.data?.name);
      setType("image");
      setFileId(id);
    } else if (
      response?.data?.type === ".xlsx" ||
      response?.data?.type === ".xls" ||
      response?.data?.type === ".csv"
    ) {
      setFileName(response?.data?.name);
      setFilePath(response?.data?.data);
      setFileId(id);
    } else if (
      response?.data?.type === ".doc" ||
      response?.data?.type === ".docx"
    ) {
      setFileName(response?.data?.name);
      setFilePath(response?.data?.data);
      setFileId(id);
    } else if (response?.data?.type === ".pdf") {
      setFileName(response?.data?.name);
      setFilePath(response?.data?.data);
      setFileId(id);
    } else if (
      response?.data?.type === ".ppt" ||
      response?.data?.type === ".pptx"
    ) {
      setFileName(response?.data?.name);
      setFilePath(response?.data?.data);
      setFileId(id);
    }
  };
  // image preview
  const chatFilePreview = () => {
    setShowPreviewPopup(true);
  };

  const renderFileImage = () => {
    switch (fileExt?.split(".").at(1)) {
      case "doc":
      case "docx":
        return wordDocument;
      case "xls":
      case "xlsx":
      case "csv":
        return excelDocument;
      case "pdf":
        return pdfDocument;
      case "ppt":
      case "pptx":
        return pptDocument;
      default:
        return unknownFile;
    }
  };
  const deleteChatFile = (fileId) => {
    setFileDelete(fileId);
    setConfirmPopup(true);
  };

  const deletechatFilesServer = () => {
    const chatFiles = deleteFilesServer(
      pToken,
      type === "image" ? image : type === "video" ? video : filePath
    );
    chatFiles.then((response) => {});
  };
  const deleteChatFileData = (option) => {
    if (option?.toString() === "Yes") {
      let docid = "";
      if (screenId.includes("-")) {
        docid = empId;
      } else {
        docid = sentTo > sentBy ? sentBy + "-" + sentTo : sentTo + "-" + sentBy;
      }
      const chatMessage = firebase.firestore();
      chatMessage
        .collection("chatroom")
        .doc(docid)
        .collection("messages")
        .doc(fileDelete)
        .delete()
        .then(() => {
          setConfirmPopup(false);
          deletechatFilesServer();
          fetchChatMessages();
        });
    }
  };

  const DownloadChatFiles = (e) => {
    e.preventDefault();
    let chatFileName =
      type === "image" ? image : type === "video" ? video : filePath;
    fetch(chatFileName)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName?.split("-").at(-1);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch();
  };
  // sender
  if (sentBy?.toString() === myId?.toString()) {
    return (
      <div className="right-side-chat">
        <div className="chat-avatar">
          {sentBy?.toString() === myId?.toString() ? (
            <img src={my_image ? my_image : blank} alt="Avatar" />
          ) : (
            <img src={other_image ? other_image : blank} alt="Avatar" />
          )}
        </div>

        {/* message and media conditions */}
        {message ? (
          <li>
            {/* for multiple line message */}
            {message?.includes("&*&")
              ? message?.split("&*&").map((curr, i) => {
                  return <div key={i}>{curr}</div>;
                })
              : message}
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : imageData ? (
          <li className="chat-media">
            <div className="chat-media-body">
              {type === "image" ? (
                <img
                  src={image}
                  alt="Media"
                  onClick={() => chatFilePreview()}
                  style={{ cursor: "pointer" }}
                />
              ) : type === "video" ? (
                <img
                  src={playbutton}
                  alt="Media"
                  onClick={() => chatFilePreview()}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <Link
                  className="chat-file"
                  to={`file-viewer`}
                  target={"_blank"}
                  onClick={() => {
                    localStorage.setItem("filePath", filePath);
                    localStorage.setItem("fileType", fileExt?.split(".").at(1));
                  }}
                >
                  <img
                    src={renderFileImage()}
                    alt="fileImage"
                    className="chat-file-image"
                  />
                  <p className="chat-file-text">
                    {fileName?.split("-").at(-1)}
                  </p>
                </Link>
              )}
              <IconButton className="chat-file-option">
                <img
                  src={options}
                  alt="Menu options"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={() => setAnchorEl(null)}
                onClick={(e) => setAnchorEl(null)}
                className="hello"
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem className="chat-screen-menuItem">
                  <a
                    onClick={(e) => DownloadChatFiles(e)}
                    className="file-download-text"
                  >
                    <DownloadIcon />
                    Download
                  </a>
                </MenuItem>
                <Divider />
                {sentBy?.toString() === myId?.toString() && (
                  <MenuItem
                    className="chat-screen-menuItem"
                    onClick={() => {
                      deleteChatFile(fileId);
                    }}
                  >
                    <DeleteIcon />
                    Delete
                  </MenuItem>
                )}
              </Menu>
            </div>
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : null}
        {showPreviewPopup && (
          <FilePreviewPopup
            path={type === "image" ? image : video}
            hide={setShowPreviewPopup}
            fileType={type === "image" ? "image" : "video"}
          />
        )}
        {confirmPopup ? (
          <RenderConfirm
            message={"Delete it?"}
            data={"Yes"}
            status={deleteChatFileData}
            hide={setConfirmPopup}
          />
        ) : null}
      </div>
    );
  }
  //receiver
  if (sentTo?.toString() === myId?.toString() || sentTo?.toString() === "all") {
    return (
      <div className="left-side-chat">
        <div className="chat-avatar">
          {sentTo?.toString() === myId?.toString() ||
          sentTo?.toString() === "all" ? (
            <img src={other_image ? other_image : blank} alt="Avatar" />
          ) : (
            <img src={my_image ? my_image : blank} alt="Avatar" />
          )}
        </div>

        {/* message and media conditions */}
        {message ? (
          <li>
            {/* for multiple line message */}
            {message?.includes("&*&")
              ? message?.split("&*&").map((curr, i) => {
                  return <div key={i}>{curr}</div>;
                })
              : message}
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : imageData ? (
          <li className="chat-media">
            <div className="chat-media-body">
              {type === "image" ? (
                <img
                  src={image}
                  alt="Media"
                  onClick={() => chatFilePreview()}
                  style={{ cursor: "pointer" }}
                />
              ) : type === "video" ? (
                <img
                  src={playbutton}
                  alt="Media"
                  onClick={() => chatFilePreview()}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <Link
                  className="chat-file"
                  to={`file-viewer`}
                  target={"_blank"}
                  onClick={() => {
                    localStorage.setItem("filePath", filePath);
                    localStorage.setItem("fileType", fileExt?.split(".").at(1));
                  }}
                >
                  <img
                    src={renderFileImage()}
                    alt="fileImage"
                    className="chat-file-image"
                  />
                  <p className="chat-file-text">
                    {fileName?.split("-").at(-1)}
                  </p>
                </Link>
              )}
              <IconButton className="chat-file-option">
                <img
                  src={options}
                  alt="Menu options"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={() => setAnchorEl(null)}
                onClick={(e) => setAnchorEl(null)}
                className="hello"
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem className="chat-screen-menuItem">
                  <a
                    className="file-download-text"
                    onClick={(e) => DownloadChatFiles(e)}
                  >
                    <DownloadIcon />
                    Download
                  </a>
                </MenuItem>
              </Menu>
            </div>
            <span className="timeconverted">
              {timeConverted ? timeConverted : null}
            </span>
          </li>
        ) : null}
        {showPreviewPopup && (
          <FilePreviewPopup
            path={type === "image" ? image : video}
            hide={setShowPreviewPopup}
            fileType={type === "image" ? "image" : "video"}
          />
        )}
      </div>
    );
  }

  return <></>;
};

export default ChatCard;
