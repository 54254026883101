import "./CreateExternalChatRoom.css";

import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import CreateExternalChatRoom from "./CreateExternalChatRoom";
import VideoCallingRates from "Components/Popup/VideoCallingRates/VideoCallingRates";
import { getRespectiveRates } from "Config/API/get";
import { useSelector } from "react-redux";

const ChatHandling = ({
  selectedTeamMember,
  setSelectedTeamMember,
  popup,
  hide,
  availableMember,
  allExternalMember,
  externalMember,
  setExternalMember,
  externalProfile,
  videoCallCreatorAdmin,
  toast,
}) => {
  const token = localStorage.getItem("token");
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const [showChatRoom, setShowChatRoom] = useState(false);
  const [showSetRate, setShowSetRate] = useState(false);
  const [isBillable, setIsBillable] = useState("");
  const [ratesData, setRatesData] = useState([]);

  useEffect(() => {
    getAllSubscriptionsRates();
  }, []);

  const getAllSubscriptionsRates = () => {
    const response = getRespectiveRates(token);
    response.then((res) => {
      if (res.data.status) {
        const videoRate = res.data.data?.filter(
          (item) => item?.rate_type === "video_chat"
        );
        setRatesData(videoRate);
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  const handleClick = (buttonNo) => {
    if (buttonNo?.toString() === "1") {
      if (!ratesData[0]?.rate) return;
      setShowChatRoom(true);
      setIsBillable("true");
      return;
    } else if (buttonNo?.toString() === "2") {
      setShowChatRoom(true);
      setIsBillable("false");
      return;
    }
  };

  return popup ? (
    <div className="create-chat-room-container">
      <div className="create-chat-room-dialog-chat-handling">
        <div className="create-chat-room-icon">
          <button
            className="create-chat-room-close-button"
            onClick={() => hide(false)}
          >
            <CloseIcon fontSize="small" color="black" />
          </button>
        </div>
        <span>How Will This Chat Be Handled?</span>
        <button
          onClick={() => handleClick(1)}
          className="create-chat-room-handle-button"
        >
          BILLABLE VIDEO CHAT (${" "}
          {ratesData[0]?.rate ? ratesData[0]?.rate : "00"} PER MINUTE)
        </button>
        {!ratesData[0]?.rate && (
          <div className="create-chat-room-rate-not-set-text">
            Rate not Set:{" "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => {
                if (loggedInUserDetails?.credit_account_manager === "manager")
                  return setShowSetRate(true);
                toast("You do not have rights to set video calling rates", {
                  type: "info",
                });
              }}
            >
              Click here
            </a>{" "}
            to set Rate
          </div>
        )}
        <button
          onClick={() => handleClick(2)}
          className="create-chat-room-handle-button"
        >
          OVERRIDING BILLING FOR FREE VIDEO CHAT
        </button>
      </div>
      <CreateExternalChatRoom
        selectedTeamMember={selectedTeamMember}
        setSelectedTeamMember={setSelectedTeamMember}
        popup={showChatRoom}
        hide={setShowChatRoom}
        availableMember={availableMember}
        hideChatHandling={hide}
        allExternalMember={allExternalMember}
        externalMember={externalMember}
        setExternalMember={setExternalMember}
        externalProfile={externalProfile}
        videoCallCreatorAdmin={videoCallCreatorAdmin}
        isBillable={isBillable}
        toast={toast}
        billingRate={ratesData[0]?.rate}
      />
      {showSetRate && (
        <VideoCallingRates
          hide={setShowSetRate}
          ratesData={ratesData}
          message="You are Setting Video Chat Rates"
          rateType="video_chat"
          getAllSubscriptionsRates={getAllSubscriptionsRates}
        />
      )}
    </div>
  ) : null;
};

export default ChatHandling;
