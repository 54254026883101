import "./ContactSupport.css";

import React, { useState } from "react";

import { Button } from "@mui/material";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import { contactUsForm } from "Config/API/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ContactSupport = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userOrganization, setUserOrganization] = useState("");
  const [content, setContent] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const formStatus = contactUsForm(
      token,
      userName,
      userEmail,
      userOrganization,
      content
    );
    formStatus
      .then((res) => {
        if (res?.status) {
          toast(res?.message, { type: "success" });
          navigate("/dashboard");
        } else {
          toast(res?.message, { type: "error" });
        }
      })
      .catch((error) => {});

    setIsProcessing(false);
  };

  return (
    <>
      <div className="contact-support-parent">
        <div className="contact-support-header">
          <Headers />
        </div>
        <div className="contact-support-navigation">
          <Navigation />
        </div>
        <div className="contact-support-main">
          <div className="contact-support-heading">
            <h1>
              We Take Pride In Providing The Highest Quality Customer Support
            </h1>
          </div>
          <div className="contact-support-content">
            <p
              style={{
                color: "#4671A4",
                fontSize: "22px",
                width: "60%",
                margin: "0 auto",
                paddingTop: "25px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              Email Or Fill Out A Contact Us Form And A Team Member Will Get In
              Touch With You Within 24 Hours
            </p>
            <h2
              style={{
                color: "#4671A4",
                fontSize: "20px",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              SITE EXPLANATION VIDEO
            </h2>
            <p
              style={{
                color: "#CB1212",
                fontSize: "20px",
                textTransform: "capitalize",
              }}
            >
              Link To Be Provided In Near Future
            </p>

            <div className="contact-support-content-text-wrapper">
              <div>
                <h4>GENERAL HELP/SUPPORT</h4>
                <p>support@amnius.us</p>
              </div>
              <div>
                <h4>BILLING HELP/SUPPORT</h4>
                <p>billingsupport@amnius.us</p>
              </div>
              <div>
                <h4>HAVE AN IDEA OR SUGGESTION?</h4>
                <p>ideas@amnius.us</p>
              </div>
            </div>

            <div className="contact-support-content-from">
              <form
                className="contact-support-content-from-heading"
                onSubmit={handleSubmit}
              >
                <h3>CONTACT US FORM</h3>
                <div className="contact-support-content-from-content">
                  <input
                    type="text"
                    placeholder="User Name"
                    onChange={(event) => {
                      setUserName(event.target.value);
                    }}
                    required
                  />
                  <input
                    type="email"
                    placeholder="User Email"
                    onChange={(event) => {
                      setUserEmail(event.target.value);
                    }}
                    required
                  />
                  <input
                    type="text"
                    placeholder="User Organization Name"
                    onChange={(event) => {
                      setUserOrganization(event.target.value);
                    }}
                    required
                  />
                  <textarea
                    cols="30"
                    rows="10"
                    placeholder="Add message"
                    onChange={(event) => {
                      setContent(event.target.value);
                    }}
                    required
                  ></textarea>
                </div>
                <div className="contact-support-content-from-button">
                  <Button
                    variant="contained"
                    disabled={isProcessing}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSupport;
