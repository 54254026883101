import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AllInternalTickets from "pages/ServiceTicketing/AllInternLTickets/AllInternalTickets";
import AllRefundRequest from "pages/MoneyCredit/TransferAndBanking/AllRefundRequest/AllRefundRequest";
import AllTransferOutRequest from "pages/MoneyCredit/TransferAndBanking/AllTransferOutRequest/AllTransferOutRequest";
import AppFileViewer from "Components/FileViewer/PureComponents/AppFileViewer";
import Completion from "Components/MoneyCredit/Stripe/Completion";
import ContactSupport from "pages/CMS/ContactSupport/ContactSupport";
import CreateInternalTicket from "pages/ServiceTicketing/CreateInternalTicket/CreateInternalTicket";
import CreditHistoryPage from "pages/MoneyCredit/CreditHistoryPage/CreditHistoryPage";
import ErrorPage from "pages/Authentication/Error/Error";
import ImageLoader from "Hooks/ImageLoader";
import LoginTemplate from "pages/Authentication/Login/Container/LoginTemplate";
import MoneyCreditManagementPageAdmin from "pages/MoneyCredit/MoneyCreditManagementPageAdmin/MoneyCreditManagementPageAdmin";
import OrganizationStorageDashboard from "pages/CloudStorage/OrganisationStorageDashboard/OrganizationStorageDashboard";
import Portfolio from "pages/Portfolio/Portfolio";
import RatesAndSubscription from "pages/MoneyCredit/RatesAndSubscription/RatesAndSubscription";
import RatesAndSubscriptionSuperAdmin from "pages/MoneyCredit/RatesAndSubscriptionSuperAdmin/RatesAndSubscriptionSuperAdmin";
import RefundRequestFormSuperAdmin from "pages/MoneyCredit/RefundRequestFormSuperAdmin/RefundRequestFormSuperAdmin";
import RenderAppointment from "pages/Appointment/Container/RenderAppointment";
import RenderChat from "pages/Chat/Container/RenderChat";
import RenderChatSR from "Components/Service Ticketing/Chat/Container/RenderChat";
import RenderCreateAccount from "pages/Authentication/Registration/Container/RenderCreateAccount";
import RenderDashboard from "pages/Dashboard/Container/RenderDashboard";
import RenderFileExplorer from "pages/FileExplorer/Container/RenderFileExplorer";
import RenderFileViewer from "Components/FileViewer/Container/RenderFileViewer";
import RenderMoneyCreditManagementPage from "pages/MoneyCredit/MoneyCreditManagementPage/Container/RenderMoneyCreditManagementPage";
import RenderNotification from "pages/Notification/Container/RenderNotification";
import RenderOrganisation from "pages/Organisation/Container/RenderOrganisation";
import RenderResetPassword from "pages/Authentication/ResetPassword/Conatiner/RenderResetPassword";
import RenderSTCreate from "pages/ServiceTicketing/STCreate/Container/RenderSTCreate";
import RenderSTDashboard from "pages/ServiceTicketing/STDashboard/Container/RenderSTDashboard";
import RenderSTDetails from "pages/ServiceTicketing/STDetails/Container/RenderSTDetails";
import RenderSTPremiumPage from "pages/ServiceTicketing/STPremiumPage/Container/RenderSTPremiumPage";
import RenderSTRaiseRequestFormShow from "Components/Popup/STRaiseRequestFormShow/Container/RenderSTRaiseRequestFormShow";
import RenderSuperAdmin from "pages/SuperAdmin/Container/RenderSuperAdmin";
import RenderTranscationActivity from "Components/MoneyCredit/TranscationActivity/Container/RenderTranscationActivity";
import RenderTrash from "pages/Trash/Container/RenderTrash";
import RenderUserManagement from "pages/ServiceTicketing/UserManagement/Container/RenderUserManagement";
import RenderVerification from "pages/Authentication/Verification/Container/RenderVerification";
import RenderVideoChat from "Components/Chat/VideoChat/Container/RenderVideoChat";
import StripeCredit from "Components/MoneyCredit/Stripe/StripeCredit";
import SuperAdminAllRefundRequest from "pages/MoneyCredit/TransferAndBankingSuperAdmin/SuperAdminAllRefundRequest/SuperAdminAllRefundRequest";
import SuperAdminAllTransferOutRequest from "pages/MoneyCredit/TransferAndBankingSuperAdmin/SuperAdminAllTransferOutRequest/SuperAdminAllTransferOutRequest";
import SuperAdminStorageDashboard from "pages/CloudStorage/SuperAdminStorageDashboard/SuperAdminStorageDashboard";
import SuperAdminTransferAndBanking from "pages/MoneyCredit/TransferAndBankingSuperAdmin/SuperAdminTransferAndBanking";
import TransferAndBanking from "pages/MoneyCredit/TransferAndBanking/TransferAndBanking";
import VideoChatRedirectUrl from "Components/Chat/VideoChat/VideoChatRedirectUrl";
import WorkBookEditing from "pages/WorkBookEditing/WorkBookEditing";
import WorkBookFiles from "pages/WorkBookFiles/WorkBookFiles";
import { handleDeepLinking } from "Constants/Functions";
import useIsAmniusOrganization from "Hooks/useIsAmniusOrganization";
import { useNavigate } from "react-router-dom";
import RenderAssetManagement from "pages/Assets/AssetManagement/Container/RenderAssetManagement"
import RenderCreateAssets from "pages/Assets/CreateAssets/Container/RenderCreateAssets";
import RenderAssetDetails from "pages/Assets/AssetDetails/Container/RenderAssetDetails";
import RenderAddAssetPart from "pages/Assets/AddAssetPart/Container/RenderAddAssetPart";
import RenderPartsDetail from "pages/Assets/PartsDetail/Container/RenderPartsDetail";
import RenderCreateAssetServiceTicket from "pages/Assets/CreateAssetServiceTicket/Container/RenderCreateAssetServiceTicket";
const AppRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const role = useSelector((state) => state.authenticationReducer?.role);
  const pageType = useSelector((state) => state.servicePageTypeReducer);
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );
  const isOrgAmnius = useIsAmniusOrganization();
  let _from = location?.pathname;

  let token = "";
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  }

  useEffect(() => {
    // auto redirect to login page
    if (_from === "/") {
      navigate("/login");
    }

    // auto redirect for QR code
    else if (_from?.includes("document/") || _from?.includes("portfolio")) {
      if (token?.toString() !== "") {
        if (_from?.includes("document/"))
          handleDeepLinking(_from, navigate, dispatch, token);
      } else {
        if (_from?.includes("portfolio")) {
          localStorage?.setItem("from", window.location.href);
        } else {
          localStorage?.setItem("from", _from);
        }
        navigate("/login");
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Login page */}
      <Routes>
        {/* authentication */}
        <Route path="/" element={<ImageLoader />} />
        <Route path="/login" element={<LoginTemplate />} />
        <Route path="/login/create-account" element={<RenderCreateAccount />} />
        <Route path="/login/reset-password" element={<RenderResetPassword />} />
        <Route path="/verification" element={<RenderVerification />} />

        {/* regular */}
        <Route path="/dashboard" element={<RenderDashboard />} />
        <Route path="/notification" element={<RenderNotification />} />
        <Route path="/organization" element={<RenderOrganisation />} />
        <Route path="/file-explorer" element={<RenderFileExplorer />} />
        <Route
          path="/file-explorer/file-viewer"
          element={<RenderFileViewer />}
        />
        <Route path="/chat" element={<RenderChat />} />
        <Route path="/appointment" element={<RenderAppointment />} />
        <Route
          path="/video-chat-redirect-url"
          element={<VideoChatRedirectUrl />}
        />
        <Route path="/video-chat" element={<RenderVideoChat />} />
        <Route path="/trash" element={<RenderTrash />} />

        {/* superadmin */}
        <Route path="/superadmin" element={<RenderSuperAdmin />} />

        {/* service tickets */}
        <Route
          path="/service-ticket-dashboard"
          element={<RenderSTDashboard />}
        />
        <Route
          path="/service-ticket-view-page"
          element={
            loggedInUserDetails?.ticket_external_read_role === "manager" ||
            pageType !== "PREMIUM" ? (
              <RenderSTPremiumPage />
            ) : (
              <ErrorPage />
            )
          }
        />
        <Route path="/service-ticket-details" element={<RenderSTDetails />} />
        <Route path="/service-ticket-create" element={<RenderSTCreate />} />
        <Route
          path="/service-ticket-user-management"
          element={<RenderUserManagement />}
        />
        <Route path="/service-ticket-chat" element={<RenderChatSR />} />
        <Route
          path="/service-raise-request-info"
          element={<RenderSTRaiseRequestFormShow />}
        />
        <Route
          path="/create-internal-service-ticket"
          element={<CreateInternalTicket />}
        />
        <Route
          path="/all-internal-service-tickets"
          element={
            loggedInUserDetails?.ticket_internal_read_role === "manager" &&
            pageType === "PREMIUM" ? (
              <AllInternalTickets />
            ) : (
              <ErrorPage />
            )
          }
        />

        {/* money/credit handling */}
        <Route
          path="/money-credit-dashboard"
          element={
            role === "super_admin" ? (
              <MoneyCreditManagementPageAdmin />
            ) : (
              <RenderMoneyCreditManagementPage />
            )
          }
        />
        {/* storage handling */}
        <Route
          path="/organization-storage-dashboard"
          element={
            role === "super_admin" ? (
              <SuperAdminStorageDashboard />
            ) : (
              <OrganizationStorageDashboard />
            )
          }
        />
        <Route
          path="/money-credit-transaction-activity"
          element={
            loggedInUserDetails?.credit_read_rights === "manager" ? (
              <RenderTranscationActivity />
            ) : (
              <ErrorPage />
            )
          }
        />
        <Route
          path="/money-credit-transaction-activity/refundId=:refundId&refundToken=:refundToken"
          element={<RefundRequestFormSuperAdmin />}
        />
        <Route path="/checkout" element={<StripeCredit />} />
        <Route path="/completion" element={<Completion />} />
        <Route
          path="/rates-and-subscription-management"
          element={
            role === "super_admin" ? (
              <RatesAndSubscriptionSuperAdmin />
            ) : (
              <RatesAndSubscription />
            )
          }
        />
        <Route
          path="/transfer-and-banking"
          element={
            role === "super_admin" ? (
              <SuperAdminTransferAndBanking />
            ) : isOrgAmnius ? (
              <ErrorPage />
            ) : (
              <TransferAndBanking />
            )
          }
        />
        <Route
          path="/all-refund-requests"
          element={
            role === "super_admin" ? (
              <SuperAdminAllRefundRequest />
            ) : loggedInUserDetails?.credit_read_rights === "manager" ? (
              <AllRefundRequest />
            ) : (
              <ErrorPage />
            )
          }
        />
        <Route
          path="/all-transfer-out-requests"
          element={
            role === "super_admin" ? (
              <SuperAdminAllTransferOutRequest />
            ) : loggedInUserDetails?.credit_read_rights === "manager" ? (
              <AllTransferOutRequest />
            ) : (
              <ErrorPage />
            )
          }
        />

        <Route
          path="/all-credit-history"
          element={
            role === "super_admin" ? <CreditHistoryPage /> : <ErrorPage />
          }
        />

        {/* Portfolio */}
        {/* <Route path="/portfolio/orgId=:orgId" element={<Portfolio />} /> */}
        
        <Route path="/portfolio/orgId/:orgId" element={<Portfolio />} />
        {/* WorkBookEditing */}
        <Route path="/workbook-editing" element={<WorkBookEditing />} />

        {/* WorkBookFiles */}
        <Route path="/workbook-files" element={<WorkBookFiles />} />

        {/* Global */}
        <Route path="*" element={<ErrorPage />} />
        <Route
          path="/contact-support"
          element={isOrgAmnius ? <ErrorPage /> : <ContactSupport />}
        />

        {/* routes for mobile app */}
        <Route path="/app-file-viewer" element={<AppFileViewer />} />
        {/* chat file viewer */}
        <Route path="/chat/file-viewer" element={<RenderFileViewer />} />
        <Route path="/asset-management-view-page"  element={
              <RenderAssetManagement/>
           
          }/>
        <Route path="/create-assets" element={
        <RenderCreateAssets/>
     
    }/>
        <Route path="/asset-details" element={
        <RenderAssetDetails/>
      
    }/>
        <Route path="/add-asset-part"  element={
        <RenderAddAssetPart/>
    
    }/>
        <Route path="/part-detail"  element={
        <RenderPartsDetail/>
    
    }/>
        <Route path="/asset-service-ticket-create" element={
        <RenderCreateAssetServiceTicket/>
      
    }/>
      </Routes>
    
    </>
  
  );
};

export default AppRoutes;
