import { RESET_DIALOG, SET_DIALOG } from "Config/ActionType/action.type";

export const setDialog = (type, message) => {
  return {
    type: SET_DIALOG,
    payload: {
      type,
      message,
    },
  };
};

export const resetDialog = () => {
  return {
    type: RESET_DIALOG,
  };
};
