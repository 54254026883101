import "./ManageStoragePlanPopup.css";

/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";

import CreateStoragePlanPopup from "../CreateStoragePlanPopup/CreateStoragePlanPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import RenderConfirm from "Components/Popup/Confirm/Container/RenderConfirm";
import { deleteStoragePlan } from "Config/API/action";
import { getStoragePlans } from "Config/API/get";
import { toast } from "react-toastify";

const ManageStoragePlanPopup = ({ hide, token, setLoader, resetLoader }) => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [showCreatePlanPopup, setShowCreatePlanPopup] = useState(false);

  const [allStoragePlans, setAllStoragePlans] = useState([]);

  const pageLoadingArray = ["", "", "", "", "", ""];

  useEffect(() => {
    getAllStoragePlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllStoragePlans = () => {
    if (!isPageLoading) setIsPageLoading(true);
    const storagePlansApiResponse = getStoragePlans(token);
    storagePlansApiResponse.then((res) => {
      setIsPageLoading(false);
      if (res?.data?.status) {
        setAllStoragePlans(res?.data?.data);
      } else toast(res?.data?.message, { type: "error" });
    });
  };

  const handleDeletePlanClick = (id) => {
    setIsPageLoading(true);
    const deletePlanApiResponse = deleteStoragePlan(token, id);
    deletePlanApiResponse.then((res) => {
      if (res?.status) {
        toast(res?.message, { type: "success" });
        getAllStoragePlans();
      } else {
        setIsPageLoading(false);
        toast(res?.message, { type: "error" });
      }
    });
  };

  return (
    <>
      <div className="manage-storage-plan-parent">
        <div className="manage-storage-plan-main">
          <div className="manage-storage-plan-heading">
            <p>Manage Plan</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="manage-storage-plan-body">
            <div className="manage-storage-plan-wrapper">
              {!isPageLoading
                ? allStoragePlans?.map((item, index) => {
                    return (
                      <div className="manage-storage-plan-wrapper-content">
                        <label>{item?.storageName}</label>
                        <div className="manage-storage-plan-wrapper-content-card">
                          <div className="manage-storage-plan-wrapper-content-card-labels">
                            <label>
                              {item?.fromSize === 0
                                ? `${item?.toSize} GB`
                                : item?.fromSize === 500
                                ? `${item?.fromSize} GB+`
                                : `${item?.fromSize} - ${item?.toSize} GB`}
                            </label>
                            <span>
                              {item?.slug === "free"
                                ? "FREE"
                                : `${(+item?.rate)?.toFixed(2)}/month`}
                            </span>
                          </div>
                          <div className="manage-storage-plan-wrapper-content-card-buttons">
                            <IconButton
                              onClick={() => {
                                setSelectedPlanDetails(item);
                                setShowCreatePlanPopup(true);
                              }}
                            >
                              <EditIcon className="manage-storage-plan-edit-icon" />
                            </IconButton>
                            {item?.slug !== "free" && (
                              <IconButton
                                onClick={() => {
                                  setSelectedPlanDetails(item);
                                  setConfirmPopup(true);
                                }}
                              >
                                <DeleteIcon className="manage-storage-plan-delete-icon" />
                              </IconButton>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : pageLoadingArray?.map((item, index) => {
                    return (
                      <div className="purchase-storage-plan-wrapper-content">
                        <label
                          className="animation"
                          style={{ height: "19px", width: "65px" }}
                        />
                        <div
                          className="animation"
                          style={{
                            marginTop: "30px",
                            height: "70px",
                            width: index === 0 ? "135px" : "197px",
                            position: "relative",
                          }}
                        >
                          <div className="manage-storage-plan-wrapper-content-card-buttons">
                            {index !== 0 && (
                              <div
                                className="animation"
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginRight: "3px",
                                }}
                              />
                            )}
                            <div
                              className="animation"
                              style={{ height: "25px", width: "25px" }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
            <button
              onClick={() => {
                if (isPageLoading) return;
                setSelectedPlanDetails({});
                setShowCreatePlanPopup(true);
              }}
              className="manage-plan-create-button"
            >
              Create Plan
            </button>
          </div>
        </div>
      </div>
      {showCreatePlanPopup && (
        <CreateStoragePlanPopup
          hide={setShowCreatePlanPopup}
          token={token}
          selectedPlanDetails={selectedPlanDetails}
          getAllStoragePlans={getAllStoragePlans}
          setSelectedPlanDetails={setSelectedPlanDetails}
          setLoader={setLoader}
          resetLoader={resetLoader}
        />
      )}
      {confirmPopup && (
        <RenderConfirm
          message={` delete it?`}
          data={selectedPlanDetails?.id}
          hide={setConfirmPopup}
          status={handleDeletePlanClick}
        />
      )}
    </>
  );
};

export default ManageStoragePlanPopup;
