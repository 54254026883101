import React, { useState } from "react";
import { disableRoom, leaveCall } from "Config/API/action";
import {
  selectIsConnectedToRoom,
  selectIsLocalScreenShared,
  selectIsSomeoneScreenSharing,
  selectPeerCount,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useDispatch, useSelector } from "react-redux";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChatIcon from "@mui/icons-material/Chat";
import call from "Media/icons/call.png";
import { isMobile } from "react-device-detect";
import logo from "Media/Amnius_connect_noText.png";
import moment from "moment-timezone";
import present from "Media/icons/present.png";
import settings from "Media/icons/settings.png";
import stopShare from "Media/icons/stopShare.png";
import { storeVideoChatLinkAction } from "Redux/action/get.action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Header({
  showChat,
  checkPermissions,
  hours,
  minutes,
  seconds,
  loggedInUser,
  localPeer,
  peers,
  startTime,
  tabUniqueId,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const isLocalScreenShared = useHMSStore(selectIsLocalScreenShared);
  const isScreenShareOn = useHMSStore(selectIsSomeoneScreenSharing);
  const hmsActions = useHMSActions();
  const count = useHMSStore(selectPeerCount);
  // const msID = useSelector((state) => state.storeVideoChatLinkReducer.id);
  const msRoom = useSelector((state) => state.storeVideoChatLinkReducer.name);
  const msToken = useSelector((state) => state.storeVideoChatLinkReducer.token);
  const from = useSelector((state) => state.storeVideoChatLinkReducer.from);
  const token = localStorage.getItem("token");
  const [disableEndButton, setDisableEndButton] = useState(false);
  const startScreenSharing = async () => {
    try {
      if (isScreenShareOn?.toString() === "true") {
        return toast("Someone is already Sharing his Screen", {
          type: "info",
        });
      } else await hmsActions.setScreenShareEnabled(true);
    } catch (error) {}
  };

  const stopScreenSharing = async () => {
    try {
      await hmsActions.setScreenShareEnabled(false);
    } catch (error) {}
  };

  const changeHost = () => {
    const allParticipants = peers?.filter((item) => {
      return item?.roleName?.toString() !== "host";
    });
    hmsActions.changeRoleOfPeer(localPeer?.id, "participant", true);
    hmsActions.changeRoleOfPeer(allParticipants[0]?.id, "host", true);
  };

  return (
    <header style={{ height: "10%", display: "flex", alignItems: "center" }}>
      <div className="room-header-first-container">
        <img className="video-call-logo" src={logo} alt="logo" />
        <AccessTimeIcon className="time-icon" fontSize="small" />
        <h5
          style={{
            color: "white",
            width: 100,
            textAlign: "center",
            marginLeft: "-35px",
          }}
        >
          {hours > 9 ? hours : `0${hours}`}:
          {minutes > 9 ? minutes : `0${minutes}`}:
          {seconds > 9 ? seconds : `0${seconds}`}
        </h5>
      </div>
      <div className="video-call-header-action-button">
        <button
          className="video-call-share-button"
          onClick={() => checkPermissions("setting")}
        >
          <img src={settings} alt="setting" style={{ height: 25, width: 25 }} />
          Settings
        </button>
        <button
          className="video-call-share-button"
          onClick={() => showChat(true)}
        >
          <ChatIcon style={{ color: "white", height: 25, width: 25 }} />
          Chat
        </button>
        {!isMobile ? (
          isLocalScreenShared ? (
            <button
              className="video-call-share-button"
              onClick={() => stopScreenSharing()}
            >
              <img
                src={stopShare}
                alt="stopShare"
                style={{ height: 25, width: 25 }}
              />
              Stop Share
            </button>
          ) : (
            <button
              className="video-call-share-button"
              onClick={() => startScreenSharing()}
            >
              <img
                src={present}
                alt="share"
                style={{ height: 25, width: 25 }}
              />
              Share
            </button>
          )
        ) : null}
        {isConnected &&
          (count?.toString() === "1" && from !== "appointment" ? (
            <button
              id="leave-btn"
              className="btn-danger"
              disabled={disableEndButton}
              onClick={() => {
                setDisableEndButton(true);
                const duration = hours * 3600 + minutes * 60 + seconds;
                const leave_room = leaveCall(
                  token,
                  loggedInUser?.id,
                  loggedInUser?.org_id,
                  duration % 30,
                  msRoom,
                  "false",
                  "false",
                  moment().format("YYYY-MM-DD HH:mm:ss"),
                  tabUniqueId,
                  startTime
                );
                leave_room.then((res) => {
                  if (res?.status) {
                    const disable_room = disableRoom(token, msToken, msRoom);
                    disable_room
                      .then((res) => {
                        if (res?.status) {
                          hmsActions.leave();
                          dispatch(storeVideoChatLinkAction(""));
                          navigate(-1);
                        } else {
                          toast(res?.message, {
                            type: "error",
                          });
                        }
                      })
                      .catch((e) => {
                        toast("Failed! Try again later", {
                          type: "error",
                        });
                      });
                  }
                });
              }}
            >
              <img src={call} alt="call" style={{ height: 15, width: 15 }} />
              End Call
            </button>
          ) : (
            <button
              id="leave-btn"
              className="btn-danger"
              disabled={disableEndButton}
              onClick={() => {
                setDisableEndButton(true);
                const duration = hours * 3600 + minutes * 60 + seconds;
                const leave_room = leaveCall(
                  token,
                  loggedInUser?.id,
                  loggedInUser?.org_id,
                  duration % 30,
                  msRoom,
                  "true",
                  "false",
                  moment().format("YYYY-MM-DD HH:mm:ss"),
                  tabUniqueId,
                  startTime
                );
                leave_room.then((res) => {
                  if (res?.status) {
                    if (localPeer.roleName?.toString() === "host") {
                      changeHost();
                    }
                    hmsActions.leave();
                    dispatch(storeVideoChatLinkAction(""));
                    if (from === "appointment") return navigate("/appointment");
                    navigate(-1);
                  }
                });
              }}
            >
              <img src={call} alt="call" style={{ height: 15, width: 15 }} />
              Leave
            </button>
          ))}
      </div>
    </header>
  );
}

export default Header;
