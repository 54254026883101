import { getToken } from "firebase/messaging";
import { messaging } from "./FirebaseConfig";
import { setDevicePushNotificationTokenApi } from "Config/API/action";

export const requestForNotificationToken = (loggedInUserId) => {
  const setDevicePushNotificationTokenApiHandler = (deviceToken, type) => {
    const response = setDevicePushNotificationTokenApi(
      loggedInUserId,
      deviceToken,
      type,
      ""
    );
    response
      .then((res) => {
        if (res?.status) {
          localStorage.setItem("deviceTokenForPushNotification", deviceToken);
        }
      })
      .catch((e) => {});
  };

  getToken(messaging, {
    vapidKey:
      "BDTdmsZGZNEO_4NBlqwn6l-_F8V4ag-NiyESmhaqgGCDWdViJrQemPLj-pYQT2i9_8H0bLTUrV1WRESlw5nzfYI",
  })
    .then((currToken) => {
      if (currToken) {
        // set push notification device token
        setDevicePushNotificationTokenApiHandler(currToken, "set");
      }
    })
    .catch((err) => {});
};
