import "./CreditHistoryPage.css";

import React, { useEffect, useState } from "react";
import { getTranscationActivityApi, renderOrganizations } from "Config/API/get";

import ApiLoader from "Components/Loader/ApiLoader";
import CompanyLogo from "Media/blank-profile-picture.png";
import DownArrow from "Media/icons/Iconly-Bold-Tick-Square.svg";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import SearchIcon from "@mui/icons-material/Search";
import UpArrow from "Media/icons/Iconly-Bold-Tick-Up-Square.svg";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useSelector } from "react-redux";

const CreditHistoryPage = () => {
  let doneTypingInterval = 1500;
  let typingTimer;
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [leftSideOrganizationList, setLeftSideOrganizationList] = useState([]);
  const [rightSideOrganizationList, setRightSideOrganizationList] = useState(
    []
  );
  const [transcationList, setTranscationList] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(false);
  const credit = useSelector((state) => state.getStripeCreditBalanceReducer);

  useEffect(() => {
    getAllOrganizations();
    // eslint-disable-next-line
  }, []);

  const divideListOfOrganizations = (value) => {
    const evenData = value?.filter((data, index) => {
      return index % 2 === 0;
    });
    setLeftSideOrganizationList(evenData);

    const oddData = value?.filter((data, index) => {
      return index % 2 !== 0;
    });
    setRightSideOrganizationList(oddData);
  };

  const search_fuction = (value) => {
    const transcation_status = renderOrganizations(token, value);
    transcation_status.then((res) => {
      divideListOfOrganizations(res?.listOfOrganisation);
    });
  };

  const getAllOrganizations = () => {
    setLoader();
    const organizationListApi = renderOrganizations(token, "");
    organizationListApi.then((res) => {
      resetLoader();
      divideListOfOrganizations(res?.listOfOrganisation);
    });
  };

  const getOrganizationData = (data) => {
    setTranscationList([]);
    if (activeTab === data?.id) {
      setActiveTab("");
    } else {
      setLoading(true);
      setActiveTab(data?.id || "");
      const transactionStatus = getTranscationActivityApi(token, data?.id);
      transactionStatus.then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          setTranscationList(res?.data?.data?.result);
        }
      });
    }
  };

  return (
    <>
      <div className="credit-history-transcation-main">
        <div className="credit-history-transcation-main-header">
          <Headers />
        </div>
        <div className="credit-history-transcation-navigation">
          <Navigation />
        </div>
        <div className="credit-history-transcation-wrapper">
          <div className="credit-history-transcation-header">
            Credit History
          </div>
          <div className="credit-history-transcation-content">
            <div className="credit-history-transcation-top-part">
              <div className="credit-history-transcation-search">
                <input
                  type="search"
                  placeholder="Search..."
                  onChange={(e) => {
                    clearTimeout(typingTimer);
                    if (e.target.value || e.target.value === "") {
                      typingTimer = setTimeout(() => {
                        search_fuction(e.target.value);
                      }, doneTypingInterval);
                    }
                  }}
                />
                <SearchIcon />
              </div>
              <div className="credit-history-transcation-balance">
                <span>TOTAL CREDIT BALANCE </span>
                <b>{credit ? credit : 0}</b>
              </div>
            </div>
            <div className="credit-history-transcation-bottom-part">
              <div className="credit-history-transcation-bottom-left-side">
                {leftSideOrganizationList &&
                leftSideOrganizationList?.length > 0
                  ? leftSideOrganizationList.map((curr) => {
                      return (
                        <div
                          className="credit-history-transcation-card"
                          key={curr?.id}
                        >
                          <div className="credit-history-transcation-card-wrapper">
                            <div className="credit-history-transcation-card-logo">
                              <img src={curr?.logo || CompanyLogo} alt="Logo" />
                            </div>
                            <div className="credit-history-transcation-card-content">
                              <h5>{curr?.organization_name || "None"}</h5>
                              <span>
                                Current Credit Balance:{" "}
                                <b>{curr?.credit || "00"}</b>
                              </span>
                            </div>
                            <div
                              className="credit-history-transcation-card-button"
                              onClick={() => getOrganizationData(curr)}
                            >
                              {activeTab === curr?.id ? (
                                <span>
                                  Hide <img src={UpArrow} alt="arrow-icon" />
                                </span>
                              ) : (
                                <>
                                  <span>
                                    View History
                                    <img src={DownArrow} alt="arrow-icon" />
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          {activeTab === curr?.id ? (
                            <>
                              <div
                                className="credit-history-transcation-card-expanded"
                                style={{ boxShadow: "0px 3px 6px #00000029" }}
                              >
                                <div className="credit-history-transcation-card-expanded-head-row">
                                  <div style={{ width: "20%" }}>DATE</div>
                                  <div
                                    style={{
                                      width: "60%",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    COMMENT
                                  </div>
                                  <div style={{ width: "20%" }}>CREDITS</div>
                                </div>
                                <div
                                  style={{
                                    height: "94px",
                                    overflowY: "scroll",
                                  }}
                                >
                                  {!loading ? (
                                    transcationList?.length > 0 ? (
                                      transcationList.map((curr, index) => {
                                        return (
                                          <div
                                            className="credit-history-transcation-card-expanded-body-row"
                                            key={curr?.transactionId}
                                          >
                                            <div style={{ width: "20%" }}>
                                              {curr?.dateTime || "-"}
                                            </div>
                                            <div
                                              style={{
                                                width: "60%",
                                                paddingLeft: "10px",
                                              }}
                                            >
                                              {curr?.description || "-"}
                                            </div>
                                            <div style={{ width: "20%" }}>
                                              <b>{curr?.credit || "-"}</b>
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div style={{ textAlign: "center" }}>
                                        No History Found
                                      </div>
                                    )
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                      }}
                                    >
                                      <ApiLoader
                                        height="35px"
                                        width="35px"
                                        borderWidth="7px"
                                        borderTopWidth="7px"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="credit-history-transcation-bottom-right-side">
                {rightSideOrganizationList &&
                rightSideOrganizationList?.length > 0
                  ? rightSideOrganizationList.map((curr) => {
                      return (
                        <div
                          className="credit-history-transcation-card"
                          key={curr?.id}
                        >
                          <div className="credit-history-transcation-card-wrapper">
                            <div className="credit-history-transcation-card-logo">
                              <img src={curr?.logo || CompanyLogo} alt="Logo" />
                            </div>
                            <div className="credit-history-transcation-card-content">
                              <h5>{curr?.organization_name || "None"}</h5>
                              <span>
                                Current Credit Balance:
                                <b>{curr?.credit || "00"}</b>
                              </span>
                            </div>
                            <div
                              className="credit-history-transcation-card-button"
                              onClick={() => getOrganizationData(curr)}
                            >
                              {activeTab === curr?.id ? (
                                <span>
                                  Hide <img src={UpArrow} alt="arrow-icon" />
                                </span>
                              ) : (
                                <>
                                  <span>
                                    View History
                                    <img src={DownArrow} alt="arrow-icon" />
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          {activeTab === curr?.id ? (
                            <>
                              <div
                                className="credit-history-transcation-card-expanded"
                                key={curr?.transactionId}
                                style={{
                                  boxShadow: "0px 3px 6px #00000029",
                                }}
                              >
                                <div className="credit-history-transcation-card-expanded-head-row">
                                  <div style={{ width: "20%" }}>DATE</div>
                                  <div style={{ width: "60%" }}>COMMENT</div>
                                  <div style={{ width: "20%" }}>CREDITS</div>
                                </div>
                                <div
                                  style={{
                                    height: "94px",
                                    overflowY: "scroll",
                                  }}
                                >
                                  {!loading ? (
                                    transcationList?.length > 0 ? (
                                      transcationList.map((curr, index) => {
                                        return (
                                          <div
                                            className="credit-history-transcation-card-expanded-body-row"
                                            key={curr?.transactionId}
                                          >
                                            <div style={{ width: "20%" }}>
                                              {curr?.dateTime || "-"}
                                            </div>
                                            <div style={{ width: "60%" }}>
                                              {curr?.description || "-"}
                                            </div>
                                            <div style={{ width: "20%" }}>
                                              <b>{curr?.credit || "-"}</b>
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div style={{ textAlign: "center" }}>
                                        No History Found
                                      </div>
                                    )
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                      }}
                                    >
                                      <ApiLoader
                                        height="35px"
                                        width="35px"
                                        borderWidth="7px"
                                        borderTopWidth="7px"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default CreditHistoryPage;
