import React from "react";
import STRaiseRequestForm from "../PureComponents/STRaiseRequestForm";

const RenderSTRaiseRequestForm = ({ hide, service_org_id, ticketData }) => {
  return (
    <STRaiseRequestForm
      hide={hide}
      service_org_id={service_org_id}
      ticketData={ticketData}
    />
  );
};

export default RenderSTRaiseRequestForm;
