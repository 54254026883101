import "./AddUser.css";

import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { addUser, createLocationCategory } from "Config/API/action";
import { getCategoryData, getLocationData } from "Config/API/get";

import ApiLoader from "Components/Loader/ApiLoader";
import CloseIcon from "@mui/icons-material/Close";
import DateInput from "Components/DateInput/DateInput";
import formValidators from "Config/Validators/formValidators";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AddUser = ({ popup, hide,getTeamsData }) => {
  const token = localStorage.getItem("token");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [number, setNumber] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [extension, setExtension] = useState("");
  const [category, setCategory] = useState("");
  const [scope, setScope] = useState("internal");
  const [category_list, setCategory_list] = useState([]);
  const [location, setLocation] = useState("");
  const [location_list, setLocation_list] = useState([]);
  const general_info = useSelector((state) => state.renderGeneralInfo);
  const [showAddUserDataModal, setShowAddUserDataModal] = useState(false);
  const [modalData, setModalData] = useState({
    placeholderText: "",
    type: "",
    data: "",
  });
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [locationId, setLocationId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [locationLoading, setLocationLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (popup) getAllLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup]);

  useEffect(() => {
    if (popup && locationId !== "") getAllCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  useEffect(() => {
    if (!popup) return;
    if (Object.keys(formErrors).length?.toString() === "0" && isSubmit) {
      const userName = `${userFirstName} ${userLastName}`;
      const dob = `${selectedMonth}/${selectedDay}/${selectedYear}`;
      const adduser_status = addUser(
        userName,
        userFirstName,
        userLastName,
        userEmail,
        designation,
        number,
        officeNumber,
        extension,
        categoryId,
        locationId,
        dob,
        scope,
        token
      );
      adduser_status.then((res) => {
        if (res?.status) {
          toast(res?.message, {
            type: "success",
          });
          getTeamsData()
          hide(false); 
        } else {
          toast(res?.message, {
            type: "error",
          });
        }
      });
    }
  }, [formErrors]);

  const getAllLocations = () => {
    setLocationLoading(true);
    const locationList = getLocationData(token, general_info?.id);
    locationList.then((res) => {
      setLocationLoading(false);
      if (res?.location?.length > 0) {
        setLocation_list(res?.location);
      }
    });
  };

  const getAllCategory = () => {
    setCategoryLoading(true);
    if (locationId !== "") {
      const categoryList = getCategoryData(token, general_info?.id, locationId);
      categoryList.then((res) => {
        setCategoryLoading(false);
        setCategory_list(res?.category);
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (number.length > 0 || userEmail.length > 0 || officeNumber.length > 0) {
      setFormErrors(formValidators(number, userEmail, officeNumber));
      setIsSubmit(true);
    }
  };

  const addUserDataModal = () => {
    const save = (e) => {
      e.preventDefault();
      switch (modalData.type) {
        case "location": {
          const res = createLocationCategory(
            token,
            modalData.type,
            modalData.data,
            ""
          );
          res
            .then((data) => {
              if (data?.status) {
                if (data?.message === "Location Already Exist.")
                  return toast(data?.message, { type: "info" });
                toast(data?.message, {
                  type: "success",
                });
                setShowAddUserDataModal(false);
                setModalData({
                  placeholderText: "",
                  type: "",
                  data: "",
                });
                getAllLocations();
              } else {
                toast("Failed! Try again later", { type: "error" });
              }
            })
            .catch((e) => {
              toast("Failed! Try again later", { type: "error" });
            });
          break;
        }
        case "category": {
          const res = createLocationCategory(
            token,
            modalData.type,
            modalData.data,
            locationId
          );
          res
            .then((data) => {
              if (data?.status) {
                if (data?.message === "Category Already Exist.")
                  return toast(data?.message, { type: "info" });
                toast(data?.message, {
                  type: "success",
                });
                setShowAddUserDataModal(false);
                setModalData({
                  placeholderText: "",
                  type: "",
                  data: "",
                });
                getAllCategory();
              } else {
                toast("Failed! Try again later", { type: "error" });
              }
            })
            .catch((e) => {
              toast("Failed! Try again later", { type: "error" });
            });
          break;
        }
        default:
          break;
      }
    };
    return (
      <div className="add-user-data-modal-wrapper">
        <form onSubmit={save} autoComplete="off">
          <TextField
            fullWidth
            required
            type="text"
            placeholder={modalData.placeholderText}
            label={modalData.placeholderText}
            onChange={(e) =>
              setModalData({ ...modalData, data: e.target.value })
            }
          />
          <Button
            className="save-button"
            type="submit"
            variant="contained"
            style={{ background: "#446FA2" }}
          >
            Save {modalData?.type}
          </Button>
        </form>
        <button
          className="closebutton"
          onClick={() => {
            setShowAddUserDataModal(false);
            setModalData({
              placeholderText: "",
              type: "",
              data: "",
            });
          }}
        >
          <CloseIcon />
        </button>
      </div>
    );
  };

  return popup ? (
    <>
      <div className="add-user-container">
        <div>
          <div className="add-user-top-wrapper">
            <div className="add-user-head">Add User</div>
            <div className="add-user-close">
              <IconButton
                onClick={() => {
                  hide(false);
                  setLocation("");
                  setCategory("");
                  setLocationId("");
                  setCategoryId("");
                  setLocation_list([]);
                  setCategory_list([]);
                  setFormErrors("");
                  setUserEmail("");
                  setNumber("");
                  setOfficeNumber("");
                  setSelectedDay("");
                  setSelectedMonth("");
                  setSelectedYear("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div className="add-user-form">
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="add-user-name">
                <TextField
                  required
                  placeholder="Ex: John"
                  label="Enter First Name"
                  variant="outlined"
                  type="text"
                  style={{ margin: "10px 0", marginTop: "unset", width: "49%" }}
                  onChange={(e) => {
                    setUserFirstName(e.target.value);
                  }}
                />
                <TextField
              
                  placeholder="Ex: Doe"
                  label="Enter Last Name"
                  variant="outlined"
                  type="text"
                  style={{ margin: "10px 0", marginTop: "unset", width: "49%" }}
                  onChange={(e) => {
                    setUserLastName(e.target.value);
                  }}
                />
              </div>
              <div className="add-user-designation">
                <TextField
                  required
                  fullWidth
                  placeholder="Ex: Developer"
                  label="Enter User Designation"
                  variant="outlined"
                  type="text"
                  style={{ margin: "10px 0" }}
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                />
              </div>
              <div className="add-user-location">
                <div className="add-user-location-inner-wrapper">
                  <FormControl fullwidth style={{ margin: "10px 0" }}>
                    <InputLabel id="select-location">
                      Select Location *
                    </InputLabel>
                    <Select
                      required
                      value={location}
                      labelId="select-location"
                      label="Select Location *"
                      onChange={(e) => setLocation(e.target.value)}
                    >
                      {!locationLoading ? (
                        location_list?.length > 0 ? (
                          location_list?.map((currEle, index) => {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => setLocationId(currEle?.id)}
                                value={currEle?.location}
                              >
                                {currEle?.location}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem disabled>
                            Add location to choose one
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "100px",
                          }}
                        >
                          <ApiLoader
                            height="35px"
                            width="35px"
                            borderWidth="6px"
                            borderTopWidth="6px"
                          />
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <button
                    onClick={() => {
                      setShowAddUserDataModal(true);
                      setModalData({
                        placeholderText: "Add Location",
                        type: "location",
                      });
                    }}
                    type="button"
                    style={{ margin: "10px 0" }}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="add-profile-category">
                <div className="add-profile-category-inner-wrapper">
                  <FormControl fullwidth style={{ margin: "10px 0" }}>
                    <InputLabel id="select-category">
                      Select Category *
                    </InputLabel>
                    <Select
                      required
                      value={category}
                      labelId="select-category"
                      label="Select Category *"
                      disabled={locationId === ""}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      {!categoryLoading ? (
                        category_list?.length > 0 ? (
                          category_list?.map((currEle, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={currEle?.category_id}
                                onClick={() => setCategoryId(currEle?.id)}
                              >
                                {currEle?.category_id}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem disabled>
                            Add category to choose one
                          </MenuItem>
                        )
                      ) : (
                        <MenuItem
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "100px",
                          }}
                        >
                          <ApiLoader
                            height="35px"
                            width="35px"
                            borderWidth="6px"
                            borderTopWidth="6px"
                          />
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <button
                    onClick={() => {
                      if (locationId === "")
                        return toast("Please select a location first", {
                          type: "info",
                        });
                      setShowAddUserDataModal(true);
                      setModalData({
                        placeholderText: "Add Category",
                        type: "category",
                      });
                    }}
                    type="button"
                    style={{ margin: "10px 0" }}
                  >
                    +
                  </button>
                </div>
              </div>

              <div className="add-user-scope">
                <label style={{ marginTop: "10px", marginBottom: "4px" }}>
                  Select A Scope:
                </label>
                <select
                  required
                  onChange={(e) => {
                    setScope(e.target.value);
                  }}
                  style={{ margin: "10px 0", marginTop: "unset" }}
                >
                  <option value="DEFAULT" disabled>
                    -- Select an option --
                  </option>
                  <option value="internal">Internal</option>
                  <option value="external">External</option>
                </select>
              </div>
              <div className="add-user-birthday">
                <label style={{ marginTop: "10px" }}>Birthday:</label>
                <>
                  <DateInput
                    selectedDay={selectedDay}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    setSelectedDay={setSelectedDay}
                    setSelectedMonth={setSelectedMonth}
                    setSelectedYear={setSelectedYear}
                  />
                </>
              </div>
              <div className="add-user-email">
                <TextField
                  required
                  fullWidth
                  placeholder="Ex: abc@xyz.com"
                  label="Enter User Email"
                  variant="outlined"
                  type="email"
                  style={{ margin: "10px 0" }}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
                {formErrors.email ? (
                  <p className="error" style={{ marginTop: "-5px" }}>
                    {formErrors.email}
                  </p>
                ) : null}
              </div>
              <div className="add-user-number">
                <TextField
                  fullWidth
                  placeholder="Ex: 9988899888"
                  label="Enter User Phone Number"
                  variant="outlined"
                  type="number"
                  style={{ margin: "10px 0" }}
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                />
                {formErrors.phone ? (
                  <p className="error" style={{ marginTop: "-5px" }}>
                    {formErrors.phone}
                  </p>
                ) : null}
              </div>
              <div className="add-user-office">
                <div>
                  <TextField
                    fullWidth
                    placeholder="Ex: 9988899888"
                    label="Enter User Office Number"
                    variant="outlined"
                    type="number"
                    style={{ margin: "10px 0" }}
                    onChange={(e) => {
                      setOfficeNumber(e.target.value);
                    }}
                  />
                  {formErrors.office ? (
                    <p className="error" style={{ marginTop: "-5px" }}>
                      {formErrors.office}
                    </p>
                  ) : null}
                </div>
                <TextField
                  fullWidth
                  placeholder="Ex: 123"
                  label="Enter Extension"
                  variant="outlined"
                  type="number"
                  style={{ margin: "10px 0" }}
                  onChange={(e) => {
                    setExtension(e.target.value);
                  }}
                />
              </div>
              <Button
                className="form-button"
                type="submit"
                variant="contained"
                style={{ background: "#446FA2" }}
              >
                Add User
              </Button>
            </form>
          </div>
        </div>
      </div>
      {showAddUserDataModal && (
        <div className="add-user-data-modal-main"> {addUserDataModal()} </div>
      )}
    </>
  ) : null;
};

export default AddUser;
