import "./AppointmentCard.css";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IconButton } from "@mui/material";
import React from "react";
import demo from "Media/blank-profile-picture.png";
import { useNavigate } from "react-router-dom";

const AppointmentCard = ({ curr }) => {
  const navigate = useNavigate();
  if (
    curr?.data === undefined ||
    curr?.data === null ||
    curr?.data === "" ||
    curr?.data === []
  ) {
    curr.data = {};
  }

  return (
    <>
      <div className="appoint-card-wrapper">
        <h4>{curr?.date}</h4>

        {Object.keys(curr?.data).length !== 0
          ? curr?.data?.map((currEle, index) => {
              return (
                <div className="appoint-meet-wrapper" key={index}>
                  <div className="appoint-tp-wrapper">
                    <div className="appoint-time">
                      <div className="app-border"></div>
                      <div>
                        <div className="app-time">{currEle?.time}</div>
                        <div className="app-duration">{currEle?.duration}</div>
                      </div>
                    </div>
                    <div className="appoint-person">
                      <h4>{currEle?.title}</h4>
                      <div className="appoint-profile">
                        <img
                          src={currEle?.image ? currEle.image : demo}
                          alt="profile"
                          width="25px"
                        />
                        <span>{currEle?.name}</span>
                      </div>
                    </div>
                  </div>

                  <div className="appoint-option">
                    <IconButton
                      onClick={() => {
                        navigate("/appointment");
                      }}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })
          : null}

        {/* <div className="appoint-meet-wrapper">
          <div className="appoint-tp-wrapper">
            <div className="appoint-time">
              <div className="app-border"></div>
              <div>
                <div className="app-time">1:00 PM</div>
                <div className="app-duration">30 Mins</div>
              </div>
            </div>
            <div className="appoint-person">
              <h4>Leisux Daily Task Sync</h4>
              <div className="appoint-profile">
                <img src={profile} alt="profile" width="25px" />
                <span>Alex Smith</span>
              </div>
            </div>
          </div>

          <div className="appoint-option">
            <IconButton>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default AppointmentCard;
