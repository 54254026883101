import {
  NEW_MSG_ALERT,
  RESET_ALERT,
  SET_ALERT,
} from "Config/ActionType/action.type";

export const setAlert = (type, message) => {
  return {
    type: SET_ALERT,
    payload: {
      type,
      message,
    },
  };
};

export const resetAlert = () => {
  return {
    type: RESET_ALERT,
  };
};

export const newChatAlert = (payload) => {
  return {
    type: NEW_MSG_ALERT,
    payload: payload,
  };
};
