import "./SuperAdminTransferAndBanking.css";

import React, { useEffect, useState } from "react";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderInformationPopup from "Components/Popup/InformationPopup/PureComponents/InformationPopup";
import TransferCredits from "Components/Popup/TransferCredits/TransferCredits";
import creditHistory from "Media/icons/credit-history.png";
import dollarIcon from "Media/icons/cards-with-dollar-sign@2x.png";
import file from "Media/icons/allRequestAdmin.png";
import { getSuperAdminClientCreditBalance } from "Config/API/get";
import moneyBack from "Media/icons/moneyBack.png";
import { toast } from "react-toastify";
import { tranferCreditsToOrganisation } from "Config/API/action";
import transferCredits from "Media/icons/transfer-credits.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
const SuperAdminTransferAndBanking = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const credit = useSelector((state) => state.getStripeCreditBalanceReducer);
  const [showTransferCreditModal, setShowTransferCreditModal] = useState(false);
  const [amniusAdminCredits, setAmniusAdminCredits] = useState("");
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabsText, setTabsText] = useState("");
  useEffect(() => {
    getAmniusAdminBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattingFn = (availableCredit) => {
    const creditString = availableCredit?.toString();
    const hasDecimal = creditString?.includes(".");
    if (hasDecimal) {
      const formattedCredit =
        creditString.split(".")[1]?.length > 1
          ? availableCredit
          : `${availableCredit}0`;
      return formattedCredit;
    } else {
      return `${availableCredit}.00`;
    }
  };

  const getAmniusAdminBalance = () => {
    const credit_status = getSuperAdminClientCreditBalance(token);
    credit_status.then((res) => {
      if (res?.data?.status) {
        const availableCredit = res?.data?.data?.amniusBalance;
        const alteredValue = formattingFn(availableCredit);
        setAmniusAdminCredits(alteredValue);
      }
    });
  };

  const transferCreditsApiHandler = async (orgId, amount) => {
    if (!processing) setProcessing(true);
    try {
      const response = await tranferCreditsToOrganisation(token, orgId, amount);
      const data = await response?.data;
      if (data?.status) {
        toast(data?.message, { type: "success" });
        getAmniusAdminBalance();
        setProcessing(false);
        setTimeout(() => {
          setShowTransferCreditModal(false);
        }, 1000);
      } else {
        toast(data?.message, { type: "error" });
        setProcessing(false);
      }
    } catch (e) {
      setProcessing(false);
      toast("Failed! Try Again Later", { type: "info" });
    }
  };

  return (
    <>
      <div className="super-admin-transfer-and-banking-main">
        <div className="super-admin-transfer-and-banking-header">
          <Headers />
        </div>

        <div className="super-admin-transfer-and-banking-navigation">
          <Navigation />
        </div>

        <div className="super-admin-transfer-and-banking-wrapper">
          <div className="super-admin-transfer-and-banking-heading">
            Transfers & Banking Page
          </div>
          <div className="super-admin-transfer-and-banking-upper-tile-container">
            <div
              className="super-admin-transfer-and-banking-tile"
              style={{ height: "110px", position: "relative" }}
            >
              <img
                src={dollarIcon}
                alt="Wallet"
                style={{ width: "65px", height: "65px" }}
              />
              <span>
                <span>
                  ALL CREDITS <b> {credit ? credit : 0}</b>
                </span>
                <span>$1 USD = 1 CREDIT</span>
              </span>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("ALL CREDITS");
                }}
              />
            </div>
          </div>
          <div className="super-admin-transfer-and-banking-content">
            <div style={{ position: "relative" }}>
              <div
                className="super-admin-transfer-and-banking-tile"
                style={{ height: "155px" }}
                onClick={() => navigate("/all-transfer-out-requests")}
              >
                <img
                  src={file}
                  alt="Wallet"
                  style={{ width: "120px", height: "120px" }}
                />
                <span>All Transfer Out Requests</span>
              </div>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("All Transfer Out Requests");
                }}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                className="super-admin-transfer-and-banking-tile"
                onClick={() => navigate("/all-credit-history")}
                style={{ height: "155px" }}
              >
                <img
                  src={creditHistory}
                  alt="Wallet"
                  style={{ width: "120px", height: "120px" }}
                />
                <span>Credit History</span>
              </div>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("Credit History");
                }}
              />
            </div>
          </div>
          <div className="super-admin-transfer-and-banking-content">
            <div style={{ position: "relative" }}>
              <div
                style={{ height: "155px" }}
                className="super-admin-transfer-and-banking-tile"
                onClick={() => navigate("/all-refund-requests")}
              >
                <img
                  src={moneyBack}
                  alt="Wallet"
                  style={{ width: "120px", height: "120px" }}
                />

                <span>All Refund Requests</span>
              </div>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("All Refund Requests");
                }}
              />
            </div>
            <div style={{ position: "relative" }}>
              <div
                style={{ height: "155px" }}
                className="super-admin-transfer-and-banking-tile"
                onClick={() => setShowTransferCreditModal(true)}
              >
                <img
                  src={transferCredits}
                  alt="transfer credits"
                  style={{ width: "120px", height: "120px" }}
                />
                <span>Transfer Credits</span>
              </div>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("Transfer Credits");
                }}
              />
            </div>
          </div>
          <div
            className="super-admin-transfer-and-banking-upper-tile-container"
            style={{ marginTop: "20px" }}
          >
            <div
              className="super-admin-transfer-and-banking-tile"
              style={{ height: "110px", position: "relative" }}
            >
              <img
                src={dollarIcon}
                alt="Wallet"
                style={{ width: "65px", height: "65px" }}
              />
              <span>
                <span>
                  AMNIUS ADMIN CREDITS{" "}
                  <b> {amniusAdminCredits ? amniusAdminCredits : 0}</b>
                </span>
                <span>$1 USD = 1 CREDIT</span>
              </span>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("AMNIUS ADMIN CREDITS");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {showTransferCreditModal && (
        <TransferCredits
          credits={amniusAdminCredits}
          status={processing}
          hide={setShowTransferCreditModal}
          callback={transferCreditsApiHandler}
        />
      )}
      {infoPopup && (
        <RenderInformationPopup message={tabsText} hide={setInfoPopup} />
      )}
    </>
  );
};

export default SuperAdminTransferAndBanking;
