import Appointment from "../PureComponents/Appointment";
import React from "react";

const RenderAppointment = () => {
  return (
    <>
      <Appointment />
    </>
  );
};

export default RenderAppointment;
