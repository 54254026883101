import "./AllCreditCardDetails.css";

import React, { useEffect, useState } from "react";
import { removeCreditCard, setDefaultCard } from "Config/API/update";

import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import EditCreditCardDetails from "../EditCreditCardDetails";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaymentIcon from "@mui/icons-material/Payment";
import americanExpress from "Media/icons/americanExpress.png";
import creditCard from "Media/icons/creditCardIcon.png";
import defaultCard from "Media/icons/defaultCard.png";
import mastercard from "Media/icons/masterCard.png";
import { toast } from "react-toastify";
import visa from "Media/icons/visa.png";

const AllCreditCardDetails = ({ hide, cardDetails, getCardDetails }) => {
  const token = localStorage.getItem("token");
  const cardImage = {
    visa: visa,
    mastercard: mastercard,
    amex: americanExpress,
  };
  const [showEditCreditCardDetailsPopup, setShowEditCreditCardDetailsPopup] =
    useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!showEditCreditCardDetailsPopup) setSelectedData([]);
  }, [showEditCreditCardDetailsPopup]);

  const deleteCreditCard = (pmId) => {
    if (loading) return;
    setSelectedData([]);
    setLoading(true);
    const removeCardResponse = removeCreditCard(token, pmId);
    removeCardResponse.then(async (res) => {
      setLoading(false);
      if (res?.status) {
        await getCardDetails();
        toast(res?.message, { type: "success" });
      } else {
        toast(res?.message, { type: "error" });
      }
    });
  };

  const setDefaultCreditCard = (pmId) => {
    if (selectedData?.default === true) {
      return toast("Already a Default Card", { type: "info" });
    }
    if (loading) return;
    setSelectedData([]);
    setLoading(true);
    const setDefaultCardResponse = setDefaultCard(token, pmId);
    setDefaultCardResponse.then(async (res) => {
      setLoading(false);
      if (res?.status) {
        await getCardDetails();
        toast(res?.message, { type: "success" });
      } else {
        toast(res?.message, { type: "error" });
      }
    });
  };

  const handleMenuButtonClick = (e, index) => {
    setAnchorEl(e.currentTarget);
    setSelectedData(cardDetails[index]);
  };

  return (
    <>
      <div className="all-credit-card-details-parent">
        <div className="all-credit-card-details-main">
          <div className="all-credit-card-details-heading">
            <p>Saved Credit Card Details</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="all-credit-card-details-add-new">
            <button onClick={() => setShowEditCreditCardDetailsPopup(true)}>
              Add New
            </button>
          </div>
          <div className="all-credit-card-details-container">
            {cardDetails?.map((item, index) => {
              return (
                <div className="all-credit-card-details-content">
                  {item?.default === true && (
                    <img
                      src={defaultCard}
                      alt="defaultCard"
                      style={{
                        width: "25px",
                        height: "20px",
                        position: "absolute",
                        backgroundColor: "#E3ECFC",
                        top: "-7px",
                        left: "-9px",
                      }}
                    />
                  )}
                  <div style={{ display: "flex", gap: "10px" }}>
                    <img
                      src={cardImage[item?.card.brand] || creditCard}
                      alt="card"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <label>*****{item?.card?.last4}</label>
                      <p>
                        Valid Thru:{" "}
                        {item?.card.exp_month < 10
                          ? `0${item?.card.exp_month}`
                          : item?.card.exp_month}
                        /{item?.card.exp_year}
                      </p>
                    </div>
                  </div>
                  <IconButton onClick={(e) => handleMenuButtonClick(e, index)}>
                    <MoreVertIcon
                      style={{ color: "#4671A4" }}
                      fontSize="medium"
                    />
                  </IconButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => {
          setAnchorEl(null);
          setSelectedData([]);
        }}
        onClick={() => setAnchorEl(null)}
        className="hello"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          className="chat-screen-menuItem"
          onClick={() => setShowEditCreditCardDetailsPopup(true)}
        >
          <ModeEditIcon style={{ color: "#4671A4" }} fontSize="medium" />
          Edit
        </MenuItem>
        <Divider />
        <MenuItem
          className="chat-screen-menuItem"
          style={{ color: "#C50000" }}
          onClick={() => deleteCreditCard(selectedData?.id)}
        >
          <DeleteIcon fontSize="medium" />
          Delete
        </MenuItem>
        <Divider />
        <MenuItem
          className="chat-screen-menuItem"
          onClick={() => setDefaultCreditCard(selectedData?.id)}
        >
          <PaymentIcon style={{ color: "#4671A4" }} fontSize="medium" />
          Make Default
        </MenuItem>
      </Menu>
      {showEditCreditCardDetailsPopup && (
        <EditCreditCardDetails
          hide={setShowEditCreditCardDetailsPopup}
          cardDetails={selectedData}
          getCardDetails={getCardDetails}
        />
      )}
    </>
  );
};

export default AllCreditCardDetails;
