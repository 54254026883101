import "./CheckoutForm.css";

import { CardElement, PaymentElement } from "@stripe/react-stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import { setClientSecretKeyAction } from "Redux/action/get.action";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
    });

    if (
      error.type?.toString() === "card_error" ||
      error.type?.toString() === "validation_error"
    ) {
      // setMessage(error.message);
      toast(error.message, { type: "error" });
      dispatch(setClientSecretKeyAction(""));
      navigate("/money-credit-dashboard");
    } else {
      // setMessage("An unexpected error occured.");
      toast("An unexpected error occured.", { type: "error" });
      dispatch(setClientSecretKeyAction(""));
      navigate("/money-credit-dashboard");
    }

    // setBalanceApiRun(true);
    setIsProcessing(false);
  };

  return (
    <form id="stripe-payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        disabled={isProcessing || !stripe || !elements}
        id="submit"
        style={{ backgroundColor: "rgb(92, 161, 39)" }}
      >
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message">{message}</div>} */}
    </form>
  );
}
