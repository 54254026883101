import "./FilePreviewPopup.css";

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const FilePreviewPopup = ({ path, hide, fileType }) => {
  return (
    <>
      <div className="filePreview-Popup-parent">
        <div className="filePreview-Popup-body">
          <div className="filePreview-Popup-close">
            <div onClick={() => hide(false)}>X</div>
          </div>
          {fileType === "image" ? (
            <img src={path} alt="Media" className="image-file" />
          ) : (
            <video className="video-file" controls>
              <source src={path} type="video/mp4" />
              <source src={path} type="video/mkv" />
              <source src={path} type="video/webm" />
            </video>
          )}
        </div>
      </div>
    </>
  );
};

export default FilePreviewPopup;
