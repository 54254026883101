import "./Trash.css";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import React from "react";
import RenderTrashCard from "Components/Cards/TrashCard/Container/RenderTrashCard";

const Trash = () => {
  return (
    <>
      <div className="trash-container">
        <div className="trash-header">
          <Headers />
        </div>
        <div className="trash-navigation">
          <Navigation />
        </div>
        <div className="trash-main">
          <div className="trash-wrapper">
            <div className="trash-lower">
              <RenderTrashCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trash;
