import { Card, CardContent } from "@mui/material";

import React from "react";
import { connect } from "react-redux";
import { resetDialog } from "Redux/action/dialog.action";

const VerificationDialog = (props) => {
  const { type, message } = props.dialog;
  setTimeout(() => {
    props.dispatch(resetDialog());
  }, 3000);
  return (
    <>
      {/* CREATED OPENDIALOG SO THAT WE CAN CLOSE POPUP AFTER FEW SECONDS */}

      {type ? (
        <div
          className="verification-container"
          style={{
            background: "rgb(255 254 254 / 80%)",
            position: "absolute",
            top: "0",
            width: "100%",
            height: "100%",
          }}
        >
          <CardContent
            style={{
              position: "fixed",
              bottom: "0px",
              width: "fit-content",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          >
            <Card
              variant="outlined"
              style={{
                padding: "10px",
              }}
            >
              <div
                className="verification-msg"
                style={{
                  width: "100%",
                  font: "var(--montserrat-medium)",
                  color: "#446FA2",
                  fontWeight: "bold",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                {message}
              </div>
            </Card>
          </CardContent>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dialog: state.dialogReducer,
  };
};

export default connect(mapStateToProps)(VerificationDialog);
