import "./ShareFolderPopup.css";

import React, { useState } from "react";
import { folderData, getInternalSharedFolder } from "Config/API/get";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import bFolder from "Media/icons/Folder-Close.png";
import { storeFolderDataAction } from "Redux/action/get.action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

/* eslint-disable array-callback-return */

const ShareFolderPopup = ({ hide, selectedFolderId, token }) => {
  const dispatch = useDispatch();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const allConnectedFolders = useSelector(
    (state) => state.getFolderDataReducer
  );

  const [selectedFolder, setSelectedFolder] = useState([]);
  const [selectedFolderData, setSelectedFolderData] =
    useState(allConnectedFolders);
  const bookmarkData = selectedFolder?.bookmark?.split(" > ");

  if (
    bookmarkData &&
    bookmarkData[bookmarkData?.length - 1]?.toString() === ""
  ) {
    bookmarkData?.pop();
  }

  const handleBookMarkClick = (array, index, bookmarkName) => {
    array?.map((item) => {
      if (item?.index === index && item?.name === bookmarkName) {
        setSelectedFolder(item);
        setSelectedFolderData(item?.items);
      } else {
        handleBookMarkClick(item?.items, index, bookmarkName);
      }
    });
  };

  const handleMoveHereClick = () => {
    setLoader();
    const share_status = getInternalSharedFolder(
      token,
      selectedFolder?.conn_id,
      selectedFolderId,
      selectedFolder?.unique_id
    );
    share_status
      .then((res) => {
        if (res?.status) {
          const allFolderApiResponse = folderData(token);
          allFolderApiResponse.then((ress) => {
            resetLoader();
            toast(res?.message, { type: "success" });
            hide(false);
            dispatch(storeFolderDataAction(ress?.result));
          });
        } else {
          resetLoader();
          toast(res?.message, { type: "error" });
        }
      })
      .catch((e) => {
        resetLoader();
        toast("Failed! Try again later!", { type: "error" });
      });
  };

  return (
    <>
      <div className="share-folder-parent">
        <div className="share-folder-main">
          <div className="share-folder-heading">
            <p>Select Location</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="share-folder-bookmarkbar">
            <button
              onClick={() => {
                if (bookmarkData?.length > 0) {
                  setSelectedFolder([]);
                  setSelectedFolderData(allConnectedFolders);
                }
              }}
            >
              <span>{"All Organization >"} </span>
            </button>
            {bookmarkData ? (
              <>
                {bookmarkData?.map((curr, index) => {
                  return (
                    <button
                      onClick={() => {
                        if (index !== bookmarkData?.length - 1) {
                          const selectedOrg = allConnectedFolders?.filter(
                            (item) => item?.conn_id === selectedFolder?.conn_id
                          );
                          handleBookMarkClick(selectedOrg, ++index, curr);
                        }
                      }}
                    >
                      <span key={index}> {curr + " >"}</span>
                    </button>
                  );
                })}
              </>
            ) : null}
          </div>
          <div className="share-folder-wrapper">
            {selectedFolderData
              ?.filter((item) => item?.type === "folder")
              ?.map((item) => (
                <button
                  onClick={() => {
                    setSelectedFolder(item);
                    if (Object.entries(item?.items)?.length !== 0) {
                      setSelectedFolderData(item?.items);
                    } else {
                      setSelectedFolderData([]);
                    }
                  }}
                >
                  <img src={bFolder} alt="Folder" />
                  <div className="directory-folder-single-aliasname">
                    <span>{item?.name}</span>
                    <span>{item?.alias_name ? item?.alias_name : null}</span>
                  </div>
                </button>
              ))}
          </div>
        </div>
        <div className="share-folder-button-container">
          {bookmarkData?.length > 0 && (
            <Button
              variant="contained"
              style={{ background: "#5ca127", whiteSpace: "nowrap" }}
              onClick={() => handleMoveHereClick()}
            >
              Share Here
            </Button>
          )}
        </div>
      </div>
      {loader}
    </>
  );
};

export default ShareFolderPopup;
