import "./Completion.css";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { balanceTranscationApi } from "Config/API/action";
import { setClientSecretKeyAction } from "Redux/action/get.action";
import { useNavigate } from "react-router-dom";

function Completion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(3);
  const meta_data = useSelector((state) => state.setClientSecretKeyReducer);
  const token = localStorage.getItem("token");

  setTimeout(() => {
    setSeconds((prev) => prev - 1);
  }, 1000);

  useEffect(() => {
    // run balance api
    const balance_status = balanceTranscationApi(token, meta_data);
    balance_status.then((res) => {});
  }, [token]);

  useEffect(() => {
    if (seconds === 0) {
      dispatch(setClientSecretKeyAction(""));
      navigate("/dashboard");
    }
  }, [seconds]);

  return (
    <div className="completion-main">
      <h1>Thank you! 🎉</h1>
      <h4>Navigating to Dashboard in {seconds} seconds</h4>
    </div>
  );
}

export default Completion;
