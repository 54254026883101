import "./Confirm.css";

import { Button } from "@mui/material";
import React from "react";

const Confirm = ({ message, data, status, hide }) => {
  return (
    <>
      <nav className="popup-container-delete">
        <div className="popup-popup confirm-box">
          <div
            className="popup-cross confirm-cross"
            onClick={() => hide(false)}
          >
            X
          </div>
          <span>Are you sure you want to {message}</span>
          <div className="popup-option-button">
            <Button
              variant="contained"
              style={{ background: "#446fa2" }}
              onClick={() => {
                status(data);
                hide(false);
              }}
            >
              CONFIRM
            </Button>
            <Button
              variant="outlined"
              style={{ color: "#446fa2", borderColor: "#446fa2" }}
              onClick={() => {
                hide(false);
              }}
            >
              CANCEL
            </Button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Confirm;
