import Confirm from "../PureComponents/Confirm";
import React from "react";

const RenderConfirm = ({ message, data, status, hide }) => {
  return (
    <>
      <Confirm message={message} data={data} status={status} hide={hide} />
    </>
  );
};

export default RenderConfirm;
