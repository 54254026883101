import "./AllTransferOutRequest.css";

import React, { useEffect, useState } from "react";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import { getAllTransferOutRequests } from "Config/API/get";
import useFullPageLoader from "Hooks/useFullPageLoader";

const AllTransferOutRequest = () => {
  let doneTypingInterval = 1500;
  let typingTimer;
  const token = localStorage.getItem("token");
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const [requestList, setRequestList] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [filterType, setFilterType] = useState("");
  const statusColor = {
    pending: "#E8B81A",
    approved: "#5CA127",
    rejected: "#CB1212",
  };

  useEffect(() => {
    setLoader();
    getData(filterType, searchedText);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (filter, searchKeywords) => {
    const requestResponse = getAllTransferOutRequests(
      token,
      searchKeywords,
      filter
    );
    requestResponse.then((res) => {
      resetLoader();
      if (res?.status === 200) {
        setRequestList(res?.data?.data);
      }
    });
  };

  const searchData = (value) => {
    setSearchedText(value);
    getData(filterType, value);
  };

  const filterData = (value) => {
    if (value === "all") {
      setFilterType("");
      getData("", searchedText);
      return;
    } else {
      setFilterType(value);
      getData(value, searchedText);
    }
  };

  return (
    <>
      <div className="all-transfer-out-request-parent">
        <div className="all-transfer-out-request-header">
          <Headers />
        </div>

        <div className="all-transfer-out-request-navigation">
          <Navigation />
        </div>

        <div className="all-transfer-out-request-main">
          <div className="all-transfer-out-request-wrapper">
            <div className="all-transfer-out-request-heading">
              Transfer Out Request History
            </div>
            <div className="all-transfer-out-request-all-active">
              <div className="all-transfer-out-request-top">
                <input
                  type="search"
                  placeholder="Search"
                  onChange={(e) => {
                    clearTimeout(typingTimer);
                    if (e.target.value || e.target.value === "") {
                      typingTimer = setTimeout(() => {
                        searchData(e.target.value);
                      }, doneTypingInterval);
                    }
                  }}
                />
                <div>
                  <p>Filter:</p>
                  <select
                    onClick={(e) => {
                      filterData(e.target.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="pending">PENDING</option>
                    <option value="approved">APPROVED</option>
                    <option value="rejected">REJECTED</option>
                  </select>
                </div>
              </div>
              <div className="all-transfer-out-request-bottom">
                <div className="all-transfer-out-request-row-wrapper">
                  <div className="all-transfer-out-request-head-row">DATE</div>
                  <div className="all-transfer-out-request-head-row">
                    REQUEST ID
                  </div>
                  <div
                    className="all-transfer-out-request-head-row"
                    style={{ width: "40.2%" }}
                  >
                    COMMENT
                  </div>
                  <div className="all-transfer-out-request-head-row">
                    CREDITS
                  </div>
                  <div className="all-transfer-out-request-head-row">
                    STATUS
                  </div>
                </div>
                <div className="all-transfer-out-request-bottom-data">
                  {requestList?.length?.toString() !== "0" ? (
                    requestList?.map((curr, index) => {
                      return (
                        <div
                          className="all-transfer-out-request-content-wrapper"
                          key={index}
                        >
                          <div className="all-transfer-out-request-content-row">
                            {curr?.dateTime}
                          </div>
                          <div className="all-transfer-out-request-content-row">
                            {curr?.transferUid}
                          </div>
                          <div
                            className="all-transfer-out-request-content-row"
                            style={{ width: "40.2%" }}
                          >
                            {curr?.comment}
                          </div>
                          <div
                            className="all-transfer-out-request-content-row"
                            style={{ color: "#5CA127", fontWeight: "bold" }}
                          >
                            {curr?.credit}
                          </div>
                          <div className="all-transfer-out-request-content-row">
                            <label
                              style={{
                                backgroundColor: statusColor[curr?.status],
                              }}
                            >
                              {curr?.status}
                            </label>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="all-transfer-out-request-msg">
                      No Requests Found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader}
    </>
  );
};

export default AllTransferOutRequest;
