import axios from 'axios'
import store from 'store'

const domainIdentifier = window.location.href

var httpRequest = ''
var dynamicUrl = ''

// we are updating url path by identifying the domain
// for production site
if (domainIdentifier.includes('https://www.amniusconnect.com')) {
  httpRequest = axios.create({
    baseURL: 'https://www.amniusconnect.com/amnius-connect/api/'
  })
  dynamicUrl = 'https://www.amniusconnect.com/amnius-connect/api/'
}

// for pre production site
else if (domainIdentifier.includes('https://testing.amniusconnect.com')) {
  httpRequest = axios.create({
    baseURL: 'https://testing.amniusconnect.com/amnius-connect/api/'
  })
  dynamicUrl = 'https://testing.amniusconnect.com/amnius-connect/api/'
}

// for staging/local site
else if (domainIdentifier.includes('https://amnius-connect.ebizonstaging.com') || domainIdentifier.includes('http://localhost:3000')) {
  httpRequest = axios.create({
    baseURL: 'https://amnius-connect.ebizonstaging.com/amnius-connect/api/'
  })
  dynamicUrl = 'https://amnius-connect.ebizonstaging.com/amnius-connect/api/'
}

export { dynamicUrl }

// token validations over api
// request work before the axios hit the api
// axios.interceptors.request.use((request) => {
//   return request;
// });

httpRequest.interceptors.request.use((config) => {
  const appState = store.getState()
  const accessToken = appState?.authenticationReducer?.token
  if (accessToken) {
    config.headers['token'] = accessToken
  }
  return config
})

// response work before ".then" of response after api call
httpRequest.interceptors.response?.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 400 || error.response?.status === 401 || error.response?.status === 403) {
      window.location.href = '/login'
    }
    return error
  }
)

export default httpRequest
