export const maxFileLength = (
  baseSize = 1000,
  charSize = 0,
  defaultSize = 0
) => {
  return baseSize - charSize?.length - defaultSize + "/" + baseSize;
};

export const textAreaLength = (baseSize = 1000, defaultSize = 0) => {
  return baseSize - defaultSize;
};
