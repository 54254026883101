import { LOGIN_STATUS } from "Config/ActionType/action.type";

const initial = "";

export const authenticationReducer = (state = initial, action) => {
  switch (action?.type) {
    case LOGIN_STATUS:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    default:
      return state;
  }
};
