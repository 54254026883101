import React, { useState } from "react";
import {
  selectCameraStreamByPeerID,
  selectIsPeerAudioEnabled,
  selectIsPeerVideoEnabled,
  selectLocalPeer,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";

import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

const ScreenShareVideoPeers = ({ peer, mirrorVideo }) => {
  const hmsActions = useHMSActions();
  const videoRef = React.useRef(null);
  const videoTrack = useHMSStore(selectCameraStreamByPeerID(peer.id));
  const audioOn = useHMSStore(selectIsPeerAudioEnabled(peer.id));
  const videoOn = useHMSStore(selectIsPeerVideoEnabled(peer.id));
  const localPeer = useHMSStore(selectLocalPeer);
  React.useEffect(() => {
    (async () => {
      if (videoRef.current && videoTrack) {
        if (videoTrack.enabled) {
          await hmsActions.attachVideo(videoTrack.id, videoRef.current);
        } else {
          await hmsActions.detachVideo(videoTrack.id, videoRef.current);
        }
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack]);

  const changeHost = (item) => {
    hmsActions.changeRoleOfPeer(localPeer?.id, "participant", true);
    hmsActions.changeRoleOfPeer(item?.id, "host", true);
  };

  return (
    <div className="peers-container-screen-sharing">
      <video
        ref={videoRef}
        autoPlay={true}
        playsInline
        muted={true}
        className={
          peer?.isLocal && !mirrorVideo
            ? "peer-video-screen-sharing-mirror"
            : "peer-video-screen-sharing"
        }
      ></video>
      {localPeer.roleName?.toString() === "host" &&
        peer.roleName?.toString() !== "host" && (
          <button
            className="peer-host-button-screen-sharing"
            onClick={() => changeHost(peer)}
          >
            Make Host
          </button>
        )}
      <div className="peer-name-screen-sharing">
        {peer.name}
        {peer.isLocal
          ? " (You)"
          : peer?.roleName?.toString() === "host"
          ? " (Host)"
          : ""}
      </div>
      <div className="peer-audio-video-status">
        {videoOn ? <VideocamIcon /> : <VideocamOffIcon />}
        {audioOn ? <MicIcon /> : <MicOffIcon />}
      </div>
    </div>
  );
};

export default ScreenShareVideoPeers;
