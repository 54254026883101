import "./ResetPassword.css";

import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";

import LockIcon from "@mui/icons-material/Lock";
import PasswordIcon from "@mui/icons-material/Password";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";

const ResetPassword = ({ changePass }) => {
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [visibility, setVisibility] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password?.toString() === verifyPassword?.toString()) {
      changePass(password, verifyPassword);
    } else {
      toast("Password Mismatched!", { type: "error" });
    }
  };

  return (
    <>
      <div className="reset-container">
        <div className="reset-wrapper">
          <h3>Reset your password</h3>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="reset-password">
              <TextField
                label="Create password"
                variant="outlined"
                fullWidth
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="reset-verify">
              <TextField
                label="Verify password"
                variant="outlined"
                fullWidth
                type={visibility ? "text" : "password"}
                onChange={(e) => {
                  setVerifyPassword(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div
                        onClick={() => {
                          setVisibility(!visibility);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {visibility ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="reset-submit">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  background: "#446fa2",
                }}
              >
                SUBMIT
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
