import React, { useState } from "react";

import Folder from "../PureComponents/Folder";
import { useSelector } from "react-redux";

const RenderFolder = () => {
  let files = useSelector((state) => state.getFolderDataReducer);
  if (
    files === undefined ||
    files === null ||
    files === "" ||
    files?.length === 0
  ) {
    files = {};
  }
  const [currentFolder, setCurrentFolder] = useState(false);

  return (
    <>
      {Object.keys(files).length?.toString() !== "0" ? (
        files?.map((curr, index) => (
          <Folder
            key={index}
            files={curr}
            parentData={files}
            currentFolder={currentFolder}
            setCurrentFolder={setCurrentFolder}
          />
        ))
      ) : (
        <>
          <p>Currently No Data Found</p>
        </>
      )}
    </>
  );
};

export default RenderFolder;
