import "./InputCard.css";

import React, { useEffect, useState } from "react";

import MentionCard from "../MentionCard/MentionCard";
import PaperClip from "Media/icons/Icon open-paperclip.svg";
import SendIcon from "Media/icons/Iconly-Bold-Send.svg";
import { getManagerList } from "Config/API/get";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const InputCard = ({
  onSend,
  message_id,
  chatType,
  setLeftPopup,
  setRightPopup,
  onMediaSend,
  type,
}) => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  let [message, setMessage] = useState("");
  const [media, setMedia] = useState("");
  const [managerList, setManagerList] = useState("");
  const resetMessage = () => {
    setMessage("");
  };

  const resetMedia = () => {
    setMedia("");
  };

  // get users list for tag functionality
  useEffect(() => {
    const user_list_status = getManagerList(token, "", chatType, type);
    user_list_status.then((res) => {
      setManagerList(res?.data?.userDetail);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatType]);

  return (
    <>
      <div className="chat-screen-inputsection">
        <div className="chat-screen-input">
          {media ? (
            <div className="preview-image">
              <div className="image-cancel" onClick={() => resetMedia()}>
                X
              </div>
              <img src={URL.createObjectURL(media)} alt="media" />
            </div>
          ) : (
            <>
              <MentionCard
                users={managerList}
                message={message}
                setMessage={setMessage}
              />

              <label htmlFor="image">
                <img src={PaperClip} alt="Attachment" />
                <input
                  type="file"
                  id="image"
                  hidden
                  accept="image/*"
                  onChange={(e) => {
                    const size = e.target.files[0]?.size;
                    if (size > 20000000) {
                      toast("File size excceded 10MB", { type: "info" });
                    } else {
                      setMedia(e.target.files[0]);
                    }
                  }}
                />
              </label>
            </>
          )}
        </div>
        <button
          className="chat-screen-send"
          onClick={() => {
            if (message) {
              // nested chat
              if (message_id) {
                onSend(message, message_id);

                // for closing inline input fields
                setLeftPopup(false);
                setRightPopup(false);
              } else {
                onSend(message);
              }

              resetMessage();
            }
            if (media) {
              onMediaSend(media);
              resetMedia();
            }
          }}
        >
          <img src={SendIcon} alt="Send" />
        </button>
      </div>
    </>
  );
};

export default InputCard;
