import ChatIcon from "@mui/icons-material/Chat";
import EmailIcon from "@mui/icons-material/Email";
import { IconButton } from "@mui/material";
import { activeInactiveEmployee } from "Config/API/action";
import blank from "Media/blank-profile-picture.png";
import { toast } from "react-toastify";

const TrashUsersCard = ({ userData, getTrashDataHandler }) => {
  const token = localStorage.getItem("token");
  const datetime = new Date(userData?.last_activity);
  const date = datetime.toLocaleDateString("en-US");
  const time = datetime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const reactivateTrashUser = () => {
    const response = activeInactiveEmployee("active", userData?.id, token);
    response
      .then((result) => {
        if (result?.status) {
          toast("User unarchived successfully", { type: "success" });
          getTrashDataHandler();
        } else {
          toast(result?.message, { type: "error" });
        }
      })
      .catch((e) => {
        toast("Failed! Try again later", { type: "error" });
      });
  };

  return (
    <div className="render-trash-card-archived-users">
      <div className="render-trash-card-container">
        <div className="render-trash-card-options">
          <div className="render-trash-card-options-actions">
            <button onClick={() => reactivateTrashUser()}>REACTIVATE</button>
          </div>
          <div className="card-board">
            <div className="card-logo">
              <img src={blank} alt="profile" width="55px" height="55px" />
            </div>
            <div className="card-text">
              <h3>{userData?.username}</h3>
              <p style={{ opacity: 0.55 }}>
                last seen {time} {date.split("/").join("-")}
              </p>
              <p>{userData?.designation}</p>
              <p>{userData?.email}</p>
              <p>{userData?.phone}</p>
            </div>
            <div className="card-icons">
              <IconButton style={{ opacity: 0.5 }} disabled>
                <ChatIcon className="chat-icon" />
              </IconButton>
              <IconButton style={{ opacity: 0.5 }} disabled>
                <EmailIcon className="email-icon" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrashUsersCard;
