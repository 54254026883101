import "./ManageFolderPopup.css";

/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { folderData, getInternalFolder } from "Config/API/get";
import {
  internalFolderDataAction,
  selectedFileDataAction,
  selectedFolderDataAction,
  selectedFolderRootDataAction,
  storeFolderDataAction,
} from "Redux/action/get.action";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@mui/material";
import bFolder from "Media/icons/Folder-Close.png";
import { moveFolder } from "Config/API/action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

const ManageFolderPopup = ({ hide, selectedFolderId, token }) => {
  const dispatch = useDispatch();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const addFolderData = useSelector(
    (state) => state.selectedFolderRootDataReducer
  );
  const internalFolder = useSelector(
    (state) => state.internalFolderDataReducer
  );

  const [selectedFolder, setSelectedFolder] = useState(
    internalFolder === "" ? addFolderData : addFolderData[0]
  );
  const moveToFolderId = selectedFolder?.unique_id;
  const bookmark_array = selectedFolder?.bookmark?.split(" > ");

  if (
    bookmark_array &&
    bookmark_array[bookmark_array?.length - 1]?.toString() === ""
  ) {
    bookmark_array?.pop();
  }

  const handleBookMarkClick = (array, index, bookmarkName) => {
    array?.map((item) => {
      if (item?.index === index && item?.name === bookmarkName)
        setSelectedFolder(item);
      else handleBookMarkClick(item?.items, index, bookmarkName);
    });
  };

  const handleRootBookMarkClick = (index, bookmarkName) => {
    const folderData = internalFolder === "" ? addFolderData : addFolderData[0];
    if (folderData?.index === index)
      return setSelectedFolder(
        internalFolder === "" ? addFolderData : addFolderData[0]
      );
    handleBookMarkClick(folderData?.items, index, bookmarkName);
  };

  const onInternalFolderMoveSuccess = (array) => {
    array.map((item) => {
      if (item?.unique_id === moveToFolderId) {
        dispatch(selectedFolderDataAction(item));
        dispatch(internalFolderDataAction(item?.items));
      } else onInternalFolderMoveSuccess(item?.items);
    });
  };

  const onConnectedFolderMoveSuccess = (array) => {
    array.map((item) => {
      if (item?.unique_id === moveToFolderId) {
        dispatch(selectedFileDataAction(item?.items));
        dispatch(selectedFolderDataAction(item));
      } else onConnectedFolderMoveSuccess(item?.items);
    });
  };

  const handleMoveHereClick = () => {
    setLoader();
    const response = moveFolder(
      token,
      internalFolder === "" ? addFolderData?.conn_id : "",
      selectedFolderId,
      moveToFolderId
    );
    response.then((res) => {
      if (res?.status) {
        if (internalFolder === "") {
          const allFolderApiResponse = folderData(token);
          allFolderApiResponse.then((res) => {
            resetLoader();
            toast("Successfully moved", { type: "success" });
            hide(false);
            onConnectedFolderMoveSuccess(res?.result);
            dispatch(storeFolderDataAction(res?.result));
          });
        } else {
          const internalFolderApiResponse = getInternalFolder(token);
          internalFolderApiResponse.then((res) => {
            resetLoader();
            toast("Successfully moved", { type: "success" });
            hide(false);
            onInternalFolderMoveSuccess(res?.result[0]?.items);
            dispatch(selectedFolderRootDataAction(res?.result));
          });
        }
      } else {
        resetLoader();
        toast(res?.message, { type: "error" });
      }
    });
  };

  return (
    <>
      <div className="move-folder-parent">
        <div className="move-folder-main">
          <div className="move-folder-heading">
            <p>Move Folder</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="move-folder-bookmarkbar">
            {bookmark_array ? (
              <>
                {bookmark_array?.map((curr, index) => {
                  return (
                    <button
                      onClick={() => handleRootBookMarkClick(++index, curr)}
                    >
                      <span key={index}>{curr + " > "}</span>
                    </button>
                  );
                })}
              </>
            ) : null}
          </div>
          <div className="move-folder-wrapper">
            {selectedFolder?.items
              ?.filter(
                (item) =>
                  item?.type === "folder" &&
                  item?.unique_id !== selectedFolderId
              )
              ?.map((item) => (
                <button onClick={() => setSelectedFolder(item)}>
                  <img src={bFolder} alt="Folder" />
                  <div className="directory-folder-single-aliasname">
                    <span>{item?.name}</span>
                    <span>{item?.alias_name ? item?.alias_name : null}</span>
                  </div>
                </button>
              ))}
          </div>
        </div>
        <div className="move-folder-button-container">
          <Button
            variant="contained"
            style={{ background: "#5ca127", whiteSpace: "nowrap" }}
            onClick={() => handleMoveHereClick()}
          >
            Move Here
          </Button>
        </div>
      </div>
      {loader}
    </>
  );
};

export default ManageFolderPopup;
