import "./AppointmentOverview.css";

import AppointmentCard from "Components/Cards/AppointmentCard/AppointmentCard";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AppointmentOverview = () => {
  let appointment_data = useSelector(
    (state) => state.appointmentDashboardReducer
  );

  const navigate = useNavigate();

  if (
    appointment_data === undefined ||
    appointment_data === null ||
    appointment_data === "" ||
    appointment_data?.length === 0
  ) {
    appointment_data = {};
  }

  return (
    <>
      <div className="appoint-wrapper">
        <div className="appoint-upper appoint-flex">
          <div className="appoint-head">Upcoming Appointments</div>
          <div className="appoint-view_all">
            <Button
              variant="contained"
              style={{ background: "#5ca127", whiteSpace: "nowrap" }}
              onClick={() => {
                navigate("/appointment");
              }}
            >
              VIEW ALL
            </Button>
          </div>
        </div>
        <div className="appoint-lower">
          {Object.keys(appointment_data).length?.toString() !== "0" ? (
            appointment_data?.map((curr, index) => {
              return <AppointmentCard key={index} curr={curr} />;
            })
          ) : (
            <div className="appointment-message">No Upcoming Appointments</div>
          )}
        </div>
      </div>
    </>
  );
};

export default AppointmentOverview;
