import "./EditCreditCardDetails.css";

import React, { useState } from "react";

import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { toast } from "react-toastify";
import { updateCreditCardDetails } from "Config/API/update";

const EditCreditCardDetails = ({ hide, cardDetails, getCardDetails }) => {
  const token = localStorage.getItem("token");

  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState(
    (cardDetails?.card?.exp_month < 10
      ? `0${cardDetails?.card?.exp_month?.toString()}`
      : cardDetails?.card?.exp_month?.toString()) || ""
  );
  const [expiryYear, setExpiryYear] = useState(
    cardDetails?.card?.exp_year || ""
  );
  const [cvc, setCvc] = useState("");
  const [zipCode, setZipCode] = useState(
    +cardDetails?.billing_details?.address?.postal_code || ""
  );

  const [loading, setLoading] = useState(false);
  const monthArray = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const presentYear = new Date().getFullYear();
  const yearsArray = Array.from(
    new Array(50),
    (val, index) => index + presentYear
  );
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 215,
        width: 150,
      },
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    const date = new Date();
    const presentMonth = date.getMonth();
    const presentYear = date.getFullYear();
    if (presentYear === expiryYear && +expiryMonth < presentMonth + 1)
      return toast("Card is Expired", { type: "error" });
    setLoading(true);

    const updateCardDetailsResponse = updateCreditCardDetails(
      token,
      expiryMonth,
      expiryYear,
      creditCardNumber,
      cvc,
      zipCode,
      cardDetails?.id ? cardDetails?.id : ""
    );

    updateCardDetailsResponse.then((res) => {
      setLoading(false);
      if (res?.status) {
        getCardDetails();
        toast(res?.message, { type: "success" });
        hide(false);
      } else {
        toast(res?.message, { type: "error" });
      }
    });
  };

  const handleCardNumberChange = (value) => {
    if (value?.length > 16) return;
    const formattedValue = value?.replace(/\D/g, "");
    setCreditCardNumber(formattedValue);
  };

  const handleCvcChange = (value) => {
    if (value?.length > 4) return;
    const formattedValue = value?.replace(/\D/g, "");
    setCvc(formattedValue);
  };

  const handleZipChange = (value) => {
    if (value?.length > 6) return;
    const formattedValue = value?.replace(/\D/g, "");
    setZipCode(formattedValue);
  };

  return (
    <>
      <div className="edit-credit-card-details-parent">
        <div className="edit-credit-card-details-main">
          <form
            className="edit-credit-card-details-form-body"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="edit-credit-card-details-heading">
              <p>Edit Credit Card Details</p>
              <div className="rename-cross" onClick={() => hide(false)}>
                X
              </div>
            </div>
            <div className="edit-credit-card-details-wrapper">
              <div className="edit-credit-card-details-fields">
                <label htmlFor="creditCardNumber">
                  Credit Card No<span>*</span>:
                </label>
                {cardDetails?.card?.last4 ? (
                  <input
                    type="text"
                    id="creditCardNumber"
                    placeholder={`************${cardDetails?.card?.last4}`}
                    required
                    readOnly
                    style={{ backgroundColor: "#f1f2f6" }}
                  />
                ) : (
                  <input
                    type="text"
                    value={creditCardNumber}
                    id="creditCardNumber"
                    placeholder="Enter Credit Card Number"
                    onChange={(e) =>
                      handleCardNumberChange(e.target.value.trim())
                    }
                    required
                    minLength={14}
                  />
                )}
              </div>

              <div className="edit-credit-card-details-middle-fields">
                <div className="cvc-Container">
                  <label htmlFor="cvc">
                    Cvc No<span>*</span>:
                  </label>
                  <input
                    type="text"
                    value={cvc}
                    id="cvc"
                    placeholder="Cvc No"
                    onChange={(e) => handleCvcChange(e.target.value.trim())}
                    required
                    minLength={3}
                  />
                </div>
                <div className="zip-Container">
                  <label htmlFor="zipCode">
                    Zip Code<span>*</span>:
                  </label>
                  <input
                    type="text"
                    value={zipCode}
                    id="cvc"
                    placeholder="Zip Code"
                    onChange={(e) => handleZipChange(e.target.value.trim())}
                    required
                    minLength={5}
                  />
                </div>
              </div>

              <div className="edit-credit-card-details-fields">
                <label htmlFor="validThru">
                  Valid Thru<span>*</span>:
                </label>
                <div style={{ display: "flex", width: "100%", gap: "15px" }}>
                  <Select
                    required
                    displayEmpty
                    style={{ width: "110px", height: "33px" }}
                    value={expiryMonth}
                    placeholder="Month"
                    onChange={(e) => setExpiryMonth(e.target.value)}
                    MenuProps={MenuProps}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Month</em>
                    </MenuItem>
                    {monthArray.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select
                    required
                    displayEmpty
                    style={{ width: "110px", height: "33px" }}
                    value={expiryYear}
                    placeholder="Year"
                    onChange={(e) => setExpiryYear(e.target.value)}
                    MenuProps={MenuProps}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Year</em>
                    </MenuItem>
                    {yearsArray.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="edit-credit-card-details-fields">
                <button type="submit">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditCreditCardDetails;
