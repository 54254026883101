import * as THREE from 'three'

import occtimportjs from 'occt-import-js'

const wasmUrl = 'https://cdn.jsdelivr.net/npm/occt-import-js@0.0.12/dist/occt-import-js.wasm'

export async function LoadStep(fileUrl) {
  const targetObject = new THREE.Object3D()

  const occt = await occtimportjs({
    locateFile: (name) => {
      return wasmUrl
    }
  })

  let response = await fetch(fileUrl)
  let buffer = await response.arrayBuffer()

  let fileBuffer = new Uint8Array(buffer)
  let result = occt.ReadStepFile(fileBuffer)

  for (let resultMesh of result.meshes) {
    let geometry = new THREE.BufferGeometry()

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(resultMesh.attributes.position.array, 3))
    if (resultMesh.attributes.normal) {
      geometry.setAttribute('normal', new THREE.Float32BufferAttribute(resultMesh.attributes.normal.array, 3))
    }
    const index = Uint16Array.from(resultMesh.index.array)
    geometry.setIndex(new THREE.BufferAttribute(index, 1))

    let material = null
    if (resultMesh.color) {
      const color = new THREE.Color(resultMesh.color[0], resultMesh.color[1], resultMesh.color[2])
      material = new THREE.MeshPhongMaterial({ color: color })
    } else {
      material = new THREE.MeshPhongMaterial({ color: 0xcccccc })
    }

    const mesh = new THREE.Mesh(geometry, material)
    targetObject.add(mesh)
  }
  return targetObject
}
