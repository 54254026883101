import "./RequestTypePopup.css";

import { Button, Radio } from "@mui/material";
import { React, useState } from "react";

import { useSelector } from "react-redux";

const RequestTypePopup = ({ message, option1, option2, funct, hide }) => {
  const loggedInUser = useSelector((state) => state.authenticationReducer);
  const [requestType, setRequestType] = useState("");
  const [disabled, setDisabled] = useState(true);
  return (
    <>
      <nav className="requestType-popup-container">
        <div className="requestType-popup-popup">
          <div
            className="requestType-popup-cross confirm-cross"
            onClick={() => hide(false)}
          >
            X
          </div>
          <span style={{ fontWeight: "bold", marginBottom: "10px" }}>
            {message}
          </span>
          <div className="requestType-popup-option-button">
            <Button
              variant="contained"
              className={
                loggedInUser?.ticket_external_role !== "manager"
                  ? "external-rights"
                  : requestType === "External" && "requestType-active"
              }
              style={{
                color: "gray",
                background: "White",
                display: "flex",
                alignItems: "center",
                fontWeight: "400",
                width: "45%",
              }}
              onClick={() => {
                if (loggedInUser?.ticket_external_role === "manager") {
                  setDisabled(false);
                  setRequestType(option1);
                }
              }}
            >
              <Radio
                color={requestType === "External" ? "success" : "default"}
                checked={requestType === "External"}
              />
              {option1}
            </Button>
            <Button
              variant="contained"
              className={
                loggedInUser?.ticket_internal_role !== "manager"
                  ? "external-rights"
                  : requestType === "Internal" && "requestType-active"
              }
              style={{
                color: "gray",
                background: "white",
                display: "flex",
                alignItems: "center",
                fontWeight: "400",
                width: "45%",
              }}
              onClick={() => {
                if (loggedInUser?.ticket_internal_role === "manager") {
                  setDisabled(false);
                  setRequestType(option2);
                }
              }}
            >
              <Radio
                color={requestType === "Internal" ? "success" : "default"}
                checked={requestType === "Internal"}
              />
              {option2}
            </Button>
          </div>
          <div className="submit-btn">
            <Button
              variant="contained"
              className={disabled ? "buttonDisable" : "buttonActive"}
              style={{
                width: "45%",
                padding: "10px",
              }}
              onClick={() => {
                funct(requestType);
              }}
              disabled={disabled}
            >
              Submit
            </Button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default RequestTypePopup;
