export const timezone_json = {
  BD: [{ tzid: "Asia/Dhaka", name: "Bangladesh" }],
  BE: [{ tzid: "Europe/Brussels", name: "Romance" }],
  BF: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  BG: [{ tzid: "Europe/Sofia", name: "FLE" }],
  BA: [{ tzid: "Europe/Belgrade", name: "Central Europe" }],
  BB: [{ tzid: "America/Barbados", name: "SA Western" }],
  WF: [{ tzid: "Pacific/Wallis", name: "UTC+12" }],
  BL: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  BM: [{ tzid: "Atlantic/Bermuda", name: "Atlantic" }],
  BN: [{ tzid: "Asia/Brunei", name: "Singapore" }],
  BO: [{ tzid: "America/La_Paz", name: "SA Western" }],
  JP: [{ tzid: "Asia/Tokyo", name: "Tokyo" }],
  BI: [{ tzid: "Africa/Maputo", name: "South Africa" }],
  BJ: [{ tzid: "Africa/Lagos", name: "W. Central Africa" }],
  BT: [{ tzid: "Asia/Thimphu", name: "Bangladesh" }],
  JM: [{ tzid: "America/Jamaica", name: "SA Pacific" }],
  JO: [{ tzid: "Asia/Amman", name: "Jordan" }],
  WS: [{ tzid: "Pacific/Apia", name: "Samoa" }],
  BQ: [{ tzid: "America/Curacao", name: "SA Western" }],
  BR: [
    { tzid: "America/Noronha", name: "UTC-02" },
    { tzid: "America/Belem", name: "Belem" },
    { tzid: "America/Fortaleza", name: "Fortaleza" },
    { tzid: "America/Recife", name: "Recife" },
    { tzid: "America/Araguaina", name: "Araguaina" },
    { tzid: "America/Maceio", name: "Maceio" },
    { tzid: "America/Bahia", name: "Bahia" },
    { tzid: "America/Sao_Paulo", name: "Sao Paulo" },
    { tzid: "America/Campo_Grande", name: "Campo Grande" },
    { tzid: "America/Cuiaba", name: "Cuiaba" },
    { tzid: "America/Porto_Velho", name: "Velho" },
    { tzid: "America/Boa_Vista", name: "Boa Vista" },
    { tzid: "America/Manaus", name: "Manaus" },
    { tzid: "America/Rio_Branco", name: "Rio Branco" },
  ],
  BS: [{ tzid: "America/Nassau", name: "Eastern" }],
  JE: [{ tzid: "Europe/London", name: "GMT" }],
  BY: [{ tzid: "Europe/Minsk", name: "Belarus" }],
  BZ: [{ tzid: "America/Belize", name: "Central America" }],
  RU: [
    { tzid: "Europe/Kaliningrad", name: "Kaliningrad" },
    { tzid: "Europe/Moscow", name: "Russian" },
    { tzid: "Europe/Simferopol", name: "Russian" },
    { tzid: "Europe/Volgograd", name: "Russian" },
    { tzid: "Europe/Samara", name: "Russia Zone 3" },
    { tzid: "Asia/Yekaterinburg", name: "Ekaterinburg" },
    { tzid: "Asia/Omsk", name: "N. Central Asia" },
    { tzid: "Asia/Novosibirsk", name: "N. Central Asia" },
    { tzid: "Asia/Novokuznetsk", name: "North Asia" },
    { tzid: "Asia/Krasnoyarsk", name: "North Asia" },
    { tzid: "Asia/Irkutsk", name: "North Asia East" },
    { tzid: "Asia/Chita", name: "Yakutsk" },
    { tzid: "Asia/Yakutsk", name: "Yakutsk" },
    { tzid: "Asia/Khandyga", name: "Yakutsk" },
    { tzid: "Asia/Vladivostok", name: "Vladivostok" },
    { tzid: "Asia/Ust-Nera", name: "Vladivostok" },
    { tzid: "Asia/Magadan", name: "Magadan" },
    { tzid: "Asia/Sakhalin", name: "Vladivostok" },
    { tzid: "Asia/Srednekolymsk", name: "Russia Zone 10" },
    { tzid: "Asia/Kamchatka", name: "Russia Zone 11" },
    { tzid: "Asia/Anadyr", name: "Russia Zone 11" },
  ],
  RW: [{ tzid: "Africa/Maputo", name: "South Africa" }],
  RS: [{ tzid: "Europe/Belgrade", name: "Central Europe" }],
  LT: [{ tzid: "Europe/Vilnius", name: "FLE" }],
  RE: [{ tzid: "Indian/Reunion", name: "Mauritius" }],
  LU: [{ tzid: "Europe/Luxembourg", name: "W. Europe" }],
  LR: [{ tzid: "Africa/Monrovia", name: "Greenwich" }],
  RO: [{ tzid: "Europe/Bucharest", name: "GTB" }],
  TK: [{ tzid: "Pacific/Fakaofo", name: "Tonga" }],
  GW: [{ tzid: "Africa/Bissau", name: "Greenwich" }],
  GU: [{ tzid: "Pacific/Guam", name: "West Pacific" }],
  GT: [{ tzid: "America/Guatemala", name: "Central America" }],
  GR: [{ tzid: "Europe/Athens", name: "GTB" }],
  GQ: [{ tzid: "Africa/Lagos", name: "W. Central Africa" }],
  GP: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  BH: [{ tzid: "Asia/Qatar", name: "Arab" }],
  GY: [{ tzid: "America/Guyana", name: "SA Western" }],
  GG: [{ tzid: "Europe/London", name: "GMT" }],
  GF: [{ tzid: "America/Cayenne", name: "SA Eastern" }],
  GE: [{ tzid: "Asia/Tbilisi", name: "Georgian" }],
  GD: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  GB: [{ tzid: "Europe/London", name: "GMT" }],
  GA: [{ tzid: "Africa/Lagos", name: "W. Central Africa" }],
  GN: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  GM: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  GL: [
    { tzid: "America/Godthab", name: "Greenland" },
    { tzid: "America/Danmarkshavn", name: "UTC" },
    { tzid: "America/Scoresbysund", name: "Azores" },
    { tzid: "America/Thule", name: "Atlantic" },
  ],
  GI: [{ tzid: "Europe/Gibraltar", name: "W. Europe" }],
  GH: [{ tzid: "Africa/Accra", name: "Greenwich" }],
  OM: [{ tzid: "Asia/Dubai", name: "Arabian" }],
  BW: [{ tzid: "Africa/Maputo", name: "South Africa" }],
  HR: [{ tzid: "Europe/Belgrade", name: "Central Europe" }],
  HT: [{ tzid: "America/Port-au-Prince", name: "Eastern" }],
  HU: [{ tzid: "Europe/Budapest", name: "Central Europe" }],
  HK: [{ tzid: "Asia/Hong_Kong", name: "China" }],
  HN: [{ tzid: "America/Tegucigalpa", name: "Central America" }],
  LV: [{ tzid: "Europe/Riga", name: "FLE" }],
  AD: [{ tzid: "Europe/Andorra", name: "W. Europe" }],
  PR: [{ tzid: "America/Puerto_Rico", name: "SA Western" }],
  PS: [{ tzid: "Asia/Gaza", name: "Palestine Time" }],
  PW: [{ tzid: "Pacific/Palau", name: "Tokyo" }],
  PT: [
    { tzid: "Europe/Lisbon", name: "Lisbon" },
    { tzid: "Atlantic/Azores", name: "Azores" },
  ],
  KR: [{ tzid: "Asia/Seoul", name: "Korea" }],
  PY: [{ tzid: "America/Asuncion", name: "Paraguay" }],
  IQ: [{ tzid: "Asia/Baghdad", name: "Arabic" }],
  PA: [{ tzid: "America/Panama", name: "SA Pacific" }],
  PF: [{ tzid: "Pacific/Tahiti", name: "Hawaiian" }],
  PG: [
    { tzid: "Pacific/Port_Moresby", name: "West Pacific" },
    { tzid: "Pacific/Bougainville", name: "Central Pacific" },
  ],
  PE: [{ tzid: "America/Lima", name: "SA Pacific" }],
  PK: [{ tzid: "Asia/Karachi", name: "Pakistan" }],
  PH: [{ tzid: "Asia/Manila", name: "Singapore" }],
  PN: [{ tzid: "Pacific/Pitcairn", name: "Pitcairn Time" }],
  PL: [{ tzid: "Europe/Warsaw", name: "Central European" }],
  PM: [{ tzid: "America/Miquelon", name: "Miquelon Time" }],
  ZM: [{ tzid: "Africa/Maputo", name: "South Africa" }],
  EH: [{ tzid: "Africa/El_Aaiun", name: "Morocco" }],
  EE: [{ tzid: "Europe/Tallinn", name: "FLE" }],
  EG: [{ tzid: "Africa/Cairo", name: "Egypt" }],
  ZA: [{ tzid: "Africa/Johannesburg", name: "South Africa" }],
  EC: [
    { tzid: "America/Guayaquil", name: "SA Pacific" },
    { tzid: "Pacific/Galapagos", name: "Central America" },
  ],
  AL: [{ tzid: "Europe/Tirane", name: "Central Europe" }],
  AO: [{ tzid: "Africa/Lagos", name: "W. Central Africa" }],
  KZ: [
    { tzid: "Asia/Almaty", name: "Almaty" },
    { tzid: "Asia/Aqtobe", name: "Aqtobe" },
    { tzid: "Asia/Aqtau", name: "Aqtau" },
  ],
  ET: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  SO: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  ZW: [{ tzid: "Africa/Maputo", name: "South Africa" }],
  KY: [{ tzid: "America/Panama", name: "SA Pacific" }],
  ES: [
    { tzid: "Europe/Madrid", name: "Madrid" },
    { tzid: "Africa/Ceuta", name: "Ceuta" },
    { tzid: "Atlantic/Canary", name: "GMT" },
  ],
  ER: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  ME: [{ tzid: "Europe/Belgrade", name: "Central Europe" }],
  MD: [{ tzid: "Europe/Chisinau", name: "E. Europe" }],
  MG: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  MF: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  MA: [{ tzid: "Africa/Casablanca", name: "Morocco" }],
  MC: [{ tzid: "Europe/Monaco", name: "W. Europe" }],
  UZ: [{ tzid: "Asia/Tashkent", name: "Tashkent" }],
  MM: [{ tzid: "Asia/Rangoon", name: "Myanmar" }],
  ML: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  MO: [{ tzid: "Asia/Macau", name: "China" }],
  MN: [
    { tzid: "Asia/Ulaanbaatar", name: "Ulaanbaatar" },
    { tzid: "Asia/Choibalsan", name: "Choibalsan" },
  ],
  MH: [
    { tzid: "Pacific/Majuro", name: "Majuro" },
    { tzid: "Pacific/Kwajalein", name: "Kwajalein" },
  ],
  MK: [{ tzid: "Europe/Belgrade", name: "Central Europe" }],
  UM: [
    { tzid: "Pacific/Pago_Pago", name: "UTC-11" },
    { tzid: "Pacific/Wake", name: "UTC+12" },
    { tzid: "Pacific/Honolulu", name: "Hawaiian" },
  ],
  MT: [{ tzid: "Europe/Malta", name: "W. Europe" }],
  MW: [{ tzid: "Africa/Maputo", name: "South Africa" }],
  MV: [{ tzid: "Indian/Maldives", name: "West Asia" }],
  MQ: [{ tzid: "America/Martinique", name: "SA Western" }],
  MP: [{ tzid: "Pacific/Guam", name: "West Pacific" }],
  MS: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  MR: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  AU: [
    { tzid: "Antarctica/Macquarie", name: "Central Pacific" },
    { tzid: "Australia/Hobart", name: "Eastern - Hobart" },
    { tzid: "Australia/Sydney", name: "Eastern - Sydney" },
    { tzid: "Australia/Brisbane", name: "Eastern - Brisbane" },
    { tzid: "Australia/Adelaide", name: "Central- Adelaide" },
    { tzid: "Australia/Darwin", name: "Central - Darwin" },
    { tzid: "Australia/Perth", name: "Western" },
  ],
  UG: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  MY: [{ tzid: "Asia/Kuala_Lumpur", name: "Kuala Lumpur" }],
  MX: [
    { tzid: "America/Mexico_City", name: "Central - Mexico City" },
    { tzid: "America/Cancun", name: "Eastern" },
    { tzid: "America/Chihuahua", name: "Mountain - Chihuahua" },
    { tzid: "America/Hermosillo", name: "Mountain - Hermosillo" },
    { tzid: "America/Tijuana", name: "Pacific" },
  ],
  VU: [{ tzid: "Pacific/Efate", name: "Central Pacific" }],
  FR: [{ tzid: "Europe/Paris", name: "Romance" }],
  AW: [{ tzid: "America/Curacao", name: "SA Western" }],
  SH: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  AF: [{ tzid: "Asia/Kabul", name: "Afghanistan" }],
  AX: [{ tzid: "Europe/Helsinki", name: "FLE" }],
  FI: [{ tzid: "Europe/Helsinki", name: "FLE" }],
  FJ: [{ tzid: "Pacific/Fiji", name: "Fiji" }],
  FK: [{ tzid: "Atlantic/Stanley", name: "SA Eastern" }],
  FM: [{ tzid: "Pacific/Kosrae", name: "Central Pacific" }],
  FO: [{ tzid: "Atlantic/Faroe", name: "Faroe Time" }],
  NI: [{ tzid: "America/Managua", name: "Central America" }],
  NL: [{ tzid: "Europe/Amsterdam", name: "W. Europe" }],
  NO: [{ tzid: "Europe/Oslo", name: "W. Europe" }],
  NA: [{ tzid: "Africa/Windhoek", name: "Namibia" }],
  NC: [{ tzid: "Pacific/Noumea", name: "Central Pacific" }],
  NE: [{ tzid: "Africa/Lagos", name: "W. Central Africa" }],
  NF: [{ tzid: "Pacific/Norfolk", name: "Central Pacific" }],
  NG: [{ tzid: "Africa/Lagos", name: "W. Central Africa" }],
  NZ: [{ tzid: "Pacific/Auckland", name: "New Zealand" }],
  NP: [{ tzid: "Asia/Kathmandu", name: "Nepal Time" }],
  NR: [{ tzid: "Pacific/Nauru", name: "UTC+12" }],
  NU: [{ tzid: "Pacific/Niue", name: "UTC-11" }],
  CK: [{ tzid: "Pacific/Rarotonga", name: "Hawaiian" }],
  CI: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  CH: [{ tzid: "Europe/Zurich", name: "W. Europe" }],
  CO: [{ tzid: "America/Bogota", name: "SA Pacific" }],
  CN: [
    { tzid: "Asia/Shanghai", name: "China" },
    { tzid: "Asia/Urumqi", name: "Central Asia" },
  ],
  CM: [{ tzid: "Africa/Lagos", name: "W. Central Africa" }],
  CL: [
    { tzid: "America/Santiago", name: "Santiago" },
    { tzid: "Pacific/Easter", name: "Easter Island" },
  ],
  CC: [{ tzid: "Indian/Cocos", name: "Myanmar" }],
  CA: [
    { tzid: "America/St_Johns", name: "Newfoundland" },
    { tzid: "America/Halifax", name: "Atlantic - Halifax" },
    { tzid: "America/Toronto", name: "Eastern - Toronto" },
    { tzid: "America/Iqaluit", name: "Eastern - Iqaluit" },
    { tzid: "America/Winnipeg", name: "Central - Winnipeg" },
    { tzid: "America/Regina", name: "Central - Regina" },
    { tzid: "America/Edmonton", name: "Mountain - Edmonton" },
    { tzid: "America/Yellowknife", name: "Mountain - Yellowknife" },
    { tzid: "America/Dawson_Creek", name: "Dawson Creek" },
    { tzid: "America/Vancouver", name: "Pacific - Vancouver" },
    { tzid: "America/Whitehorse", name: "Pacific - Whitehorse" },
  ],
  CG: [{ tzid: "Africa/Lagos", name: "W. Central Africa" }],
  CF: [{ tzid: "Africa/Lagos", name: "W. Central Africa" }],
  CD: [
    { tzid: "Africa/Maputo", name: "South Africa" },
    { tzid: "Africa/Lagos", name: "W. Central Africa" },
  ],
  CZ: [{ tzid: "Europe/Prague", name: "Central Europe" }],
  CY: [{ tzid: "Asia/Nicosia", name: "GTB" }],
  CX: [{ tzid: "Indian/Christmas", name: "SE Asia" }],
  CR: [{ tzid: "America/Costa_Rica", name: "Central America" }],
  CW: [{ tzid: "America/Curacao", name: "SA Western" }],
  CV: [{ tzid: "Atlantic/Cape_Verde", name: "Cape Verde" }],
  CU: [{ tzid: "America/Havana", name: "Eastern" }],
  SZ: [{ tzid: "Africa/Johannesburg", name: "South Africa" }],
  SY: [{ tzid: "Asia/Damascus", name: "Syria" }],
  SX: [{ tzid: "America/Curacao", name: "SA Western" }],
  KG: [{ tzid: "Asia/Bishkek", name: "Central Asia" }],
  KE: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  SS: [{ tzid: "Africa/Khartoum", name: "E. Africa" }],
  SR: [{ tzid: "America/Paramaribo", name: "SA Eastern" }],
  KI: [
    { tzid: "Pacific/Tarawa", name: "UTC+12" },
    { tzid: "Pacific/Enderbury", name: "Tonga" },
    { tzid: "Pacific/Kiritimati", name: "Line Islands" },
  ],
  KH: [{ tzid: "Asia/Bangkok", name: "SE Asia" }],
  SV: [{ tzid: "America/El_Salvador", name: "Central America" }],
  KM: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  ST: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  SK: [{ tzid: "Europe/Prague", name: "Central Europe" }],
  SJ: [{ tzid: "Europe/Oslo", name: "W. Europe" }],
  SI: [{ tzid: "Europe/Belgrade", name: "Central Europe" }],
  KP: [{ tzid: "Asia/Pyongyang", name: "North Korea" }],
  KW: [{ tzid: "Asia/Riyadh", name: "Arab" }],
  SN: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  SM: [{ tzid: "Europe/Rome", name: "W. Europe" }],
  SL: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  SC: [{ tzid: "Indian/Mahe", name: "Mauritius" }],
  SB: [{ tzid: "Pacific/Guadalcanal", name: "Central Pacific" }],
  SA: [{ tzid: "Asia/Riyadh", name: "Arab" }],
  SG: [{ tzid: "Asia/Singapore", name: "Singapore" }],
  SE: [{ tzid: "Europe/Stockholm", name: "W. Europe" }],
  SD: [{ tzid: "Africa/Khartoum", name: "E. Africa" }],
  DO: [{ tzid: "America/Santo_Domingo", name: "SA Western" }],
  DM: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  DJ: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  DK: [{ tzid: "Europe/Copenhagen", name: "Romance" }],
  DE: [{ tzid: "Europe/Berlin", name: "Berlin" }],
  YE: [{ tzid: "Asia/Riyadh", name: "Arab" }],
  AT: [{ tzid: "Europe/Vienna", name: "W. Europe" }],
  DZ: [{ tzid: "Africa/Algiers", name: "W. Central Africa" }],
  US: [
    { tzid: "America/New_York", name: "Eastern" },
    { tzid: "America/Chicago", name: "Central" },
    { tzid: "America/Denver", name: "Mountain" },
    { tzid: "America/Phoenix", name: "Mountain - AZ" },
    { tzid: "America/Los_Angeles", name: "Pacific" },
    { tzid: "America/Anchorage", name: "Alaskan" },
    { tzid: "Pacific/Honolulu", name: "Hawaiian" },
  ],
  UY: [{ tzid: "America/Montevideo", name: "Montevideo" }],
  YT: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  MU: [{ tzid: "Indian/Mauritius", name: "Mauritius" }],
  KN: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  LB: [{ tzid: "Asia/Beirut", name: "Middle East" }],
  LC: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  LA: [{ tzid: "Asia/Bangkok", name: "SE Asia" }],
  TV: [{ tzid: "Pacific/Funafuti", name: "UTC+12" }],
  TW: [{ tzid: "Asia/Taipei", name: "Taipei" }],
  TT: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  TR: [{ tzid: "Europe/Istanbul", name: "Turkey" }],
  LK: [{ tzid: "Asia/Colombo", name: "Sri Lanka" }],
  LI: [{ tzid: "Europe/Zurich", name: "W. Europe" }],
  TN: [{ tzid: "Africa/Tunis", name: "W. Central Africa" }],
  TO: [{ tzid: "Pacific/Tongatapu", name: "Tonga" }],
  TL: [{ tzid: "Asia/Dili", name: "Tokyo" }],
  TM: [{ tzid: "Asia/Ashgabat", name: "West Asia" }],
  TJ: [{ tzid: "Asia/Dushanbe", name: "West Asia" }],
  LS: [{ tzid: "Africa/Johannesburg", name: "South Africa" }],
  TH: [{ tzid: "Asia/Bangkok", name: "SE Asia" }],
  TG: [{ tzid: "Africa/Abidjan", name: "Greenwich" }],
  TD: [{ tzid: "Africa/Ndjamena", name: "W. Central Africa" }],
  TC: [{ tzid: "America/Grand_Turk", name: "SA Western" }],
  LY: [{ tzid: "Africa/Tripoli", name: "Libya" }],
  VA: [{ tzid: "Europe/Rome", name: "W. Europe" }],
  VC: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  AE: [{ tzid: "Asia/Dubai", name: "Arabian" }],
  VE: [{ tzid: "America/Caracas", name: "Venezuela" }],
  AG: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  VG: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  AI: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  VI: [{ tzid: "America/Port_of_Spain", name: "SA Western" }],
  IS: [{ tzid: "Atlantic/Reykjavik", name: "Greenwich" }],
  IR: [{ tzid: "Asia/Tehran", name: "Iran" }],
  AM: [{ tzid: "Asia/Yerevan", name: "Caucasus" }],
  IT: [{ tzid: "Europe/Rome", name: "W. Europe" }],
  VN: [{ tzid: "Asia/Bangkok", name: "SE Asia" }],
  AS: [{ tzid: "Pacific/Pago_Pago", name: "UTC-11" }],
  AR: [{ tzid: "America/Argentina/Salta", name: "Argentina" }],
  IM: [{ tzid: "Europe/London", name: "GMT" }],
  IL: [{ tzid: "Asia/Jerusalem", name: "Israel" }],
  IO: [{ tzid: "Indian/Chagos", name: "Central Asia" }],
  IN: [{ tzid: "Asia/Kolkata", name: "India" }],
  TZ: [{ tzid: "Africa/Nairobi", name: "E. Africa" }],
  AZ: [{ tzid: "Asia/Baku", name: "Azerbaijan" }],
  IE: [{ tzid: "Europe/Dublin", name: "GMT" }],
  ID: [
    { tzid: "Asia/Jakarta", name: "Jakarta" },
    { tzid: "Asia/Makassar", name: "Singapore" },
    { tzid: "Asia/Jayapura", name: "Tokyo" },
  ],
  UA: [{ tzid: "Europe/Kiev", name: "Kiev" }],
  QA: [{ tzid: "Asia/Qatar", name: "Arab" }],
  MZ: [{ tzid: "Africa/Maputo", name: "South Africa" }],
};
