import "./TrashCard.css";

import { hardDelete, restoreData } from "Config/API/action";
import { useDispatch, useSelector } from "react-redux";

import { IconButton } from "@mui/material";
import React from "react";
import bFolder from "Media/icons/Folder-Close.png";
import cadIcon from "Media/icons/cad.png";
import excelIcon from "Media/icons/simple-microsoftexcel.svg";
import { globalOptionButtonAction } from "Redux/action/variable.action";
import imageIcon from "Media/icons/simple-image.png";
import officeIcon from "Media/icons/simple-microsoftword.svg";
import options from "Media/icons/feather-more-vertical.svg";
import pdfIcon from "Media/icons/simple-pdf.svg";
import pptDocument from "Media/icons/pptx.png";
import { toast } from "react-toastify";
import unknownFile from "Media/icons/unknownFile.png";
import videoFile from "Media/icons/videoFile.png";
import webLinkImage from "Media/icons/webLinkImage.png";

const DropDownMenu = ({ getTrashDataHandler, id, keyValue, folderScope }) => {
  const token = localStorage.getItem("token");

  return (
    <>
      <div className="dropdown-menu">
        <ul>
          <li
            onClick={() => {
              const restore_status = restoreData(token, id, folderScope);
              restore_status
                .then((res) => {
                  if (res?.status) {
                    toast(res?.message, {
                      type: "success",
                    });
                    getTrashDataHandler();
                  } else {
                    toast(res?.message, {
                      type: "error",
                    });
                  }
                })
                .catch((e) => {
                  toast("Failed! Try again later", {
                    type: "error",
                  });
                });
            }}
          >
            Restore
          </li>
          <li
            onClick={() => {
              const delete_status = hardDelete(
                token,
                id,
                keyValue,
                folderScope
              );
              delete_status
                .then((res) => {
                  if (res?.status) {
                    toast(res?.message, {
                      type: "success",
                    });
                    getTrashDataHandler();
                  } else {
                    toast(res?.message, {
                      type: "error",
                    });
                  }
                })
                .catch((e) => {
                  toast("Failed! Try again later", {
                    type: "error",
                  });
                });
            }}
          >
            Delete
          </li>
        </ul>
      </div>
    </>
  );
};

const TrashCard = ({ getTrashDataHandler, files }) => {
  const isOptions = useSelector((state) => state.globalOptionButtonReducer);
  const role = useSelector((state) => state.authenticationReducer?.role);
  const dispatch = useDispatch();

  if (
    files === undefined ||
    files === null ||
    files === "" ||
    files?.length === 0
  ) {
    files = {};
  }

  if (!files) return <p>No Data Found.</p>;

  if (files?.type?.toString() !== "folder") {
    let icon = "";
    if (files?.type === "weblink") {
      icon = webLinkImage;
    } else {
      const fileType = files?.path?.split(".").at(-1)?.toLowerCase();

      switch (fileType) {
        case "png":
        case "jpg":
        case "jpeg":
          icon = imageIcon;
          break;

        case "pdf":
          icon = pdfIcon;
          break;

        case "docx":
        case "doc":
          icon = officeIcon;
          break;

        case "xlsx":
        case "xls":
        case "csv":
          icon = excelIcon;
          break;

        case "ppt":
        case "pptx":
          icon = pptDocument;
          break;

        case "mp4":
        case "mkv":
        case "webm":
          icon = videoFile;
          break;

        case "stp":
        case "step":
        case "dxf":
        case "stl":
          icon = cadIcon;
          break;

        default:
          icon = unknownFile;
      }
    }

    return (
      <>
        <div className="trash-file-container">
          {role?.toString() === "admin" ||
          role?.toString() === "super_admin" ? (
            <IconButton
              className="trash-file-options"
              onClick={() => {
                dispatch(globalOptionButtonAction(files.unique_id));
                if (isOptions?.toString() === files.unique_id?.toString()) {
                  dispatch(globalOptionButtonAction(""));
                }
              }}
            >
              <img src={options} alt="Menu options" />
              {isOptions?.toString() === files.unique_id?.toString() ? (
                <DropDownMenu
                  getTrashDataHandler={getTrashDataHandler}
                  id={files?.unique_id}
                  keyValue={files?.key}
                  folderScope={files?.scope}
                />
              ) : null}
            </IconButton>
          ) : null}

          <div>
            <div className="trash-file-icon">
              <img src={icon} alt="File Icon" />
            </div>
            <div className="trash-file-text">
              <p>{files?.name ? files.name : null}</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {Object.keys(files).length?.toString() !== "0" ? (
        <div className="trash-folder-single">
          {role?.toString() === "admin" ||
          role?.toString() === "super_admin" ? (
            <IconButton
              className="trash-folder-options"
              onClick={() => {
                dispatch(globalOptionButtonAction(files.unique_id));
                if (isOptions?.toString() === files.unique_id?.toString()) {
                  dispatch(globalOptionButtonAction(""));
                }
              }}
            >
              <img src={options} alt="Menu options" />
              {isOptions?.toString() === files.unique_id?.toString() ? (
                <DropDownMenu
                  getTrashDataHandler={getTrashDataHandler}
                  id={files.unique_id}
                  key={files.key}
                  folderScope={files?.scope}
                />
              ) : null}
            </IconButton>
          ) : null}
          <div>
            <img src={bFolder} alt="Folder" />
            <p>{files?.name ? files.name : null}</p>
          </div>
        </div>
      ) : (
        <p>No Data Found.</p>
      )}
    </>
  );
};

export default TrashCard;
