import React from "react";
import { sendSubscriptionRequest } from "Config/API/action";
import { toast } from "react-toastify";

export default function SendRequestpopup({
  selectedOrgId,
  hide,
  getOrgPortfolioData,
  token,
  setLoader,
  resetLoader,
  searchData,
  initialBookmark,
  searchedValue,
}) {
  const handleSendSubscriptionRequest = () => {
    setLoader();
    const response = sendSubscriptionRequest(token, "portfolio", selectedOrgId);
    response.then((res) => {
      resetLoader();
      if (res.status) {
        hide(false);
        if (
          initialBookmark === "All Portfolios" ||
          initialBookmark === "Searched Portfolios"
        )
          searchData(searchedValue);
        else getOrgPortfolioData(selectedOrgId, 0);
        toast(res?.data?.message, {
          type: "success",
        });
      } else
        toast(res?.data?.message, {
          type: "error",
        });
    });
  };

  return (
    <div className="purchase-subscription-parent">
      <div className="purchase-subscription-body">
        <div className="purchase-subscription-close">
          <div onClick={() => hide(false)}>X</div>
        </div>
        <label className="purchase-subscription-heading">
          Subscription Request
        </label>
        <label className="purchase-subscription-description">
          You don't have it's access. Please send request to access it.
        </label>
        <button onClick={() => handleSendSubscriptionRequest()}>
          Send Request
        </button>
      </div>
    </div>
  );
}
