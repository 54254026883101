import React, { useEffect, useState } from "react";
import {
  getNewMsToken,
  getVideoRoomDetails,
  statusOfRoom,
} from "Config/API/get";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderAcceptExternalVideoChat from "Components/Popup/AcceptExternalVideoChat/Container/RenderAcceptExternalVideoChat";
import { storeVideoChatLinkAction } from "Redux/action/get.action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

const VideoChatRedirectUrl = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  let orgId = useSelector((state) => state.renderGeneralInfo?.id);
  let id = useSelector((state) => state.authenticationReducer?.id);
  const usableCredit = useSelector(
    (state) => state.getUsableCreditBalanceReducer
  );

  const [roomDetails, setRoomDetails] = useState({});
  const [showAcceptExternalChatPopup, setShowAcceptExternalChatPopup] =
    useState(false);

  useEffect(() => {
    getRoomDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const joinRoom = (videoResponse) => {
    const role =
      videoResponse?.created_by?.toString() === id?.toString()
        ? "host"
        : "participant";
    const newMsToken = getNewMsToken(token);
    newMsToken.then((response) => {
      if (response?.data?.status) {
        const room_status = statusOfRoom(
          token,
          response?.data?.msToken,
          searchParams.get("roomId")
        );
        room_status.then((res) => {
          resetLoader();
          if (res?.status) {
            if (res?.data.enabled) {
              navigate("/video-chat");
              dispatch(
                storeVideoChatLinkAction({
                  token: response?.data?.msToken,
                  id: searchParams.get("roomId"),
                  name: videoResponse?.name,
                  role,
                  from: "appointment",
                })
              );
            } else {
              toast(res?.message, {
                type: "info",
              });
              navigate("/appointment");
            }
          } else {
            toast(res?.message, {
              type: "error",
            });
            navigate("/appointment");
          }
        });
      } else {
        toast(response?.message, {
          type: "error",
        });
        navigate("/appointment");
      }
    });
  };

  const getRoomDetails = () => {
    setLoader();
    const videoDetailsResponse = getVideoRoomDetails(
      token,
      searchParams.get("roomId")
    );
    videoDetailsResponse.then((videoResponse) => {
      if (videoResponse?.data?.status) {
        if (videoResponse?.data?.message === "Room is Inactive") {
          toast(videoResponse?.data?.message, { type: "info" });
          return navigate("/appointment");
        }
        if (
          orgId?.toString() ===
            videoResponse?.data?.data?.conn_id?.toString() &&
          videoResponse?.data?.data?.is_billable === "true"
        ) {
          if (usableCredit < +videoResponse?.data?.data?.billing_rate) {
            navigate("/appointment");
            return toast("You do not have enough Credit to Join the Call", {
              type: "info",
            });
          }
          resetLoader();
          setShowAcceptExternalChatPopup(true);
          return setRoomDetails(videoResponse?.data?.data);
        }
        joinRoom(videoResponse?.data?.data);
      } else {
        toast(videoResponse?.data?.message, { type: "error" });
        navigate("/appointment");
      }
    });
  };

  const acceptButtonClick = () => {
    setLoader();
    joinRoom(roomDetails);
    setShowAcceptExternalChatPopup(false);
  };

  return (
    <>
      <div className="appointment-parent">
        <div className="appointment-header">
          <Headers />
        </div>
        <div className="appointment-navigation">
          <Navigation />
        </div>
      </div>
      {loader}
      <RenderAcceptExternalVideoChat
        popup={showAcceptExternalChatPopup}
        hide={setShowAcceptExternalChatPopup}
        accept={acceptButtonClick}
        rate={roomDetails?.billing_rate}
      />
    </>
  );
};

export default VideoChatRedirectUrl;
