import httpRequest from "Config/httpsClient";
import moment from "moment-timezone";

// login api
export const login = async (email, password) => {
  try {
    let tmz = moment.tz.guess();
    const promise = await httpRequest.post("login", {
      email: email,
      password: password,
      custom_timezone: tmz,
    });
    return await promise.data;
  } catch (e) {}
};

// registration api
export const register = async (
  organization_name,
  organization_website,
  organization_phone_number,
  time_zone,
  username,
  firstname,
  lastname,
  email,
  phone,
  office_phone,
  extension,
  password
) => {
  try {
    let tmz = moment.tz.guess();
    const promise = await httpRequest.post("admin-registration", {
      organization_name,
      organization_website,
      organization_phone_number,
      time_zone,
      username,
      firstname,
      lastname,
      email,
      phone,
      office_phone,
      extension,
      password,
      custom_timezone: tmz,
    });

    return await promise.data;
  } catch (e) {}
};

// forget password
export const forgetPassword = async (email) => {
  try {
    const promise = await httpRequest.post("forgot-password", { email: email });
    return await promise.data;
  } catch (e) {}
};

// reset password
export const resetPassword = async (password, verifyPassword, token) => {
  try {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const promise = await httpRequest.post(
      "reset-password",
      {
        new_password: password,
        verify_password: verifyPassword,
      },
      {
        headers: header,
      }
    );

    return await promise.data;
  } catch (e) {}
};

// verification
export const userVerification = async (token) => {
  try {
    const promise = await httpRequest.post("verification", { token: token });
    return await promise.data;
  } catch (e) {}
};
