import "./DefaultPaymentPopup.css";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeDefaultPaymentMethod } from "Config/API/update";
import creditCard from "Media/icons/creditCardIcon.png";
import dollarIcon from "Media/icons/cards-with-dollar-sign@2x.png";
import { getUserDetails } from "Config/API/get";
import { logging } from "Redux/action/auth.action";
import { toast } from "react-toastify";

const DefaultPaymentPopup = ({ hide, defaultCardDetails }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const defaultPaymentPreference = useSelector(
    (state) => state.authenticationReducer?.defaultPaymentPreference
  );

  const getuserData = () => {
    const apiResponse = getUserDetails(token);
    apiResponse.then((res) => {
      setIsLoading(false);
      if (res?.data?.status) dispatch(logging(res.data.data));
    });
  };

  const handleButtonClick = (value) => {
    if (value === defaultPaymentPreference) return;
    if (defaultCardDetails?.length === 0 && value === "card")
      return toast(
        "You haven't saved any default card yet to select this method",
        { type: "info" }
      );
    setIsLoading(true);
    const response = changeDefaultPaymentMethod(token, value);
    response.then((res) => {
      if (res?.status) {
        getuserData();
        toast(res?.message, { type: "success" });
      } else {
        setIsLoading(false);
        toast(res?.message, { type: "error" });
      }
    });
  };

  return (
    <>
      <div className="default-payment-parent">
        <div className="default-payment-main">
          <div className="default-payment-heading">
            <p>Edit Default Payment Method</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="default-payment-wrapper">
            {!isLoading ? (
              <>
                <div className="default-payment-card">
                  <div>
                    <img src={dollarIcon} alt="dolarIcon" />
                    <label>Site Credits</label>
                  </div>
                  <button
                    onClick={() => handleButtonClick("credit")}
                    style={{
                      backgroundColor:
                        defaultPaymentPreference === "credit"
                          ? "#5ca127"
                          : "#446fa2",
                    }}
                  >
                    {defaultPaymentPreference === "credit"
                      ? "Selected"
                      : "Select"}
                  </button>
                </div>
                <div className="default-payment-card">
                  <div>
                    <img src={creditCard} alt="" />
                    <label>Default Card</label>
                  </div>
                  <button
                    onClick={() => handleButtonClick("card")}
                    style={{
                      backgroundColor:
                        defaultPaymentPreference === "credit"
                          ? "#446fa2"
                          : "#5ca127",
                    }}
                  >
                    {defaultPaymentPreference === "credit"
                      ? "Select"
                      : "Selected"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div
                  className="default-payment-card"
                  style={{ borderColor: "#ececec" }}
                >
                  <div>
                    <div
                      style={{ height: "45px", width: "45px" }}
                      className="animation"
                    />
                    <label
                      style={{ height: "19px", width: "91px" }}
                      className="animation"
                    />
                  </div>
                  <div
                    style={{ height: "28px", width: "81px" }}
                    className="animation"
                  />
                </div>
                <div
                  className="default-payment-card"
                  style={{ borderColor: "#ececec" }}
                >
                  <div>
                    <div
                      style={{ height: "45px", width: "45px" }}
                      className="animation"
                    />
                    <label
                      style={{ height: "19px", width: "102px" }}
                      className="animation"
                    />
                  </div>
                  <div
                    style={{ height: "28px", width: "81px" }}
                    className="animation"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultPaymentPopup;
