import React from "react";
import RequestTypePopup from "../PureComponents/RequestTypePopup";

const RenderRequestTypePopup = ({ message, option1, option2, funct, hide }) => {
  return (
    <>
      <RequestTypePopup
        message={message}
        option1={option1}
        option2={option2}
        funct={funct}
        hide={hide}
      />
    </>
  );
};

export default RenderRequestTypePopup;
