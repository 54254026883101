import "./MoneyCreditManagementPageAdmin.css";

import React, { useState } from "react";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderInformationPopup from "Components/Popup/InformationPopup/PureComponents/InformationPopup";
import dollarIcon from "Media/icons/cards-with-dollar-sign@2x.png";
import moneyIcon from "Media/icons/money@2x.png";
import renewIcon from "Media/icons/renew@2x.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
const MoneyCreditManagementPageAdmin = () => {
  const navigate = useNavigate();
  const credit = useSelector((state) => state.getStripeCreditBalanceReducer);
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabsText, setTabsText] = useState("");
  return (
    <>
      <div className="mc-management-admin-main">
        <div className="mc-management-admin-header">
          <Headers />
        </div>

        <div className="mc-management-admin-navigation">
          <Navigation />
        </div>

        <div className="mc-management-admin-wrapper">
          <div className="mc-management-admin-heading">
            Credit/Money Management Page
          </div>
          <div className="mc-management-admin-upper-tile-container">
            <div
              className="mc-management-admin-tile"
              style={{ height: "110px", position: "relative" }}
            >
              <img
                src={dollarIcon}
                alt="Wallet"
                style={{ width: "65px", height: "65px" }}
              />
              <span>
                <span>
                  ALL CREDITS <b> {credit ? credit : 0}</b>
                </span>
                <span>$1 USD = 1 CREDIT</span>
              </span>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("ALL CREDITS");
                }}
              />
            </div>
          </div>
          <div className="mc-management-admin-content">
            <div className="mc-management-admin-tile-header">
              <div
                style={{ position: "relative" }}
                className="mc-management-admin-body"
              >
                <div
                  className="mc-management-admin-tile"
                  style={{ height: "155px", width: "100%" }}
                  onClick={() => navigate("/transfer-and-banking")}
                >
                  <img
                    src={moneyIcon}
                    alt="Wallet"
                    style={{ width: "120px", height: "120px" }}
                  />

                  <span>Transfer & Banking</span>
                </div>
                <QuestionMarkIcon
                  className="moneyCreditManagement-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setTabsText("Transfer & Banking");
                  }}
                />
              </div>
            </div>
            <div className="mc-management-admin-tile-header">
              <div
                style={{ position: "relative" }}
                className="mc-management-admin-body"
              >
                <div
                  className="mc-management-admin-tile"
                  style={{ height: "155px", width: "100%" }}
                  onClick={() => navigate("/rates-and-subscription-management")}
                >
                  <img
                    src={renewIcon}
                    alt="Wallet"
                    style={{ width: "120px", height: "120px" }}
                  />
                  <span>Rates & Subscription</span>
                </div>
                <QuestionMarkIcon
                  className="moneyCreditManagement-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setTabsText("Rates & Subscription");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {infoPopup && (
        <RenderInformationPopup message={tabsText} hide={setInfoPopup} />
      )}
    </>
  );
};

export default MoneyCreditManagementPageAdmin;
