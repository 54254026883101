import "./GroupPopup.css";

import React, { useState } from "react";

import { Button } from "@mui/material";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";
import blank from "Media/blank-profile-picture.png";
import { toast } from "react-toastify";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";

const GroupPopup = ({ setGroupPopup, data, verify, update }) => {
  const isStorageLimitReached = useIsStorageLimitReached();
  let list_item = [];
  let remove_item = [];
  let imageData = "";
  let name = "";

  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isStorageLimitReached(imageData?.size))
      return setShowStorageLimitReachedPopup(true);
    if (data[0]?.connected) {
      if (
        list_item.length?.toString() !== "0" ||
        remove_item.length?.toString() !== "0" ||
        name?.toString() !== "" ||
        imageData?.toString() !== ""
      ) {
        update(list_item, remove_item, name, imageData);
        setTimeout(() => {
          setGroupPopup(false);
        }, 1000);
      } else {
        toast("Select at least one person", {
          type: "info",
        });
      }
    } else {
      if (list_item?.length?.toString() !== "0") {
        verify(list_item, imageData, name);
        setTimeout(() => {
          setGroupPopup(false);
        }, 1000);
      } else {
        toast("Select at least one person", {
          type: "info",
        });
      }
    }
  };

  const handleInput = (e) => {
    const { name, checked } = e.target;

    if (data[1]?.disconnected) {
      let tempuser = data[1]?.disconnected?.map((curr) => {
        if (
          curr?.id?.toString() === name?.toString() &&
          checked?.toString() === "true"
        )
          list_item.push(name);
        else if (
          curr?.id?.toString() === name?.toString() &&
          checked?.toString() === "false"
        ) {
          const index = list_item.indexOf(name);
          if (index > -1) {
            // only splice array when item is found
            list_item.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      });
    } else {
      let tempuser = data?.map((curr) => {
        if (
          curr?.id?.toString() === name?.toString() &&
          checked?.toString() === "true"
        )
          list_item.push(name);
        else if (
          curr?.id?.toString() === name?.toString() &&
          checked?.toString() === "false"
        ) {
          const index = list_item.indexOf(name);
          if (index > -1) {
            // only splice array when item is found
            list_item.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      });
    }
  };

  const handleRemoveInput = (e) => {
    const { name, checked } = e.target;
    if (data[0]?.connected) {
      let tempuser = data[0]?.connected?.map((curr) => {
        if (
          curr?.id?.toString() === name?.toString() &&
          checked?.toString() === "true"
        )
          remove_item.push(name);
        else if (
          curr?.id?.toString() === name?.toString() &&
          checked?.toString() === "false"
        ) {
          const index = remove_item.indexOf(name);
          if (index > -1) {
            // only splice array when item is found
            remove_item.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      });
    }
  };

  return (
    <>
      <nav className="group-popup-container">
        <div className="group-popup-popup">
          <div className="group-together">
            <span className="title">
              {data[0]?.connected ? "Update Group:" : "Create a group:"}
            </span>
            <div
              className="group-popup-cross"
              onClick={() => setGroupPopup(false)}
            >
              X
            </div>
          </div>

          {Object.keys(data).length?.toString() !== "0" ? (
            <form
              onSubmit={handleSubmit}
              className="group-popup-organization"
              autoComplete="off"
            >
              {data[0].connected ? (
                <div className="group-popup-name">
                  <label
                    htmlFor="group-name"
                    onChange={(e) => {
                      name = e.target.value;
                    }}
                  >
                    <span> Update Name: </span>
                    <input type="text" id="group-name" />
                  </label>
                </div>
              ) : (
                <div className="group-popup-name">
                  <label
                    htmlFor="group-name"
                    onChange={(e) => {
                      name = e.target.value;
                    }}
                  >
                    <span> Group Name: </span>
                    <input type="text" id="group-name" required />
                  </label>
                </div>
              )}

              {data[0]?.connected ? (
                <label className="group-popup-image" htmlFor="group-image">
                  <span>Update Logo:</span>
                  <input
                    type="file"
                    id="group-image"
                    onChange={(e) => {
                      const size = e.target.files[0]?.size;
                      if (size > 10000000) {
                        toast("File size excceded 10MB", {
                          type: "info",
                        });
                      } else {
                        imageData = e.target.files[0];
                      }
                    }}
                  />
                </label>
              ) : (
                <label className="group-popup-image" htmlFor="group-image">
                  <span>Group Logo:</span>
                  <input
                    type="file"
                    id="group-image"
                    onChange={(e) => {
                      const size = e.target.files[0]?.size;
                      if (size > 10000000) {
                        toast("File size excceded 10MB", {
                          type: "info",
                        });
                      } else {
                        imageData = e.target.files[0];
                      }
                    }}
                  />
                </label>
              )}

              {/* if group is already created then it will go to first condition */}
              {data[0].connected ? (
                <>
                  <h3>Remove Candidates:</h3>
                  <div className="group-popup-connected">
                    {data[0].connected
                      ? data[0].connected?.map((curr, index) => {
                          return (
                            <label
                              className="group-popup-checkbox"
                              key={index}
                              htmlFor={curr?.id}
                              onChange={(e) => {
                                handleRemoveInput(e);
                              }}
                            >
                              <input
                                type="checkbox"
                                name={curr?.id}
                                id={curr?.id}
                                value={curr?.id}
                              />
                              <img
                                src={curr?.image ? curr?.image : blank}
                                alt="Logo"
                              />
                              <span>
                                {curr?.username ? curr?.username : "No name"}
                              </span>
                            </label>
                          );
                        })
                      : null}
                  </div>

                  {/* new members to add */}
                  <h3>Add Candidates:</h3>
                  <div className="group-popup-disconnected">
                    {data[1].disconnected
                      ? data[1].disconnected?.map((curr, index) => {
                          return (
                            <label
                              className="group-popup-checkbox"
                              key={index}
                              htmlFor={curr?.id}
                              onChange={(e) => {
                                handleInput(e);
                              }}
                            >
                              <input
                                type="checkbox"
                                name={curr?.id}
                                id={curr?.id}
                                value={curr?.id}
                              />
                              <img
                                src={curr?.image ? curr?.image : blank}
                                alt="Logo"
                              />
                              <span>
                                {curr?.username ? curr?.username : "No name"}
                              </span>
                            </label>
                          );
                        })
                      : null}
                  </div>
                </>
              ) : (
                <>
                  <div className="group-popup-list">
                    {data?.map((curr, index) => {
                      return (
                        <label
                          className="group-popup-checkbox"
                          key={index}
                          htmlFor={curr?.id}
                          onChange={(e) => {
                            handleInput(e);
                          }}
                        >
                          <input
                            type="checkbox"
                            name={curr?.id}
                            id={curr?.id}
                            value={curr?.id}
                          />
                          <img
                            src={curr?.image ? curr?.image : blank}
                            alt="Logo"
                          />
                          <span>{curr?.name ? curr?.name : "No name"}</span>
                        </label>
                      );
                    })}
                  </div>
                </>
              )}

              <Button variant="contained" type="submit">
                Submit
              </Button>
            </form>
          ) : null}
        </div>
      </nav>
      <div style={{ position: "absolute", left: 0 }}>
        {showStorageLimitReachedPopup && (
          <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
        )}
      </div>
    </>
  );
};

export default GroupPopup;
