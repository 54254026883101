import LogoPopup from "../PureComponents/LogoPopup";
import React from "react";

const RenderLogoPopup = ({ popup, hide }) => {
  return (
    <>
      <LogoPopup popup={popup} hide={hide} />
    </>
  );
};

export default RenderLogoPopup;
