import React, { useState } from "react";

import PaperClip from "Media/icons/Icon open-paperclip.svg";
import SendIcon from "Media/icons/Iconly-Bold-Send.svg";
import excelDocument from "Media/icons/excelDocument.png";
import pdfDocument from "Media/icons/pdfDocument.png";
import pptDocument from "Media/icons/pptx.png";
import { toast } from "react-toastify";
import useIsStorageLimitReached from "Hooks/useIsStorageLimitReached";
import videoFile from "Media/icons/videoFile.png";
import wordDocument from "Media/icons/wordDocument.png";

const ChatInput = ({
  onSend,
  onMediaSend,
  location,
  setShowStorageLimitReachedPopup,
}) => {
  const isStorageLimitReached = useIsStorageLimitReached();

  let [message, setMessage] = useState("");
  const [media, setMedia] = useState("");

  const resetMessage = () => {
    setMessage("");
    document.getElementById("textarea-id").value = "";
  };

  const resetMedia = () => {
    setMedia("");
  };

  const renderFileImage = () => {
    switch (media?.name.split(".").at(-1)) {
      case "doc":
      case "docx":
        return wordDocument;
      case "xls":
      case "xlsx":
      case "csv":
        return excelDocument;
      case "pdf":
        return pdfDocument;
      case "ppt":
      case "pptx":
        return pptDocument;
      case "mp4":
      case "mkv":
      case "webm":
        return videoFile;
      default:
        return URL.createObjectURL(media);
    }
  };
  return (
    <>
      <div className="chat-screen-inputsection">
        <div className="chat-screen-input">
          {media ? (
            <>
              <div className="preview-image">
                <div className="image-cancel" onClick={() => resetMedia()}>
                  X
                </div>
                <img src={renderFileImage()} alt="media" />
              </div>
            </>
          ) : (
            <>
              <textarea
                style={{ background: { SendIcon } }}
                id="textarea-id"
                rows="2"
                // value={message}
                placeholder="Type a message"
                onKeyDown={(e) => {
                  if (e.key?.toString() === "Enter") {
                    setMessage(message + "&*&");
                  }
                }}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
              {location ? null : (
                <label htmlFor="image">
                  <img src={PaperClip} alt="Attachment" />
                  <input
                    type="file"
                    id="image"
                    hidden
                    accept="image/*,.pdf, .docx, .doc, .xlsx, .xls, .csv, .mp4, .ppt, .pptx"
                    onChange={(e) => {
                      const size = e.target.files[0]?.size;
                      if (isStorageLimitReached(size))
                        return setShowStorageLimitReachedPopup(true);
                      if (size > 20000000) {
                        toast("File size exceeded 20MB", { type: "info" });
                      } else {
                        setMedia(e.target.files[0]);
                      }
                    }}
                  />
                </label>
              )}
            </>
          )}
        </div>
      
        <button
          className="chat-screen-send"
          onClick={() => {
            const formattedMessage = message.trim().replace(/\n/g, "&*&");

            if (formattedMessage) {
              onSend(formattedMessage);
              resetMessage();
            }
            if (media) {
              onMediaSend(media);
              resetMedia();
            }
          }}
        >
          <img src={SendIcon} alt="Send" />
        </button>

      </div>
    </>
  );
};

export default ChatInput;
