import "./loginui.css";

import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";

import LockIcon from "Media/icons/awesome-lock.svg";
import MailIcon from "Media/icons/social-email.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import logo from "Media/Amnius_connect_with_text.png";

const LoginUI = ({ verify }) => {
  const [visibility, setVisibility] = useState(true);
  const [eye, setEye] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    verify(email, password);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <div className="login-container">
        <div className="login-wrapper">
          <div className="login-upper">
            <div className="login-logo">
              <img src={logo} alt="Aminus logo" />
            </div>
          </div>

          <div className="login-below">
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="login-email">
                <TextField
                  fullWidth
                  required
                  value={email}
                  label="Email"
                  variant="outlined"
                  type="email"
                  // onFocus={(e) => {
                  //   if (e.target.autocomplete) {
                  //     e.target.autocomplete = "";
                  //   }
                  // }}
                  onChange={handleEmail}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={MailIcon} alt="Email" />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="login-password">
                <TextField
                  fullWidth
                  required
                  value={password}
                  label="Password"
                  type={visibility ? "password" : "text"}
                  onChange={handlePassword}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={LockIcon} alt="Password" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          onClick={() => {
                            setVisibility(!visibility);
                            setEye(!eye);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {eye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="login-btn">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  style={{ background: "#446fa2" }}
                >
                  LOGIN
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginUI;
