import "./Appointment.css";

import React, { useEffect, useRef, useState } from "react";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderAppointmentSchedule from "Components/Appointment/AppointmentSchedule/Container/RenderAppointmentSchedule";
import RenderCreateAppointment from "Components/Appointment/CreateAppointment/Container/RenderCreateAppointment";
import VideoCallingRates from "Components/Popup/VideoCallingRates/VideoCallingRates";
import { getRespectiveRates } from "Config/API/get";

const Appointment = () => {
  const createAppointmentRef = useRef(null);
  const token = localStorage.getItem("token");
  const [showVideoChatRatePopup, setShowVideoChatRatePopup] = useState(false);
  const [videoChatDetails, setVideoChatDetails] = useState({});

  useEffect(() => {
    getVideoChatRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getVideoChatRate = () => {
    const response = getRespectiveRates(token);
    response.then((res) => {
      const videoRate = res.data.data?.filter(
        (item) => item?.rate_type === "video_chat"
      );
      setVideoChatDetails(videoRate);
    });
  };

  return (
    <>
      <div className="appointment-parent">
        <div className="appointment-header">
          <Headers />
        </div>
        <div className="appointment-navigation">
          <Navigation />
        </div>
        <div className="appointment-main">
          <div className="appointment-left">
            <RenderAppointmentSchedule
              createAppointmentRef={createAppointmentRef}
              videoChatDetails={videoChatDetails}
              setVideoChatDetails={setVideoChatDetails}
            />
          </div>
          <div className="appointment-right" ref={createAppointmentRef}>
            <RenderCreateAppointment
              videoChatDetails={videoChatDetails}
              setShowVideoChatRatePopup={setShowVideoChatRatePopup}
            />
          </div>
        </div>
      </div>
      {showVideoChatRatePopup && (
        <VideoCallingRates
          hide={setShowVideoChatRatePopup}
          ratesData={videoChatDetails}
          message="You are Setting Video Chat Rates"
          rateType="video_chat"
          getAllSubscriptionsRates={getVideoChatRate}
        />
      )}
    </>
  );
};

export default Appointment;
