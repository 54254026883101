import "./InfoPopup.css";

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const InfoPopup = ({ header, description, hide }) => {
  return (
    <>
      <div className="info-popup-parent">
        <div className="info-popup-body">
          <div className="info-popup-close">
            <div onClick={() => hide(false)}>X</div>
          </div>
          <label className="info-popup-heading">{header}</label>
          <label className="info-popup-description">{description}</label>
          <button onClick={() => hide(false)}>OK</button>
        </div>
      </div>
    </>
  );
};

export default InfoPopup;
