import {
  APPOINTMENT_EVENT_LIST,
  CREDIT_BALANCE,
  EVENT_LIST,
  GET_FULL_NOTIFICATION,
  GET_NOTIFICATION,
  GET_NOTIFICATION_BADGE,
  GET_TRASH_DATA,
  GROUP_CHAT_DATA,
  INTERNAL_FOLDER_DATA,
  ORGANIZATION_STORAGE_DATA,
  SECRET_KEY,
  SELECT_FILE_DATA,
  SELECT_FOLDER_DATA,
  SELECT_FOLDER_ROOT_DATA,
  STORE_FOLDER_DATA,
  USABLE_CREDIT_BALANCE,
  VIDEO_EVENT_LIST,
} from "Config/ActionType/action.type";

export const getRecentNotificationAction = (res) => {
  return {
    type: GET_NOTIFICATION,
    payload: res,
  };
};

export const getFullNotificationAction = (res) => {
  return {
    type: GET_FULL_NOTIFICATION,
    payload: res,
  };
};

export const getRecentNotificationCountAction = (res) => {
  return {
    type: GET_NOTIFICATION_BADGE,
    payload: res,
  };
};

export const storeFolderDataAction = (res) => {
  return {
    type: STORE_FOLDER_DATA,
    payload: res,
  };
};

export const selectedFileDataAction = (res) => {
  return {
    type: SELECT_FILE_DATA,
    payload: res,
  };
};

export const selectedFolderDataAction = (res) => {
  return {
    type: SELECT_FOLDER_DATA,
    payload: res,
  };
};

export const internalFolderDataAction = (res) => {
  return {
    type: INTERNAL_FOLDER_DATA,
    payload: res,
  };
};

export const selectedFolderRootDataAction = (res) => {
  return {
    type: SELECT_FOLDER_ROOT_DATA,
    payload: res,
  };
};

export const getTrashDataAction = (res) => {
  return {
    type: GET_TRASH_DATA,
    payload: res,
  };
};

export const storeVideoChatLinkAction = (res) => {
  return {
    type: GROUP_CHAT_DATA,
    payload: res,
  };
};

export const getEventListAction = (res) => {
  return {
    type: EVENT_LIST,
    payload: res,
  };
};

export const getEventListAppointmentAction = (res) => {
  return {
    type: APPOINTMENT_EVENT_LIST,
    payload: res,
  };
};

export const getEventListVideoConferenceAction = (res) => {
  return {
    type: VIDEO_EVENT_LIST,
    payload: res,
  };
};

export const setClientSecretKeyAction = (res) => {
  return {
    type: SECRET_KEY,
    payload: res,
  };
};

export const getStripeCreditBalanceAction = (res) => {
  return {
    type: CREDIT_BALANCE,
    payload: res,
  };
};

export const getUsableCreditBalanceAction = (res) => {
  return {
    type: USABLE_CREDIT_BALANCE,
    payload: res,
  };
};

export const getOrganizationStorageDataAction = (res) => {
  return {
    type: ORGANIZATION_STORAGE_DATA,
    payload: res,
  };
};
