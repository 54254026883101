import "./MoneyCreditManagementPage.css";

import React, { useState } from "react";

import AddMoneyPopup from "Components/Popup/AddMoneyPopup/AddMoneyPopup";
import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderInformationPopup from "Components/Popup/InformationPopup/PureComponents/InformationPopup";
import dollarIcon from "Media/icons/cards-with-dollar-sign@2x.png";
import lifeSaverIcon from "Media/icons/lifesaver@2x.png";
import moneyBack from "Media/icons/moneyBack.png";
import moneyIcon from "Media/icons/money@2x.png";
import plusIcon from "Media/icons/plus (1)@2x.png";
import renewIcon from "Media/icons/renew@2x.png";
import { toast } from "react-toastify";
import tradeIcon from "Media/icons/trade@2x.png";
import useIsAmniusOrganization from "Hooks/useIsAmniusOrganization";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import userIcon from "Media/icons/user (1)@2x.png";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
const MoneyCreditManagementPage = () => {
  const navigate = useNavigate();
  const isOrgAmnius = useIsAmniusOrganization();
  const [addCreditPopup, setAddCreditPopup] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabsText, setTabsText] = useState("");
  const credit = useSelector((state) => state.getStripeCreditBalanceReducer);
  const loggedInUserDetails = useSelector(
    (state) => state.authenticationReducer
  );

  return (
    <>
      <div className="mc-management-main">
        <div className="mc-management-header">
          <Headers />
        </div>

        <div className="mc-management-navigation">
          <Navigation />
        </div>

        <div className="mc-management-wrapper">
          <div className="mc-management-heading">
            Credit/Money Management Page
          </div>
          <div className="mc-management-content">
            <div className="mc-management-tile" style={{ height: "110px" }}>
              <img
                src={dollarIcon}
                alt="Wallet"
                style={{ width: "65px", height: "65px" }}
              />
              <span>
                <span>
                  CURRENT CREDITS <b> {credit ? credit : 0}</b>
                </span>
                <span>$1 USD = 1 CREDIT</span>
              </span>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("CURRENT CREDITS");
                }}
              />
            </div>
            {isOrgAmnius ? (
              <div
                className="mc-management-tile"
                style={{ height: "110px" }}
                onClick={() => navigate("/rates-and-subscription-management")}
              >
                <img
                  src={renewIcon}
                  alt="Wallet"
                  style={{ width: "65px", height: "65px" }}
                />
                <span>Rates & Subscription</span>
              </div>
            ) : (
              <div
                className="mc-management-tile"
                style={{
                  height: "110px",
                  border: "unset",
                  boxShadow: "unset",
                  padding: "unset",
                  background: "unset",
                }}
              >
                <div className="mc-management-tile-internal-wrapper">
                  <div className="mc-management-tile">
                    <div
                      className="moneyCredit-tile"
                      style={{
                        background: "white",
                      }}
                      onClick={() => {
                        if (
                          loggedInUserDetails?.add_credit_rights === "manager"
                        )
                          return setAddCreditPopup(true);
                        toast("You do not have rights to add credit", {
                          type: "info",
                        });
                      }}
                    >
                      <img
                        src={plusIcon}
                        alt="Wallet"
                        style={{ width: "42px", height: "42px" }}
                      />
                      <span style={{ marginTop: "10px" }}>Add Credit</span>
                    </div>
                    <QuestionMarkIcon
                      className="moneyCreditManagement-info"
                      onClick={() => {
                        setInfoPopup(true);
                        setTabsText("Add Credit");
                      }}
                    />
                  </div>
                  <div
                    style={{
                      background: "white",
                    }}
                    onClick={() =>
                      navigate("/rates-and-subscription-management")
                    }
                  >
                    <img
                      src={renewIcon}
                      alt="Wallet"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <span>Rates & Subscription</span>
                  </div>
                  <QuestionMarkIcon
                    className="moneyCreditManagement-info"
                    onClick={() => {
                      setInfoPopup(true);
                      setTabsText("Rates & Subscription");
                    }}
                  />
                </div>
              </div>
            )}
            {!isOrgAmnius && (
              <div className="mc-management-tile">
                <div
                  className="moneyCredit-management-tile"
                  style={{
                    height: "155px",
                  }}
                  onClick={() => navigate("/transfer-and-banking")}
                >
                  <img
                    src={moneyIcon}
                    alt="Wallet"
                    style={{ width: "120px", height: "120px" }}
                  />

                  <span>Transfer & Banking</span>
                </div>
                <QuestionMarkIcon
                  className="moneyCreditManagement-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setTabsText("Transfer & Banking");
                  }}
                />
              </div>
            )}
            <div className="mc-management-tile">
              <div
                className="moneyCredit-management-tile"
                style={{
                  height: "155px",
                }}
                onClick={() =>
                  navigate("/service-ticket-user-management", {
                    state: { tab: "creditHandling" },
                  })
                }
              >
                <img
                  src={userIcon}
                  alt="Wallet"
                  style={{ width: "120px", height: "120px" }}
                />
                <span>User Management</span>
              </div>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("User Management");
                }}
              />
            </div>
            <div className="mc-management-tile">
              <div
                className="moneyCredit-management-tile"
                style={{
                  height: "155px",
                }}
                onClick={() => {
                  if (loggedInUserDetails?.credit_read_rights === "manager")
                    return navigate("/money-credit-transaction-activity");
                  toast(
                    "You do not have rights to view transaction activities",
                    {
                      type: "info",
                    }
                  );
                }}
              >
                <img
                  src={tradeIcon}
                  alt="Wallet"
                  style={{ width: "120px", height: "120px" }}
                />
                <span>Transaction Activity</span>
              </div>
              <QuestionMarkIcon
                className="moneyCreditManagement-info"
                onClick={() => {
                  setInfoPopup(true);
                  setTabsText("Transaction Activity");
                }}
              />
            </div>
            {!isOrgAmnius && (
              <div className="mc-management-tile">
                <div
                  className="moneyCredit-management-tile"
                  style={{
                    height: "155px",
                  }}
                  onClick={() => {
                    navigate("/contact-support");
                  }}
                >
                  <img
                    src={lifeSaverIcon}
                    alt="Wallet"
                    style={{
                      width: "105px",
                      height: "105px",
                      marginRight: "20px",
                    }}
                  />

                  <span>Contact Support</span>
                </div>
                <QuestionMarkIcon
                  className="moneyCreditManagement-info"
                  onClick={() => {
                    setInfoPopup(true);
                    setTabsText("Contact Support");
                  }}
                />
              </div>
            )}
          </div>
          {isOrgAmnius && (
            <div
              className="mc-management-lower-tile"
              onClick={() => navigate("/all-refund-requests")}
            >
              <img
                src={moneyBack}
                alt="Wallet"
                style={{ width: "65px", height: "65px" }}
              />
              <span>Refund Requests</span>
            </div>
          )}
        </div>
      </div>

      {addCreditPopup ? <AddMoneyPopup hide={setAddCreditPopup} /> : null}
      {infoPopup ? (
        <RenderInformationPopup message={tabsText} hide={setInfoPopup} />
      ) : null}
    </>
  );
};

export default MoneyCreditManagementPage;
