import Confirm from "../PureComponents/Confirm";
import React from "react";

const RenderConfirm = ({ message, option1, option2, funct, hide }) => {
  return (
    <>
      <Confirm
        message={message}
        option1={option1}
        option2={option2}
        funct={funct}
        hide={hide}
      />
    </>
  );
};

export default RenderConfirm;
