import "./Folder.css";

import { Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  currentFolderAutomationAction,
  currentVisitOrgAction,
  hashIdAction,
} from "Redux/action/variable.action";
import {
  internalFolderDataAction,
  selectedFileDataAction,
  selectedFolderDataAction,
  selectedFolderRootDataAction,
} from "Redux/action/get.action";
import { useDispatch, useSelector } from "react-redux";

import addButton from "Media/icons/Iconly-Bold-Plus.svg";
import bFolder from "Media/icons/Folder-Close.png";
import demo from "Media/blank-profile-picture.png";
import minusButton from "Media/icons/Iconly-Bold-Minus.svg";
import oFolder from "Media/icons/Folder-Open-removebg.png";
import { renderOrganizationInfo } from "Redux/action/render.action";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const Folder = ({
  files,
  parentData,
  subsequentParent,
  currentFolder,
  setCurrentFolder,
}) => {
  const dispatch = useDispatch();
  let internalFolder = useSelector((state) => state.internalFolderDataReducer);

  // scrolling effect
  const scroll_org = useSelector((state) => state.hashIdReducer);
  const scrolling_ref = useRef(null);
  const scrollToBottom = () => {
    scrolling_ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();

    // disabling it after 4 seconds
    setTimeout(() => {
      dispatch(hashIdAction(""));
    }, [4000]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const navigate = useNavigate();
  const [childCurrentFolder, setChildCurrentFolder] = useState(false);

  // this state is for automated folder opening
  const folderPathFromUrl = useSelector(
    (state) => state.currentFolderAutomationReducer
  );
  // eslint-disable-next-line no-unused-vars
  const [automation_id, setAutomation_id] = useState(
    folderPathFromUrl ? folderPathFromUrl : ""
  );

  if (
    files.items === undefined ||
    files.items === null ||
    files.items === "" ||
    files.items?.length === 0
  ) {
    files.items = {};
  }

  /**
   * We are storing the states in the session
   * so that we get the same directory structure
   * on refresh
   */

  // useEffect(() => {
  //   if (JSON.parse(window.sessionStorage.getItem(`${files.unique_id}`))) {
  //     setCurrentFolder(files.unique_id);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // // saving data into the session
  // useEffect(() => {
  //   window.sessionStorage.setItem(
  //     `${files?.unique_id}`,
  //     currentFolder?.toString() === files.unique_id?.toString()
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentFolder]);

  // useEffect(() => {
  //   // dispatching the right side
  //   if (
  //     currentFolder?.toString() === files?.unique_id?.toString() &&
  //     internalFolder === ""
  //   ) {
  //     dispatch(selectedFileDataAction(files?.items));
  //     dispatch(selectedFolderDataAction(files));

  //     // visit page for right side
  //     if (files?.location?.toString() === "root") {
  //       dispatch(currentVisitOrgAction(files));
  //     }
  //   }

  //   // when user presses the already opened folder
  //   if (currentFolder?.toString() === "false" && internalFolder === "") {
  //     dispatch(selectedFileDataAction(parentData));
  //     dispatch(selectedFolderDataAction(subsequentParent));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentFolder]);

  // folder automation
  const automaticFolderFn = (auto_id) => {
    if (auto_id.includes("/")) {
      if (auto_id.includes(files?.unique_id)) {
        document.getElementById(files?.unique_id)?.click();

        // end point
        let end_point = auto_id.split("/")[auto_id.split("/").length - 1];
        if (end_point?.toString() === "") {
          end_point = auto_id.split("/")[auto_id.split("/").length - 2];
        }
        if (end_point?.toString() === files?.unique_id?.toString()) {
          dispatch(currentFolderAutomationAction(""));
        }
      }
    }
  };

  useEffect(() => {
    if (automation_id && automation_id[0] !== "/") {
      automaticFolderFn(automation_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Returning the files except folder type
  if (files.type !== "folder") return;

  return (
    <>
      <div
        id={files?.unique_id}
        className={`file_explorer_folder_wrapper ${
          files?.conn_id?.toString() === scroll_org?.toString()
            ? "selected_folder"
            : ""
        }`}
        ref={
          files?.conn_id?.toString() === scroll_org?.toString()
            ? scrolling_ref
            : null
        }
        onClick={() => {
          dispatch(internalFolderDataAction(""));
          setCurrentFolder(files?.unique_id);
          dispatch(selectedFileDataAction(files?.items));
          dispatch(selectedFolderDataAction(files));
          if (currentFolder?.toString() === files?.unique_id?.toString()) {
            // when user click already opened Folder
            setCurrentFolder(false);
            if (files?.location === "root") dispatch(currentVisitOrgAction({}));
            dispatch(selectedFileDataAction(parentData));
            return dispatch(selectedFolderDataAction(subsequentParent));
          }
          if (files?.location === "root") {
            dispatch(selectedFolderRootDataAction(files));
            dispatch(currentVisitOrgAction(files));
          }
        }}
      >
        <div>
          {currentFolder?.toString() === files?.unique_id?.toString() ? (
            <img src={oFolder} alt="Folder" />
          ) : (
            <img src={bFolder} alt="Folder" />
          )}
          <div className="alias-container">
            <p>{files?.name}</p>
            {files?.location?.toString() ===
            "root" ? null : files?.alias_name?.toString() === "" ? (
              ""
            ) : (
              <span className="hover-text">{files?.alias_name}</span>
            )}
          </div>
        </div>
        <div>
          {files?.location ? (
            <div className="visit-icon">
              <img
                src={
                  files?.profiledetail?.logo ? files?.profiledetail?.logo : demo
                }
                alt="Logo"
              />
              <Button
                variant="text"
                className="visit-page-cta"
                onClick={() => {
                  navigate("/organization", {
                    state: { data: files?.conn_id },
                  });
                  dispatch(renderOrganizationInfo({}));
                }}
              >
                VISIT PAGE
              </Button>
            </div>
          ) : null}

          <IconButton>
            {currentFolder?.toString() === files?.unique_id?.toString() ? (
              <img src={minusButton} alt="Expanded" />
            ) : (
              <img src={addButton} alt="Unexpanded" />
            )}
          </IconButton>
        </div>
      </div>

      <div className="extra-left-padding" style={{ paddingLeft: "10px" }}>
        {currentFolder?.toString() === files?.unique_id?.toString() &&
        Object.keys(files?.items).length !== 0
          ? files.items.map((curr, index) => (
              <Folder
                files={curr}
                key={index}
                parentData={files?.items}
                subsequentParent={files}
                currentFolder={childCurrentFolder}
                setCurrentFolder={setChildCurrentFolder}
              />
            ))
          : null}
      </div>
    </>
  );
};

export default Folder;
