import "./VideoCallingRates.css";

import React, { useState } from "react";

import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import blank from "Media/blank-profile-picture.png";
import { toast } from "react-toastify";
import { updateRespectiveRate } from "Config/API/update";
import { useSelector } from "react-redux";

const VideoCallingRates = ({
  hide,
  ratesData,
  message,
  rateType,
  getAllSubscriptionsRates,
}) => {
  const token = localStorage.getItem("token");
  const logo = useSelector((state) => state.renderGeneralInfo?.logo);
  const rateArray = Array.from(new Array(40), (val, index) =>
    (Math.round((index / 2 + 0.5) * 100) / 100).toFixed(2)
  );
  const portfolioRateArray = Array.from(new Array(100), (val, index) =>
    (Math.round((index / 2 + 0.5) * 100) / 100).toFixed(2)
  );

  const [rate, setRate] = useState(ratesData[0]?.rate);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 215,
        width: 150,
      },
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let updatedRate;
    const rateString = rate?.toString();
    const hasDecimal = rateString?.includes(".");
    if (hasDecimal) {
      const formattedRate =
        rateString.split(".")[1]?.length > 1 ? rate : `${rate}0`;
      updatedRate = formattedRate;
    } else {
      updatedRate = `${rate}.00`;
    }
    const response = updateRespectiveRate(
      token,
      rateType,
      updatedRate,
      ratesData[0]?.id ? ratesData[0]?.id : ""
    );
    response.then((res) => {
      if (res?.status) {
        getAllSubscriptionsRates();
        toast(res?.message, {
          type: "success",
        });
        hide(false);
      } else {
        toast(res?.message, {
          type: "error",
        });
      }
    });
  };

  return (
    <div className="video-calling-rate-parent">
      <div className="video-calling-rate-main">
        <form
          className="video-calling-rate-form-body"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="video-calling-rate-heading">
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="video-calling-rate-wrapper">
            <div className="video-calling-rate-fields">
              <label style={{ marginTop: "25px" }}>{message}</label>
              <div style={{ marginTop: "10px" }}>
                <img
                  src={logo ? logo : blank}
                  alt="logo"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: 50,
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>

            <div className="video-calling-rate-fields">
              {rateType === "service_ticket" ? (
                <input
                  type="number"
                  placeholder="Enter Rate"
                  min={0}
                  step="0.01"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                />
              ) : (
                <Select
                  required
                  displayEmpty
                  style={{ width: "100%", height: "40px" }}
                  value={rate}
                  placeholder="Rate"
                  onChange={(e) => setRate(e.target.value)}
                  MenuProps={MenuProps}
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Rate</em>
                  </MenuItem>
                  {rateType === "portfolio"
                    ? portfolioRateArray.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })
                    : rateArray.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                </Select>
              )}
            </div>

            <div className="video-calling-rate-fields">
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VideoCallingRates;
