import "./Email.css";

import React, { useState } from "react";

import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const Email = ({ email_data, setEmailPopup, toEmail }) => {
  const myEmail = useSelector((state) => state.authenticationReducer?.email);

  // form fields
  const [from, setFrom] = useState(myEmail ? myEmail : "");
  const [to, setTo] = useState(toEmail ? toEmail : "");
  const [cc, setCc] = useState("");
  const [subject, setSubject] = useState("");
  const [bodydata, setBodyData] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // handling multiple email in to's sections
    let splitTo = [to];
    if (to.includes(",")) {
      splitTo = to.split(",");
    }

    // joining cc's email id with to's email id
    if (cc) {
      if (cc.includes(",")) {
        let ccSplit = cc.split(",");
        for (let i in ccSplit) {
          splitTo.push(ccSplit[i]);
        }
      } else {
        splitTo.push(cc);
      }
    }

    email_data(splitTo, from, subject, bodydata);

    setTimeout(() => {
      setEmailPopup(false);
    }, 1000);
  };
  return (
    <>
      <nav className="email-popup-container">
        <div className="email-popup-popup">
          <div className="email-together">
            <span className="title">Write an Email</span>
            <div
              className="email-popup-cross"
              onClick={() => setEmailPopup(false)}
            >
              X
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="email-popup-organization"
            autoComplete="off"
          >
            <div className="from">
              <label htmlFor="email-from">
                <span>From</span>
                <input
                  type="text"
                  id="email-from"
                  value={from}
                  required
                  onChange={(e) => setFrom(e.target.value)}
                />
              </label>
            </div>
            <div className="to">
              <label htmlFor="email-to">
                <span>To</span>
                <input
                  type="text"
                  id="email-to"
                  value={to}
                  required
                  onChange={(e) => setTo(e.target.value)}
                />
              </label>
            </div>
            <div className="cc">
              <label htmlFor="email-cc">
                <span>Cc</span>
                <input
                  type="text"
                  id="email-cc"
                  placeholder="Ex: abc@gmail.com, 123@gmail.com"
                  onChange={(e) => setCc(e.target.value)}
                />
              </label>
            </div>
            <div className="subject">
              <input
                type="text"
                placeholder="Add a Subject"
                required
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="email-text">
              <textarea
                placeholder="Write Your Email..."
                required
                onChange={(e) => setBodyData(e.target.value)}
              ></textarea>
            </div>
            <Button variant="contained" type="submit">
              Send
            </Button>
          </form>
        </div>
      </nav>
    </>
  );
};

export default Email;
