import AddUser from "../PureComponents/AddUser";
import React from "react";

const RenderAddUser = ({ popup, hide ,getTeamsData}) => {
  return (
    <>
      <AddUser popup={popup} hide={hide} getTeamsData={getTeamsData} />
    </>
  );
};

export default RenderAddUser;
