import { useSelector } from "react-redux";

const useIsMyOrganization = () => {
  const generalInfo = useSelector((state) => state.renderGeneralInfo);
  const loggedInUserOrgId =
    generalInfo?.role === "super_admin" ? generalInfo?.org_id : generalInfo?.id;

  const isMyOrganization = (orgId) => {
    return loggedInUserOrgId?.toString() === orgId?.toString();
  };

  return isMyOrganization;
};

export default useIsMyOrganization;
