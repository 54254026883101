import "./RefundRequestFormSuperAdmin.css";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import PageNotFoundSection from "pages/Authentication/Error/PageNotFoundSection";
import { getRefundIdDetails } from "Config/API/get";
import { raiseRefundRequestAdmin } from "Config/API/action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

const RefundRequestFormSuperAdmin = () => {
  const navigate = useNavigate();
  const { refundId, refundToken } = useParams();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const [refundData, setRefundData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRefundIdData();
    // eslint-disable-next-line
  }, []);

  const getRefundIdData = () => {
    setLoader();
    const response = getRefundIdDetails(token, refundId);
    response.then((res) => {
      resetLoader();
      if (res?.data.status) {
        setRefundData(res?.data.data);
      } else {
        toast("Failed! Try again later", {
          type: "error",
        });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    const refundRequestResponse = raiseRefundRequestAdmin(token, refundId);
    refundRequestResponse.then((res) => {
      setLoading(false);
      if (res?.status) {
        toast(res?.message, { type: "success" });
        navigate("/money-credit-transaction-activity");
      } else
        toast("Failed! Try again later", {
          type: "error",
        });
    });
  };

  return (
    <>
      <div className="refund-request-form-super-admin-main">
        <div className="refund-request-form-super-admin-header">
          <Headers />
        </div>

        <div className="refund-request-form-super-admin-navigation">
          <Navigation />
        </div>
        {refundData.length !== 0 && (
          <>
            {refundToken === refundData?.refundToken ? (
              <div className="refund-request-form-super-admin-wrapper">
                <form
                  className="refund-request-form-super-admin-form-body"
                  onSubmit={handleSubmit}
                  autoComplete="off"
                >
                  <div className="refund-request-form-super-admin-form-heading">
                    <p>Refund Request To Super Admin</p>
                  </div>
                  <div className="refund-request-form-super-admin-form-wrapper">
                    <div className="refund-request-form-super-admin-fields">
                      <label htmlFor="requestorOrg">
                        Requestor Organization<span>*</span>:
                      </label>

                      <input
                        style={{ backgroundColor: "#f1f2f6" }}
                        type="text"
                        id="requestorOrg"
                        value={refundData?.requestorOrg}
                        readOnly
                        required
                      />
                    </div>

                    <div className="refund-request-form-super-admin-fields">
                      <label htmlFor="requesteeOrg">
                        Requestee Organization<span>*</span> :
                      </label>

                      <input
                        style={{ backgroundColor: "#f1f2f6" }}
                        type="text"
                        id="requesteeOrg"
                        value={refundData?.requesteeOrg}
                        readOnly
                        required
                      />
                    </div>

                    <div className="refund-request-form-super-admin-fields">
                      <label htmlFor="transactionId">
                        Transaction ID<span>*</span> :
                      </label>
                      <input
                        style={{ backgroundColor: "#f1f2f6" }}
                        type="text"
                        value={refundData?.transactionId}
                        id="transactionId"
                        readOnly
                        required
                      />
                    </div>

                    <div className="refund-request-form-super-admin-fields">
                      <label htmlFor="credit">
                        Credits<span>*</span> :
                      </label>
                      <input
                        style={{ backgroundColor: "#f1f2f6" }}
                        type="number"
                        id="credits"
                        value={refundData?.credit}
                        readOnly
                        required
                      />
                    </div>

                    <div className="refund-request-form-super-admin-fields">
                      <label htmlFor="reason">
                        Reason For Request<span>*</span> :
                      </label>
                      <input
                        style={{ backgroundColor: "#f1f2f6" }}
                        type="text"
                        id="reason"
                        value={refundData?.reason}
                        readOnly
                        required
                      />
                    </div>

                    <div
                      className="refund-request-form-super-admin-fields"
                      style={{ marginBottom: 5 }}
                    >
                      <button type="submit">Raise Request</button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div className="error-wrapper">
                <PageNotFoundSection />
              </div>
            )}
          </>
        )}
        {loader}
      </div>
    </>
  );
};

export default RefundRequestFormSuperAdmin;
