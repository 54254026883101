import { LOGIN_STATUS, USER_LOGOUT } from "Config/ActionType/action.type";

export const logging = (response) => {
  return {
    type: LOGIN_STATUS,
    payload: response,
  };
};

export const signout = () => {
  return {
    type: USER_LOGOUT,
  };
};
