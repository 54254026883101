import "./index.css";

import store, { persistor } from "./store";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <HMSRoomProvider>
          <App />
        </HMSRoomProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
