import "./Chat.css";

import React, { useState } from "react";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import RenderChatConnection from "Components/Chat/ChatConnection/Container/RenderChatConnection";
import RenderChatOrganization from "Components/Chat/ChatOrganization/Container/RenderChatOrganization";
import RenderChatScreen from "Components/Chat/ChatScreen/Container/RenderChatScreen";
import StorageLimitReachedPopup from "Components/Popup/StorageLimitReachedPopup/StorageLimitReachedPopup";

const Chat = () => {
  const [showStorageLimitReachedPopup, setShowStorageLimitReachedPopup] =
    useState(false);
  return (
    <>
      <div className="chat-parent">
        <div className="chat-header">
          <Headers />
        </div>
        <div className="chat-navigation">
          <Navigation />
        </div>
        <div className="chat-main">
          <div className="chat-left">
            <RenderChatOrganization />
            <RenderChatConnection />
          </div>
          <div className="chat-right">
            <RenderChatScreen
              setShowStorageLimitReachedPopup={setShowStorageLimitReachedPopup}
            />
          </div>
        </div>
      </div>
      {showStorageLimitReachedPopup && (
        <StorageLimitReachedPopup hide={setShowStorageLimitReachedPopup} />
      )}
    </>
  );
};

export default Chat;
