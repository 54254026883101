import MoneyCreditManagementPage from "../PureComponents/MoneyCreditManagementPage";
import React from "react";

const RenderMoneyCreditManagementPage = () => {
  return (
    <>
      <MoneyCreditManagementPage />
    </>
  );
};

export default RenderMoneyCreditManagementPage;
