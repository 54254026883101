import React from "react";
import STPremiumPage from "../PureComponents/STPremiumPage";

const RenderSTPremiumPage = () => {
  return (
    <>
      <STPremiumPage />
    </>
  );
};

export default RenderSTPremiumPage;
