import CreateAccount from "../PureComponents/CreateAccount";
import React from "react";
const RenderCreateAccount = () => {
  return (
    <div>
      <CreateAccount />
    </div>
  );
};

export default RenderCreateAccount;
