import React from "react";
import TimeZone from "../PureComponents/TimeZone";

const RenderTimeZone = ({ getTimezone, codee, zonee }) => {
  return (
    <>
      <TimeZone getTimezone={getTimezone} codee={codee} zonee={zonee} />
    </>
  );
};

export default RenderTimeZone;
