import { Mention, MentionsInput } from "react-mentions";

import React from "react";
import classNames from "./display.module.css";

const MentionDisplayCard = ({ message }) => {
  return (
    <>
      <MentionsInput value={message} classNames={classNames}>
        <Mention
          className={classNames.mentions__mention}
          markup={
            message?.includes("&&*&&")
              ? "@[__display__]&&*&&__id__&&*&&"
              : "@[__display__](__id__)"
          }
        />
      </MentionsInput>
    </>
  );
};

export default MentionDisplayCard;
