import "./EditBankDetails.css";

import React, { useState } from "react";

import { toast } from "react-toastify";
import { updateBankDetails } from "Config/API/update";

const EditBankDetails = ({ hide, bankDetails, getBankingDetails }) => {
  const token = localStorage.getItem("token");

  const [accountHoldersName, setAccountHoldersName] = useState(
    bankDetails[0]?.accountHolderName || ""
  );
  const [bankName, setBankName] = useState(bankDetails[0]?.bankName || "");
  const [accountNumber, setAccountNumber] = useState(
    bankDetails[0]?.accountNumber
  );
  const [routingNumber, setRoutingNumber] = useState(
    bankDetails[0]?.routingNumber || ""
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);

    const updateBankDetailsResponse = updateBankDetails(
      token,
      accountNumber,
      accountHoldersName,
      routingNumber,
      bankDetails[0]?.id ? bankDetails[0]?.id : "",
      bankName
    );

    updateBankDetailsResponse.then((res) => {
      setLoading(false);
      if (res?.status) {
        getBankingDetails();
        toast(res?.message, { type: "success" });
        hide(false);
      } else {
        toast(res?.message, { type: "error" });
      }
    });
  };

  const handleAccountNumberChange = (value) => {
    if (value?.length > 15) return;
    const formattedValue = value?.replace(/\D/g, "");
    setAccountNumber(formattedValue);
  };

  const handleRoutingChange = (value) => {
    const formattedValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    setRoutingNumber(formattedValue);
  };

  return (
    <>
      <div className="edit-bank-details-parent">
        <div className="edit-bank-details-main">
          <form
            className="edit-bank-details-form-body"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <div className="edit-bank-details-heading">
              <p>Edit Bank Details</p>
              <div className="rename-cross" onClick={() => hide(false)}>
                X
              </div>
            </div>
            <div className="edit-bank-details-wrapper">
              <div className="edit-bank-details-fields">
                <label htmlFor="accountHolderName">
                  Account Holders Name<span>*</span>:
                </label>

                <input
                  type="text"
                  id="accountHolderName"
                  value={accountHoldersName}
                  onChange={(e) => setAccountHoldersName(e.target.value)}
                  required
                  placeholder="Enter Account Holders Name"
                />
              </div>

              <div className="edit-bank-details-fields">
                <label htmlFor="bankName">
                  Bank Name<span>*</span>:
                </label>
                <input
                  type="text"
                  value={bankName}
                  id="bankName"
                  onChange={(e) => setBankName(e.target.value)}
                  required
                  placeholder="Enter Bank Name"
                />
              </div>

              <div className="edit-bank-details-fields">
                <label htmlFor="accountNo">
                  Account No<span>*</span>:
                </label>
                <input
                  type="text"
                  value={accountNumber}
                  id="accountNo"
                  onChange={(e) =>
                    handleAccountNumberChange(e.target.value.trim())
                  }
                  required
                  minLength={6}
                  placeholder="Enter Account Number"
                />
              </div>

              <div className="edit-bank-details-fields">
                <label htmlFor="routingNumber">
                  Routing Number<span>*</span>:
                </label>
                <input
                  type="text"
                  value={routingNumber}
                  id="routingNumber"
                  placeholder="Enter Routing Number"
                  onChange={(e) => handleRoutingChange(e.target.value)}
                  required
                />
              </div>

              <div className="edit-bank-details-fields">
                <button type="submit">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditBankDetails;
