/* eslint-disable array-callback-return */

import "./ShareFolderLinkExpiryDayPopup.css";

import React, { useState } from "react";

import { Button } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { downloadFolder } from "Config/API/action";
import { getZip } from "Config/API/get";
import { toast } from "react-toastify";

const ShareFolderLinkExpiryDayPopup = ({
  token,
  hide,
  folderId,
  folderScope,
  setLoader,
  resetLoader,
  setFolderLink,
  setShowFolderLinkPopup,
}) => {
  const [expirydays, setExpiryDays] = useState("");
  const expiryDaysArray = Array.from(new Array(7), (val, index) =>
    Math.round(index + 1)
  );

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 215,
        width: 150,
      },
    },
  };

  const handleCreateLinkClick = () => {
    if (!expirydays) return;
    setLoader();
    const download_status = downloadFolder(token, folderId, folderScope);
    download_status.then((res) => {
      if (res?.status) {
        const zip_status = getZip(
          token,
          folderId,
          folderScope,
          "share",
          expirydays
        );
        zip_status.then((response) => {
          resetLoader();
          if (response?.status) {
            hide(false);
            setFolderLink(response?.results);
            setShowFolderLinkPopup(true);
          } else toast(response?.message, { type: "error" });
        });
      } else {
        toast(res?.message, {
          type: "error",
        });
        resetLoader();
      }
    });
  };

  return (
    <>
      <div className="share-folder-expiry-day-link-parent">
        <div className="share-folder-expiry-day-link-main">
          <div className="share-folder-expiry-day-link-heading">
            <p>Expiry Days</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="share-folder-expiry-day-link-wrapper">
            <Select
              required
              displayEmpty
              style={{ width: "100%", height: "40px" }}
              value={expirydays}
              placeholder="Rate"
              onChange={(e) => setExpiryDays(e.target.value)}
              MenuProps={MenuProps}
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
              }}
            >
              <MenuItem disabled value="">
                <em>Days</em>
              </MenuItem>
              {expiryDaysArray.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
            <Button
              variant="contained"
              style={{
                background: "rgb(92, 161, 39)",
              }}
              onClick={() => handleCreateLinkClick()}
            >
              Create Link
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareFolderLinkExpiryDayPopup;
