import "./ShareFolderPopup.css";

import React, { useState } from "react";

import { Button } from "@mui/material";
import bFolder from "Media/icons/Folder-Close.png";
import { shareExternalFolders } from "Config/API/action";
import { toast } from "react-toastify";
import useFullPageLoader from "Hooks/useFullPageLoader";

/* eslint-disable array-callback-return */

const ShareExternalFolderPopup = ({
  hide,
  selectedFolderId,
  token,
  internalFolder,
}) => {
  const [loader, setLoader, resetLoader] = useFullPageLoader();

  const [selectedFolder, setSelectedFolder] = useState(internalFolder);
  const [selectedFolderData, setSelectedFolderData] = useState(
    Object.entries(internalFolder?.items)?.length !== 0
      ? internalFolder?.items
      : []
  );
  const bookmarkData = selectedFolder?.bookmark?.split(" > ");

  if (
    bookmarkData &&
    bookmarkData[bookmarkData?.length - 1]?.toString() === ""
  ) {
    bookmarkData?.pop();
  }

  const handleBookMarkClick = (array, index, bookmarkName) => {
    array?.map((item) => {
      if (item?.index === index && item?.name === bookmarkName) {
        setSelectedFolder(item);
        setSelectedFolderData(item?.items);
      } else {
        handleBookMarkClick(item?.items, index, bookmarkName);
      }
    });
  };

  const handleMoveHereClick = () => {
    setLoader();
    const share_status = shareExternalFolders(
      token,
      selectedFolderId,
      selectedFolder?.unique_id
    );
    share_status
      .then((res) => {
        resetLoader();
        if (res?.status) {
          toast(res?.message, { type: "success" });
          hide(false);
        } else {
          toast(res?.message, { type: "error" });
        }
      })
      .catch((e) => {
        resetLoader();
        toast("Failed! Try again later!", { type: "error" });
      });
  };

  return (
    <>
      <div className="share-folder-parent">
        <div className="share-folder-main">
          <div className="share-folder-heading">
            <p>Select Location</p>
            <div className="rename-cross" onClick={() => hide(false)}>
              X
            </div>
          </div>
          <div className="share-folder-bookmarkbar">
            {bookmarkData ? (
              <>
                {bookmarkData?.map((curr, index) => {
                  return (
                    <button
                      onClick={() => {
                        if (index === 0) {
                          setSelectedFolder(internalFolder);
                          return setSelectedFolderData(
                            Object.entries(internalFolder?.items)?.length !== 0
                              ? internalFolder?.items
                              : []
                          );
                        }
                        handleBookMarkClick(
                          internalFolder?.items,
                          ++index,
                          curr
                        );
                      }}
                    >
                      <span key={index}> {curr + " >"}</span>
                    </button>
                  );
                })}
              </>
            ) : null}
          </div>
          <div className="share-folder-wrapper">
            {selectedFolderData
              ?.filter((item) => item?.type === "folder")
              ?.map((item) => (
                <button
                  onClick={() => {
                    setSelectedFolder(item);
                    if (Object.entries(item?.items)?.length !== 0) {
                      setSelectedFolderData(item?.items);
                    } else {
                      setSelectedFolderData([]);
                    }
                  }}
                >
                  <img src={bFolder} alt="Folder" />
                  <div className="directory-folder-single-aliasname">
                    <span>{item?.name}</span>
                    <span>{item?.alias_name ? item?.alias_name : null}</span>
                  </div>
                </button>
              ))}
          </div>
        </div>
        <div className="share-folder-button-container">
          {bookmarkData?.length > 0 && (
            <Button
              variant="contained"
              style={{ background: "#5ca127", whiteSpace: "nowrap" }}
              onClick={() => handleMoveHereClick()}
            >
              Share Here
            </Button>
          )}
        </div>
      </div>
      {loader}
    </>
  );
};

export default ShareExternalFolderPopup;
