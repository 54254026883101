import "./AppointmentSchedule.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import React, { useEffect, useState } from "react";
import { getNewMsToken, statusOfRoom } from "Config/API/get";

import { Button } from "@mui/material";
import RenderEditAppointmentPopup from "Components/Popup/EditAppointmentPopup/PureComponents/RenderEditAppointmentPopup";
import SelectedAppointmentEventPopup from "Components/Popup/SelectedAppointmentEventPopup/SelectedAppointmentEventPopup";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { storeVideoChatLinkAction } from "Redux/action/get.action";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useFullPageLoader from "Hooks/useFullPageLoader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AppointmentSchedule = ({
  createAppointmentRef,
  videoChatDetails,
  setVideoChatDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader, resetLoader] = useFullPageLoader();
  const token = localStorage.getItem("token");
  const userId = useSelector((state) => state.authenticationReducer?.id);
  const allEvents = useSelector((state) => state.getEventListReducer);
  const appointmentsEvents = useSelector(
    (state) => state.getEventListAppointmentReducer
  );
  const videoConferenceEvents = useSelector(
    (state) => state.getEventListVideoConferenceReducer
  );
  const [events, setEvents] = useState([]);
  const [selectedTab, setSelectedTab] = useState("all");
  const [selectedEvent, setSelectedEvent] = useState({});
  const [showSelectedEvent, setShowSelectedEvent] = useState(false);
  const [showEditAppointmentPopup, setShowEditAppointmentPopup] =
    useState(false);

  const localizer = momentLocalizer(moment);

  // we are modifing the dates here
  if (events.length.toString() !== "0") {
    for (let i in events) {
      events[i].start = new Date(events[i].start);
      events[i].end = new Date(events[i].end);
    }
  }

  useEffect(() => {
    if (selectedTab === "all") return setEvents(allEvents);
    if (selectedTab === "appointment") return setEvents(appointmentsEvents);
    if (selectedTab === "videoConference")
      return setEvents(videoConferenceEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, allEvents]);

  const joinRoom = (videoRoomDetails) => {
    setLoader();
    const role =
      videoRoomDetails?.created_by === userId?.toString()
        ? "host"
        : "participant";
    const newMsToken = getNewMsToken(token);
    newMsToken.then((response) => {
      if (response?.data?.status) {
        const room_status = statusOfRoom(
          token,
          response?.data?.msToken,
          videoRoomDetails?.roomid
        );
        room_status.then((res) => {
          resetLoader();
          if (res?.status) {
            if (res?.data.enabled) {
              navigate("/video-chat");
              dispatch(
                storeVideoChatLinkAction({
                  token: response?.data?.msToken,
                  id: videoRoomDetails?.roomid,
                  name: videoRoomDetails?.name,
                  role,
                  from: "appointment",
                })
              );
            } else {
              toast(res?.message, {
                type: "info",
              });
            }
          } else {
            toast(res?.message, {
              type: "error",
            });
          }
        });
      } else {
        resetLoader();
        toast(response?.message, {
          type: "error",
        });
      }
    });
  };

  const openEditAppointmentPopup = () => {
    setShowEditAppointmentPopup(true);
  };
  return (
    <>
      <div className="appointment-schedule-container">
        <div
          className="appointment-schedule-upper"
          style={{ paddingBottom: "0px" }}
        >
          <div className="appointment-schedule-head">
            <div className="appointment-schedule-head-top">
              Appointments Schedule
              {isMobile && (
                <Button
                  onClick={() =>
                    createAppointmentRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    })
                  }
                  style={{
                    background: "rgb(92, 161, 39)",
                    color: "white",
                    fontSize: "9px",
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                  }}
                >
                  Create New
                </Button>
              )}
            </div>
            <div className="appointment-schedule-head-lover-button">
              <button
                onClick={() => setSelectedTab("all")}
                className={`${selectedTab === "all" && "activeTab"}`}
              >
                All
              </button>
              <button
                onClick={() => setSelectedTab("appointment")}
                className={`${selectedTab === "appointment" && "activeTab"}`}
              >
                Appointment
              </button>
              <button
                onClick={() => setSelectedTab("videoConference")}
                className={`${
                  selectedTab === "videoConference" && "activeTab"
                }`}
              >
                Video Conference
              </button>
            </div>
          </div>
        </div>
        <div
          className="appointment-schedule-lower"
          style={{ marginTop: "10px" }}
        >
          <Calendar
            popup
            defaultView={isMobile ? "month" : "week"}
            dayLayoutAlgorithm="no-overlap"
            localizer={localizer}
            events={events ? events : []}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "100%", minHeight: 500 }}
            onSelectEvent={(e) => {
              setSelectedEvent(e);
              setShowSelectedEvent(true);
            }}
          />
        </div>
      </div>
      {loader}
      {showSelectedEvent && (
        <SelectedAppointmentEventPopup
          event={selectedEvent}
          joinRoom={joinRoom}
          resetLoader={resetLoader}
          setLoader={setLoader}
          token={token}
          userId={userId}
          hide={setShowSelectedEvent}
          openEditAppointmentPopup={openEditAppointmentPopup}
        />
      )}
      {showEditAppointmentPopup && (
        <RenderEditAppointmentPopup
          hide={setShowEditAppointmentPopup}
          event={selectedEvent}
          videoChatDetails={videoChatDetails}
          setVideoChatDetails={setVideoChatDetails}
        />
      )}
    </>
  );
};

export default AppointmentSchedule;
