import {
  NEW_MSG_ALERT,
  RESET_ALERT,
  SET_ALERT,
} from "Config/ActionType/action.type";

const initial = {
  type: "",
  message: "",
};

export const alertReducer = (state = initial, action) => {
  switch (action?.type) {
    case SET_ALERT:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    case RESET_ALERT:
      return initial;

    default:
      return state;
  }
};

export const newChatAlertReducer = (state = false, action) => {
  switch (action?.type) {
    case NEW_MSG_ALERT:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action?.payload;
      return state;

    default:
      return state;
  }
};
