export const seenOnSend = (ids, send_by) => {
  const indiviual_id = ids?.split("-");
  const seen_status = [];
  for (let i in indiviual_id) {
    if (indiviual_id[i]?.toString() === send_by?.toString()) {
      const obj = { [indiviual_id[i]]: "seen" };
      seen_status.push(obj);
    } else {
      const obj = { [indiviual_id[i]]: "unseen" };
      seen_status.push(obj);
    }
  }

  return seen_status;
};

export const seenOnClick = (seenData, send_by) => {
  for (let i in seenData) {
    if (
      Object.values(seenData[i])?.toString() === "unseen" &&
      Object.keys(seenData[i])?.toString() === send_by?.toString()
    ) {
      seenData[i][Object.keys(seenData[i])] = "seen";
    }
  }

  return seenData;
};
