import "./Dashboard.css";

import Headers from "Components/Header/Headers";
import Navigation from "Components/SideNavBar/Navigation";
import Notification from "Components/Dashboard/NotificationOverview/NotificationOverview";
import ProfileOverview from "Components/Dashboard/ProfileOverview/ProfileOverview";
import React from "react";
import RenderAppointmentOverview from "Components/Appointment/AppointmentOverview/Container/RenderAppointmentOverview";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const role = useSelector((state) => state.authenticationReducer.role);

  return (
    <>
      <div className="dashboard-parent">
        <div className="dashboard-header">
          <Headers />
        </div>
        <div className="dashboard-navigation">
          <Navigation />
        </div>
        <div className="dashboard-main">
          {role?.toString() !== "super_admin" ? (
            <div className="dashboard-left">
              <Notification />
              <RenderAppointmentOverview />
            </div>
          ) : null}

          <div className="dashboard-right"><ProfileOverview /></div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
