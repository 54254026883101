import { RESET_DIALOG, SET_DIALOG } from "Config/ActionType/action.type";

const initial = { type: "", message: "" };

export const dialogReducer = (state = initial, action) => {
  switch (action?.type) {
    case SET_DIALOG:
      if (action.payload === null || action.payload === undefined) {
        action.payload = initial;
      }
      state = action.payload;
      return state;

    case RESET_DIALOG:
      return initial;

    default:
      return state;
  }
};
