import React, { useEffect, useRef, useState } from "react";
import { renderEmployees, renderOrganizations } from "Config/API/get";

import { Button } from "@mui/material";
import blank from "Media/blank-profile-picture.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const OrganizationList = () => {
  const token = localStorage.getItem("token");
  const [orgList, setOrgList] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  const [userList, setUserList] = useState("");
  const dispatch = useDispatch();

  const wrapperRef = useRef(null);
  const userRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useOutsideAlerter(userRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          ref.current.classList.add("hide-dropdown");
        } else {
          if (ref.current !== null) {
            ref.current.classList.remove("hide-dropdown");
          }
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      <div className="organization-wrapper">
        <div className="internal-head">List of Organization: </div>
        <div className="internal-wrapper" ref={wrapperRef}>
          <input
            type="text"
            placeholder="Search Organization..."
            onClick={() => {
              const organization_list = renderOrganizations(token);
              organization_list?.then((res) => {
                if (res?.status) {
                  setOrgList(res?.listOfOrganisation);
                } else {
                  toast(res?.message, {
                    type: "error",
                  });
                }
              });
            }}
            onChange={(e) => {
              const organization_list = renderOrganizations(
                token,
                e.target.value
              );
              organization_list.then((res) => {
                if (res?.status) {
                  setOrgList(res?.listOfOrganisation);
                } else {
                  toast(res?.message, {
                    type: "error",
                  });
                }
              });
            }}
          />

          <div className="organization-list">
            {/*****************search result*********************/}
            {orgList?.length?.toString()?.toString() !== "0" ? (
              !orgList?.message ? (
                orgList?.map((curr, index) => {
                  return (
                    <div className="data-list" key={index}>
                      <div>
                        <img src={curr?.logo ? curr?.logo : blank} alt="Logo" />
                        <span>
                          {curr?.organization_name
                            ? curr?.organization_name
                            : "No Name"}
                        </span>
                      </div>
                      <div>
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => {
                            setOrgList("");
                            setSelectedOrg(curr?.id);
                          }}
                        >
                          Select
                        </Button>
                        <Button
                          type="button"
                          variant="outlined"
                          className="superadmin-delete"
                          onClick={() => {
                            setOrgList("");
                          }}
                        >
                          Deactivate
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="orgListAlert">
                  No Such Connected Organizaton Found
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>

      <div className="user-wrapper">
        <div className="internal-head">List of Employees: </div>
        <div className="internal-wrapper" ref={userRef}>
          <input
            type="text"
            placeholder="Search User..."
            onClick={() => {
              if (selectedOrg?.toString() === "") {
                toast("Select an Organization First!", { type: "info" });
              } else {
                const user_list = renderEmployees(token, selectedOrg);
                user_list.then((res) => {
                  if (res?.status) {
                    setUserList(res?.employeOfOrganization);
                  } else {
                    toast(res?.message, {
                      type: "error",
                    });
                  }
                });
              }
            }}
            onChange={(e) => {
              if (selectedOrg?.toString() === "") {
                toast("Select an Organization First!", { type: "info" });
              } else {
                const user_list = renderEmployees(token, selectedOrg);
                user_list.then((res) => {
                  if (res?.status) {
                    setUserList(res?.employeOfOrganization);
                  } else {
                    toast(res?.message, {
                      type: "error",
                    });
                  }
                });
              }
            }}
          />

          {/****************  User List ***************/}
          <div className="organization-list">
            {userList?.length?.toString() !== "0" ? (
              !userList.message ? (
                userList?.map((curr, index) => {
                  return (
                    <div className="data-list" key={index}>
                      <label htmlFor={curr?.id}>
                        <input
                          type="checkbox"
                          name={curr?.id}
                          id={curr?.id}
                          value={curr?.id}
                        />
                        <div>
                          <img
                            src={curr?.image ? curr?.image : blank}
                            alt="Logo"
                          />
                          <span>
                            {curr?.username ? curr?.username : "No Name"}
                          </span>
                        </div>
                      </label>
                    </div>
                  );
                })
              ) : (
                <div className="orgListAlert">No Such User Found</div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationList;
