import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChatUserSubCard from "./ChatUserSubCard";
import { getUserList } from "Config/API/get";
import { renderRecentChatUserAction } from "Redux/action/render.action";
import { toast } from "react-toastify";

const ChatUserCard = () => {
  const dispatch = useDispatch();
  const user_id_list = useSelector((state) => state.userListReducer);
  const token = localStorage.getItem("token");

  useEffect(() => {
    // calling api which return user list
    if (user_id_list !== "" && user_id_list.length?.toString() !== "0") {
      const user_list_status = getUserList(token, user_id_list);
      user_list_status
        .then((res) => {
          if (res?.status) {
            dispatch(renderRecentChatUserAction(res?.data));
          } else {
            toast(res?.message, {
              type: "error",
            });
          }
        })
        .catch((e) => {
          toast("Failed! Try again later", {
            type: "error",
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id_list]);

  return (
    <>
      <ChatUserSubCard />
    </>
  );
};

export default ChatUserCard;
