import { Button, Divider } from "@mui/material";

import { Link } from "react-router-dom";
import React from "react";
import RenderForgetUI from "pages/Authentication/ForgetAccount/Container/RenderForgetUI";
import RenderLoginUI from "./RenderLoginUI";

const Login = () => {
  const [popup, setPopup] = React.useState(false);
  return (
    <>
      {/* login UI  */}
      <RenderLoginUI />

      {/* forget account  */}
      <div
        className="forget-account-link"
        onClick={() => setPopup(true)}
        style={{ cursor: "pointer" }}
      >
        Forgot Password?
      </div>

      {/* Render popup  */}
      <RenderForgetUI popup={popup} hide={setPopup} />

      <div className="divider">
        <Divider>Or</Divider>
      </div>

      {/* create an account  */}
      <div className="create-account-link">
        <Link to="create-account" style={{ textDecoration: "none" }}>
          <Button variant="outlined" fullWidth>
            Create an Account
          </Button>
        </Link>
      </div>
    </>
  );
};

export default Login;
