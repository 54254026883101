import "./WorkBookFileAgreementPopup.css";

import { useState } from "react";

const WorkBookFileAgreementPopup = ({ hide, data, onAgreeCallback }) => {
  const [value, setValue] = useState(false);

  return (
    <div className="agreement-popup-wrapper">
      <div className="agreement-popup-card">
        <div className="agreement-popup-card-header">
          <button
            onClick={() => {
              hide(false);
              setValue(false);
            }}
          >
            X
          </button>
        </div>
        <div className="agreement-popup-card-body">
          <label>{data?.label}</label>
          <p>{data?.description}</p>
          <div className="agreement-popup-card-actions">
            <input
              value={value}
              type="checkbox"
              onChange={(e) => setValue(e.target.checked)}
            />
            <button onClick={() => onAgreeCallback()} disabled={!value}>
              I Agree
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkBookFileAgreementPopup;
