import Eclipse from "Media/Amnius-Loading-GIF-2.gif";
import React from "react";

const ImageLoader = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "10",
          background: "#f5f5f5",
        }}
      >
        <img
          style={{ width: "400px", height: "400px", objectFit: "contain" }}
          src={Eclipse}
          alt="gif"
        />
      </div>
    </>
  );
};

export default ImageLoader;
