import React from "react";
import UserManagement from "../PureComponents/UserManagement";

const RenderUserManagement = () => {
  return (
    <>
      <UserManagement />
    </>
  );
};

export default RenderUserManagement;
