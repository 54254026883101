import NotificationCard from "../PureComponents/NotificationCard";
import React from "react";

const RenderNotificationCard = () => {
  return (
    <>
      <NotificationCard/>
    </>
  );
};

export default RenderNotificationCard;
